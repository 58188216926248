import React from 'react';
import { Row, Col } from 'reactstrap';

const NoDataFound = (props) => {
    const { title } = props;

    return (
        <Row>
            <Col xs="12" align="center">
                {title}
            </Col>
        </Row>
    );
};

export default NoDataFound;