import pnbLogo from "../../../../assets/img/logos/pnb.svg";
import indiaFirstLogo from "../../../../assets/img/logos/indiaFirst.svg";
import iciciLogo from "../../../../assets/img/logos/ICICI.svg";

export const initialFormState = {
    gender: "mr",
    name: "",
    mobile: "",
    dob: "",
    pincode: "",
    isSmoker: "no",
    occupation: "salaried",
    income: "4_8"
};
export const initialProductState = {
    0: {
        img: pnbLogo,
        title: "Term Life",
        checked: false,
        premium: "400",
        lightColor: "pnbMetLifeColorLight",
        darkColor: "pnbMetLifeColorDark",
        details: {
            row0: "Policy Details",
            row1: "Claim Settlement Ratio",
            row2: "Premium Payment Type",
            row3: "Tax Benefit",
            row4: "Max Coverage Upto",
        },
        riderDetails: {
            row0: "Rider Details",
            row1: "Death Due To Accident",
            row2: "Death due to Natural Causes",
            row3: "Death due to any Diseases",
            row4: "Suicide Covered from Second Year ",
            row5: "Terminal Illness",
            row6: "Critical Illness Benefit Payment",
            row7: "Maturity Benefits",
        }
    },
    58: {
        img: pnbLogo,
        title: "Term Life",
        checked: false,
        premium: "600",
        lightColor: "pnbMet-life-color-light",
        darkColor: "pnbMet-life-color-dark",
        details: {
            row0: " ",
            row1: "96.21%",
            row2: "Regular",
            row3: "Yes",
            row4: "55 Years",
        },
        riderDetails: {
            row0: " ",
            row1: "fa fa-check-circle text-success fnt-18",
            row2: "fa fa-check-circle text-success fnt-18",
            row3: "fa fa-check-circle text-success fnt-18",
            row4: "fa fa-check-circle text-success fnt-18",
            row5: "fa fa-times-circle text-danger fnt-18",
            row6: "fa fa-times-circle text-danger fnt-18",
            row7: "fa fa-times-circle text-danger fnt-18",
        }
    },
    52: {
        img: indiaFirstLogo,
        title: "Term Life",
        checked: false,
        premium: "600",
        lightColor: "india-first-color-light",
        darkColor: "india-first-color-dark",
        details: {
            row0: " ",
            row1: "96.7%",
            row2: "Regular",
            row3: "Yes",
            row4: "80 Years",
        },
        riderDetails: {
            row0: " ",
            row1: "fa fa-check-circle text-success fnt-18",
            row2: "fa fa-check-circle text-success fnt-18",
            row3: "fa fa-check-circle text-success fnt-18",
            row4: "fa fa-check-circle text-success fnt-18",
            row5: "fa fa-times-circle text-danger fnt-18",
            row6: "fa fa-times-circle text-danger fnt-18",
            row7: "fa fa-times-circle text-danger fnt-18",
        }
    },
    48: {
        img: iciciLogo,
        title: "Term Life",
        checked: false,
        premium: "600",
        lightColor: "icici-color-light",
        darkColor: "icici-color-dark",
        details: {
            row0: " ",
            row1: "97.8%",
            row2: "Regular/Limited",
            row3: "Yes",
            row4: "80 Years",
        },
        riderDetails: {
            row0: " ",
            row1: "fa fa-check-circle text-success fnt-18",
            row2: "fa fa-check-circle text-success fnt-18",
            row3: "fa fa-check-circle text-success fnt-18",
            row4: "fa fa-check-circle text-success fnt-18",
            row5: "fa fa-check-circle text-success fnt-18",
            row6: "fa fa-times-circle text-danger fnt-18",
            row7: "fa fa-times-circle text-danger fnt-18",
        }
    },
    // 56: {
    //     img: indiaFirstLogo,
    //     title: "Increasing SA",
    //     checked: true,
    //     premium: "600",
    //     lightColor: "india-first-sa-color-light",
    //     darkColor: "india-first-sa-color-dark",
    //     details: {
    //         row0: " ",
    //         row1: "96.7%",
    //         row2: "Regular/Limited",
    //         row3: "yes",
    //         row4: "99 Years",
    //     },
    //     riderDetails: {
    //         row0: " ",
    //         row1: "fa fa-check-circle text-success fnt-18",
    //         row2: "fa fa-check-circle text-success fnt-18",
    //         row3: "fa fa-check-circle text-success fnt-18",
    //         row4: "fa fa-check-circle text-success fnt-18",
    //         row5: "fa fa-check-circle text-success fnt-18",
    //         row6: "fa fa-times-circle text-danger fnt-18",
    //         row7: "fa fa-times-circle text-danger fnt-18",
    //     }
    // },
    // 57: {
    //     img: indiaFirstLogo,
    //     title: "Return of Premium",
    //     checked: false,
    //     premium: "600",
    //     lightColor: "india-first-rp-color-light",
    //     darkColor: "india-first-rp-color-dark",
    //     details: {
    //         row0: " ",
    //         row1: "96.7%",
    //         row2: "Regular/Limited",
    //         row3: "yes",
    //         row4: "85 Years",
    //     },
    //     riderDetails: {
    //         row0: " ",
    //         row1: "fa fa-check-circle text-success fnt-18",
    //         row2: "fa fa-check-circle text-success fnt-18",
    //         row3: "fa fa-check-circle text-success fnt-18",
    //         row4: "fa fa-check-circle text-success fnt-18",
    //         row5: "fa fa-check-circle text-success fnt-18",
    //         row6: "fa fa-times-circle text-danger fnt-18",
    //         row7: "fa fa-check-circle text-success fnt-18",
    //     }
    // },
    // 59: {
    //     img: indiaFirstLogo,
    //     title: "Accelerated Critical Illness",
    //     checked: false,
    //     premium: "600",
    //     lightColor: "india-first-aci-color-light",
    //     darkColor: "india-first-aci-color-dark",
    //     details: {
    //         row0: " ",
    //         row1: "96.7%",
    //         row2: "Regular/Limited",
    //         row3: "yes",
    //         row4: "80 Years",
    //     },
    //     riderDetails: {
    //         row0: " ",
    //         row1: "fa fa-check-circle text-success fnt-18",
    //         row2: "fa fa-check-circle text-success fnt-18",
    //         row3: "fa fa-check-circle text-success fnt-18",
    //         row4: "fa fa-check-circle text-success fnt-18",
    //         row5: "fa fa-times-circle text-danger fnt-18",
    //         row6: "fa fa-check-circle text-success fnt-18",
    //         row7: "fa fa-times-circle text-danger fnt-18",
    //     }
    // }
};
