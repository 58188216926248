import React, { Component, Fragment } from 'react';
import Select2 from "react-select2-wrapper";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    CardFooter,
    Button,
    Input,
    Modal,
    Form
} from "reactstrap";
import * as actions from "../../../Store/actions";
import { connect } from "react-redux";
import Loader from "../../../components/CustomUi/Loader/Loader";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
// import { IndiaFirstLocation } from "../../../locations/indiaFirstLocations";
import { buildPartnerProductList, buildMultiPartnerProductList, extractProductIdsFromList } from "../../../helpers/partners";
import { MultiSelect } from "react-multi-select-component";
import { Label, Checkbox } from 'semantic-ui-react'

const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const password_regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

class AddPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commission_percentage: 1,
            partner_name: '',
            loaderMsg: 'Wait...',
            partnerUserEmail: '',
            partnerUsername: '',
            view_claim: 1,
            view_commission: 1,
            product_id: [],
            commission_value: 0,
            password: '',
            confirmPassword: '',
            user_check: 0,
            business_category: null,
            businessCategories: [{ id: 'motor', text: 'Motor' }, { id: 'other', text: 'Other' }],
            productsVisible: false
        }
        this.handlePartnerSlug = this.handlePartnerSlug.bind(this);
        this.partnerUsername = this.partnerUsername.bind(this);
        this.handlepartnerUserEmail = this.handlepartnerUserEmail.bind(this);
        this.handleCommission_value = this.handleCommission_value.bind(this);
        // this.selectRef = React.createRef(null)
    }

    componentDidMount = () => {
        this.props.onGetPartnerFilterList()
        this.getProductList()

    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: typeof msg === 'string' ? msg : Object.values(msg).map((data) => { return data[0] }).join(","),
            title: "",
            type: type
        })
    };

    handlePartnerSlug = (event) => {
        this.setState({
            commission_percentage: event.target.value
        });
    }

    handlepartner_name = (event) => {
        this.setState({
            partner_name: event.target.value
        });
    }

    handlepartnerUserEmail = (event) => {
        this.setState({
            partnerUserEmail: event.target.value
        });
    }

    handleCommission_value = (event) => {
        this.setState({
            commission_value: event.target.value
        });
    }

    partnerUsername = (event) => {
        this.setState({
            partnerUsername: event.target.value
        });
    }

    password = (event) => {
        this.setState({
            password: event.target.value
        });
    }

    confirmPassword = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
    }

    handleCreateUser = (event) => {
        this.setState({
            user_check: event.target.value
        });
    }

    submit = (payload) => {
        let isValid = true;
        if (this.state.partner_name === '') {
            this.showMsg("Partner Name Cannot be Empty", "danger")
            isValid = false
        }

        else if (this.state.business_category === '') {
            this.showMsg("Business Category Cannot be Empty", "danger")
            isValid = false
        }

        else if (this.state.product_id === '') {
            this.showMsg("Product Cannot be Empty", "danger")
            isValid = false
        }

        if (this.state.user_check === 1) {
            if (this.state.partnerUserEmail === '') {
                this.showMsg("Email Cannot be Empty", "danger")
                isValid = false
            } else if (!this.state.partnerUserEmail.match(email_regx)) {
                this.showMsg("Please Enter Valid Email", "danger")
                isValid = false
            } else if (this.state.password === '') {
                this.showMsg("Password Cannot be Empty", "danger")
                isValid = false
            } else if (!this.state.password.match(password_regx)) {
                this.showMsg("Please Enter Valid Password", "danger")
                isValid = false
            } else if (this.state.password !== this.state.confirmPassword) {
                this.showMsg("Password and Confirm password have to be same", "danger")
                isValid = false
            }
        }

        if (!isValid) {
            return false;
        }

        this.setState({
            loaderMsg: "Please Wait",
            showLoader: true
        }, () => {
            this.setState({
                showLoader: true
            });

            AxiosReactClient.postRequest('partners/createPartner', { ...payload, product_id: extractProductIdsFromList(this.state.product_id) }).then(response => {
                this.setState({
                    showLoader: false,
                    commission_percentage: payload.commission_percentage == '1' ? true : false,
                    partner_name: '',
                    partnerUserEmail: '',
                    partnerUsername: '',
                    view_claim: payload.view_claim == '1' ? true : false,
                    view_commission: payload.view_commission == '1' ? true : false,
                    product_id: [],
                    commission_value: 0,
                    password: '',
                    confirmPassword: '',
                    user_check: payload.user_check == '1' ? true : false,
                    business_category: null
                });

                if (response["data"].data.message === "Partner already exists.") {
                    this.showMsg(response["data"].data.message, "danger");
                    return
                }
                if (response["data"].data.message === "User already exists.") {
                    this.showMsg(response["data"].data.message, "danger");
                    return
                }
                if (response.data.success === true) {
                    this.showMsg("Partner created Successfully", "success");
                    return
                }
                if (response.data.success === false) {
                    this.showMsg(response["data"].data.message, "danger");
                }
            })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    });

                    if (err.response) {
                        this.showMsg(err.response.data?.data?.error_msg ? err.response.data?.data?.error_msg : err.response.data?.message, "danger")
                    } else if (err.message) {
                        this.showMsg(err.message, "danger")
                    }
                });
        });
    }

    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            ...this.state,
            [name]: value
        })
        if (name === 'pinCode') {
            this.populateStateCity(value)
        }
        if (name === 'partnerId') {
            this.getProductList(value);
        }
    }

    // cbProductList = (products = []) => {
    //     this.setState({ 'products': buildPartnerProductList(products), product_id: '' })
    // }

    cbProductList = (products = []) => {
        this.setState({ 'products': buildMultiPartnerProductList(products), product_id: [], productsVisible: true });
    }

    getProductList = async () => {
        try {
            const response = await AxiosReactClient.getRequest('filter/product/list');

            const { data: { product } } = response.data;
            this.cbProductList(product);
        } catch (error) {
            alert('It looks like something went wrong. Please try again in a while.');
            this.cbProductList();
        }
    };

    onSelect = (e) => {
        this.setState({ product_id: e })
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    {this.state.showLoader && <div className="loader-container">
                        <Loader msg={this.state.loaderMsg} />
                    </div>}
                    <Row>
                        <Col xs="12">
                            <Card className="mt-4">
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h4 className="h4 pt-2 font-weight-400">
                                                Add Partner
                                            </h4>
                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <label className=" form-control-label required"
                                            htmlFor="dealer">
                                            Partner Name
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Name"
                                            value={this.state.partner_name}
                                            onChange={this.handlepartner_name}
                                        />
                                        {/* <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Partner Slug
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter partner_slug"
                                            value={this.state.partner_slug}
                                            onChange={this.handlePartnerSlug}
                                        /> */}
                                        {/* <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Onboarding Steps
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter onboarding_steps"
                                            name="onboarding_steps"
                                            value={this.state.onboarding_steps}
                                            onChange={this.handleChange}
                                        /> */}

                                        {/* <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Partner data
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Dealership Name"
                                            name="partner_data"
                                            value={this.state.partner_data}
                                            onChange={this.handleChange}
                                        /> */}

                                        <label className=" form-control-label mt-3 required"
                                            htmlFor="dealer">
                                            Dashboard Credentials
                                        </label>

                                        <input type='radio' id='user_check2' name='user_check' value={0} defaultChecked={true}
                                            onChange={this.handleChange} style={{ margin: '0px 1rem 0 2rem' }}
                                        >
                                        </input>
                                        <label htmlFor='user_check2' style={{ margin: '0px 1rem 0 2rem' }}>No</label>

                                        <input type='radio' id='user_check1' name='user_check' value={1}
                                            style={{ margin: '0px 1rem 0 1rem' }} onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='user_check1' style={{ margin: '0px 1rem 0 1rem' }}>Yes</label>

                                        <br />
                                        {
                                            this.state.user_check == 1 &&
                                            <Fragment>

                                                <label className=" form-control-label mt-3 required"
                                                    htmlFor="dealer">
                                                    Email
                                                </label>

                                                <Input
                                                    type="email"
                                                    placeholder="Enter Email"
                                                    value={this.state.partnerUserEmail}
                                                    onChange={this.handlepartnerUserEmail}
                                                />
                                                <label className=" form-control-label mt-3 required"
                                                    htmlFor="dealer">
                                                    Partner User Name
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter User Name"
                                                    value={this.state.partnerUsername}
                                                    onChange={this.partnerUsername}
                                                />

                                                <label className=" form-control-label mt-3 required"
                                                    htmlFor="dealer">
                                                    Password
                                                </label>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    value={this.state.password}
                                                    onChange={this.password}
                                                    minLength="6"
                                                    maxLength="14"
                                                />

                                                <label className=" form-control-label mt-3 required"
                                                    htmlFor="dealer">
                                                    Confirm Password
                                                </label>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter Confirm Password"
                                                    value={this.state.confirmPassword}
                                                    onChange={this.confirmPassword}
                                                    minLength="6"
                                                    maxLength="14"
                                                />

                                            </Fragment>
                                        }

                                        <label className=" form-control-label mt-3 required"
                                            htmlFor="dealer">
                                            Business Category
                                        </label>

                                        <Select2 className=" form-control-label mt-3"
                                            options={{ placeholder: "Select Business Category" }}
                                            name="business_category"
                                            value={this.state.business_category}
                                            defaultValue=""
                                            data={this.state.businessCategories}
                                            onChange={this.handleChange}
                                        />

                                        <label className="form-control-label mt-3 required"
                                            htmlFor="productId">
                                            Product
                                        </label>

                                        {this.state.productsVisible > 0 &&
                                            <MultiSelect className="form-control-label"
                                                name="product_id"
                                                multiple
                                                labelledBy="Select"
                                                hasSelectAll={true}
                                                ref={this.selectRef}
                                                valueRenderer={(items) => {
                                                    return items instanceof Array && items.map(item => {
                                                        return <Label key={item.id}>
                                                            {item.label}
                                                        </Label>
                                                    })
                                                }}
                                                value={this.state.product_id}
                                                defaultValue={[]}
                                                options={this.state.products}
                                                onChange={this.onSelect}
                                            />}

                                        <label className=" form-control-label mt-3" htmlFor="dealer">
                                            View Commission
                                        </label>
                                        {/* <Input
                                            type="text"
                                            placeholder="Enter view commission"
                                            name="view_commission"
                                            value={this.state.view_commission}
                                            onChange={this.handleChange}
                                        /> */}

                                        <input type='radio' id='view_commission1' name='view_commission' value={1} defaultChecked={true}
                                            style={{ margin: '0px 1rem 0 1rem' }} onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='view_commission1' style={{ margin: '0px 1rem 0 1rem' }}>Yes</label>

                                        <input type='radio' id='view_commission2' name='view_commission' value={0}
                                            onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='view_commission2' style={{ margin: '0px 1rem 0 2rem' }}>No</label>
                                        <br />

                                        <label className=" form-control-label mt-3"
                                            htmlFor="dealer">
                                            Commission
                                        </label>

                                        <input type='radio' id='commission_percentage1' name='commission_percentage' value={1} defaultChecked={true}
                                            style={{ margin: '0px 1rem 0 1rem' }} onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='commission_percentage1' style={{ margin: '0px 1rem 0 1rem' }}>Percentage</label>

                                        <input type='radio' id='commission_percentage2' name='commission_percentage' value={0}
                                            onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='commission_percentage2' style={{ margin: '0px 1rem 0 2rem' }}>Flat</label>
                                        <br />

                                        <label className=" form-control-label mt-3"
                                            htmlFor="dealer">
                                            Commission Value
                                        </label>
                                        <Input
                                            type="number"
                                            min="0"
                                            placeholder="Enter Commission Value"
                                            name="commission_value"
                                            value={this.state.commission_value}
                                            onChange={this.handleChange}
                                        />


                                        <label className=" form-control-label mt-3"
                                            htmlFor="dealer">
                                            View Claim
                                        </label>
                                        {/* <Input
                                            type="text"
                                            placeholder="Enter view claim"
                                            name="view_claim"
                                            value={this.state.view_claim}
                                            onChange={this.handleChange}
                                        /> */}

                                        <input type='radio' id='view_claim1' name='view_claim' value={1} defaultChecked={true}
                                            style={{ margin: '0px 1rem 0 1rem' }} onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='view_claim1' style={{ margin: '0px 1rem 0 1rem' }}>Yes</label>

                                        <input type='radio' id='view_claim2' name='view_claim' value={0}
                                            onChange={this.handleChange}
                                        >
                                        </input>
                                        <label htmlFor='view_claim2' style={{ margin: '0px 1rem 0 2rem' }}>No</label>
                                        <br />


                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <h5 className="h5 mb-0 font-weight-400 text-primary cursor-pointer text-center">
                                        <Button
                                            block
                                            color="primary"
                                            size="lg"
                                            type="button" onClick={e => {
                                                e.preventDefault();
                                                this.submit({
                                                    commission_percentage: this.state.commission_percentage == '1' ? true : false,
                                                    partner_name: this.state.partner_name,
                                                    partner_user_mail: this.state.partnerUserEmail,
                                                    partner_user_name: this.state.partnerUsername,
                                                    product_id: this.state.product_id,
                                                    view_commission: this.state.view_commission == '1' ? true : false,
                                                    view_claim: this.state.view_claim == '1' ? true : false,
                                                    commission_value: parseInt(this.state.commission_value),
                                                    password: this.state.password,
                                                    user_check: this.state.user_check == '1' ? true : false,
                                                    business_category: this.state.business_category
                                                })
                                            }}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-plus" />
                                            </span>
                                            Add Partner
                                        </Button>
                                    </h5>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        partnerFilterList: state.common.partnerFilterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPartnerFilterList: () => dispatch(actions.getPartnerFilterListData()),
        postCreatePartner: (params) => dispatch(actions.postCreatePartner(params)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPartner);
