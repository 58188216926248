import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../../Store/actions';
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import Toastrr from '../../../components/CustomToaster/Toastrr';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import DealerPayout from '../../../components/Payouts/DealerPayout';
import PromoterPayout from '../../../components/Payouts/PromoterPayout';
import classnames from "classnames";
import Can from '../../../config/Casl/Can';
import * as permissions from '../../../config/Casl/permissions';
import {
    Card,
    CardTitle,
    CardBody,
    Badge,
    Row,
    Col,
    Container,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    ButtonGroup
} from 'reactstrap';
import moment from 'moment';

class Payout extends Component {
    reactDatetimeRef;
    previousMonthDate = (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    constructor(props) {
        super(props)
        this.state = {
            openDateDialog: false,
            openMonthDialog: false,
            selectedImportFile: null,
            cardFilter: 'custom',
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            selectedDate: (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth(), 0),
            tabs: 0,
            selectedCardsFilterDate: new Date()
        }
        this.reactDatetimeRef = React.createRef();
        props.onGetPayoutCardsData(this.buildDefaultParams());
    }
    getMonthYear = (date) => {
        return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()

    }
    getDateMonthYear = (date) => {
        return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getDate() + ", " + new Date(date).getFullYear()
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    handleReactMonthFilterChange(e) {
        this.setState({
            cardFilter: 'custom',
            selectedCardsFilterDate: e._d
        })
        this.props.onGetPayoutCardsData({ month: e._d.getMonth() + 1, year: e._d.getFullYear() })
    }
    validDateMonthFilter = (current) => {
        return (moment(moment(current).format('YYYY-MM-DD 23:59:59')).isSameOrBefore(moment(new Date()).format('YYYY-MM-DD 23:59:59')));
    };
    handleClickOutside = (e) => {
        let classArray = [];
        if (e.path) {
            e.path.forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        } else {
            e.composedPath().forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        }
        if (!classArray.includes('reactDateTimeCustomClass')) {
            this.setState({
                openDateDialog: false,
                openMonthDialog: false
            })
        }
    }
    getTwoFixedPoint = (num) => {
        var x = num * 1;
        return x.toFixed(2)
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    onRefreshAllCards = (filter) => {
        this.setState({
            cardFilter: filter
        })
        this.props.onGetPayoutCardsData(this.buildDefaultParams())
    }
    buildDefaultParams = () => {
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
    
        return { month, year };
    }
    render() {
        return (<div>
            {this.state.alert}
            <Toastrr data={this.state.notificationData}/>
            <Fragment>
                <Container fluid >

                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="8" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Finance
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Payout
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="4" xs="6">
                                <Can I={permissions.FINANCE.payout_cards} a={permissions.FINANCE.subject}>
                                    <div className="d-inline">

                                        {1 === 2 && 
                                        <Badge
                                            color={this.state.cardFilter === 'all' ? 'primary' : 'secondary'}
                                            href="#pablo"
                                            pill
                                            onClick={e => {
                                                e.preventDefault();
                                                this.onRefreshAllCards('all')
                                            }}
                                        >
                                            All</Badge>}

                                        <Badge
                                            color={this.state.cardFilter === 'custom' ? 'primary' : 'secondary'}
                                            href="#pablo"
                                            pill
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({
                                                    openMonthDialog: true,
                                                })
                                            }}
                                        >
                                            {
                                                this.state.cardFilter === 'custom' ? this.getMonthYear(this.state.selectedCardsFilterDate) : "Select Month"

                                            }   </Badge>
                                        <ReactDatetime
                                            className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                                            open={this.state.openMonthDialog}
                                            onFocus={e => {
                                                this.setState({
                                                    openMonthDialog: true
                                                })
                                            }}
                                            onBlur={e => {
                                                this.setState({
                                                    openMonthDialog: false
                                                })
                                            }}
                                            disableCloseOnClickOutside={false}
                                            inputProps={{
                                                placeholder: "Month"
                                            }}
                                            value={this.state.selectedCardsFilterDate}
                                            dateFormat="MMM-YYYY"
                                            timeFormat={false}
                                            onChange={e => {
                                                this.handleReactMonthFilterChange(e)
                                                this.setState({
                                                    openMonthDialog: false
                                                })
                                            }}
                                            isValidDate={this.validDateMonthFilter}
                                        />
                                    </div>
                                </Can>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Can I={permissions.FINANCE.payout_cards} a={permissions.FINANCE.subject}>
                            <Col md="6" xl="3">
                                <Card className="bg-gradient-primary card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0 text-white"
                                                >
                                                    Total Dealer Payout
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                                                    {(!this.props.dealerPayoutCardsLoading && this.props.dealerPayoutCardsData) ? '₹ '+ this.getTwoFixedPoint(this.props.dealerPayoutCardsData.total_dealer_payout) : <Shimmer />}
                                                </span>
                                            </div>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getDateMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className=" bg-gradient-primary card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0 text-white"
                                                >
                                                    Total Pending Payout
                                            </CardTitle>
                                                <span className="h2 font-weight-bold  mb-0 text-white">
                                                    {(!this.props.dealerPayoutCardsLoading && this.props.dealerPayoutCardsData) ? '₹ '+ this.getTwoFixedPoint(this.props.dealerPayoutCardsData.pending_dealer_payout) : <Shimmer />}

                                                </span>
                                            </div>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getDateMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="bg-gradient-primary card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0 text-white"
                                                >
                                                    Disbursed Payout
                                            </CardTitle>
                                                <span className="h2 font-weight-bold  mb-0 text-white">
                                                    {(!this.props.dealerPayoutCardsLoading && this.props.dealerPayoutCardsData) ? '₹ '+ this.getTwoFixedPoint(this.props.dealerPayoutCardsData.disbursed_dealer_payout) : <Shimmer />}
                                                </span>
                                            </div>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getDateMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className=" bg-gradient-primary card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0 text-white"
                                                >
                                                    Dealers with EXPOSURE
                                            </CardTitle>
                                                <span className="h2 font-weight-bold mb-0 text-white">
                                                    {(!this.props.dealerPayoutCardsLoading && this.props.dealerPayoutCardsData) ? this.props.dealerPayoutCardsData.dealers_with_exposures : <Shimmer />}
                                                </span>
                                            </div>
                                        </Row>
                                        <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getDateMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Can>
                    </Row>
                    <Row >
                        <Can I={permissions.FINANCE.payout_list} a={permissions.FINANCE.subject}>
                            <Col xs={12} sm={6} className="d-flex">
                                <>
                                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">

                                        <Button className={classnames({ active: this.state.tabs === 0 })} color="secondary" onClick={() => this.setState({ tabs: 0 })}>
                                            <input
                                                autoComplete="off"
                                                name="options"
                                                type="radio"
                                                value={this.state.tabs === 0}
                                            />
                                            Dealer Payout
                                            </Button>
                                        <Button className={classnames({ active: this.state.tabs === 1 })} color="secondary" onClick={() => this.setState({ tabs: 1 })}>
                                            <input
                                                autoComplete="off"
                                                name="options"
                                                type="radio"
                                                value={this.state.tabs === 1}
                                            />
                                            Promoter Payout
                                            </Button>
                                    </ButtonGroup>
                                </>
                            </Col>
                            <Col>
                                {this.state.tabs === 0 && <DealerPayout/>}
                                {this.state.tabs === 1 && <PromoterPayout/>}
                            </Col>
                        </Can>
                    </Row>
                </Container>
            </Fragment>

            <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert" />
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dealerPayoutCardsLoading: state.finance.payoutCardsLoading,

        dealerPayoutCardsData: state.finance.payoutCardsData,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetPayoutCardsData: (params) => dispatch(actions.getPayoutCardsData(params)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payout);
