import * as actionTypes from './actionTypes/providerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'





// START
export const getInsuranceProvidersStart = () => {
    return {
        type: actionTypes.GET_INSURANCE_PROVIDERS_START,
    };
};

export const getProductPartnersStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_PARTNETS_START,
    };
};


export const postAddFloatStart = () => {
    return {
        type: actionTypes.POST_ADD_FLOAT_START,
    };
};


// SUCCESS
export const getInsuranceProvidersSuccess = (data) => {
    return {
        type: actionTypes.GET_INSURANCE_PROVIDERS_SUCCESS,
        data: data,
    };
};


export const getProductPartnersSuccess = (data,nonPartner) => {
    return {
        type: actionTypes.GET_PRODUCT_PARTNETS_SUCCESS,
        data: data,
        nonPartner: nonPartner
    };
};


export const postAddFloatSuccess = () => {
    return {
        type: actionTypes.POST_ADD_FLOAT_SUCCESS,
    };
};


// FAIL
export const getInsuranceProvidersFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_INSURANCE_PROVIDERS_FAIL,
        error: error
    };
};

export const getProductPartnersFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_PRODUCT_PARTNETS_FAIL,
        error: error
    };
};



export const postAddFloatFail = () => {
    return {
        type: actionTypes.POST_ADD_FLOAT_FAIL,
    };
};



// ACTION
export const getInsuranceProvidersList = () => {
    return dispatch => {
        dispatch(getInsuranceProvidersStart());
        // console.log(dateParams);

        let params = {};

        AxiosReactClient.getRequest('providers', params).then(response => {
                // console.log(response.data);
                dispatch(getInsuranceProvidersSuccess(response.data.data.providers));
            })
            .catch(err => {
                // console.log(err);
                if (err.response)
                    dispatch(getInsuranceProvidersFail(err.response.data.error));
                else
                    dispatch(getInsuranceProvidersFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getProductPartnersList = (product_id) => {
    return dispatch => {
        dispatch(getProductPartnersStart());
        let params = {};
        AxiosReactClient.getRequest('providers/product/' + product_id+"/float", params).then(response => {
                // console.log(response.data);
                dispatch(getProductPartnersSuccess(response.data.data.partner,response.data.data.nonPartner));
            })
            .catch(err => {
                // console.log(err);
                if (err.response)
                    dispatch(getProductPartnersFail(err.response.data.error));
                else
                    dispatch(getProductPartnersFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};