import moment from "moment";

export const buildPartnerList = (list = []) => {
    const partners = [];

    if (!list.length) {
        return partners;
    }

    list.map(item => partners.push({
        id: item.id,
        text: `${item.name} (${item.id})`,
        slug: item.sug
    }));

    return partners;
};

export const buildSearchParams = ({ searchText, partnerId }) => {
    return {
        vehicle_search: searchText.trim(),
        partner_id: partnerId
    };
};

export const buildSystemErrorMessage = () => {
    return { data: { error_msg: 'It looks like something went wrong. Please try again in a while.' } };
};

export const buildSaveMappingPayload = ({ partnerId, searchResult = [] }) => {
    const payload = [];

    searchResult.map(item => {
        if (item?.checked) {
            const { product_id, vehicle_make_code, vehicle_type } = item;

            payload.push({
                partner_id: Number(partnerId),
                product_id,
                vehicle_make_code,
                vehicle_type
            });
        }
    });

    return payload;
};

export const renderFormattedDate = item => moment(item.created_at).format('DD-MM-YYYY');

export const validateInput = (event) => {
    let elValue = event.target.value;

    if (event.target.name === 'searchText') {
        elValue = elValue.replace(/(\s{2,})|[^a-zA-Z0-9']/g, ' ');
        elValue = elValue.replace(/^\s*/, '');
    }

    event.target.value = elValue;
};