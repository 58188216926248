import dealerRoute from "./views/pages/Dealers/dealerRoute";
import partnerRoute from "./views/pages/Partners/partnerRoute";
import authRoute from "./views/pages/Auth/authRoute";
import profileRoute from "./views/pages/Profile/profileRoute";
import providerRoute from "./views/pages/Providers/providerRoute";
import settingsRoute from "./views/pages/Settings/settingsRoute";
import dashboardRoute from "./views/pages/Dashboard/dashboardRoute";
import policiesRoute from "./views/pages/Policies/policiesRoute";
import financeRoute from "./views/pages/Finance/financeRoute";
import quotationsRoute from "./views/pages/Quotations/quotationsRoute";
import petRoute from "./views/pages/pet/petRoute";
import forgotPasswordRoute from "./views/pages/Password/forgotPasswordRoute";
import agentRoute from "./views/pages/Agents/agentRoute";

const routes = [
    dashboardRoute,
    authRoute,
    profileRoute,
    partnerRoute,
    policiesRoute,
    financeRoute,
    dealerRoute,
    agentRoute,
    quotationsRoute,
    petRoute,
    settingsRoute,
    forgotPasswordRoute,
];

export default routes;