import { initialVehicleMasterFormState, options, mapping } from '../defaultValues/vehicleMaster';
import { Utils } from '../constants/utils';

export const ADD_SLUG = 'add';
export const EDIT_SLUG = 'edit';
export const VEHICLE_MASTER_NUMERIC_FIELDS = ['ex_showroom_price', 'cubic_capacity'];
export const VEHICLE_MASTER_ALPHANUMERIC_FIELDS = ['vehicle_code', 'search_vehicle_code'];
export const BAGIC_PRODUCT_ID = 61;
export const DIGIT_PRODUCT_ID = 64;
export const FUTURE_PRODUCT_ID = 66;

export const isSubmitButtonDisabled = ({ cubic_capacity, carrying_capacity, hasError, fuel, make, model, 
  no_of_wheels, partner_id, product_id, vehicle_code, variant, make_code, model_code, variant_code }) => {
  return !cubic_capacity || !carrying_capacity ||  hasError || !fuel || !make || !model 
      || !no_of_wheels || !partner_id || !product_id || !vehicle_code  || !variant
      || (isBagicDealer(product_id) && (!make_code || !model_code || !variant_code));
};

export const buildFuelList = (product_id = BAGIC_PRODUCT_ID) => {
  return options.fuel[product_id];
};

export const buildPartnerList = (list = []) => {
  let partners = [];

  if (!list.length) {
    return partners;
  }
  
  list.map((item) => partners.push({ id: item.id, text: `${item.name}` }));

  return partners;
};

export const buildAddEditVehicleMasterPayload = ({ fuelTypes, id, partners, products, ...payload }) => {
  return payload;
};

export const syncVehicleMasterData = (vehicleMaster) => {
  if (!vehicleMaster.length) {
    return { ... initialVehicleMasterFormState };
  }

  const { carrying_capacity, cubic_capacity, ex_showroom_price, fuel, id, product_id, vehicle_make, vehicle_model, wheel_flag, vehicle_code, 
    vehicle_subtype, vehicle_make_code = '', vehicle_model_code = '', vehicle_subtype_code = '' } = vehicleMaster[0];

  return { 
    ...initialVehicleMasterFormState, 
    carrying_capacity, 
    cubic_capacity, 
    ex_showroom_price, 
    fuel, 
    id, 
    make: vehicle_make, 
    model: vehicle_model, 
    no_of_wheels: mapping.no_of_wheels[wheel_flag], 
    product_id, 
    vehicle_code, 
    variant: vehicle_subtype,
    make_code: vehicle_make_code, 
    model_code: vehicle_model_code,
    variant_code: vehicle_subtype_code, 
  };
};

export const buildSearchParams = ({ search_vehicle_code, product }) => { 
  return {
    vehicle_code: search_vehicle_code, 
    product_id: product
  };
};

export const isSearchResultNotFound = ({ product = '', search_vehicle_code = '', selection = 'add', result = [] }, isSearchResultLoaded) => {
  return selection === EDIT_SLUG && product && search_vehicle_code && !result.length && isSearchResultLoaded;
};

export const buildSystemErrorMessage = () => {
  return { data: {error_msg: 'It looks like something went wrong. Please try again in a while.'} };
};

export const validateInputLength = (event) => {
  let elName = event.target.name;
  let elValue = event.target.value;

  if (VEHICLE_MASTER_NUMERIC_FIELDS.indexOf(elName) > -1) {
    var clean = elValue.replace( /[^0-9]+/g, '');
    event.target.value = clean;
  }

  if (VEHICLE_MASTER_ALPHANUMERIC_FIELDS.indexOf(elName) > -1) {
    var clean = elValue.replace( /[^0-9a-zA-Z]+/g, '');
    event.target.value = clean;
  }
  
  if (elValue.length > Utils.limit.digits[elName]) {
    event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
  }
};

export const isBagicDealer = (product_id = null) => {
  return product_id && product_id == BAGIC_PRODUCT_ID;
};

export const shouldSkipValidation = (product_id) => {
  return product_id && !isBagicDealer(product_id);
};