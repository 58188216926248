import moment from 'moment';
// import { Utils } from '../constants/utils';
// import { convertDateToString } from './utils';
import { mapping } from '../defaultValues/lead';
import { convertDateToString } from './utils';

export const buildCreateLeadPayload = ({ gender, name, mobile, email, payment_status, source_id, add_ons }) => {
  return {
    gender,
    name,
    mobile: parseInt(mobile, 10),
    email,
    payment_status,
    source_id,
    add_ons
  };
};

export const buildUpdatePolicyStartDatePayload = ({ start_date, policy_number, premium, payment_status, remarks }) => {
  return {
    start_date: convertDateToString(start_date, "DD-MM-YYYY"),
    policy_number,
    premium,
    payment_status,
    remarks
  };
};

export const buildCustomerDetailsPayload = ({ dob, hasError, ...rest }, customer_dob) => {
  const { addressLine1, addressLine2, mobile } = rest;

  const payload = {
    ...rest,
    mobile: parseInt(mobile, 10),
    customer_dob: convertDateToString(customer_dob),
    address1: addressLine1,
    address2: addressLine2,
    form_type: 'customer_details'
  };

  delete payload.addressLine1;
  delete payload.addressLine2;

  return payload;
};

export const buildDogDetailsPayload = ({ hasError, ...rest }) => {
  const payload = {
    ...rest,
    form_type: 'dog_details'
  };

  if (rest.dog_breed !== 'others') {
    delete payload.other_breed;
  }

  return payload;
};

export const buildCsvHeaders = () => {
  return [
    { label: "ID", key: "id"},

    { label: "Customer Gender", key: "gender" },
    { label: "Customer Name", key: "name" },
    { label: "Customer Date of Birth", key: "customer_dob" },
    { label: "Customer Mobile", key: "mobile" },
    { label: "Customer Email", key: "email" },
    { label: "Source", key: "source_id" },
    { label: "Pin Code", key: "pincode" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Address Line 1", key: "address1" },
    { label: "Address Line 2", key: "address2" },

    { label: "Dog Name", key: "dog_name" },
    { label: "Dog Gender", key: "dog_gender" },
    { label: "Dog Breed", key: "dog_breed" },
    { label: "Other Breed", key: "other_breed" },
    { label: "Dog Age (Year)", key: "dog_age_year" },
    { label: "Dog Age (Month)", key: "dog_age_month" },
    { label: "Does your dog have any unique identification marks?", key: "dog_mark" },
    { label: "Is your dog Spayed or Castrated?", key: "spayed_castrated"},
    { label: "Does your dog have any defects or ailments?", key: "defects_ailments" },
    { label: "Addons", key: "add_ons" },
    { label: "Unique Identification Mark", key: "dog_mark" },
    
    { label: "Policy Number", key: "policy_number" },
    { label: "Policy Premium", key: "premium" },
    { label: "Policy Start Date", key: "start_date" },
    { label: "Policy End Date", key: "end_date" },
    { label: "Policy Status", key: "status_text" },
    { label: "Payment Status", key: "payment_status_text" },

    { label: "Lead Creation Date", key: "created_at_readable" },
    { label: "Policy Issue Remarks", key: "remarks" }
  ]
};

export const isLeadCaptureSubmitButtonDisabled = ({ gender, name, email, mobile, hasError }, errorMessage) => {
  return (!gender || !name || (name && errorMessage.name) || !email || (email && errorMessage.email) || !mobile || (mobile && errorMessage.mobile) || hasError);
};

export const isIssuePolicySubmitButtonDisabled = ({ start_date, policy_number, premium, payment_status }) => {
  return !start_date || !policy_number || !premium || !payment_status;
};

export const isCustomerDetailsNextButtonDisabled = ({ gender, name, dob, email, mobile, pincode, city, state, addressLine1, hasError }, errorMessage) => {
  return (!gender || !name || (name && errorMessage.name) || !dob || (dob && errorMessage.dob)
    || !email || (email && errorMessage.email) || !mobile || (mobile && errorMessage.mobile)
    || !pincode || (pincode && errorMessage.pincode) 
    || !city || !state || !addressLine1 || hasError);
};

export const isDogDetailsNextButtonDisabled = ({ dog_name, dog_gender, dog_breed, other_breed, dog_age_year, dog_age_month, dog_mark, spayed_castrated, defects_ailments, hasError }, errorMessage) => {
  return (!dog_name || (dog_name && errorMessage.dog_name)
    || !dog_gender || (dog_gender && errorMessage.dog_gender) 
    || !dog_breed || (dog_breed && errorMessage.dog_breed)
    || (dog_breed === 'others' && (!other_breed || (other_breed && errorMessage.other_breed)))
    || dog_age_year === '' || (dog_age_year && errorMessage.dog_age_year)
    || dog_age_month === '' || (dog_age_month && errorMessage.dog_age_month)
    || !dog_mark || (dog_mark && errorMessage.dog_mark)
    || !spayed_castrated || (spayed_castrated && errorMessage.spayed_castrated)
    || !defects_ailments || (defects_ailments && errorMessage.defects_ailments)
    || hasError);
};

export const isValidDate = (current, field) => {
  if (field === 'dob') {
    // return current.isBefore( moment().subtract(0, 'day') );
    return current.isBefore( moment().subtract(18, 'years') );
  } else if (field === 'start_date') {
    return current.isAfter( moment().subtract(1, 'day') );
  }
};

export const rebuildAPIResponse = (list) => {
  let response = [];

  list.map((item, index) => 
    response.push({ 
      ...item,
      created_at_readable: moment(item.created_at).format('DD-MMM-Y'),
      status_text: mapping.status[item.status],
      payment_status_text: mapping.paymentStatus[item.payment_status]
    })
  );
  
  return response;
};
