export const ReducerUtils = {
  otherPolicies: {
    details: 'POLICY_DETAILS',
    list: 'POLICIES_LIST',
    search: 'SEARCH_TEXT',
    sort: 'SORT_LIST'
  },
  comparison: {
    details: 'COMPARISION_DETAILS',
    list: 'COMPARISION_LIST',
    search: 'SEARCH_TEXT',
    sort: 'SORT_LIST'
  },
  partners: {
    list: 'PARTNERS_LIST',
    search: 'SEARCH_TEXT',
    sort: 'SORT_LIST',
    updateFloatAmount: 'UPDATE_FLOAT_AMOUNT'
  },
  leads: {
    list: 'LEADS_LIST',
    details: 'LEAD_DETAILS',
    search: 'SEARCH_TEXT',
    sort: 'SORT_LIST',
    create: 'CREATE_LEAD',
    issuePolicy: 'ISSUE_POLICY',
    filter: 'FILTER_LIST'
  },
  unifiedPolicies: {
    list: 'POLICIES_LIST',
    search: 'SEARCH_TEXT',
    sort: 'SORT_LIST'
  },
  agentExam: {
    studyMaterial: 'AGENT_EXAM_STUDY_MATERIAL',
    questions: 'EXAM_QUESTIONS',
    result: 'AGENT_EXAM_RESULT'
  },
  agent: {
    details: 'AGENT_DETAILS'
  }
};