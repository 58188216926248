import React, { useContext, useEffect, useState, useReducer } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle
} from 'reactstrap';

// Import all third party default exports
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

// Import all custom default exports
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import OtherPolicyContext from '../../../context/otherPolicyContext';
import OtherPoliciesReducer from '../../../reducers/otherPoliciesReducer';
import Loader from '../../../components/CustomUi/Loader/Loader';
import OtherPolicyDocument from './otherPolicyDocument';
import OtherPolicyForm from './otherPolicyForm';
import useApi from '../../../hooks/useApi';

// Import all third party named exports
// Import here if any

// Import all custom named exports
import { buildSubmitDetailsPayload } from '../../../helpers/otherPolicyDetails';
import { errorMessagePolicyDetails, initialManualPolicyDetails, options, touchFieldsPolicyDetails } from './otherPolicyDefaultValues';
import { IndiaFirstLocation } from "../../../locations/indiaFirstLocations";
import { ReducerUtils } from '../../../constants/reducers';
import { Validators } from '../../../messages/validators';
import { Utils } from '../../../constants/utils';
import { validataInputLength } from '../../../helpers/utils';

const OtherPolicyDetails = (props) => {
  const initialOtherPolicyReducerState = useContext(OtherPolicyContext);
  const [state, dispatch] = useReducer(OtherPoliciesReducer, initialOtherPolicyReducerState);
  const [errorMessage, setErrorMessage] = useState(errorMessagePolicyDetails);
  const [form, setForm] = useState(initialManualPolicyDetails);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [touchFields, setTouchFields] = useState(touchFieldsPolicyDetails);

  // Call useApi hook to fetch other policy details
  const policyDetails = useApi(`v2/documents/${props.match.params.id}`);
  
  useEffect(() => {
    dispatchEvent(ReducerUtils.otherPolicies.details, policyDetails);
  }, [policyDetails]);

  useEffect(() => {
    validateAge('age');
  }, [form.age]);

  useEffect(() => {
    validateMobile('contact');
  }, [form.contact]);

  useEffect(() => {
    validateEmail('email');
  }, [form.email]);

  useEffect(() => {
    validateEmail('nominee_email');
  }, [form.nominee_email]);

  useEffect(() => {
    validateMobile('nominee_mobile');
  }, [form.nominee_mobile]);

  useEffect(() => {
    getCityStateByPincode(form.pincode);
  }, [form.pincode]);

  useEffect(() => {
    validateName('policy_holder_name');
  }, [form.policy_holder_name]);

  useEffect(() => {
    validateName('nominee_name');
  }, [form.nominee_name]);

  // Custom dispatch event responsible for triggering requested event with payload
  const dispatchEvent = (type, payload) => {
		dispatch({
			type,
			payload
    });
  };

  // Set form state
  const setFormState = (name, value) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const cbCityState = (response=['', '']) => {
    setForm({
      ...form,
      'city': response[0],
      'state': response[1]
    });
  };

  // Get city and state name for a requested pincode
  const getCityStateByPincode = async (pincode) => {
    pincode ? cbCityState(await new IndiaFirstLocation().get(pincode)) : cbCityState();
  }; 

  // Input change handler
  const handleInputChange = (event) => {
    setFormState(event.target.name, event.target.value);
  };

  // Date change handler
  const handleDateChange = (date, keyname) => {
    setFormState(keyname, date);
  };

  // Covered, not covered change handler
  const handleCoveredChange = (event, currentIndex, section) => {
    const shallowCopy = [...form[section]];

    shallowCopy.map((item, index) => {
      if (index === currentIndex) {
        item.title = event.target.value;
      }
    });

    setFormState(section, shallowCopy);
  };

  // This method will push item to list and will remove item as provided index
  const addRemoveItem = (action, keyname, index) => {
    // Create a shallow copy of list
    const shallowCopy = [...form[keyname]];

    // Perform either remove item from shallowCopy or push item to shallowCopy
    action === 'remove' ? shallowCopy.splice(index, 1) : shallowCopy.push({title: ''});

    // Finally update the actual list with the recent one
    setFormState(keyname, shallowCopy);
  };

  // Covered, not covered add more and remove item click handler
  const addItem = (event) => {
    addRemoveItem('add', event.target.name);
  };

  // Covered, not covered add more and remove item click handler
  const removeItem = (keyname, index) => {
    addRemoveItem('remove', keyname, index);
  };

  const setInputFieldErrorState = (field, hasError = false, message = '', isTouched = false) => {
    setFormState('hasError', hasError);
    setErrorMessage({ ...errorMessage, [field]: message });
    setTouchFields({ ...touchFields, [field]: isTouched });
  };
  
  // Validate age field
  const validateAge = () => {
    if (form.age) {
      let age = parseInt(form.age, 10);
      let isValid = age >= Utils.limit.age.min && age <= Utils.limit.age.max;
      let message = isValid ? '' : Validators.age.valid;
      
      setInputFieldErrorState('age', !isValid, message, true);
    } else if (touchFields.age) {
      setInputFieldErrorState('age');
    }
  };

  // Validate email pattern format
  const validateEmail = (field) => {
    if (form[field]) {
      let isValid = isEmail(form[field]);
      let message = isValid ? '' : Validators[field].valid;

      setInputFieldErrorState(field, !isValid, message, true);
    } else if (touchFields[field]) {
      setInputFieldErrorState(field);
    }
  };

  // Validate mobile number format
  const validateMobile = (field) => {
    if (form[field]) {
      let isValid = isMobilePhone(form[field], ['en-IN']);
      let message = isValid ? '' : Validators[field].valid;

      setInputFieldErrorState(field, !isValid, message, true);
    } else if (touchFields[field]) {
      setInputFieldErrorState(field);
    }
  };

  // Validate policy holdername field
  const validateName = (field) => {
    if (form[field]) {
      let name = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
      let isValid = name.test(form[field]);
      let message = isValid ? '' : Validators.name.valid;

      setInputFieldErrorState(field, !isValid, message, true);
    } else if (touchFields.name) {
      setInputFieldErrorState(field);
    }
  };

  const navigateToListing = () => {
    props.history.push('/admin/policies/other');
  };
  
  const updatePolicyDetails = async () => {
    setIsFormSubmitted(true);
    
    const url = `v2/documents/${props.match.params.id}`;
    const { hasError, ...rest } = form;
    const payload = buildSubmitDetailsPayload(rest);
    const response = await AxiosReactClient.putRequest(url, payload);
    const { data } = response.data;

    setIsFormSubmitted(false);
    navigateToListing();
  };

  // Form submit handler
  const onSubmit = (event) => {
    event.preventDefault();
    updatePolicyDetails();
  };

  return (
    <>
      <Row>
        <Card className={"bg-primary w-100"} style={{height: "200px"}}/>
      </Row>

      <Container fluid className={"mt--9"}>
        <Row>
          <Card className={"w-100 p-4 h"}>
            <CardTitle>
              <Row>
                <Col md="6" xl="4">
                  <h1>
                    <span className="cursor-pointer" onClick={navigateToListing}>
                      <i className="fas fa-arrow-left mr-3 fnt-20"></i>
                    </span> Enter Policy Details
                  </h1>
                </Col>
              </Row>
            </CardTitle>

            <hr className={"p-0 m-0"}/>

            {!state.othePolicyDetails ? 
            <Loader msg={"Please Wait..."}/> :
            <CardBody style={{padding: 0}}>
              <Row>
                <Col xl="5" style={{padding: '1.5rem', height: '100vh'}} >
                  <OtherPolicyDocument 
                    pages={state.othePolicyDetails.pages}
                  /> 
                </Col>
                <Col xl="7" style={{'background': '#f7fafc', padding: '1.5rem',height: '100vh', overflowY: 'scroll' }}>
                  <OtherPolicyContext.Provider value={{ state, dispatch }}>
                    <OtherPolicyForm
                      addItem={addItem}
                      errorMessage={errorMessage} 
                      form={form}
                      handleCoveredChange={handleCoveredChange}
                      handleDateChange={handleDateChange}
                      handleInputChange={handleInputChange}
                      isFormSubmitted={isFormSubmitted}
                      onSubmit={onSubmit}
                      options={options}
                      setForm={setForm}
                      removeItem={removeItem}
                      validataInputLength={validataInputLength}
                    />
                  </OtherPolicyContext.Provider>
                </Col>
              </Row>
            </CardBody>}
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default OtherPolicyDetails;