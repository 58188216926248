import React from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

const Paginator = ({ pagination, handleClick }) => {
  return (
    <Pagination
      aria-label="Page navigation example"
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem disabled={pagination.currentPage <= 0}>
        <PaginationLink
          onClick={(e) => handleClick(e, pagination.currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>

      {[...Array(pagination.pagesCount)].map((page, i) => (
        <PaginationItem active={i === pagination.currentPage} key={i}>
          <PaginationLink onClick={(e) => handleClick(e, i)} href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      ))}

      <PaginationItem
        disabled={pagination.currentPage >= pagination.pagesCount - 1}
      >
        <PaginationLink
          onClick={(e) => handleClick(e, pagination.currentPage + 1)}
          next
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

export default Paginator;
