import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import { Ability, AbilityBuilder } from "@casl/ability"
import ability from '../config/Casl/ability'
import AxiosReactClient from '../utilities/AxiosRestClient'
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";

import routes from "../routes.js";
import Notification from "../components/CustomUi/Notification";

const ProtectedRoute
  = ({ isAllowed, ...props }) =>
    isAllowed
      ? <Route {...props} />
      : <Redirect to="/admin" />;
class Admin extends React.Component {
  state = {
    sidenavOpen: true
  };
  constructor() {
    super()
    this.getUserPermissions()
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getNames = (array) => {
    return array.map(a => a.name)
  }
  getRoutes = routes => {
    // permissionArray = [];

    // console.log(permissionArray);
    return routes.map((prop, key) => {
      // prop.permission && console.log(permissionArray.includes(prop.permission));

      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          // <ProtectedRoute
          //   isAllowed={prop.permission && permissionArray.includes(prop.permission)}
          //   exact
          //   path={prop.layout + prop.path}
          //   component={prop.component}
          // />
          // (prop.permission && permissionArray.includes(prop.permission)) ?
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
          //   : <Redirect to="/admin" />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  getUserPermissions = () => {
    const userId = localStorage.getItem('userId');
    // console.log(userId);
    AxiosReactClient.getRequest('users/' + userId + "/permissions").then(response => {
      // console.log(response.data.data.Permissions);
      if (response.data.data.Permissions) {
        this.updateAbility(response.data.data.Permissions)
      }
    })
      .catch(err => {
        console.log(err);
      });
  }
  updateAbility = (permissions) => {
    let permissionArray = [];
    permissions.forEach(group => {
      var permissionObject = {
        groupName: group.groupName,
        permissions: group.permissions.map(a => {
          let arr = a.name.split('_')
          arr.shift()
          return arr.join("_")
        })
      }
      permissionObject.permissions.length > 0 && permissionObject.permissions.push('menu')
      // console.log(permissionObject.permissions);
      
      permissionArray.push(permissionObject)
    });
    // console.log(permissionArray);
    
    const { can, rules } = AbilityBuilder.extract()
    permissionArray.forEach(element => {
      can(element.permissions, element.groupName)
    });
    ability.update(rules)
    this.props.history.push(this.props.history.location.pathname ? this.props.history.location.pathname : '/admin/dashboard/home')
    
    // console.log(ability);

  }
  render() {
    return (
      <div>
        <Notification />

        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("../assets/img/brand/toffee-logo-colored.png").default,
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div style={{ minHeight: '84vh' }}>

            <Switch>
              {this.getRoutes(routes)}
              <Redirect to="/404" />
            </Switch>
          </div>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </div>
    );
  }
}

export default Admin;
