import AllPolicies from "./AllPolicies";
import * as permissions from '../../../config/Casl/permissions';
import Reports from "./Reports";
import IssuePolicy from "./IssuePolicy";
import ViewPolicies from "./viewPolicy";
import OtherPolicyDetails from "./otherPolicyDetails";
import OtherPoliciesList from "./otherPoliciesList";
import UnifiedPolicies from "./unified";

const policiesRoute = {
    path: "/policies",
    collapse: true,
    name: "Policies",
    icon: "ni ni-badge text-primary",
    state: "policiesCollapse",
    permission: permissions.POLICY.menu,
    subject: permissions.POLICY.subject,
    views: [
        {
            path: "/policies/other/:id",
            name: "View Other Policy",
            component: OtherPolicyDetails,
            layout: "/admin",
            // permission: permissions.POLICY.list,
            // subject: permissions.POLICY.subject
        },
        {
            path: "/policies/other",
            name: "Other Policies",
            component: OtherPoliciesList,
            layout: "/admin",
            permission: permissions.OTHER_POLICIES.list,
            subject: permissions.OTHER_POLICIES.subject
            // permission: permissions.OTHERPOLICIES.documents_list,
            // subject: permissions.OTHERPOLICIES.documents_list
        },

        {
            path: "/policies/issue",
            name: "Issue Policy",
            component: IssuePolicy,
            layout: "/admin",
            // permission: permissions.POLICY.policy_issuePolicy,
            permission: permissions.POLICY.list,
            subject: permissions.POLICY.subject
        },
        {
            path: "/policies/all",
            name: "Policies",
            component: AllPolicies,
            layout: "/admin",
            permission: permissions.POLICY.list,
            subject: permissions.POLICY.subject
        },
        {
            path: "/policies/reports",
            name: "Reports",
            component: Reports,
            layout: "/admin",
            // permission: permissions.POLICY.list,
            // subject: permissions.POLICY.subject
        },
        {
            path: "/policies/unified",
            name: "Unified Policies",
            component: UnifiedPolicies,
            layout: "/admin",
            permission: permissions.UNIFIED_POLICIES.list,
            subject: permissions.UNIFIED_POLICIES.subject
        },
        {
            path: "/policies/:id",
            name: "View Policies",
            component: ViewPolicies,
            layout: "/admin",
            // permission: permissions.POLICY.list,
            // subject: permissions.POLICY.subject
        },
    ]
};
export default policiesRoute;