import CreateUser from "./Users/CreateUser";
import EditUsers from "./Users/EditUsers";
import ViewUser from "./Users/ViewUser";
import CreateRole from "./Roles/CreateRole";
import EditRole from "./Roles/EditRole";
import ViewRoles from "./Roles/ViewRoles";
import Users from "./Users/Users.jsx";
import Roles from "./Roles/Roles.jsx";
import Config from "./Config/Config.jsx"
import AddConfig from "./Config/CreateConfig.jsx"
import EditConfig from "./Config/EditConfig.jsx"

import * as permissions from '../../../config/Casl/permissions';

const settingsRoute = {
    collapse: true,
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-primary",
    state: "settingsCollapse",
    permission: permissions.USERS.menu,
    subject: permissions.USERS.subject,
    views: [{
        path: "/users/create",
        name: "Users",
        component: CreateUser,
        layout: "/admin",
        invisible: true,
        permission: permissions.USERS.store,
        subject: permissions.USERS.subject
      },
      {
        path: "/users/:id/edit",
        name: "EditUser",
        component: EditUsers,
        layout: "/admin",
        invisible: true,
        permission: permissions.USERS.update,
        subject: permissions.USERS.subject
      },
      {
        path: "/users/:id",
        name: "ViewUser",
        component: ViewUser,
        layout: "/admin",
        invisible: true,
        permission: permissions.USERS.show,
        subject: permissions.USERS.subject
      },
      {
        path: "/users",
        name: "Users",
        component: Users,
        layout: "/admin",
        permission: permissions.USERS.list,
        subject: permissions.USERS.subject
      },
            {
        path: "/config/create",
        name: "AddConfig",
        component: AddConfig,
        layout: "/admin",
              invisible: true,
        permission: permissions.USERS.store,
        subject: permissions.USERS.subject
      },
            {
        path: "/config/:entity/edit/:entityId/:id",
        name: "EditConfig",
        component: EditConfig,
        layout: "/admin",
              invisible: true,
       permission: permissions.USERS.update,
        subject: permissions.USERS.subject
      },
      {
        path: "/config",
        name: "Config",
        component: Config,
        layout: "/admin",
        permission: permissions.USERS.list,
        subject: permissions.USERS.subject
      },


      {
        path: "/roles/create",
        name: "Roles",
        component: CreateRole,
        layout: "/admin",
        invisible: true,
        permission: permissions.ROLES.store,
        subject: permissions.ROLES.subject
      },
      {
        path: "/roles/:id/edit",
        name: "EditRole",
        component: EditRole,
        layout: "/admin",
        invisible: true,
        permission: permissions.ROLES.update,
        subject: permissions.ROLES.subject
      },
      {
        path: "/roles/:id",
        name: "ViewRole",
        component: ViewRoles,
        layout: "/admin",
        invisible: true,
        permission: permissions.ROLES.show,
        subject: permissions.ROLES.subject
      },
      {
        path: "/roles",
        name: "Roles",
        component: Roles,
        layout: "/admin",
        permission: permissions.ROLES.list,
        subject: permissions.ROLES.subject
      },
    ]
  };
export default settingsRoute;

