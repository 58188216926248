import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
import DropdownCustom from '../../../../components/dropDown';
import moment from 'moment';

// Import all custom default exports
import CompareProductReducer from '../../../../reducers/comparisonReducer';
// import Loader from '../../../components/CustomUi/Loader/Loader';
import Spinner from '../../../../components/Spinner/Spinner';
import useApi from '../../../../hooks/useApi';

// Import all third party named exports
// Import if required

// Import all custom named exports
import { getOptionsList } from '../../../../helpers/comparison';
import { ReducerUtils } from '../../../../constants/reducers';
import { Products } from '../../../../data/products';
import { mapping } from '../../../../defaultValues/comparison';
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import Can from '../../../../config/Casl/Can';
import * as permissions from "../../../../config/Casl/permissions";
import CustomDropdown from "../../../../components/Filters/CustomDropdown";

const QuotationComparisonList = (props) => {
  // Call useApi hook to fetch other policies list
  const comparisonList = useApi('v2/comparisons', { }, false, {list: [], totalCount: 0});

  const [state, dispatch] = useReducer(CompareProductReducer, { quotationDetails: null, comparisonList: [], comparisonListApi: [] });
  const [action, setAction] = useState({ isDropdownOpen: false, ddValue: '' });
  const [loader, setLoader] = useState(true);
  const [pager, setPager] = useState({ pagerStart: 0, pagerEnd: 0, pageArray: [], limit: 25 });
  
  useEffect(() => {
    localStorage.removeItem('compareQuotation');
  }, []);

  useEffect(() => {
    dispatchEvent(ReducerUtils.comparison.list, comparisonList.list);
    updatePagination(comparisonList.totalCount);
    comparisonList && comparisonList.list.length ? setLoader(false) : setTimeout(() => { setLoader(false) }, 10000);
  }, [comparisonList.list]);

  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const handleAction = (event, { value }, item) => {
    setActionState('ddValue', value);
    navigateTo(`/admin/comparisons/${item.id}`);

    setTimeout(() => {
      setActionState('ddValue', '');
    }, 1000);
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  const setActionState = (key, value) => {
    setAction({ ...action, [key]: value });
  };

  const isProductSelected = (item) => {
    let isSelected = false;

    Object.keys(item.products).map(productId => {
      isSelected = isSelected || item.products[productId].is_selected === true;
    });

    return isSelected;
  };

  const renderItemList = (item, productId, index) => {
    return (<Row key={index}> 
      <Col> {`${Products[productId].provider} - ₹${item.products[productId].premium}/${item.payment_frequency === 'monthly' ? 'Month' : 'Year'}`} </Col>
    </Row>)
  };

  const renderComparedProducts = (item) => {
    let str = (
      Object.keys(item.products).map((productId, index) => {
        return (item.products[productId].is_selected === true && renderItemList(item, productId, index));
      })
    );

    // This condition has been introduced in order to display compared products if custom has made changes as per his/her need
    if (!isProductSelected(item)) {
      str = (
        Object.keys(item.products).map((productId, index) => {
          return (item.products[productId].premium > 0 && renderItemList(item, productId, index));
        })
      );
    }

    return str;
  };

  const updatePagination = (total) => {
    pager.pageArray = [];
    pager.pagerEnd = Math.ceil(total / pager.limit);
    for (let index = 0; index < pager.pagerEnd; index++) {
      pager.pageArray.push(index + 1);
    }
    setPager(pager);
  };

  const switchPage = async (page) => {
    if (((pager.pagerStart + 1) !== page)) {
      pager.pagerStart = page - 1;
      const response = await AxiosReactClient.getRequest('v2/comparisons', { page: pager.pagerStart });
      const { data } = response.data;
      const { list } = data;
      dispatchEvent(ReducerUtils.comparison.list, list);
    }
  };

  const isHidden = (page) => {
    var start = pager.pagerStart + 1;
    if (page > start) {
      if ((page - start) > 2 && (start === 1)) {
        return true;
      } else if ((start !== 1) && ((page - start) > 1)) {
        return true;
      }
    } else {
      if ((start - page) > 2 && (start === pager.pagerEnd)) {
        return true;
      } else if ((start !== pager.pagerEnd) && ((start - page) > 1)) {
        return true;
      }
    }
    return false;
  };
  
  return (
    <Container fluid>
      <div className="header-body">
        <Row className="align-items-center py-4">
          <Col lg="8" xs="6">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <a href="#" onClick={e => e.preventDefault()}>
                  <i className="fas fa-home"/>
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={e => {
                  e.preventDefault();
                }}>
                  Comparisons
                </a>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="text-right" lg="4" xs="6"></Col>
        </Row>
      </div>
      
			<div className={"d-flex justify-content-end mb-4"}>
                <Can I={permissions.COMPARISONS.store} a={permissions.COMPARISONS.subject}>
                  <button
                      className={'btn btn-icon btn-primary btn-lg'}
                      type={'button'}
                      onClick={(e) => navigateTo('/admin/comparisons/create')}
                  >
                      <b>Create Compare Quotation</b>
                  </button>
                </Can>
			</div>

      <Card className="m-0 mt-1" style={{ minHeight: '300px' }}>
        <CardBody className="px-0 py-0">
          <div className="table-responsive">
            <Table className="align-items-center table-flush" hover responsive>
              <thead className="thead-light">
                <tr>
                  <th>Customer Name</th>
                  <th>Mobile Number</th>
                  <th>Compared Plans</th>
                  <th>Date of Quoation Creation</th>
                  <th style={{width: '5%'}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader &&
                <tr>
                  <td colSpan="8">
                    <Spinner></Spinner>
                  </td>
                </tr>}
                {(!loader && !state.comparisonList.length) &&
                <tr className="cursor-pointer">
                  <td colSpan="12" className="text-center">
                    <span className="text-muted">
                      <b>
                        No compare quotation data found
                      </b>
                    </span>
                  </td>
                </tr>}

                {(!loader && state.comparisonList.length > 0) && state.comparisonList
                  .map((item, index) => {
                    return <tr key={index + 'hgshdsh'}>
                      <td className="table-user">{item.name}</td>
                      <td>{item.mobile}</td>
                      <td>{item.shared_count ? renderComparedProducts(item) : '-'}</td>
                      <td>{moment(item.created_at).format('Y-M-D')}</td>
                      <td style={{width: '5%'}}>
                        <DropdownCustom
                          // open={action.isDropdownOpen}
                          trigger={
                            <i className="fa fa-ellipsis-v cursor-pointer ml-2" aria-hidden="true"/>
                          }
                          options={getOptionsList(item)}
                          position={(index > 0 && (index >= state.comparisonList.length - 1) || state.comparisonList.length == 1)? 'bottom right' : 'top right' }
                          onClickOption={(e, {value}) => {
                            handleAction(e, {value}, item)
                          }}
                          value={action.ddValue}
                      />
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
          </div>
        </CardBody>
        <CardFooter className="py-4">
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem className={pager.pagerStart > 0 ?
                '' :
                'disabled'}>
                <PaginationLink
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    switchPage(pager.pagerStart);
                  }}
                  tabIndex="-1"
                >
                  <i className="fas fa-angle-left"/>
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              {pager.pageArray.map(page => {
                return < PaginationItem key={'pager_' +page} hidden={isHidden(page)}
                  className={((pager.pagerStart + 1) === page) ? 'active' : ''}>
                  <PaginationLink
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      switchPage(page);
                    }}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>;
              })}
              <PaginationItem className={pager.pagerEnd > pager.pagerStart + 1 ? '' : 'disabled'}>
                <PaginationLink
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    switchPage(pager.pagerStart + 2);
                  }}
                >
                  <i className="fas fa-angle-right"/>
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        </CardFooter>
      </Card>
    </Container>
  );
};

export default QuotationComparisonList;