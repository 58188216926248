import React from 'react';
import { Row, Col, Table } from "reactstrap";
import OemMappingSearchListItem from './OemMappingSearchListItem';
import OemMappingSearchFooter from './OemMappingSearchFooter';
import ErrorMessage from './ErrorMessage';
import Can from '../../../config/Casl/Can';
import * as permissions from '../../../config/Casl/permissions';

const OemMappingSearchList = (props) => {
    const {
        form: {
            searchResult
        },
        errors = {},
        handleSaveClick,
        handleResetClick,
        handleToggleCheckbox
    } = props;

    return (
        <>
            <Row className="m-1" >
                <Col xs="12">
                    <Table borderless>
                        <thead>
                            <tr>
                                <th width="40%">Make</th>
                                <th width="40%">Product Name</th>
                                <th width="20%">Vehicle Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                searchResult.map((item, index) => (
                                    <OemMappingSearchListItem
                                        handleToggleCheckbox={handleToggleCheckbox}
                                        index={index}
                                        item={item}
                                        key={`search_row_${index}`}
                                    />)
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {errors.length > 0 &&
                <Row className="mt-5">
                    <Col xs="12" justify="center" align="center">
                        <ErrorMessage
                            errors={errors}
                        />
                    </Col>
                </Row>}

            <Can I={permissions.PARTNERS.oem_store} a={permissions.PARTNERS.subject}>
                <Row>
                    <Col xs="12" justify="center" align="center">
                        <OemMappingSearchFooter
                            handleResetClick={handleResetClick}
                            handleSaveClick={handleSaveClick}
                        />
                    </Col>
                </Row>
            </Can>
        </>
    );
};

export default OemMappingSearchList;