import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import OemMappingListItem from './OemMappingListItem';
import AlertModal from "../../../components/Modal/Alert";
import NoDataFound from './NoDataFound';

const OemMappingList = (props) => {
    const {
        boolean,
        form,
        handleCancel,
        handleConfirm,
        handleDelete,
        selectedObject
    } = props;

    return (
        <>
            <Card>
                <CardHeader>
                    Existing OEM Mapping
                </CardHeader>

                <CardBody>
                    <Row className="m-1" >
                        <Col xs="12">
                            {
                                form.mappedList.length > 0 ?
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Make</th>
                                                <th>Product Name</th>
                                                <th>Vehicle Type</th>
                                                <th>Created At</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                form.mappedList.map((item, index) => (
                                                    <OemMappingListItem
                                                        handleDelete={handleDelete}
                                                        index={index}
                                                        item={item}
                                                        key={item.id}
                                                        selectedObject={selectedObject}
                                                    />)
                                                )
                                            }
                                        </tbody>
                                    </Table> :
                                    <NoDataFound
                                        title={"No OEM mapping found"}
                                    />
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {
                boolean.showAlert &&
                <AlertModal
                    showCancel={true}
                    confirmText="Yes"
                    cancelText="Cancel"
                    isDisabled={boolean.deletionSubmitted}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    title={`Are you sure to delete "${selectedObject?.vehicle_make} - ${selectedObject?.product_name} - ${selectedObject?.vehicle_type}" mapping?`}
                />
            }
        </>
    );
}

export default OemMappingList;