import React, {Component, Fragment} from 'react';
import {
    Button,
    Card,
    CardHeader,
    Media,
    Breadcrumb,
    BreadcrumbItem,
    Collapse,
    Table,
    Container,
    Row,
    Col,
    Input,
    Modal, Form, FormGroup,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import AxiosRestClient from '../../../utilities/AxiosRestClient'

import {Search} from "react-bootstrap-table2-toolkit";
import {connect} from "react-redux";
import * as actions from '../../../Store/actions';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import Spinner from '../../../components/Spinner/Spinner';

import Can from '../../../config/Casl/Can'
import * as permissions from '../../../config/Casl/permissions';

class ManageFloat extends Component {
    state = {
        showFloatDialog: false,
        currentProductId: "",
        currentProvider: null,
        currentProduct: null,
        partnerList: null,
        selectedPartnerId: '',
        amount: 0
    }

    constructor(props) {
        super(props)
        this.props.onGetInsuranceProvidersList()
    }

    closeRangeModal = () => {
        this.setState({
            showFloatDialog: false
        })
    }
    sendDateRequest = () => {
        if (this.state.currentProduct && (this.state.currentProduct.is_group_policy && !this.state.currentProduct.is_assured) && this.state.selectedPartnerId === "") {
            this.showMsg("Please select a partner", "danger")
            return
        }
        if (this.state.amount * 1 <= 0) {
            this.showMsg("Amount should be greater than zero", "danger")
            return
        }
        console.log({
            partner_id: this.state.selectedPartnerId,
            product_id: this.state.currentProduct.id,
            amount: this.state.amount * 1
        });

        AxiosRestClient.postRequest('admin/float/add', {
            partner_id: this.state.selectedPartnerId,
            product_id: this.state.currentProduct.id,
            amount: this.state.amount * 1
        }).then(response => {
            this.showMsg("Float amount added successfully", "success")
            this.state.currentProductId !== '' && this.refreshPartners(this.state.currentProductId)
            this.setState({
                showFloatDialog: false
            })
        })
            .catch(err => {
                if (err.response)
                    this.showMsg(err.response.data.error.message, "danger")
                else
                    this.showMsg(err.message, "danger")
            });
    }
    openDialog = (provider, product, partnerList) => {
        this.setState({
            currentProvider: provider,
            currentProduct: product,
            partnerList: partnerList,
            amount: 0,
            selectedPartnerId: partnerList.length > 0 ? partnerList[0]["id"] : "",
            showFloatDialog: true,
        })
    }

    refreshPartners(prod_id) {
        this.props.onGetProductPartnersList(prod_id)
    }

    getPartners = (prod_id) => {
        this.state.currentProductId !== prod_id ? this.setState({
            currentProductId: prod_id
        }, () => {
            this.props.onGetProductPartnersList(prod_id)
        }) : this.setState({
            currentProductId: ""
        })
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }

    render() {
        return (
            <Fragment>
                <Container className="mt-3" fluid>
                    <Row>
                        <Col>
                            <Breadcrumb
                                className="d-none d-md-inline-block ml-md-4"
                                listClassName="breadcrumb-links"
                            >
                                <BreadcrumbItem>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        <i className="ni ni-money-coins"/>
                                    </a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                        Insurance Providers
                                    </a>
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row><br/>


                    {this.props.providerListLoading && <div className="text-center m-3">
                        <Spinner/>
                    </div>}
                    {
                        this.props.providerList && this.props.providerList.map((provider, top_index) => {
                            return <Card hidden={provider.products.length < 1} key={top_index}>
                                <CardHeader className="  border-0 ">
                                    <h3 className=" mb-0">{provider.name}</h3>
                                </CardHeader>
                                <hr className="m-0 bg-white opacity-5"/>
                                <Table
                                    className="align-items-center  table-flush"
                                    responsive
                                >
                                    <tbody className="list">
                                    {provider.products && provider.products.length < 1 &&
                                    <h5 className="text-center text-gray m-2">No Products</h5>}

                                    {
                                        provider.products.map((product, index) => {
                                            return <tr key={top_index + "abc" + index} className="cursor-pointer">
                                                <td className="p-0">
                                                    <tr className="d-table w-100"
                                                        id={"product" + index + "" + top_index}>

                                                        <th className="py-2 col-lg-4" onClick={e => {
                                                            e.preventDefault();
                                                            this.getPartners(product.id);
                                                        }}>
                                                            <Media className="align-items-center">
                                                                <a
                                                                    className="avatar bg-white rounded-circle mr-3 border"
                                                                    href="#pablo"
                                                                    onClick={e => e.preventDefault()}
                                                                >
                                                                    <img
                                                                        alt=""
                                                                        className="bg-white"
                                                                        src={product.image}
                                                                    />
                                                                </a>
                                                                <Media>
                                                                        <span className="name mb-0 text-sm">
                                                                            {product.name + " "}{(product.is_assured) ? "(A)" : ((product.is_group_policy) ? "(G)" : "(R)")}
                                                                        </span>
                                                                </Media>
                                                            </Media>
                                                        </th>

                                                        <td className=" col-lg-3" onClick={e => {
                                                            e.preventDefault();
                                                            this.getPartners(product.id);
                                                        }}>
                                                            <div className="avatar-group d-flex">
                                                                {
                                                                    product.partners.map((partner, p_index) => {
                                                                        return <a
                                                                            className="avatar avatar-sm bg-white rounded-circle border"
                                                                            href="#pablo"
                                                                            // id={"partner_" + p_index + "_" + top_index}
                                                                            onClick={e => e.preventDefault()}

                                                                            title={partner.name}
                                                                        >
                                                                            <img
                                                                                className="bg-white"
                                                                                alt=""
                                                                                src={partner.logo}
                                                                            />
                                                                        </a>
                                                                    })
                                                                }

                                                            </div>
                                                            {(product.partners.length > 0) && <small>PARTNERS</small>}
                                                        </td>
                                                        <td className="budget py-2  col-lg-3" onClick={e => {
                                                            e.preventDefault();
                                                            this.getPartners(product.id);
                                                        }}>{product.orders_count} <br/><small>POLICIES SOLD</small>
                                                        </td>

                                                        <td className="col- py-2  col-lg-2 text-right">
                                                            <Can I={permissions.PROVIDERS.float_add}
                                                                 a={permissions.PROVIDERS.subject}>
                                                                <Button
                                                                    className="btn-icon-only rounded-circle"
                                                                    color="success"
                                                                    type="button"

                                                                    // size="sm"
                                                                    title="Add float"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.openDialog(provider, product, product.partners)
                                                                    }

                                                                    }
                                                                >
                                                                        <span className="btn-inner--icon">
                                                                            <i className="ni ni-fat-add"/>
                                                                        </span>
                                                                </Button>
                                                            </Can>
                                                        </td>
                                                    </tr>

                                                    {(this.state.currentProductId === product.id) &&
                                                    <Collapse isOpen={this.state.currentProductId === product.id}>

                                                        <Table
                                                            className="align-items-center table-flush"
                                                            responsive
                                                            striped
                                                        >
                                                            <thead className="thead-light">
                                                            <tr>
                                                                <th>PARTNER</th>
                                                                <th>LAST FLOAT</th>
                                                                <th>CURRENT FLOAT</th>
                                                                <th>USED FLOAT</th>
                                                                <th className="text-right">TOTAL FLOAT</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                !this.props.productPartnersLoading && !this.props.nonPartner && this.props.productPartnersList && this.props.productPartnersList.length < 1 &&
                                                                <tr>
                                                                    <td>
                                                                        <h5 className="text-center text-gray m-2 w-100">No
                                                                            Partners</h5>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                this.props.nonPartner && !this.props.productPartnersLoading &&
                                                                <tr>
                                                                    <td className="table-user">

                                                                        <b>NA</b>
                                                                    </td>
                                                                    <td>

                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            {this.props.nonPartner.last_float != null ? this.props.nonPartner.last_float : "NA"}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            {this.props.nonPartner.current_float != null ? this.props.nonPartner.current_float : "NA"}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            {this.props.nonPartner.used_float != null ? this.props.nonPartner.used_float : "NA"}

                                                                        </a>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >

                                                                            {this.props.nonPartner.total_float != null ? this.props.nonPartner.total_float : "NA"}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            }

                                                            {
                                                                this.props.productPartnersLoading && <tr>
                                                                    <td className="table-user">

                                                                        <Shimmer/>
                                                                    </td>
                                                                    <td>

                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            <Shimmer/>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            <Shimmer/>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >
                                                                            <Shimmer/>

                                                                        </a>
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <a
                                                                            className="font-weight-bold"
                                                                            href="#pablo"
                                                                            onClick={e => e.preventDefault()}
                                                                        >

                                                                            <Shimmer/>
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            }


                                                            {
                                                                this.props.productPartnersList && !this.props.productPartnersLoading && this.props.productPartnersList.map((partner, index_ss) => {
                                                                    return <tr key={"abcdef_" + index_ss}>
                                                                        <td className="table-user">
                                                                            <img
                                                                                alt=""
                                                                                className="avatar rounded-circle mr-3 bg-white border"
                                                                                src={partner.logo}
                                                                            />
                                                                            <b>{partner.name}</b>
                                                                        </td>
                                                                        <td>

                                                                            <a
                                                                                className="font-weight-bold"
                                                                                href="#pablo"
                                                                                onClick={e => e.preventDefault()}
                                                                            >
                                                                                {partner.last_float != null ? partner.last_float : "NA"}
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a
                                                                                className="font-weight-bold"
                                                                                href="#pablo"
                                                                                onClick={e => e.preventDefault()}
                                                                            >
                                                                                {partner.current_float != null ? partner.current_float : "NA"}
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <a
                                                                                className="font-weight-bold"
                                                                                href="#pablo"
                                                                                onClick={e => e.preventDefault()}
                                                                            >
                                                                                {partner.used_float != null ? partner.used_float : "NA"}

                                                                            </a>
                                                                        </td>
                                                                        <td className="text-right">
                                                                            <a
                                                                                className="font-weight-bold"
                                                                                href="#pablo"
                                                                                onClick={e => e.preventDefault()}
                                                                            >

                                                                                {partner.total_float != null ? partner.total_float : "NA"}
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                })

                                                            }


                                                            </tbody>
                                                        </Table>

                                                    </Collapse>
                                                    }
                                                </td>
                                            </tr>

                                        })
                                    }

                                    </tbody>
                                </Table>
                            </Card>
                        })
                    }

                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={this.state.showFloatDialog}
                        toggle={() => this.closeRangeModal()}
                    >
                        <div className="modal-body">

                            <Form role="form">

                                <Row className="input-daterange datepicker align-items-center">
                                    <Col xs={12} sm={6}>
                                        <label className=" form-control-label">
                                            Provider
                                        </label>
                                        <p>{this.state.currentProduct && this.state.currentProvider.name}</p>
                                        <FormGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <label className=" form-control-label">
                                                Product
                                            </label>
                                            <p>{this.state.currentProduct && this.state.currentProduct.name}</p>
                                        </FormGroup>
                                    </Col>
                                    <Col
                                        hidden={this.state.currentProduct && (!this.state.currentProduct.is_group_policy || this.state.currentProduct.is_assured)}
                                        xs={12} sm={6}>
                                        <FormGroup>
                                            <label className=" form-control-label" htmlFor="select_partner">
                                                Partner
                                            </label>

                                            <Input id="select_partner" type="select" onChange={(e) => {
                                                this.setState({
                                                    selectedPartnerId: e.target.value
                                                })
                                            }}>
                                                {
                                                    this.state.partnerList && this.state.partnerList.map((partner) => {
                                                        return <option value={partner.id}>{partner.name}</option>
                                                    })
                                                }

                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormGroup>
                                            <label className=" form-control-label"
                                                   htmlFor="amountInput">
                                                Amount
                                            </label>

                                            <Input
                                                id="amountInput"
                                                placeholder="123..."
                                                type="number"
                                                min="0"
                                                value={this.state.amount}
                                                onChange={(e) => {
                                                    this.setState({
                                                        amount: e.target.value
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        <div className="modal-footer date-modal-footer ">
                            <Button
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.closeRangeModal()}
                            >
                                Close</Button>
                            <Button color="primary"
                                    className="ml-auto" type="button"

                                    onClick={() => this.sendDateRequest()}
                                    size="sm"
                            >
                                ADD FLOAT</Button>
                        </div>
                    </Modal>


                </Container>

                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert"/>
                </div>
            </Fragment>

        );
    }
}


const mapStateToProps = state => {
    return {
        providerListLoading: state.provider.insuranceProvidersLoading,
        productPartnersLoading: state.provider.productPartnersLoading,

        providerList: state.provider.insuranceProvidersList,
        productPartnersList: state.provider.productPartnersList,
        nonPartner: state.provider.nonPartner,
        error: state.provider.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetInsuranceProvidersList: () => dispatch(actions.getInsuranceProvidersList()),
        onGetProductPartnersList: (prod_id) => dispatch(actions.getProductPartnersList(prod_id)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFloat);