import React from 'react';
import { Collapse } from 'reactstrap';

function TableToggleRow({ children, SlideComponent, expand, setExpand, expandId }) {
    const [activeId, setActiveId] = React.useState(expand);

    function getDetailsPane(){
        return <SlideComponent/>
    }

    React.useEffect(() =>{
        setActiveId(expand)
    },[expand])

    function onRowClick(){
        if(activeId!==expandId){
            setExpand(expandId)
            setActiveId(expandId)
        }else{
            setExpand("")
            setActiveId("")
        }
    }

    return (
        React.Children.map(children, (child) => {
            return <>
                {React.cloneElement(child,{onClick:onRowClick})}
                <tr>
                    <td colSpan="10" style={{border:"none",padding:0}}>
                        <Collapse isOpen={activeId===expandId}>
                            {getDetailsPane()}
                        </Collapse>
                    </td>
                </tr>
            </>
        })
    );
}

export default TableToggleRow;