import React, { useContext, useState, useEffect, useReducer, Fragment } from "react";
import {
	Card,
	Container,
	Row,
	Col,
	BreadcrumbItem,
	Breadcrumb,
	CardBody,
	CardFooter,
	Button, 
	FormGroup, 
	Input
} from "reactstrap";
import LeadContext from '../../../context/leadContext';
import { Dropdown } from "semantic-ui-react";
import { options } from '../../../defaultValues/lead';
import AxiosRestClient from "../../../utilities/AxiosRestClient";

const DogDetailsForm = (props) => {
  const { 
    errorMessage,
    form,
    handleChange,
    updateDetails,
    handleDateChange,
    isDogDetailsNextButtonDisabled,
    isFormSubmitted,
    isValidDate,
    setForm,
    validateInputLength
  } = props;
  const { state: { leadDetails = {} } } = useContext(LeadContext);
   const [filter, setFilter] = useState({ search: '', sortBy: '', sortType: 'desc', status: '', dealerText:"", sourceText:"" });
  const [selectedSources, setSelectedSources] = useState([]);
  const [sources, setSources] = useState([{text: "Others", value: "Others", id: "Others"},{text: 'B2C Leads', value: 'B2C Leads', id: 'B2C Leads'}]);
  const [sourceLoader, setSourceLoader] = useState(false);
  const [sourceBandWidth, setSourceBandWidth] = useState();

  useEffect(() => {
	console.log(leadDetails)
    if (leadDetails) {
		console.log(leadDetails)
      setForm({
        ...form,
        dog_name: leadDetails.dog_name || '',
        dog_gender: leadDetails.dog_gender || '',
        dog_breed: leadDetails.dog_breed || '',
		    other_breed: leadDetails.other_breed || '',
        dog_age_year: leadDetails.dog_age_year !== '' ? leadDetails.dog_age_year : '',
        dog_age_month: leadDetails.dog_age_month !== '' ? leadDetails.dog_age_month : '',
		    dog_mark: leadDetails.dog_mark || '',
        spayed_castrated: leadDetails.spayed_castrated || 'Not Applicable',
        defects_ailments: leadDetails.defects_ailments || '',
        source_id: leadDetails.source_id || '',
        payment_status: leadDetails.payment_status || '',
        add_ons: leadDetails.add_ons || 'None',
      });
    }
  }, [leadDetails]);

  const isDogAgeMonthVisible = (item) => {
    // return ((form.dog_age_year == 0 && item.value != 0) || form.dog_age_year != 0);

    let isVisible = false;

    if (form.dog_age_year == 0 || form.dog_age_year == 7) {
      if (form.dog_age_year == 0  && item.value > 2) {
        isVisible = true;
      } else if (form.dog_age_year == 7  && item.value < 1) {
        isVisible = true;
      }
    } else {
      isVisible = true;
    }
    
    return isVisible;
  };

  useEffect(() => {
	getSources({})
  },[])

  function onSourceChange(e,value){
	e.target = {value:value.value,name:"source_id"}
	handleChange(e)
  }

  const getSources = async(params) => {
    try{
      setSourceLoader(true);
      const res = await AxiosRestClient.getRequest("dealers",{...params,partner_slug:"pet-partner", limit:0})
      if(res.data?.data?.dealers){
        const sourceList = res.data?.data?.dealers.map((item)=>{
          item.text = item.name;
		  item.value = item.id;
		  return item;
        })
		setSourceBandWidth(res?.data?.data?.totalCount)
        const filterObj = {};
        [...sourceList, ...sources].forEach(item=>{  
          if(!filterObj[item.id||item.value]){
            filterObj[item.id||item.value] = item;
          }
        });
        
        setSources([...Object.values(filterObj)]);
        setSourceLoader(false)
        // return res.data?.data?.dealers;
      }
    }catch(error){
      console.log(error);
      return [];
    }
  }

	let timeout = null;

  const onSearchChange = (data,value)=>{
    try{
      if(data[1]===""){
        return data;
      }
      if(timeout){
        clearTimeout(timeout);  
      }
      timeout = setTimeout(function(){
        // dispatchEvent(ReducerUtils.leads.search, event.target.value);
        // setPagination({ ...pagination });
        // getSources({dealer_search:this.value});
      }.bind({value:value?.searchQuery}),2000)
    }catch(error){
      console.log(error);
    }
  }

  function getSourceId(id){
	if(id){
		let src = sources.find(i=>i.text===id || i.value===id);
		src = src?.id || src?.value;
		console.log(src)
		if(src){
			form.source_id = src
		}
		return src;
	}

  }
	
	return (
		<Fragment>
			<Container fluid>
				<Card className="m-0 mt-1">
					<CardBody className="">
						<div className="">
							<div className={"mb-2"}>
								<h3>Fill in details of dog</h3>
							</div>
							<FormGroup>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is your dog’s name?
										</label>
									</div>
									<div className={""}>
										<Input
										label="Name of your dog"
										id="outlined-margin-none"
										name="dog_name"
										type={'text'}
										value={form.dog_name}
										onInput={validateInputLength}
										onChange={handleChange}
										/>
                    {errorMessage.dog_name && <small className="text-danger">{errorMessage.dog_name}</small>}
									</div>
								</div>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is your dog's gender?
										</label>
									</div>
									<div className={""}>
										<select
											className="form-control"
											name="dog_gender"
											value={form.dog_gender}
											onChange={handleChange}>
											<option value={""}>Select</option>
											{options.gender.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
                    {errorMessage.dog_gender && <small className="text-danger">{errorMessage.dog_gender}</small>}
									</div>
								</div>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is your dog's breed?
										</label>
									</div>
									<div className={""}>
										<select
											className="form-control"
											name="dog_breed"
											value={form.dog_breed}
											onChange={handleChange}>
											<option value={""}>Select</option>
											{options.breed.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
                    {errorMessage.dog_breed && <small className="text-danger">{errorMessage.dog_breed}</small>}
									</div>
								</div>
                {form.dog_breed === 'others' &&
                <div className={"mt-2"}>
									<div className={""}>
                    <Input
                      label="Other Breed Name"
                      id="outlined-margin-none"
                      name="other_breed"
                      type={'text'}
                      value={form.other_breed}
                      // onInput={validateInputLength}
                      onChange={handleChange}
                    />
                    {errorMessage.other_breed && <small className="text-danger">{errorMessage.other_breed}</small>}
									</div>
								</div>}
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is your dog's age?
										</label>
									</div>
									<div className={"d-flex w-100"}>
										<div className={"w-100 mr-2"}>
                      <select
												className="form-control"
												name="dog_age_year"
                        value={form.dog_age_year}
												onChange={handleChange}>
												<option value={""}>Select</option>
                        {options.year.map((item, index) => (
                          <option value={item.value} key={index}>{item.title}</option>
                        ))}
											</select>
                      {errorMessage.dog_age_year && <small className="text-danger">{errorMessage.dog_age_year}</small>}
										</div>
										<div className={"w-100"}>
                      <select
												className="form-control"
												name="dog_age_month"
                        value={form.dog_age_month}
												onChange={handleChange}>
												<option value={""}>Select</option>
                        {options.month.map((item, index) => (
                          isDogAgeMonthVisible(item) && <option value={item.value} key={index}>{item.title}</option>
                        ))}
											</select>
                      {errorMessage.dog_age_month && <small className="text-danger">{errorMessage.dog_age_month}</small>}
										</div>
									</div>
								</div>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										Does your dog have any unique identification marks?
										</label>
									</div>
									<div className={""}>
                    <Input
                      label="Unique Identification Marks"
                      id="outlined-margin-none"
                      name="dog_mark"
                      type={'text'}
                      value={form.dog_mark}
                      onInput={validateInputLength}
                      onChange={handleChange}
                    />
                    {errorMessage.dog_mark && <small className="text-danger">{errorMessage.dog_mark}</small>}
									</div>
								</div>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3"	htmlFor="dealer">
										Is your dog Spayed or Castrated?
										</label>
									</div>
									<div className={""}>
                  <select
											className="form-control"
                      name="spayed_castrated"
											value={form.spayed_castrated}
											onChange={handleChange}>
											<option value={""}>Select</option>
											{options.spayedCastrated.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
                    {errorMessage.spayed_castrated && <small className="text-danger">{errorMessage.spayed_castrated}</small>}
									</div>
								</div>
								<div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										Does your dog have any defects or ailments?
										</label>
									</div>
									<div className={""}>
										<select
											className="form-control"
                      name="defects_ailments"
											value={form.defects_ailments}
											onChange={handleChange}>
											<option value={""}>Select</option>
											{options.defectAilments.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
                    {errorMessage.defects_ailments && <small className="text-danger">{errorMessage.defects_ailments}</small>}
									</div>
								</div>

                <div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is source?
										</label>
									</div>
									<div className={""}>
										{/* <select
											className="form-control"
											name="source_id"
											value={form.source_id}
											onChange={handleChange}
                      { ...(form.source_id === 'Doggie the App') && { disabled: true } }
                    >
											{options.source.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select> */}
										<Dropdown
										placeholder='Select Source'
										fluid
										search
										selection
										value={getSourceId(form.source_id)}
										loading={sourceLoader}
										onChange={onSourceChange}
										onSearchChange={onSearchChange}
										options={sources}
										/>
									</div>
								</div>

                <div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What is payment status?
										</label>
									</div>
									<div className={""}>
										<select
											className="form-control"
											name="payment_status"
											value={form.payment_status}
											onChange={handleChange}
                      { ...(1 === 1) && { disabled: true } }
                    >
											{options.paymentStatus.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
									</div>
								</div>

                <div className={""}>
									<div>
										<label className=" form-control-label mt-3" htmlFor="dealer">
										What addons?
										</label>
									</div>
									<div className={""}>
										<select
											className="form-control"
											name="add_ons"
											value={form.add_ons}
											onChange={handleChange}>
											{options.addons.map((item, index) => (
												<option value={item.value} key={index}>{item.title}</option>
											))}
										</select>
									</div>
								</div>
							</FormGroup>
						</div>
					</CardBody>

					<CardFooter className="py-4">
            <div className="text-right">
              <Button className="shadow-none mt-3" 
                size="md"
                contained={"true"}
                color={"primary"}
                type="button" 
                onClick={(e)=>updateDetails(e,sources)}
                { ...( (isDogDetailsNextButtonDisabled(form, errorMessage) || isFormSubmitted) && { disabled: true } ) }
              >
                Next
              </Button>
            </div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};

export default DogDetailsForm;