import Dashboard from "./Index";
import BusinessDashboard from "./BusinessDashboard";
import * as permissions from '../../../config/Casl/permissions';

const dashboardRoute = {
    path: "/dashboard",
    collapse: true,
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    state: "dashboardCollapse",
    permission: permissions.DASHBOARD.menu,
    subject: permissions.DASHBOARD.subject,
    views: [{
            path: "/dashboard/home",
            name: "Home",
            component: Dashboard,
            layout: "/admin",
            permission: permissions.DASHBOARD.menu,
            subject: permissions.DASHBOARD.subject,
        },
        {
            path: "/dashboard/business",
            name: "Business Dashboard",
            component: BusinessDashboard,
            layout: "/admin",
            invisible: true,
        },

    ]
    
};
export default dashboardRoute;
