import React from "react";
import NotificationAlert from "react-notification-alert";
class Toastrr extends React.Component {
    notify = (type, text, title) => {
        let options = {
            place: "tr",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {title}
                    </span>
                    <span data-notify="message">
                        {text}
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 10
        };
        this.refs.notificationAlert.notificationAlert(options);
    };
    componentWillReceiveProps(nextProps) {

    }
    render() {
        return (
            <>
                <div className="rna-wrapper">
                    <NotificationAlert ref="notificationAlert" />
                </div>
            </>
        );
    }
}

export default Toastrr;
