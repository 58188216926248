import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../../Store/actions';
import CustomDropdown from '../../../components/Filters/CustomDropdown';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import Spinner from "../../../components/Spinner/Spinner";
import ReactDatetime from "react-datetime";
import {
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
import Can from '../../../config/Casl/Can'
import * as permissions from '../../../config/Casl/permissions';

const orderIdSearch = /^[0-9]*$/;

class Finance extends Component {
  previousMonthDate = (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1)
  constructor(props) {
    super(props);
    this.state = {
      hideFilters: false,
      searchText: "",
      view: "finance-table",
      productId: '',
      partnerId: '',
      hideProductDropdown: true,
      openDateDialog: false,
      openMonthDialog: false,
      cardFilter: 'custom',
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      selectedDate: (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      selectedCardsFilterDate: new Date()
    };
    props.onGetPartnerFinanceCardsData(this.buildDefaultParams())
    this.getFinance()
  }
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
  }
  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1)
    }
  }
  switchPage = (page) => {
    if (((this.pager.pagerStart + 1) !== page)) {
      this.pager.pagerStart = page - 1
      this.getFinance()
    }
  }
  getFinance = () => {
    this.props.onGetPartnerFinanceListData({}, { start: this.pager.pagerStart, product_id: this.state.productId, partner_id: this.state.partnerId, month: this.state.selectedCardsFilterDate.getMonth() + 1, year: this.state.selectedCardsFilterDate.getFullYear() })
  }
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if ((page - start) > 2 && (start === 1)) {
        return true;
      } else if ((start !== 1) && ((page - start) > 1)) {
        return true;
      }
    } else {
      if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
        return true;
      } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
        return true;
      }
    }
    return false
  }
  productChanged = (productId) => {
    this.pager.pagerStart = 0;
    this.setState({
      productId: productId
    }, () => {
      this.getFinance()
    })
  }
  partnerChanged = (partnerId) => {
    this.pager.pagerStart = 0;
    this.setState({
      partnerId: partnerId
    }, () => {
      this.getFinance()
    })
  }
  componentDidMount() {
    this.props.onGetProductFinanceFilterListData()
    this.props.onGetPartnerFinanceFilterListData()
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  refreshAllComponents(filter, params) {
    this.props.onGetPartnerFinanceListData(filter, params)
  }
  refreshFilters = () => {
    this.pager.pagerStart = 0
    this.setState({
      productId: '',
      partnerId: '',
      view: "finance-table",
      searchText: ""
    }, () => {
      this.getFinance()
    })
  }
  onSearch = (id) => {
    if (!this.state.searchText.match(orderIdSearch)) {
      this.showMsg("Please, enter numeric value only", "danger")
      return
    }
    this.props.onGetPartnerFinanceListData('', { order_id: id })
    this.setState({
      view: "table"
    })
  }
  onRefreshAllCards = (filter) => {
    this.pager.pagerStart = 0
    this.setState({
      cardFilter: filter
    })
    this.props.onGetPartnerFinanceCardsData({})
  }
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach(element => {
        classArray = classArray.concat(element.className && element.className.split(" "));
      });
    } else {
      e.composedPath().forEach(element => {
        classArray = classArray.concat(element.className && element.className.split(" "));
      });
    }
    if (!classArray.includes('reactDateTimeCustomClass')) {
      this.setState({
        openMonthDialog: false
      })
    }
  }
  handleReactMonthFilterChange(e) {
    this.pager.pagerStart = 0;
    this.setState({
      cardFilter: 'custom',
      selectedCardsFilterDate: e._d
    }, () => {
      this.getFinance()
    })
    this.props.onGetPartnerFinanceCardsData({ month: e._d.getMonth() + 1, year: e._d.getFullYear() })
  }
  getMonthYear = (date) => {
    return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
  }
  validDateMonthFilter = (current) => {
    return (current.isSameOrBefore(new Date()));
  };
  getTwoFixedPoint = (num) => {
    if (!num) {
      return 0;
    }
    var x = num * 1;
    return x.toFixed(2)
  }
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type
    })
  }
  buildDefaultParams = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    return { month, year };
  }
  render() {
    this.financeList = this.props.partnerFinanceListData ? this.props.partnerFinanceListData : [];
    (this.props.financeTotalCount !== null) && this.updatePagination(this.props.financeTotalCount)
    return (<>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 bg-transparent px-0 ">
            <Row>
              <Col xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-0"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary h5 font-weight-400" onClick={e => e.preventDefault()}>
                      Partners
                      </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary h5 font-weight-400" onClick={e => e.preventDefault()}>
                      Finance
                      </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" xs="6">
                <div className="d-inline">
                  {/* <Badge
                    color={this.state.cardFilter === 'all' ? 'primary' : 'secondary'}
                    href="#pablo"
                    pill
                    onClick={e => {
                      e.preventDefault();
                      this.onRefreshAllCards('all')
                    }}
                  >
                    All</Badge> */}
                  <Badge
                    color={this.state.cardFilter === 'custom' ? 'primary' : 'secondary'}
                    href="#pablo"
                    pill
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        openMonthDialog: true,
                      })
                    }}
                  >
                    {this.state.cardFilter === 'custom' ? this.getMonthYear(this.state.selectedCardsFilterDate) : "Select Month"}
                  </Badge>
                  <ReactDatetime
                    className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                    open={this.state.openMonthDialog}
                    onFocus={e => {
                      this.setState({
                        openMonthDialog: true
                      })
                    }}
                    onBlur={e => {
                      this.setState({
                        openMonthDialog: false
                      })
                    }}
                    disableCloseOnClickOutside={false}
                    inputProps={{
                      placeholder: "Month"
                    }}
                    value={this.state.selectedCardsFilterDate}
                    dateFormat="MMM-YYYY"
                    timeFormat={false}
                    onChange={e => {
                      this.handleReactMonthFilterChange(e)
                      this.setState({
                        openMonthDialog: false
                      })
                    }}
                    isValidDate={this.validDateMonthFilter}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Can I={permissions.PARTNERS.finance_cards} a={permissions.PARTNERS.subject}>
            <Row>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted text-white mb-0"
                        >
                          Total Float Amount
                          </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {(!this.props.partnerFinanceCardsLoading && this.props.partnerFinanceCardsData) ? "₹ " + this.getTwoFixedPoint(this.props.partnerFinanceCardsData.floatAmount) : <Shimmer />}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                    </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className=" bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted text-white mb-0"
                        >
                          Total Revenue
                          </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {(!this.props.partnerFinanceCardsLoading && this.props.partnerFinanceCardsData) ? "₹ " + this.getTwoFixedPoint(this.props.partnerFinanceCardsData.revenue) : <Shimmer />}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted text-white mb-0"
                        >
                          Policies Count
                        </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {(!this.props.partnerFinanceCardsLoading && this.props.partnerFinanceCardsData) ? this.props.partnerFinanceCardsData.policiesSold : <Shimmer />}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className=" bg-gradient-primary card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted text-white mb-0"
                        >
                          Total Claims
                          </CardTitle>
                        <span className="h2 font-weight-bold text-white mb-0">
                          {(!this.props.partnerFinanceCardsLoading && this.props.partnerFinanceCardsData) ? this.props.partnerFinanceCardsData.claims : <Shimmer />}
                        </span>
                      </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap text-light">
                        {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Can>
          <Row className="py-1 ">
            <Col xs={12} sm={6}>
              <h5 className="text-primary h5 font-weight-400" >
                Transactions
              </h5>
            </Col>
            <Col>
              <div className="float-right">
                <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button" onClick={e => {
                  this.setState({
                    hideFilters: !this.state.hideFilters
                  })
                }}>
                  <span className="btn-inner--icon">
                    <i class="fas fa-filter"></i>
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
          <div >
            <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
              <Col className="p-1 px-3 text-muted">
                <small>
                  <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                    <input className="ml-2 bg-white border-0 text-muted pr-2"
                      value={this.state.searchText}
                      onKeyDown={e => {
                        (e.key === 'Enter') && this.onSearch(e.target.value)
                      }}
                      onChange={e => {
                        if (e.target.value == " ") {
                          this.setState({
                            view: "finance-table"
                          })
                        }
                        this.setState({
                          searchText: e.target.value
                        })
                      }}
                      type="text" placeholder="Order ID" aria-label="Search" />
                    <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                      onClick={
                        e => {
                          e.preventDefault()
                          this.onSearch(this.state.searchText)
                        }}
                    ></i>
                  </form>
                </small>
                {(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                  {this.state.searchText}
                  <span className="p-1 ml-2 cursor-pointer" onClick={
                    e => {
                      e.preventDefault()
                      this.refreshFilters()
                      this.setState({
                        view: "finance-table",
                        searchText: ""
                      })
                    }} >
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </span>
                </Badge>}
              </Col>
              <Col className="p-1 text-right px-3">
                <CustomDropdown name="Product" selectedId={this.state.productId} handleSelection={id => { this.productChanged(id) }} options={this.props.productFinanceFilterListData} />
                <CustomDropdown name="Partner" selectedId={this.state.partnerId} handleSelection={id => { this.partnerChanged(id) }} options={this.props.partnerFinanceFilterListData} />
                <Button color="secondary " outline size="sm" type="button" onClick={e => {
                  e.preventDefault()
                  this.refreshFilters()
                }}>
                  Clear
                </Button>
              </Col>
            </Row>
          </div>
          <Card className="m-0 mt-1" style={{ minHeight: "708px" }}>
            <CardBody className="px-0 py-0" >
              <div className="table-responsive">
                <Table className="align-items-center table-flush" hover responsive>
                  <thead className="thead-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Transaction ID</th>
                      <th>Transaction Date</th>
                      <th>Amount</th>
                      <th>Transaction Type</th>
                      <th className="text-right">Product Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.partnerFinanceListLoading && <tr >
                        <td colSpan="4">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    }
                    {!this.props.partnerFinanceListLoading && this.props.partnerFinanceListData && this.props.partnerFinanceListData.length < 1 && <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                      <td colSpan="6" className="text-center">
                        <span className="text-muted">
                          <b>
                            No Transactions found
                          </b>
                        </span>
                      </td>
                    </tr>}
                    {!this.props.partnerFinanceListLoading && this.financeList.map((finance, index) => (
                      <tr key={finance.order_id}>
                        <td className="table-user">{finance.order_id}</td>
                        <td>{finance.transaction_id ? finance.transaction_id : "N/A"}
                        </td>
                        <td>{finance.transaction_date}</td>
                        <td>{"₹ " + this.getTwoFixedPoint(finance.amount)} {finance.transaction_type && finance.transaction_type === "DEBIT" ? <span className="text-danger">[Dr]</span> : <span className="text-success">[Cr]</span>}</td>
                        <td>{finance.transaction_type_description}</td>
                        <td className="text-right">{finance.product_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {!this.props.partnerFinanceListLoading && this.props.partnerFinanceListData && this.props.partnerFinanceListData.length > 0 && <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {this.pager.pageArray.map(page => {
                    return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                      <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          this.switchPage(page);
                        }}
                      >
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  })}
                  <PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart + 2);
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>}
          </Card>
        </Container>
      </Fragment>
    </>
    );
  }
}
const mapStateToProps = state => {
  return {
    partnerFinanceListLoading: state.partnerFinance.partnerFinanceListLoading,
    partnerFinanceCardsLoading: state.partnerFinance.partnerFinanceCardsLoading,

    partnerFinanceListData: state.partnerFinance.partnerFinanceListData,
    financeTotalCount: state.partnerFinance.totalFinanceListCount,
    partnerFinanceCardsData: state.partnerFinance.partnerFinanceCardsData,

    productFinanceFilterListData: state.partnerFinance.productFinanceFilterListData,
    partnerFinanceFilterListData: state.partnerFinance.partnerFinanceFilterListData,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onGetPartnerFinanceListData: (filter, params) => dispatch(actions.getPartnerFinanceListData(filter, params)),
    onGetPartnerFinanceCardsData: (data) => dispatch(actions.getPartnerFinanceCardsData(data)),
    onGetProductFinanceFilterListData: () => dispatch(actions.getProductFinanceFilterListData()),
    onGetPartnerFinanceFilterListData: () => dispatch(actions.getPartnerFinanceFilterListData()),
    onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Finance);
