import React, { Component, Fragment } from "react";
import {
  Card,
  PaginationItem,
  Pagination,
  PaginationLink,
  Container,
  Row,
  Col,
  BreadcrumbItem,
  Breadcrumb,
  CardBody,
  CardFooter,
  Table,
  Button,
  Modal,
  Form,
  FormGroup
} from "reactstrap";
import * as actions from "../../../Store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import moment from "moment";
import ReactDatetime from "react-datetime";

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMonthDialog: false,
      cardFilter: "all",
      startDateFilter: "",
      endDateFilter: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedDate:
        new Date().getMonth() === 0
          ? new Date(new Date().getFullYear() - 1, 11)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      showMultiSelectOption: false,
      approveInvoiceDialog: false,
      approveInvoiceId: null,
      alert: false,
      notificationData: null,
      bulkApproveCheckAll: false,
      bulkApproveInvoiceIds: [],
      bulkApproveInvoiceDialog: false,
      isSubmitted: false,
      updateInvoiceDialog: false,
      invoiceDetails: null,
      startDate: moment(moment(new Date())).subtract(6, "days").format('YYYY-MM-DD'),
      endDate: moment(moment(new Date())).format('YYYY-MM-DD')
    };
    this.getInvoices();
  }

  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);

  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
    dealerId: "",
    productIds: [],
    providerIds: [],
    sortBy: 0,
    filter: "today",
  };

  getInvoices = () => {
    this.props.onGetInvoiceListData({
      page: this.pager.pagerStart,
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD')
    });
  };

  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getInvoices();
    }
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };

  dateChangeFilter = (date, type) => {
    switch (type) {
      case "startDate":
        this.setState(
          {
            startDateFilter: date,
          },
          () => {
            this.getInvoices();
          }
        );
        break;
      case "endDate":
        this.setState(
          {
            endDateFilter: date,
          },
          () => {
            this.getInvoices();
          }
        );
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.props.onGetInvoiceListData();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  sortByColumn = (column_no) => {
    this.pager.sortBy = column_no;
    this.getInvoices();
  };

  onRefreshAllCards = (filter) => {
    this.setState({
      cardFilter: filter,
    });
  };
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    }
    if (!classArray.includes("reactDateTimeCustomClass")) {
      this.setState({
        openMonthDialog: false,
      });
    }
  };

  handleReactMonthFilterChange(e) {
    this.setState({
      cardFilter: "custom",
      selectedCardsFilterDate: e._d,
    });
  }

  getMonthYear = (date) => {
    return (
      this.state.months[new Date(date).getMonth()] +
      " " +
      new Date(date).getFullYear()
    );
  };
  validDateMonthFilter = (current) => {
    var thisMonth = new Date();
    thisMonth.setDate(1);
    return current.isBefore(thisMonth);
  };
  productChanged = (id) => {};
  providerChanged = (id) => {};

  openMultiSelectOption() {
    this.setState({
      showMultiSelectOption: true,
    });
  }
  closeMultiSelectOption() {
    this.setState({
      showMultiSelectOption: false,
    });
  }

  openRangeModal() {
    this.setState({
      showRangeModal: true,
    });
  }
  closeRangeModal() {
    this.setState({
      showRangeModal: false,
    });
  }
  sendDateRequest() {
    this.getInvoices();
  }
  clearFilter () {
    this.pager.pagerStart = 0;
    this.state.startDate = moment(moment(new Date())).subtract(6, "days").format('YYYY-MM-DD');
    this.state.endDate = moment(moment(new Date())).format('YYYY-MM-DD');

    this.getInvoices();
  }
  onReactDateFocused = (who) => {
    if (who === "startDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: true,
      });
    } else {
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
  };
  handleReactDatetimeChange = (who, date) => {
    this.setState({
      [who]: moment(date).format('YYYY-MM-DD'),
    });

    if (who === "startDate") {
      this.setState({
        endDate: moment(date).add(6, 'days').format('YYYY-MM-DD')
      });
    }
  };
  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };

  onCustomDateSelection = (filter) => {
    this.pager.filter = filter;
    this.getInvoices();
  };

  approveInvoice = (id) => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.postRequest("v2/invoices/" + id + "/approve")
      .then((res) => {
        this.showMsg("Invoice Approved successfuly");
        this.setState({
          showLoader: false,
          approveInvoiceDialog: false,
          approveInvoiceId: null,
        });
        this.getInvoices();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
          approveInvoiceDialog: false,
          approveInvoiceId: null,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  onClickApprove = (id) => {
    this.setState({ approveInvoiceDialog: true, approveInvoiceId: id });
  };

  exportInvoices = async () => {
    this.setState({ isSubmitted: true });
    const params = {
      startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.state.endDate).format('YYYY-MM-DD')
    };
    const response = await AxiosReactClient.getRequest("v2/invoices/export", params);
    const { message } = response.data
    this.showMsg(message ?? 'Invoice has been sent successfully to your registered email.');
    this.setState({ isSubmitted: false });
  };

  getTotalPendingCount = () => {
    let totalPendingCount = 0;

    if (this.props.invoiceListData && this.props.invoiceListData.length) {
      this.props.invoiceListData.map((item) => {
        if (!item.is_approved) {
          ++totalPendingCount;
        }
      });
    }

    return totalPendingCount;
  };

  bulkApproveCheckAllHandle = (event) => {
    this.setState({ bulkApproveInvoiceIds: [] });
    this.setState({ bulkApproveCheckAll: event.target.checked });

    if (event.target.checked) {
      let ids = [];
      this.props.invoiceListData.map((item) => {
        if (!item.is_approved) {
          ids.push(item.id);
        }
      });
      this.setState({ bulkApproveInvoiceIds: ids });
    } else {
      this.setState({ bulkApproveInvoiceIds: [] });
    }
  };

  individualCheckboxChangeHandler = (id) => {
    const shallowCopy = [...this.state.bulkApproveInvoiceIds];
    const findIndex = shallowCopy.indexOf(id);
    findIndex > -1 ? shallowCopy.splice(findIndex, 1) : shallowCopy.push(id);
    this.setState({ bulkApproveCheckAll: shallowCopy.length === this.getTotalPendingCount() })
    this.setState({ bulkApproveInvoiceIds: shallowCopy });
    this.forceUpdate();
  };

  bulkApproveInvoice = () => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.postRequest("v2/invoices/bulk-approve", { ids: this.state.bulkApproveInvoiceIds })
      .then((res) => {
        this.showMsg("Selected Invoices have been approved successfully");
        this.setState({
          showLoader: false,
          bulkApproveInvoiceDialog: false,
          bulkApproveInvoiceIds: [],
          bulkApproveCheckAll: false
        });
        this.getInvoices();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
          bulkApproveInvoiceDialog: false,
          bulkApproveInvoiceIds: [],
          bulkApproveCheckAll: false
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  onClickBulkApprove = () => {
    this.setState({ bulkApproveInvoiceDialog: true });
  };

  onClickUpdate = async (id) => {
    const response = await AxiosReactClient.getRequest(  `v2/invoices/${id}`);
    const { data } = response.data;

    this.setState({
      invoiceDetails: data,
    }, () => {
      this.setState({
        updateInvoiceDialog: true
      })
    });
  };

  updateInvoice = async () => {
    const response = await AxiosReactClient.putRequest(  `v2/invoices/${this.state.invoiceDetails.id}`);
    const { message } = response.data;

    this.getInvoices();
    this.setState({ updateInvoiceDialog: false });
    this.showMsg(message);
  };

  handleReactDatetimeChange = (type, date) => {
    let modifiedDate = moment(date).format("DD-MM-YYYY");
    
    if (this.state.startDate && type === "endDate" && new Date(this.state.startDate._d + "") > new Date(date._d + "")) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (this.state.endDate && type === "startDate" && new Date(this.state.endDate._d + "") < new Date(date._d + "")) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [type]: date,
      });
    }
    
    if (type === "startDate") {
      this.setState({
        endDate: moment(date).add(6, 'days').format('YYYY-MM-DD'),
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    } else {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: false,
      });
    }
  };

  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  disableFutureDt = (current) => {
    const today = moment();
    return current.isBefore(today);
  };

  isValidEndDate = (current) => {
    const endDate = moment(this.state.startDate).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');

    return current.isSameOrAfter(this.state.startDate) && current.isSameOrBefore(endDate);
  };
 
  render() {
    const { invoiceListData, invoiceListLoading } = this.props;
    this.props.totalRecords !== null &&
      this.updatePagination(this.props.totalRecords);
    return (
      <Fragment>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block "
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/admin/finance");
                      }}
                    >
                      Finance
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Invoices
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
          <div>
          <Row className="bg-white m-0 py-2">
            <Col sm={2}></Col>
            <Col className="text-right mt-3">
              <Row className="input-daterange datepicker align-items-center">
                <Col>
                  <FormGroup>
                    <label>Invoice Date:</label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <ReactDatetime
                      open={this.state.openStartDateDialog}
                      isValidDate={this.disableFutureDt}
                      onFocus={(e) =>
                        this.onReactDateFocused("startDate")
                      }
                      inputProps={{
                        placeholder: "Start Date",
                        readOnly: true
                      }}
                      value={this.state.startDate}
                      timeFormat={false}
                      onChange={(e) =>
                        this.handleReactDatetimeChange("startDate", e)
                      }
                      renderDay={(
                        props,
                        currentDate,
                        selectedDate
                      ) => {
                        let classes = props.className;
                        classes += this.getClassNameReactDatetimeDays(
                          currentDate
                        );
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <ReactDatetime
                      open={this.state.openEndDateDialog}
                      isValidDate={this.isValidEndDate}
                      onFocus={(e) =>
                        this.onReactDateFocused("endDate")
                      }
                      inputProps={{
                        placeholder: "End Date",
                        readOnly: true
                      }}
                      value={this.state.endDate}
                      timeFormat={false}
                      onChange={(e) =>
                        this.handleReactDatetimeChange("endDate", e)
                      }
                      renderDay={(
                        props,
                        currentDate,
                        selectedDate
                      ) => {
                        let classes = props.className;
                        classes += this.getClassNameReactDatetimeDays(
                          currentDate
                        );
                        return (
                          <td {...props} className={classes}>
                            {currentDate.date()}
                          </td>
                        );
                      }}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect={true}
                    />
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Button
                      color="primary"
                      className="ml-auto"
                      type="button"
                      onClick={() => this.sendDateRequest()}
                    >
                      Apply
                    </Button>
                    <Button
                      color="secondary"
                      className="ml-auto"
                      type="button"
                      onClick={() => this.clearFilter()}
                    >
                      Clear
                    </Button>
                  </FormGroup>
                </Col>
                <Col >
                  <FormGroup>
                    <Button color="btn-round btn-icon btn btn-outline-primary" type="button" 
                      onClick={(e) => this.onClickBulkApprove()}
                      {...(!this.state.bulkApproveInvoiceIds.length && { disabled: true })}
                    > 
                      Approve {this.state.bulkApproveInvoiceIds.length > 0 && `(${this.state.bulkApproveInvoiceIds.length})`}
                    </Button>
                  </FormGroup>
                </Col>
                <Col sm={1} className="mr-5">
                  <FormGroup>
                    <Button color="btn-round btn-icon btn btn-outline-primary" type="button" 
                      onClick={(e) => this.exportInvoices()}
                      {...((!invoiceListData || invoiceListData.length === 0 || this.state.isSubmitted) && { disabled: true })}
                    > Export
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
          <Card
            className="m-0 mt-1"
            style={{
              height: "1708px",
              overflowY: "scroll",
            }}
          >
            <CardBody
              className="px-0 py-0"
              style={{ height: "2066px", overflowY: "scroll" }}
            >
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <input type="checkbox" checked={this.state.bulkApproveCheckAll} className="mr-2"
                          onChange={(event) => this.bulkApproveCheckAllHandle(event)} />
                      </th>
                      <th>Invoice ID</th>
                      <th>Dealer Name</th>
                      <th>Dealership Name</th>
                      <th>Date </th>
                      <th>Invoice </th>
                      <th>Amount </th>
                      <th>Status </th>
                      <th>Period </th>
                      <th>Approved By</th>
                      <th className="text-right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceListLoading && (
                      <tr>
                        <td colSpan="12">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    )}
                    {!invoiceListLoading &&
                      invoiceListData &&
                      invoiceListData.length < 1 && (
                        <tr className="cursor-pointer">
                          <td colSpan="12" className="text-center">
                            <span className="text-muted">
                              <b>No invoice Data found</b>
                            </span>
                          </td>
                        </tr>
                      )}
                    {invoiceListData &&
                      invoiceListData.map((item, index) => {
                        return (
                          <tr key={index + "hgshdsh"}>
                            <td>
                              <input id={item.id} type="checkbox"
                                {...(this.state.bulkApproveInvoiceIds.indexOf(item.id) > -1 ? {checked: true} : {}) }
                                {...(item.is_approved && { disabled: true })}
                                onClick={() => this.individualCheckboxChangeHandler(item.id)} /> 
                            </td>
                            <td className="table-user">
                              {item.id}
                            </td>
                            <td
                              className="text-primary cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  "/admin/dealers/" + item.dealer.id
                                );
                              }}
                            >
                              {item.dealer && item.dealer.name}
                            </td>
                            <td
                              className="text-primary cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  "/admin/dealers/" + item.dealer.id
                                );
                              }}
                            >
                              {item.dealer && item.dealer.dealership_name}
                            </td>
                            <td>{item.date}</td>
                            <td
                              className="text-primary cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(item.pdf);
                              }}
                            >
                              {item.number}
                            </td>
                            <td>{item.amount}</td>
                            <td>{item.status}</td>
                            <td>{item.period}</td>
                            <td>{item.approver && item.approver.name}</td>
                            <td className="text-right">
                              {!item.is_approved && (
                                <Button
                                  color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                  size="sm"
                                  type="button"
                                  onClick={(e) => this.onClickApprove(item.id)}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-check" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Approve
                                  </span>
                                </Button>
                              )}
                              <Button
                                color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                size="sm"
                                type="button"
                                onClick={(e) => this.onClickUpdate(item.id)}
                              >
                                <span className="btn-inner--text">
                                  Update
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem
                    className={this.pager.pagerStart > 0 ? "" : "disabled"}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {this.pager.pageArray.map((page) => {
                    return (
                      <PaginationItem
                        key={"pager_" + page}
                        hidden={this.isHidden(page)}
                        className={
                          this.pager.pagerStart + 1 === page ? "active" : ""
                        }
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(page);
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}
                  <PaginationItem
                    className={
                      this.pager.pagerEnd > this.pager.pagerStart + 1
                        ? ""
                        : "disabled"
                    }
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart + 2);
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.approveInvoiceDialog}
            toggle={() => {
              this.setState({
                approveInvoiceDialog: !this.state.approveInvoiceDialog,
              });
            }}
          >
            <div className={"modal-header"}>
              <h4>Approve</h4>
              <i
                className="ni ni-fat-remove fnt-20 cursor-pointer"
                onClick={(e) => {
                  this.setState({
                    approveInvoiceDialog: false,
                  });
                }}
              />
            </div>
            <hr className={"text-dark m-0 p-0"} />
            <div className="modal-body text-center">
              <h5 className="mt-0 m-auto">
                Are you sure to approve this invoice?
              </h5>
            </div>
            <div className="modal-footer date-modal-footer ">
              <Button
                color="primary"
                className="ml-auto"
                type="button"
                size="sm"
                onClick={(e) => {
                  this.approveInvoice(this.state.approveInvoiceId);
                }}
              >
                Yes
              </Button>
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                size="sm"
                onClick={() => {
                  this.setState({
                    approveInvoiceDialog: false,
                  });
                }}
              >
                No
              </Button>
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.bulkApproveInvoiceDialog}
            toggle={() => {
              this.setState({
                bulkApproveInvoiceDialog: !this.state.bulkApproveInvoiceDialog,
              });
            }}
          >
            <div className={"modal-header"}>
              <h4>Approve</h4>
              <i
                className="ni ni-fat-remove fnt-20 cursor-pointer"
                onClick={(e) => {
                  this.setState({
                    bulkApproveInvoiceDialog: false,
                  });
                }}
              />
            </div>
            <hr className={"text-dark m-0 p-0"} />
            <div className="modal-body text-center">
              <h5 className="mt-0 m-auto">
                Are you sure to approve all the selected invoices?
              </h5>
            </div>
            <div className="modal-footer date-modal-footer ">
              <Button
                color="primary"
                className="ml-auto"
                type="button"
                size="sm"
                onClick={(e) => {
                  this.bulkApproveInvoice();
                }}
              >
                Yes
              </Button>
              <Button
                color="primary"
                data-dismiss="modal"
                type="button"
                size="sm"
                onClick={() => {
                  this.setState({
                    bulkApproveInvoiceDialog: false,
                  });
                }}
              >
                No
              </Button>
            </div>
          </Modal>

          <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={this.state.updateInvoiceDialog}
              toggle={() => {
                this.setState({
                  updateInvoiceDialog: !this.state.updateInvoiceDialog,
                });
              }}
          >
            <div className={"modal-header"}>
              <h4>Update Invoice</h4>
              <i
                  className="ni ni-fat-remove fnt-20 cursor-pointer"
                  onClick={(e) => {
                    this.setState({
                      updateInvoiceDialog: false,
                    });
                  }}
              />
            </div>
            <hr className={"text-dark m-0 p-0"} />
            <div className="modal-body text-center">
              <div className="table-responsive">
                <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Product</th>
                      <th>Type</th>
                      <th className="text-center">Pricing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.invoiceDetails &&
                    Object.keys(this.state.invoiceDetails.pricings).map((productId, index) => {
                      return (
                        <tr key={index + "parent"}>
                          <td className="table-user"> {this.state.invoiceDetails.pricings[productId].name} </td>
                          <td>{`${this.state.invoiceDetails.pricings[productId].is_transfer_pricing ? 'Transfer Pricing' : 'Commision'}`}</td>
                          <td className="text-center">
                            {
                              this.state.invoiceDetails.pricings[productId].is_transfer_pricing
                                ? Object.keys(this.state.invoiceDetails.pricings[productId].data).map((keyName, innerIndex) => {
                                    return keyName ? <div key={innerIndex + "child"}>{`${keyName ? keyName : ''} : ${this.state.invoiceDetails.pricings[productId].data[keyName]}%`}</div>
                                        : <div key={innerIndex + "child"}>{this.state.invoiceDetails.pricings[productId].data[keyName]}%</div>
                                  })
                                : this.state.invoiceDetails.pricings[productId].data.is_flat
                                  ? 'Rs. ' + this.state.invoiceDetails.pricings[productId].data.value
                                  : this.state.invoiceDetails.pricings[productId].data.value + '%'
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <hr />
              {this.state.invoiceDetails &&
              <div className="d-flex justify-content-around align-content-center my-4">
                <div>Invoice ID: <br />{this.state.invoiceDetails.id}</div>
                <div>Dealership Name: <br />{this.state.invoiceDetails.dealer.name}</div>
                <div>Amount: <br />{this.state.invoiceDetails.amount}</div>
                <div>Period: <br />{this.state.invoiceDetails.period}</div>
              </div>}

              <hr />
              {this.state.invoiceDetails &&
              <div className="d-flex align-content-center m-4">
                If you want to change the above mentioned rates then please &nbsp;<a href={`/admin/dealers/${this.state.invoiceDetails.dealer.id}`} target="_blank">click here</a>
              </div>}
            </div>
            <div className="modal-footer date-modal-footer ">
              <Button
                  color="primary"
                  className="ml-auto"
                  type="button"
                  onClick={(e) => {
                    this.updateInvoice();
                  }}
              >
                Yes
              </Button>
              <Button
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    this.setState({
                      updateInvoiceDialog: false,
                    });
                  }}
              >
                No
              </Button>
            </div>
          </Modal>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoiceListData: state.invoices.invoiceListData,
    invoiceListLoading: state.invoices.invoiceListLoading,
    totalRecords: state.invoices.totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetInvoiceListData: (params) =>
      dispatch(actions.getInvoiceListData(params)),
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
