import React from "react";
import { Col } from "reactstrap";

const UnifiedPoliciesSearch = (props) => {
  const {
    filter,
    searchHandler,
  } = props;

  return (
    <Col md={6} className="p-1 px-3 text-muted">
      <small className="form-inline md-form mr-3 mb-4 d-inline">
        <div className="form-group border justify-content-between rounded-5">
          <input
            type="text"
            name="searchText"
            className="form-control flex-grow-1 border-0"
            placeholder="Search by Policy Number or Insured Name"
            aria-label="search"
            aria-describedby="basic-addon1"
            value={filter.searchText}
            onChange={searchHandler}
          />
          <div className="input-group">
            <div className="input-group-prepend">
              <span
                className="input-group-text cursor-pointer border-0 mr-2"
                id="basic-addon1"
              >
                <i
                  className="fas fa-search cursor-pointer mt-2"
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
        </div>
      </small>
    </Col>
  );
};

export default UnifiedPoliciesSearch;
