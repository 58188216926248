export const mapping = {
    policyStatus: {
        pending: 'Pending'
    },
    plan: {
        basic_plan: 'TWO WHEELER PACKAGE POLICY',
        basic_plan_rsa: 'TWO WHEELER PACKAGE POLICY + DRIVE ASSURE BASIC',
        one_yr_od_tp: 'TWO WHEELER PACKAGE POLICY',
        standalone_tp: 'LIABILITY TO THIRD PARTY',
        standalone_od: 'OWN DAMAGE ONLY'
    },
    planDescription: {
        basic_plan: '1 Year OD + 5 Year TP',
        basic_plan_rsa: '1 Year OD + 5 Year TP + Depreciation Shield',
        one_yr_od_tp: '1 Year OD + 1 Year TP',
        standalone_tp: '1 Year TP',
        standalone_od: '1 Year OD'
    },
    addOns: {
        S1: '24 X 7 Spot Assistance',
        DRIVE_ASSURE_BASIC: 'Drive Assure Basics',
        compulsory_deductible: 'Compulsory Deductible',
        part_depreciation_protect: 'Parts Depreciation Protect',
        consumable_cover: 'Consumable Cover',
        engine_and_gear_box_protect: 'Engine and Gear Box Protect',
        return_to_invoice: 'Return to Invoice',
        road_side_assistance: 'Road Side Assistance',
        S2: 'Drive Assure Silver'
    },
    existingPolicyStatus: {
        not_expired: 'Not-Expired',
        expired_within_90_days: 'Expired within 90 days',
        expired_more_than_90_days_ago: 'Expired more than 90 days ago',
        never_bought: 'Never Bought'
    },
    existingPolicyType: {
        bundled: 'Bundled (1 Year Own Damage + 5 Year Third Party)',
        comprehensive: 'Comprehensive (1 Year Own Damage + 1 Year Third Party)',
        third_party_only: 'Third Party Only',
        own_damage_only: 'Own Damage Only'
    },
    maritalStatus: {
        "S": "Single",
        "M": "Married",
        "D": "Divorced",
        "W": "Widow"
    }
};

export const DEALER = 'dealer';
export const CUSTOMER = 'customer';
export const PAYMENT_BY_DEALER = 'Dealer';
export const PAYMENT_BY_CUSTOMER = 'Customer';
export const NOT_AVAILABLE = 'N/A';
export const BASIC_PLAN_RSA_SLUG = 'basic_plan_rsa';
export const DRIVE_ASSURE_BASIC = 'DRIVE_ASSURE_BASIC';
export const OWN_DAMAGE_SLUG = 'own_damage';
export const RENEWAL_PACKAGE_TP_ONLY = 'standalone_tp';
export const RENEWAL_PACKAGE_OD_ONLY = 'standalone_od';
export const COMPULSORY_DEDUCTIBLE = 'compulsory_deductible';
export const VEHICLE_OWNER_TYPE_INDIVIDUAL = 'individual';
export const VEHICLE_OWNER_TYPE_COMPANY = 'company';
export const YES_TEXT = 'Yes';
export const NO_TEXT = 'No';

export const renderPaymentByText = ({ status_id = 1, manufacturer_detail: { payment_by = null } }) => {
    return status_id === 1 ? NOT_AVAILABLE : ((!payment_by || payment_by === DEALER) ? PAYMENT_BY_DEALER : PAYMENT_BY_CUSTOMER);
};

export const renderPACoverYesNoText = ({ manufacturer_detail: { pa_cover_required, pa_cover = [], selected_plan, vehicle_owner_type }}) => {
    let text = '';

    if (selected_plan === RENEWAL_PACKAGE_OD_ONLY || vehicle_owner_type === VEHICLE_OWNER_TYPE_COMPANY) {
        text = NOT_AVAILABLE;
    } else {
        if (pa_cover.length) {
            pa_cover.map((item) => {
                text = item.checked ? YES_TEXT : NO_TEXT;
            });
        } else {
            text = pa_cover_required ? YES_TEXT : NO_TEXT;
        }
    }

    return text;
};

export const isCheckedDriveAssureBasicAddOn = ({ is_new_insurance, selected_plan, selected_addons=[] }) => {
    return is_new_insurance 
        ? selected_plan === BASIC_PLAN_RSA_SLUG 
        : (selected_plan === RENEWAL_PACKAGE_TP_ONLY ? false : selected_addons.indexOf(DRIVE_ASSURE_BASIC) > -1);
};

export const buildDefaultAddonObject = () => {
    return {
        amount: 0,
        amount_not_available: true,
        editable: true
    };
};

export const buildODAddonsObject = ({ manufacturer_detail: { od_addons = [] }, manufacturer_detail }) => {
    if (od_addons.length) {
        return od_addons;
    }

    return {
        ...buildDefaultAddonObject(),
        checked: isCheckedDriveAssureBasicAddOn(manufacturer_detail),
        slug: DRIVE_ASSURE_BASIC,
        type: OWN_DAMAGE_SLUG,
    };
};

export const buildODAddonsList = (orderDetails = null) => {
    return new Array(buildODAddonsObject(orderDetails));
};

export const renderSelctedAddOns = (addOns = []) => {
    let selectedAddOns = [];

    addOns.map(item => {
        if (item.checked && item.slug !== COMPULSORY_DEDUCTIBLE) {
            selectedAddOns.push(mapping.addOns[item.slug]);
        }
    });

    return selectedAddOns;
};

export const renderOwnDamageAddOns = (orderDetails = null) => {
    const { manufacturer_detail: { od_addons = [] }} = orderDetails;
    const addOns = od_addons?.length ? od_addons : buildODAddonsList(orderDetails);
    const selectedAddOns = renderSelctedAddOns(addOns);

    return selectedAddOns.length ? selectedAddOns.join(', ') : NOT_AVAILABLE;
};

export const renderOwnDamageDiscount = ({ manufacturer_detail: { od_discounting = [] }}) => {
    let discount = NOT_AVAILABLE;

    if (od_discounting.length) {
        od_discounting.map(item => {
            if (item.checked) {
                discount = item.percentage ? `${item.percentage}%` : '0%';
            }
        });
    }

    return discount;
};

export const renderThirdPartyAddOnsCoverage = (orderDetails = null) => {
    const { manufacturer_detail: { tp_addons = [] }} = orderDetails;
    let coverage = 0;

    if (tp_addons.length) {
        tp_addons.map(item => {
            if (item.checked && item.sum_insured) {
                coverage = `₹${item.sum_insured}` ?? 0;
            }
        });
    }

    return tp_addons.length && coverage ? coverage : NOT_AVAILABLE;
};