import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { Col, Container, Row } from "reactstrap";
// import Papa from "papaparse";
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import UnifiedPoliciesFilter from "./filter";
import UnifiedPoliciesHeader from "./breadcrumb";
import UnifiedPoliciesAppliedFilters from "./applied-filters";
import UnifiedPoliciesSearch from "./search";
import UnifiedPoliciesCount from "./count";
import UnifiedPoliciesExport from "./export";
import UnifiedPoliciesList from "./list";
import UnifiedPoliciesReducer from "../../../../reducers/unifiedPoliciesReducer";
import useApi from "../../../../hooks/useApi";
import Notification from '../../../../components/CustomUi/Notify/Notification';
// import Loader from "../../../../components/CustomUi/Loader/Loader";
import { initialUnifiedPolicyReducerState } from "../otherPolicyDefaultValues";
import { ReducerUtils } from "../../../../constants/reducers";
import {
  buildSearchUnifiedPoliciesPayload,
  buildUnifiedPoliciesCsvHeaders,
  calculateUpdatedToDate,
  getAppliedFilters,
  getAppliedFiltersCount,
  getUnifiedPoliciesCSVFileName,
  initialUnifiledPoliciesFilter,
  rebuildListExcludingNullableValues,
  // buildUnifiedPoliciesTableHeaders,
  // exportData,
} from "../../../../helpers/otherPolicyDetails";
import { notify } from "../../../../helpers/utils";
// import UnifiedPoliciesTable from "./unifiedPoliciesTable";

const UnifiedPolicies = (props) => {
  const pageSize = 300;
  const csvHeaders = buildUnifiedPoliciesCsvHeaders();
  // const tableHeaders = buildUnifiedPoliciesTableHeaders();

  // Call useApi hook to fetch other policies list
  const masterData = useApi("unified/master/data");

  const [state, dispatch] = useReducer(
    UnifiedPoliciesReducer,
    initialUnifiedPolicyReducerState
  );

  const [filter, setFilter] = useState({ ...initialUnifiledPoliciesFilter });
  const [loader, setLoader] = useState(true);
  const [isLoaded, setIsLoaded] = useState({ masters: false, listing: false });
  const [isSubmitted, setIsSubmitted] = useState({
    apply: false,
    clear: false,
  });
  const [options, setOptions] = useState({
    insurers: [],
    productCategories: [],
    productSubCategories: [],
    channels: [],
    platforms: [],
  });
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pagesCount: Math.ceil(0 / pageSize),
  });

  useEffect(() => {
    masterData && syncUnifiedMasterData(masterData);
  }, [masterData]);

  /* useEffect(() => {
    fetchUnifiedMasterData();
  }, []); */

  useEffect(() => {
    setFilter({
      ...filter,
      selectedToDate: filter.selectedFromDate
        ? calculateUpdatedToDate(filter)
        : null,
    });
  }, [filter.selectedFromDate]);

  useEffect(() => {
    setPagination({
      ...pagination,
      pagesCount: Math.ceil(state.unifiedPolicesList.length / pageSize),
    });
  }, [state.unifiedPolicesList]);

  useEffect(() => {
    filter.clear && handleApplyFilterClick("clear", 0);
  }, [filter.clear]);

  useEffect(() => {
    isLoaded.masters && handleApplyFilterClick("apply", 0);
  }, [isLoaded.masters]);

  const dispatchEvent = (type, payload) => {
    dispatch({ type, payload });
  };

  const syncUnifiedMasterData = ({
    insurers = [],
    product_categories = [],
    product_sub_categories = [],
    channels = [],
    platforms = [],
  } = {}) => {
    setOptions({
      ...options,
      insurers: rebuildListExcludingNullableValues(insurers),
      productCategories: rebuildListExcludingNullableValues(product_categories),
      productSubCategories: rebuildListExcludingNullableValues(product_sub_categories),
      channels: rebuildListExcludingNullableValues(channels),
      platforms: rebuildListExcludingNullableValues(platforms),
    });
    setStateIsLoaded("masters", true);
  };

  /* const fetchUnifiedMasterData = async () => {
    const response = await AxiosReactClient.getRequest("unified/master/data");

    const { data } = response.data;
    syncUnifiedMasterData(data);
  }; */

  const handleClick = (e, index) => {
    e.preventDefault();
    setPagination({ ...pagination, currentPage: index });
  };

  const handleSearch = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
    dispatchEvent(ReducerUtils.unifiedPolicies.search, event.target.value);
    setPagination({ ...pagination, currentPage: 0 });
  };

  const handleMultiSelectChange = (values, keyname) => {
    setFilter({
      ...filter,
      [keyname]: values,
    });
    setStateIsLoaded("listing", false);
  };

  const handleDateChange = (keyname, value) => {
    setFilter({
      ...filter,
      [keyname]: value,
    });
    setStateIsLoaded("listing", false);
  };

  const handleClearFilterClick = () => {
    setFilter({ ...initialUnifiledPoliciesFilter, clear: true });
    setPagination({ ...pagination, currentPage: 0 });
  };

  const handleApplyFilterClick = async (isSubmittedKeyname = "apply", sendEmail = 1) => {
    setPagination({ ...pagination, currentPage: 0 });
    setStateIsLoaded("listing");
    setStateIsSubmitted(isSubmittedKeyname, true);
    const response = await AxiosReactClient.postRequest(
      "unified/policies",
      buildSearchUnifiedPoliciesPayload(filter, sendEmail)
    );
    const { data } = response.data;

    dispatchEvent(ReducerUtils.unifiedPolicies.list, data);
    setPagination({
      ...pagination,
      currentPage: 0,
      pagesCount: Math.ceil(data.length / pageSize),
    });
    
    setStateIsLoaded("listing", true);
    setStateIsSubmitted(isSubmittedKeyname, false);
    data.length
      ? setLoader(false)
      : setTimeout(() => {
          setLoader(false);
      }, 1000);
    
    sendEmail && notify("This report has been sent to your registered email. Please check your mailbox after 2 minutes.");
    
    // Convert data to CSV format
    /* const csvData = Papa.unparse(response.data);
    exportData(csvData, "unified-policies.csv", "text/csv;charset=utf-8;"); */
  };

  const setStateIsLoaded = (keyname, flag = false) => {
    setIsLoaded({
      ...isLoaded,
      [keyname]: flag,
    });
  };

  const setStateIsSubmitted = (keyname, flag = false) => {
    setIsSubmitted({
      ...isSubmitted,
      [keyname]: flag,
    });
  };

  return (
    <Container fluid>
      <UnifiedPoliciesHeader />

      {isLoaded.masters && (
        <div>
          <UnifiedPoliciesFilter
            changeHandler={handleMultiSelectChange}
            changeDateHandler={handleDateChange}
            clearFilterHandler={handleClearFilterClick}
            clickHandler={handleApplyFilterClick}
            csvHeaders={csvHeaders}
            filter={filter}
            list={state.unifiedPolicesList}
            // listApi={state.unifiedPolicesListApi}
            options={options}
            searchHandler={handleSearch}
            isLoaded={isLoaded}
            isSubmitted={isSubmitted}
          />

          {isLoaded.listing && (
            <UnifiedPoliciesAppliedFilters filter={filter} title={"Applied Filters"} />
          )}

          {isLoaded.listing && (
            <Row className="bg-white mt-5 py-2">
              <UnifiedPoliciesSearch
                filter={filter}
                searchHandler={handleSearch}
              />

              {/* <UnifiedPoliciesCount
                count={getAppliedFiltersCount(filter)}
                title={"Applied Filters"}
              /> */}

              {/* <UnifiedPoliciesCount
                count={state.unifiedPolicesList.length}
                title={"Total Polices"}
              /> */}

              <UnifiedPoliciesExport
                csvHeaders={csvHeaders}
                filename={getUnifiedPoliciesCSVFileName(filter)}
                list={state.unifiedPolicesList}
              />
            </Row>
          )}
        </div>
      )}

      {/* {state.unifiedPolicesList.length > 0 && <UnifiedPoliciesTable headers={tableHeaders} userData={state.unifiedPolicesList} />} */}

      {isLoaded.listing && (
        <UnifiedPoliciesList
          handleClick={handleClick}
          loader={loader}
          list={state.unifiedPolicesList}
          pageSize={pageSize}
          pagination={pagination}
        />
      )}

      <Notification autoClose={10000} />
    </Container>
  );
};

export default UnifiedPolicies;
