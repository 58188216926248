import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  Fragment,
} from "react";
import {
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardBody,
  CardHeader,
  Modal,
} from "reactstrap";
import ExamStudyMaterialContext from "../../../context/agentExamContext";
import AgentExamReducer from "../../../reducers/agentExamReducer";
import useApi from "../../../hooks/useApi";
import { ReducerUtils } from "../../../constants/reducers";
import {
  buildSubmitAgentExamPayload,
  isAgentExamSubmitButtonDisabled,
  reBuildQuestionList,
} from "../../../helpers/utils";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";

const rowStyle = {
  border: "1px solid #dee2e6",
  borderRadius: "20px",
  padding: "10px",
  margin: "20px 10px",
};

const AgentExam = (props) => {
  const initialReducerState = useContext(ExamStudyMaterialContext);
  const [state, dispatch] = useReducer(AgentExamReducer, initialReducerState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    score: null,
    result: null,
  });
  const quesions = useApi(`questions`);

  useEffect(
    () => dispatchEvent(ReducerUtils.agentExam.questions, quesions),
    [quesions]
  );

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const handleAnswerRadio = (questionId, choiceId) => {
    let newRadios = JSON.parse(JSON.stringify(state?.examQuestions));

    newRadios.map((item) => {
      item.question_choices.map((choice) => {
        if (item.id === questionId) {
          choice.checked = choice.id === choiceId;
        }
      });
    });

    dispatchEvent(ReducerUtils.agentExam.questions, newRadios);
  };

  const navigateToListing = () => {
    props.history.push("/admin/agents/all");
  };

  const submitQuestion = async () => {
    setIsFormSubmitted(true);
    const payload = buildSubmitAgentExamPayload(state?.examQuestions);

    const response = await AxiosReactClient.postRequest(
      `exam/${props.match.params.id}`,
      payload
    );
    const {
      data: { general_exam_result: result, general_exam_score: score },
    } = response.data;

    setIsFormSubmitted(false);

    setModal({ ...modal, isOpen: true, score: (score * 100) / 20, result });
  };

  const fetchQuestions = async () => {
    const response = await AxiosReactClient.getRequest(`questions`);
    const { data } = response.data;

    dispatchEvent(ReducerUtils.agentExam.questions, reBuildQuestionList(data));
  };

  const handleRetakeExamClick = () => {
    console.log("handle retake exam");

    setModal({ ...modal, isOpen: false, score: null, result: null });
    fetchQuestions();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    submitQuestion();
  };

  const renderQuestionChoice = (item, choice, innerKey) => {
    return (
      <div key={`choice_${innerKey}`} className="pl-5 mt-2">
        <Label>
          <Input
            type="radio"
            name={`radio_${choice.question_id}`}
            value={choice.id}
            checked={item.checked}
            onChange={() => handleAnswerRadio(item.id, choice.id)}
          />{" "}
          &nbsp;&nbsp;
          {choice.choice_text}
          {/* {`${choice.is_correct ? 'T' : 'F'}`} */}
        </Label>
      </div>
    );
  };

  const renderQuestion = (item, index) => {
    return (
      <Row key={`question_${item.id}`} style={rowStyle}>
        <Col xs="12">
          <Row>
            <Col>
              <p>{`${index + 1}. ${item.question_text}`}</p>
            </Col>
          </Row>
          {item.question_choices.map((choice, innerKey) => {
            return renderQuestionChoice(item, choice, innerKey);
          })}
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card className="mt-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h4 className="h4 pt-2 font-weight-400">
                      Agent Certification Examination
                    </h4>
                  </Col>
                  <Col xs="6"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" className="pl-6">
                    In this exam, there are 20 questions, each carrying 1 mark.
                    Agents must score at least 50% to pass, and there is no
                    negative marking.
                  </Col>
                </Row>
                <Form onSubmit={onSubmit}>
                  {state?.examQuestions?.length > 0 && (
                    <Container fluid>
                      {state?.examQuestions?.map((item, index) => {
                        return renderQuestion(item, index);
                      })}
                      <Row>
                        <Col xs="12" className="text-center">
                          <Button
                            color="btn-round btn-icon btn btn-primary btn-md"
                            size="md"
                            type="submit"
                            {...((isAgentExamSubmitButtonDisabled(
                              state?.examQuestions
                            ) ||
                              isFormSubmitted) && { disabled: true })}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal.isOpen}
        toggle={() =>
          setModal({ ...modal, isOpen: false, score: null, result: null })
        }
      >
        <div className="modal-header">Exam Result</div>
        <div className="modal-body">
          <Row>
            <Col xs={12}>
              {modal.result
                ? "Congratulations! you have passed this exam. Now you are a certified POSP Agent."
                : `In this exam, you scored only ${modal.score}% marks. To pass, you need at least 50% marks. Please retake this exam.`}
            </Col>
          </Row>
        </div>
        <div className="modal-footer date-modal-footer ">
          {modal.result ? (
            <Button
              color="primary"
              className="ml-auto"
              type="button"
              size="sm"
              onClick={navigateToListing}
            >
              Proceed
            </Button>
          ) : (
            <Button
              color="primary"
              className="ml-auto"
              type="button"
              size="sm"
              onClick={handleRetakeExamClick}
            >
              Retake Exam
            </Button>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default AgentExam;
