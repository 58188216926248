import React from "react";
import ReactDatetime from "react-datetime";

class CustomDateSelect extends React.Component {
  state = {
    hideDropdown: true,
    selectedFilter: "",
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    }
    if (!classArray.includes("reactDateTimeCustomClass")) {
      this.setState({
        openMonthDialog: false,
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.selectedDate === "" &&
      this.setState({
        selectedDate: nextProps.selectedDate,
      });
  }

  dateChanged = (date) => {
    this.setState({
      selectedDate: new Date(date._d),
      openMonthDialog: false,
    });
    this.props.dateChanged(date._d);
  };

  render() {
    return (
      <>
        <div
          className="d-inline text-muted cursor-pointer hoverBg px-2 py-1"
          onClick={(e) => {
            this.setState({
              openMonthDialog: true,
            });
          }}
        >
          <small>
            {" "}
            {this.props.name}:{" "}
            <b>
              {this.state.selectedDate &&
                this.state.selectedDate.toLocaleDateString()}
            </b>
            <i className="fa fa-angle-down ml-1" aria-hidden="true" />
          </small>
        </div>

        <ReactDatetime
          className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
          open={this.state.openMonthDialog}
          onFocus={(e) => {
            this.setState({
              openMonthDialog: true,
            });
          }}
          onBlur={(e) => {
            this.setState({
              openMonthDialog: false,
            });
          }}
          disableCloseOnClickOutside={false}
          inputProps={{
            placeholder: "Month",
          }}
          value={this.state.selectedDate}
          dateFormat="DD-MMM-YYYY"
          timeFormat={false}
          onChange={(e) => {
            this.dateChanged(e);
          }}
          isValidDate={this.validDateMonthFilter}
        />
      </>
    );
  }
}

export default CustomDateSelect;
