import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from 'reactstrap';
import DropdownCustom from '../../../components/dropDown';

// Import all custom default exports
import OtherPoliciesReducer from '../../../reducers/otherPoliciesReducer';
import Loader from '../../../components/CustomUi/Loader/Loader';
import Spinner from '../../../components/Spinner/Spinner';
import useApi from '../../../hooks/useApi';

// Import all third party named exports
import { CSVLink } from "react-csv";

// Import all custom named exports
import { initialOtherPolicyReducerState } from './otherPolicyDefaultValues';
import { getOptionsList, renderResponse } from '../../../helpers/otherPolicyDetails';
import { ReducerUtils } from '../../../constants/reducers';
import { convertToArray, downloadFile, downloadZipFile } from '../../../helpers/utils';
import { buildCsvHeaders, extractTypeAndUrl, rebuildAPIResponse } from '../../../helpers/otherPolicyDetails';

const Paginator = ({ pagination, handleClick }) => {
  return (
    <Pagination aria-label="Page navigation example"
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0">
      
      <PaginationItem disabled={pagination.currentPage <= 0}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>

      {[...Array(pagination.pagesCount)].map((page, i) => 
        <PaginationItem active={i === pagination.currentPage} key={i}>
          <PaginationLink onClick={e => handleClick(e, i)} href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      )}

      <PaginationItem disabled={pagination.currentPage >= pagination.pagesCount - 1}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage + 1)}
          next
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

const OtherPoliciesList = (props) => {
  const pageSize = 25;
  const csvHeaders = buildCsvHeaders();

  // Call useApi hook to fetch other policies list
  const policiesList = useApi('v2/documents', { type: 'DOCUMENT_OTHER_POLICES' }, false, []);

  const [state, dispatch] = useReducer(OtherPoliciesReducer, initialOtherPolicyReducerState);
  const [action, setAction] = useState({ isDropdownOpen: false, ddValue: '' });
  const [filter, setFilter] = useState({ searchText: '', sortBy: '', sortType: 'desc' });
  const [loader, setLoader] = useState(true);
  const [pagination, setPagination] = useState({ currentPage: 0, pagesCount: Math.ceil(policiesList.length / pageSize) });
  
  useEffect(() => {
    dispatchEvent(ReducerUtils.otherPolicies.list, rebuildAPIResponse(policiesList));
    policiesList.length ? setLoader(false) : setTimeout(() => { setLoader(false) }, 10000);
  }, [policiesList]);

  useEffect(() => {
    setPagination({ ...pagination, 'pagesCount': Math.ceil(state.otherPolicesList.length / pageSize)});
  }, [state.otherPolicesList])

  // useEffect(() => {
  //   console.log(filter.sortBy, filter.sortType);
  //   // setFilter({ ...filter, 'sortType': filter.sortType === 'asc' ? 'desc' : 'asc' });
  // }, [filter.sortBy])

  const cbDownload = ({ pages }) => {
    if (!pages.length) {
      alert('Sorry it seems that there is no policy document to download!');
      return false;
    }

    const { type, url} = extractTypeAndUrl(pages);
    type === 'application/pdf' ? downloadFile(url) : downloadZipFile(convertToArray(pages))
    
    setActionState('ddValue', '');
  };

  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const handleAction = (event, { value }, item) => {
    setActionState('ddValue', value);
    value === 'downloadPolicyDocument' ? cbDownload(item) : navigateTo(`/admin/policies/other/${item.id}`);
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setPagination({ ...pagination, currentPage: index });
  };

  const handleSearch = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
    dispatchEvent(ReducerUtils.otherPolicies.search, event.target.value);
    setPagination({ ...pagination, currentPage: 0 });
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  // Table column header sort by click handler
  const handleColumnSort = (sortBy) => {
    const sortType = filter.sortType === 'desc' ? 'asc': 'desc';

    setFilter({ ...filter, 'sortType': sortType, 'sortBy': sortBy });
    dispatchEvent("SORT_LIST", { sortBy: sortBy, sortType: sortType });
  };

  const setActionState = (key, value) => {
    setAction({ ...action, [key]: value });
  };
  
  return (
    <Container fluid>
      <div className="header-body">
        <Row className="align-items-center py-4">
          <Col lg="8" xs="6">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <a href="#" onClick={e => e.preventDefault()}>
                  <i className="fas fa-home"/>
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={e => {
                  e.preventDefault();
                }}>
                  Other Policies
                </a>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="text-right" lg="4" xs="6"></Col>
        </Row>
      </div>
      
      {/* <Row className="py-1 ">
        <Col xs={12} sm={6}>
          <h5 className="text-primary h5 font-weight-400">Other Policies</h5>
        </Col>
        <Col></Col>
      </Row> */}

      <div>
        <Row className="bg-white m-0 py-2">
          <Col lg={5} md={8} className="p-1 px-3 text-muted">
            <small className="form-inline md-form mr-3 mb-4 d-inline">
              <div className="form-group border justify-content-between rounded-5">
                <input type="text" 
                  name="searchText" 
                  className="form-control flex-grow-1 border-0" 
                  placeholder="Search by Policy Number, Policy Holder Name, Product or Insurance Provider" 
                  aria-label="search" 
                  aria-describedby="basic-addon1" 
                  value={filter.searchText}
                  onChange={handleSearch}
                />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text cursor-pointer border-0 mr-2" id="basic-addon1">
                      <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"/>
                    </span>
                  </div>
                </div>
              </div>
            </small>
          </Col>
          <Col className="text-right mt-3">
            {state.otherPolicesList.length > 0 && 
            <CSVLink
              data={state.otherPolicesList} 
              headers={csvHeaders}
              filename={"other-policies-list.csv"}
              target="_blank"
            >
              <Button color="primary " outline size="sm" type="button">Export</Button>
            </CSVLink>}
          </Col>
        </Row>
      </div>
      <Card className="m-0 mt-1" style={{ minHeight: '300px' }}>
        <CardBody className="px-0 py-0">
          <div className="table-responsive">
            <Table className="align-items-center table-flush" hover responsive>
              <thead className="thead-light">
                <tr>
                  <th className={(filter.sortBy === 'policy_number' ? 'text-primary' : '')} 
                    onClick={() => handleColumnSort('policy_number')}>
                    Policy Number <i className="fas fa-sort ml-2"/>
                  </th>
                  <th>Product</th>
                  <th className={(filter.sortBy === 'policy_premium_sortby' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('policy_premium_sortby')}>
                    Policy Premium <i className="fas fa-sort ml-2"/>
                  </th>
                  <th className={(filter.sortBy === 'sum_insured_sortby' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('sum_insured_sortby')}>
                    Sum Assured <i className="fas fa-sort ml-2"/>
                  </th>
                  <th className={(filter.sortBy === 'issued_date_timestamp' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('issued_date_timestamp')}>
                    Issued Date <i className="fas fa-sort ml-2"/>
                  </th>
                  <th className={(filter.sortBy === 'expiry_date_timestamp' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('expiry_date_timestamp')}>
                    Expiry Date <i className="fas fa-sort ml-2"/>
                  </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader &&
                <tr>
                  <td colSpan="8">
                    <Spinner></Spinner>
                  </td>
                </tr>}
                {(!loader && !state.otherPolicesList.length) &&
                <tr className="cursor-pointer">
                  <td colSpan="12" className="text-center">
                    <span className="text-muted">
                      <b>
                        No policy Data found
                      </b>
                    </span>
                  </td>
                </tr>}

                {(!loader && state.otherPolicesList.length > 0) && 
                  state.otherPolicesList
                  .slice(
                    pagination.currentPage * pageSize,
                    (pagination.currentPage + 1) * pageSize
                  )
                  .map((item, index) => {
                  return <tr key={index + 'hgshdsh'}>
                    <td className="table-user">{item.policy_number}</td>
                    <td>{item.product}</td>
                    <td>{item.policy_premium}</td>
                    <td>{item.sum_insured}</td>
                    <td>{item.issued_date}</td>
                    <td>{item.expiry_date}</td>
                    <td>{item.policy_status_text}</td>
                    <td>
                      <DropdownCustom
                        // open={action.isDropdownOpen}
                        trigger={
                          <i className="fa fa-ellipsis-v cursor-pointer ml-2" aria-hidden="true"/>
                        }
                        options={getOptionsList(item)}
                        position={(index > 0 && (index >= state.otherPolicesList.length - 1) || state.otherPolicesList.length == 1)? 'bottom right' : 'top right' }
                        onClickOption={(e, {value}) => {
                          handleAction(e, {value}, item)
                        }}
                        value={action.ddValue}
                    />
                    </td>
                  </tr>;
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        
        {(!loader && state.otherPolicesList.length > 0 && state.otherPolicesList.length > pageSize) && 
        <CardFooter className="py-4">
          <Paginator 
            pagination={pagination}
            handleClick={handleClick}
          />
        </CardFooter>}
      </Card>
    </Container>
  );
};

export default OtherPoliciesList;