import React from "react";
import { Button, Col, Form, FormGroup, Modal, Row } from "reactstrap";
import {MultiSelect} from "react-multi-select-component";

class CustomMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props ?? props.options ?? [],
      selected: [],
    };
  }

  setSelected = (value) => {
    this.setState(
      {
        selected: value,
      },
      () => {
        this.props.filterByArray(value);
      }
    );
  };
  componentDidMount = () => {};

  filterOptions = () => {
    let options = [];
    let finalOptions = [];
    options = this.props.options && this.props.options;
    options.map((item) => {
      return finalOptions.push({ label: item.name, value: item.id });
    });
    return finalOptions;
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.props.showMultiSelectOption}
          toggle={() => this.props.closeMultiSelectOption()}
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.closeMultiSelectOption()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <pre>{JSON.stringify(this.state.selected)}</pre> */}
            <MultiSelect
              options={this.filterOptions()}
              value={this.state.selected}
              onChange={this.setSelected}
              labelledBy={"Select " + this.props.title + " ..."}
            />
          </div>

          <div className="modal-footer date-modal-footer ">
            <Button
              color="primary"
              className="ml-auto"
              type="button"
              onClick={() => this.props.closeMultiSelectOption()}
            >
              Done
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CustomMultiSelect;
