import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Notification from '../../../components/CustomUi/Notify/Notification';
import SectionHeader from './SectionHeader';
import OemMappingPartner from './OemMappingPartner';
import OemMappingList from './OemMappingList';
import OemMappingAdd from './OemMappingAdd';

const OemMappingContainer = (props) => {
    const {
        boolean,
        errorMessage,
        errors,
        form,
        handleCancel,
        handleChange,
        handleConfirm,
        handleDelete,
        handleResetClick,
        handleSaveClick,
        handleToggleCheckbox,
        onSubmitSearch,
        partners = [],
        selectedObject
    } = props;

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs="12">
                        <Card className="mt-4">
                            <CardHeader>
                                <SectionHeader
                                    title={"Partner - OEM Mapping"}
                                />
                            </CardHeader>

                            <CardBody>

                                <OemMappingPartner
                                    boolean={boolean}
                                    form={form}
                                    handleChange={handleChange}
                                    partners={partners}
                                />

                                {form.partnerId &&
                                    <>
                                        <OemMappingList
                                            boolean={boolean}
                                            form={form}
                                            handleCancel={handleCancel}
                                            handleConfirm={handleConfirm}
                                            handleDelete={handleDelete}
                                            selectedObject={selectedObject}
                                        />

                                        <OemMappingAdd
                                            boolean={boolean}
                                            errors={errors}
                                            form={form}
                                            handleCancel={handleCancel}
                                            handleChange={handleChange}
                                            handleResetClick={handleResetClick}
                                            handleSaveClick={handleSaveClick}
                                            handleToggleCheckbox={handleToggleCheckbox}
                                            onSubmitSearch={onSubmitSearch}
                                        />
                                    </>}
                            </ CardBody>
                        </ Card>
                    </ Col>
                </Row>
                <Notification />
            </Container>
        </>
    );
}

export default OemMappingContainer; 