import React, { useEffect, useState } from 'react';
import {
    errorMessageObject,
    initialBooleanObject,
    initialFormState,
    touchFieldsObject
} from '../../../defaultValues/oemMapping';
import { notify, parseMessage } from '../../../helpers/utils';
import { buildPartnerProductList } from '../../../helpers/partners';
import {
    buildPartnerList,
    buildSaveMappingPayload,
    buildSearchParams,
    buildSystemErrorMessage
} from '../../../helpers/oemMapping';
import { buildPartnerListParams } from '../../../helpers/partners';
import AxiosReactClient from '../../../utilities/AxiosRestClient';
import OemMappingContainer from './OemMappingContainer';
import Spinner from '../../../components/Spinner/Spinner';

const OemMapping = () => {
    const DEFAULT_SELECTED_INDEX = -1;
    const [boolean, setBoolean] = useState(initialBooleanObject);
    const [errorMessage, setErrorMessage] = useState(errorMessageObject);
    const [errors, setErrors] = useState([]);
    const [form, setForm] = useState(initialFormState);
    const [partners, setPartners] = useState([]);
    const [touchFields, setTouchFields] = useState(touchFieldsObject);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(DEFAULT_SELECTED_INDEX);

    useEffect(() => {
        getPartnerList();

        return () => {
            // TODO: Clean this up
        };
    }, []);

    useEffect(() => {
        if (form.partnerId) {
            getMappedList();
        }

        return () => {
            // TODO: Clean this up
        };
    }, [form.partnerId]);

    const syncMappedListResponse = ({ data }) => {
        setForm({ ...form, mappedList: data, searchText: '', searchResult: [] });
        setBoolean({ ...boolean, mappingLoaded: false, searchResult: false });
    };

    const getMappedList = async () => {
        try {
            setBoolean({ ...boolean, mappingLoaded: true });
            const response = await AxiosReactClient.getRequest('v2/admin/partner/oem', { partner_id: form.partnerId });

            syncMappedListResponse(response.data);
        } catch (error) {
            setForm({ ...form, mappedList: [] });
            setBoolean({ ...boolean, mappingLoaded: false, searchResult: false });
        }
    };

    const syncPartnerListResponse = ({ data: { partner } }) => {
        setPartners(buildPartnerList(partner));
        setBoolean({ ...boolean, partnerLoaded: true });
    };

    const getPartnerList = async () => {
        try {
            const response = await AxiosReactClient.getRequest('filter/partner/list', buildPartnerListParams());

            syncPartnerListResponse(response.data)
        } catch (error) {
            buildPartnerProductList();
        }
    };

    const setErrorMessageState = (name, value) => {
        setErrorMessage({
            ...errorMessage,
            [name]: value
        });
    };

    const setFormState = function (name, value) {
        setForm({
            ...form,
            [name]: value
        });
    };

    const setTouchFieldsState = (name, value) => {
        setTouchFields({
            ...touchFields,
            [name]: value
        });
    };

    const handleChange = (event) => {
        setFormState(event.target.name, event.target.value);
    };

    const handleResetClick = () => {
        resetForm();
    };

    const cbError = ({ data: { error_msg } }) => {
        setSelectedObject(null);
        setSelectedIndex(DEFAULT_SELECTED_INDEX);
        setBoolean({ ...boolean, deletionSubmitted: false, formSubmitted: false, searchSubmitted: false });

        const message = parseMessage(error_msg);
        if (Array.isArray(message)) {
            setErrors(message);
        } else {
            let messageArray = [];
            messageArray.push(message);
            setErrors(messageArray);
        }
    };

    const successSaveClick = ({ message }) => {
        // Reload mapped list in order to updated the above mapped list
        getMappedList();

        setBoolean({ ...boolean, formSubmitted: false });
        resetForm();
        notify(message);
    };

    const handleSaveClick = async () => {
        try {
            const payload = buildSaveMappingPayload(form);

            if (!payload.length) {
                setErrors(new Array("Please selected minimum one make to map"));
                return false;
            }

            setBoolean({ ...boolean, formSubmitted: false });
            const response = await AxiosReactClient.postRequest('v2/admin/partner/oem', payload);

            successSaveClick(response.data);
        } catch (error) {
            cbError(error && error.response && error.response.data ? error.response.data : buildSystemErrorMessage());
        }
    };

    const successSearchClick = ({ vehicle_model = [] }) => {
        setForm({ ...form, searchResult: vehicle_model });
        setBoolean({ ...boolean, searchResult: true, searchSubmitted: false });
    };

    const handleSearchClick = async () => {
        try {
            setErrors([]);
            setBoolean({ ...boolean, searchResult: false, searchSubmitted: true });

            const response = await AxiosReactClient.getRequest('admin/vehicle_model', buildSearchParams(form));
            const { data } = response.data;

            successSearchClick(data);
        } catch (error) {
            cbError(error.response.data);
        }
    };

    const handleToggleCheckbox = (item, index) => {
        // Create a copy of searchResult
        const searchResult = JSON.parse(JSON.stringify(form.searchResult));

        // Create a copy of specific object
        const object = [...form.searchResult][index];

        // set checkbox checked property
        object.checked = !object.checked ? true : false;

        // Assign updated object to appropriate index of cloned array
        searchResult[index] = object;

        // Finally update the searchResult
        setForm({ ...form, searchResult });
    };

    const handleDelete = (item, index) => {
        setSelectedObject({ ...item });
        setSelectedIndex(index);
        setBoolean({ ...boolean, showAlert: true });
    };

    const handleCancel = () => {
        setSelectedObject(null);
        setSelectedIndex(DEFAULT_SELECTED_INDEX);
        setBoolean({ ...boolean, showAlert: false, deletionSubmitted: false });
    };

    const successConfirmDelete = ({ message }) => {
        // Here we need to extract revelant mapped object from the array
        const shallowMappedList = JSON.parse(JSON.stringify(form.mappedList ?? []));
        shallowMappedList.splice(selectedIndex, 1);

        setForm({ ...form, mappedList: shallowMappedList });

        // Its time to reset some flag to its default state
        handleCancel();
        notify(message);
    };

    const handleConfirm = async () => {
        try {
            setBoolean({ ...boolean, deletionSubmitted: true });
            const response = await AxiosReactClient.deleteRequest(`v2/admin/partner/oem/${selectedObject.id}`, {});

            successConfirmDelete(response.data);
        } catch (error) {
            cbError(error.response.data);
        }
    };

    const resetForm = () => {
        setErrors([]);
        setTouchFields(touchFieldsObject);
        setErrorMessage(errorMessageObject);
        setForm({ ...form, searchText: '', searchResult: [] });
        setBoolean({ ...boolean, searchResult: false });
    };

    const onSubmitSearch = (event) => {
        event.preventDefault();

        handleSearchClick();
    };

    return (
        boolean.partnerLoaded ?
            <OemMappingContainer
                boolean={boolean}
                errorMessage={errorMessage}
                errors={errors}
                form={form}
                handleCancel={handleCancel}
                handleChange={handleChange}
                handleConfirm={handleConfirm}
                handleDelete={handleDelete}
                handleResetClick={handleResetClick}
                handleSaveClick={handleSaveClick}
                handleToggleCheckbox={handleToggleCheckbox}
                onSubmitSearch={onSubmitSearch}
                partners={partners}
                selectedObject={selectedObject}
            /> : <Spinner />
    );
}

export default OemMapping;