import React from 'react';

function DataSkeleton({data, classes=[], loading}) {
    if(!data && loading){
        return <div className="shimmer">
            <div className="shine line"></div>
        </div>
    }
    if(!data && !loading){
        return <>N/A</>
    }
    return (
        <div className={`bg-transparent border-0 text-dark p-0 mt--0 fnt-16 bg-white border-0 text-dark ${classes.join(" ")}`}>
            {typeof data === "string"?`${data}`.capitalizeFirstLetter():data}
        </div>
    );
}

export default DataSkeleton;