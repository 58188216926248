import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../../Store/actions';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import Spinner from "../../../components/Spinner/Spinner";
import Loader from '../../../components/CustomUi/Loader/Loader';
import AxiosReactClient from '../../../utilities/AxiosRestClient';
import Select2 from "react-select2-wrapper";
import Alert from "../../../components/Modal/Alert";
import { ExportTransactionSection } from './exportTransactionSection';
import {
    Breadcrumb,
    BreadcrumbItem,
    Table,
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Modal,
    Form,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
} from "reactstrap";

import Can from '../../../config/Casl/Can'
import * as permissions from '../../../config/Casl/permissions';

const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const contact_regx = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
const orderIdSearch = /^[ a-zA-Z&0-9_-]*$/;

class DealerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: '',
            dealershipValue: '',
            emailValue: '',
            mobileValue: '',
            address_1_Value: '',
            address_2_Value: '',
            address_3_Value: '',
            address_4_Value: '',
            address_5_Value: '',
            dealerName: '',
            dealershipName: '',
            dealerEmail: '',
            dealerMobile: '',
            hideDetails: true,
            selectedDealer: '',
            selectedProduct: '',
            parentShare:"",
            view_commission:false,
            selectedProductAdd: '',
            productCommission: null,
            productCommissionAdd: null,
            transferPricingId: null,
            isDynamicPricingEnabled: false,
            confirmDeleteModel: false,
            confirmProductDeleteModel: false,
            addContact: '',
            storeName: '',
            addContactCommission: 0,
            is_flat: false,
            is_flat_add: false,
            is_promoter: false,
            check: (!!this.props.check) || false,
            brandIdsList: [],
            selectedContact: '',
            isClicked: false,
            period: "",
            isOpened: false,
            isEditingBrands: false,
            isEditingDealer: false,
            selected_Dealer: '',
            selectedTab: 'policies',
            view: "search-table",
            searchText: '',
            searchTransactionText: '',
            searchHistory: '',
            selectedOrderList: [],
            selectedMobile: '',
            isEditingInvoicingModal: false,
            selectedInvoicingModel: '',
            invoicingValue: '',
            needValue: false,
            isModalProcess:false,
            confirmationPopup:{status:false,productId:null,dealerDetailsViewData:null},
            showDynamicPricing: false,
            commissionModelTitle: 'Edit Commission',
            tabs: [
                {item: "Policies", key: 'policies'},
                {item: "Transactions", key: 'transaction'},
                {item: "Products", key: 'products'},
                {item: "Additional Data", key: 'additional'},
                {item: "History", key: 'history'},
            ],
            orderIdView: false,
            products: [],
            exportDateRangeError: '',
            exportDateErrorClass: '',
            isFilterTransactionView: false,
            filterTransactionParams: {},
            cut_and_pay: false,
        };
        this.getDealerDetails();
        this.getHistoryData();
        props.onGetDealerDetailsViewCardData(props.match.params.id);
        props.onGetDealerViewProductListData();
        props.onGetBrandListData();
        props.onGetDealerBrandListData(this.props.match.params.id);
        props.onGetAdditionalContactListData(this.props.match.params.id);
        props.onGetDealerHistoryData(this.props.match.params.id, {start: this.pager1.pagerStart});
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDealershipChange = this.handleDealershipChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleAddress_1_Change = this.handleAddress_1_Change.bind(this);
        this.handleAddress_2_Change = this.handleAddress_2_Change.bind(this);
        this.handleAddress_3_Change = this.handleAddress_3_Change.bind(this);
        this.handleAddress_4_Change = this.handleAddress_4_Change.bind(this);
        this.handleAddress_5_Change = this.handleAddress_5_Change.bind(this);
        this.handleInvoicingValue = this.handleInvoicingValue.bind(this);

    }
    componentDidMount() {
        this.props.onGetDealerInvoicingModelData(this.props.match.params.id);
        this.setState({
            selectedInvoicingModel: this.props.invoicingModelData && this.props.invoicingModelData.type
        });

        this.props.onGetDealerHistoryData(this.props.match.params.id)
    }

    customLoader = {
        addProductLoader: false,
        updateCommissionLoader: false,
        addContactLoader: false
    };
    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
    };
    pager1 = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
    };
    pager2 = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
    };
    filterTransactionPager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
    };
    updatePagination = (total) => {
        this.pager.pageArray = [];
        this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
        for (let index = 0; index < this.pager.pagerEnd; index++) {
            this.pager.pageArray.push(index + 1)
        }

    };
    updateHistoryPagination = (total) => {
        this.pager1.pageArray = [];
        this.pager1.pagerEnd = Math.ceil(total / this.pager1.limit);
        for (let index = 0; index < this.pager1.pagerEnd; index++) {
            this.pager1.pageArray.push(index + 1)
        }
    };
    updateTransactionPagination = (total) => {
        this.pager2.pageArray = [];
        this.pager2.pagerEnd = Math.ceil(total / this.pager2.limit);
        for (let index = 0; index < this.pager2.pagerEnd; index++) {
            this.pager2.pageArray.push(index + 1)
        }
    };
    
    updateFilterTransactionPagination = (total) => {
        this.filterTransactionPager.pageArray = [];
        this.filterTransactionPager.pagerEnd = Math.ceil(total / this.filterTransactionPager.limit);
        for (let index = 0; index < this.filterTransactionPager.pagerEnd; index++) {
            this.filterTransactionPager.pageArray.push(index + 1)
        }
    };
    switchPage = (page) => {
        if (((this.pager.pagerStart + 1) !== page)) {
            this.pager.pagerStart = page - 1;
            this.getDealerDetails()
        }
    };
    switchHistoryPage = (page) => {
        if (((this.pager1.pagerStart + 1) !== page)) {
            this.pager1.pagerStart = page - 1;
            this.getHistoryData()
        }
    };
    switchTransactionPage = (page) => {
        if (((this.pager2.pagerStart + 1) !== page)) {
            this.pager2.pagerStart = page - 1;
            this.getDealerDetails()
        }
    };
    
    switchFilterTransactionPage = (page) => {
        if (((this.filterTransactionPager.pagerStart + 1) !== page)) {
            this.filterTransactionPager.pagerStart = page - 1;
            this.getFilteredTransDetails()
        }
    };
    isHidden = (page) => {
        var start = this.pager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
                return true;
            } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    };
    isHiddenH = (page) => {
        var start = this.pager1.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager1.pagerEnd)) {
                return true;
            } else if ((start !== this.pager1.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    };
    isHiddenT = (page) => {
        var start = this.pager2.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager2.pagerEnd)) {
                return true;
            } else if ((start !== this.pager2.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    };
    
    isHiddenTFilter = (page) => {
        var start = this.filterTransactionPager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.filterTransactionPager.pagerEnd)) {
                return true;
            } else if ((start !== this.filterTransactionPager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    };
    getDealerDetails = () => {
        this.props.onGetDealerDetailsViewData(this.props.match.params.id, {
            policy_start: this.pager.pagerStart,
            policy_search: this.state.searchText,
            transaction_start: this.pager2.pagerStart,
            transaction_search: this.state.searchTransactionText
        })
    };
    
    getFilteredTransDetails = () => {
        this.props.onGetFilteredViewData(this.props.match.params.id, {
            ...this.state.filterTransactionParams,
            transaction_start: this.filterTransactionPager.pagerStart
        })
    };

    getHistoryData = () => {
        this.props.onGetDealerHistoryData(this.props.match.params.id, { start: this.pager1.pagerStart, search: this.state.searchHistory })
    };

    openCommissionModel = ({
                             dealer_id,
                             product_id,
                             commission,
                             is_flat,
                             transferPricingId,
                               isDynamicPricingEnabled,
                               showDynamicPricing,
                               commissionModelTitle
                           }) => {
        this.setState({
            showDynamicPricing: showDynamicPricing,
            showCommissionModel: true,
            selectedDealer: dealer_id,
            selectedProduct: product_id,
            productCommission: commission,
            transferPricingId: transferPricingId,
            is_flat: is_flat,
            isDynamicPricingEnabled: isDynamicPricingEnabled,
            commissionModelTitle: commissionModelTitle,
        })
    };
    onUpdateCommission = () => {
        this.sendUpdatedCommission(this.state.selectedDealer, {
            id: this.state.selectedProduct,
            commission: this.state.productCommission,
            is_flat: this.state.is_flat,
            transfer_pricing_id: this.state.transferPricingId,
            is_dynamic_pricing_enabled: this.state.isDynamicPricingEnabled
        })
    };
    sendUpdatedCommission = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('dealers/' + id + '/product', payload).then(response => {
            this.setState({
                showLoader: false,
                showCommissionModel: false

            });
            this.showMsg("Commission updated successfully", "success");
            this.getDealerDetails()
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };
    handleFlatCommissionChange = (e) => {
            this.setState({
                is_flat: e.target.checked
            });
    };
    handleChange = (e) => {
            this.setState({
                isDynamicPricingEnabled: e.target.checked
            });
    };
    handleChangeAdd = (e) => {
        this.setState({
            is_flat_add: e.target.checked
        })
    };
    handleChangePromoter = (e) => {
        this.setState({
            is_promoter: e.target.checked
        })
    };
    openProductModel = ({
                            dealer_id,
                            addProduct_id,
                            addCommission,
                            is_flat_add,
                            partner_id = null
                        }) => {

        AxiosReactClient.getRequest('filter/product/list/', { partner_id, group_retail_suffix: "no" }).then(response => {
            this.setState({
                products: response?.data?.data?.product ?? [],
                showProductModel: true,
                selectedDealer: dealer_id,
                selectedProductAdd: addProduct_id,
                productCommissionAdd: addCommission,
                is_flat_add: is_flat_add,
            })
        })
    };
    removeProduct = (product_id,dealer_id) => {
      this.setState({
          showLoader:true
      });
      AxiosReactClient.deleteRequest('dealers/'+dealer_id+'/deLinkProduct/'+product_id).then(response => {
          this.setState({
              showLoader:false,
                      isModalProcess:false,
                      confirmationPopup:{status:false,productId:null,dealerDetailsViewDataId:null}
          })
          this.props.onRemoveProductFromDealer(product_id);
          this.showMsg("Product removed successfully","success")
      }).catch(err=>{
          this.setState({
              showLoader:false,
                      isModalProcess:false,
                      confirmationPopup:{status:false,productId:null,dealerDetailsViewDataId:null}
          })
          if(err.response){
              this.showMsg(err.response.data.message,"danger");
          }else if(err.message){
              this.showMsg(err.message,"danger")
          }
      })
    };
    onAddProduct = () => {
        if (this.state.productCommissionAdd === null) {
            this.showMsg("Please add commission", "danger");
            return
        }
        this.sendAddedProduct(this.state.selectedDealer, {
            id: this.state.selectedProductAdd,
            commission: this.state.productCommissionAdd,
            is_flat: this.state.is_flat_add
        })
    };
    sendAddedProduct = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.postRequest('dealers/' + id + '/product', payload).then(response => {
            this.setState({
                showLoader: false,
                showProductModel: false

            });
            this.showMsg("Product added successfully", "success");
            this.getDealerDetails()
        })
            .catch(err => {

                this.setState({
                    showLoader: false,
                    showProductModel: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    removeBrandElement = (brand) => {
        this.setState({
            brandIdsList: this.state.brandIdsList.filter(function (value, index, arr) {
                return value !== brand.id;
            })
        });
    };
    addBrandElement = (brand) => {
        this.setState({
            brandIdsList: [brand.id].concat(this.state.brandIdsList)
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            selectedInvoicingModel: nextProps.invoicingModelData && nextProps.invoicingModelData.type
        });
        nextProps.dealerBrandListData && this.setState({
            brandIdsList: nextProps.dealerBrandListData,
            nameValue: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.name ? nextProps.dealerDetailsViewData.name : '',
            dealershipValue: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.dealership_name ? nextProps.dealerDetailsViewData.dealership_name : '',
            emailValue: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.email ? nextProps.dealerDetailsViewData.email : '',
            mobileValue: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.mobile ? nextProps.dealerDetailsViewData.mobile : '',
            parentShare: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.parent_share ? nextProps.dealerDetailsViewData.parent_share : '',
            address_1_Value: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.address && nextProps.dealerDetailsViewData.address.address_line_1 ? nextProps.dealerDetailsViewData.address.address_line_1 : '',
            address_2_Value: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.address && nextProps.dealerDetailsViewData.address.address_line_2 ? nextProps.dealerDetailsViewData.address.address_line_2 : '',
            address_3_Value: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.address && nextProps.dealerDetailsViewData.address.city ? nextProps.dealerDetailsViewData.address.city : '',
            address_4_Value: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.address && nextProps.dealerDetailsViewData.address.state ? nextProps.dealerDetailsViewData.address.state : '',
            address_5_Value: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData.address && nextProps.dealerDetailsViewData.address.pincode ? nextProps.dealerDetailsViewData.address.pincode : '',
            invoicingValue: nextProps.invoicingModelData && nextProps.invoicingModelData.value,
            view_commission: nextProps?.dealerDetailsViewData?.dealer_user instanceof Array && nextProps?.dealerDetailsViewData?.dealer_user.length > 0 && nextProps?.dealerDetailsViewData?.dealer_user[0]?.permissions ? nextProps.dealerDetailsViewData.dealer_user[0].permissions.view_commission : false,
            cut_and_pay: nextProps.dealerDetailsViewData && nextProps.dealerDetailsViewData?.data && (nextProps.dealerDetailsViewData?.data?.cut_and_pay === true || nextProps.dealerDetailsViewData?.data?.cut_and_pay === false) ? nextProps.dealerDetailsViewData?.data?.cut_and_pay : true, // this has been set as true since assurance dealers are on cut and pay model
        })
    }

    sendUpdatedBrands = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('dealers/' + id + '/brands', payload).then(response => {
            this.setState({
                showLoader: false,
                isEditingBrands: false
            });
            this.showMsg("Brands updated successfully", "success");
            this.props.onGetDealerBrandListData(this.props.match.params.id)

        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    toggleBrandsEditButton = (editing) => {
        switch (editing) {
            case true:
                this.sendUpdatedBrands(this.props.match.params.id, {brands_id: this.state.brandIdsList});

                break;
            case false:
                this.setState({
                    isEditingBrands: true
                });
                break;
            default:
                break;
        }
    };
    openContactModel = (dealer_id, mobile, commission, store, is_promoter) => {
        this.setState({
            showContactModel: true,
            selectedDealer: dealer_id,
            addContact: mobile,
            addContactCommission: commission,
            storeName: store,
            is_promoter: is_promoter

        })
    };
    onAddContact = () => {

        if (this.state.addContact === '') {
            this.showMsg("Contact number can not be empty", 'danger');
            return
        }
        if (!contact_regx.test(this.state.addContact)) {
            this.showMsg("Contact number should be of 10 digits", 'danger');
            return
        }
        if (this.state.storeName === '') {
            this.showMsg("Please provide same or new store name", 'danger');
            return
        }

        this.sendAddedContact(this.state.selectedDealer, {
            mobile: this.state.addContact,
            commission: this.state.addContactCommission,
            store_name: this.state.storeName,
            is_promoter: this.state.is_promoter
        })


    };
    sendAddedContact = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.postRequest('dealers/' + id + '/additional-number', payload).then(response => {
            this.setState({
                showLoader: false,
                showContactModel: false,
                addContact: '',
                addContactCommission: 0,
                storeName: '',
                is_promoter: false

            });
            this.showMsg("Contact added successfully", "success");
            this.props.onGetAdditionalContactListData(this.props.match.params.id)
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {

                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    openDeleteModel = (dealer_id, contact_id, mobile) => {
        this.setState({
            confirmDeleteModel: true,
            selected_Dealer: dealer_id,
            selectedContact: contact_id,
            selectedMobile: mobile
        })
    };
    onDeleteContact = () => {

        this.sendDeletedContact(this.state.selected_Dealer, this.state.selectedContact)
    };
    sendDeletedContact = (id, contact_id) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.deleteRequest('dealers/' + id + '/' + contact_id + '/additional-number').then(response => {
            this.setState({
                showLoader: false,
                confirmDeleteModel: false
            });
            this.showMsg("Contact deleted successfully", "success");
            this.props.onGetAdditionalContactListData(this.props.match.params.id)
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    modifyStr = (key) => {
        let myStr = key;
        myStr = key.replace('_', ' ');
        return myStr.charAt(0).toUpperCase() + myStr.slice(1);
    };
    toggleDealerEditButton = (editing) => {
        switch (editing) {
            case true:
                if (!email_regx.test(this.state.emailValue)) {
                    this.showMsg("Please Input A Valid Email Only", 'danger');
                    return
                }
                if (!contact_regx.test(this.state.mobileValue)) {
                    this.showMsg("Contact number should be of 10 digits", 'danger');
                    return
                }
                if(this.state.parentShare != '' && isNaN(this.state.parentShare)){
                    this.showMsg("Parent Share Should Be Number","danger")
                    return 
                }  else if(this.state.parentShare != ''&&this.state.parentShare > 100){
                    this.showMsg("Please Enter Valid Parent Share","danger")
                    return
                }   else if(this.state.parentShare != '' && this.state.parentShare < 0){
                    this.showMsg("Please Enter Valid Parent Share","danger")
                    return
                }
                this.sendUpdatedDealer(this.props.match.params.id, {
                    name: this.state.nameValue,
                    dealership_name: this.state.dealershipValue,
                    email: this.state.emailValue,
                    mobile: this.state.mobileValue,
                    parent_share: this.state.parentShare,
                    address_line_1: this.state.address_1_Value,
                    address_line_2: this.state.address_2_Value,
                    pincode: this.state.address_5_Value,
                    city: this.state.address_3_Value,
                    state: this.state.address_4_Value,
                    view_commission: this.state.view_commission,
                    cut_and_pay: this.state.cut_and_pay,
                });
                break;
            case false:
                this.setState({
                    isEditingDealer: true
                });
                break;
        }
    };
    handleNameChange = (event) => {
        this.setState({
            nameValue: event.target.value
        });
    };
    handleDealershipChange = (event) => {
        this.setState({
            dealershipValue: event.target.value
        });
    };
    handleEmailChange = (event) => {
        this.setState({
            emailValue: event.target.value
        });
    };
    handleMobileChange = (event) => {
        this.setState({
            mobileValue: event.target.value
        });
    };
    handleAddress_1_Change = (event) => {
        this.setState({
            address_1_Value: event.target.value
        });
    };
    handleAddress_2_Change = (event) => {
        this.setState({
            address_2_Value: event.target.value
        });
    };
    handleAddress_3_Change = (event) => {
        this.setState({
            address_3_Value: event.target.value
        });
    };
    handleAddress_4_Change = (event) => {
        this.setState({
            address_4_Value: event.target.value
        });
    };
    handleAddress_5_Change = (event) => {
        this.setState({
            address_5_Value: event.target.value
        });
    };
      handleToggleChange=(e)=>{
        const name = e.target.name;
        const value = e.target.checked;
         if(name){
            this.setState({
                ...this.state,
                [name]:value
            })
        }
    }
    sendUpdatedDealer = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('dealers/' + id, payload).then(response => {
            this.setState({
                showLoader: false,
                isEditingDealer: false
            });
            this.showMsg("Dealer details updated successfully", "success");
            this.props.onGetDealerDetailsViewData(this.props.match.params.id);
            this.getHistoryData();
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.data.error_msg
                        ? this.parseMessage(err.response.data.data.error_msg) : err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    parseMessage = message => {
        if (typeof message === "string") {
            return message;
        } else if (Array.isArray(message) && message.length) {
            let errors = [];

            const output = message.map(item => [...errors, item.msg] );

            return output.join(', ');
        } else {
            let errors = [];

            for (const [key, value] of Object.entries(message)) {
                errors.push(value);
            }

            return errors;
        }
    };
    openProductDeleteModel = (dealer_id, product_id) => {
        this.setState({
            confirmProductDeleteModel: true,
            selectedDealer: dealer_id,
            selectedProduct: product_id
        });
    };
    onDeleteProduct = () => {
        this.sendDeletedProduct(this.state.selectedDealer, this.state.selectedProduct);
    };
    sendDeletedProduct = (id, product_id) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.deleteRequest('dealers/' + id + '/product/' + product_id).then(response => {
            this.setState({
                showLoader: false,
                confirmProductDeleteModel: false
            });
            this.showMsg("Product deleted successfully", "success");
            this.getDealerDetails();
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    onSearch = (value) => {
        if (!this.state.searchText || this.state.searchText.length < 2) {
            this.showMsg(" Please, enter atlest 2 characters", "danger");
            return
        }
        if (!this.state.searchText.match(orderIdSearch)) {
            this.showMsg(" Please, don't enter any special characters", "danger");
            return
        }
        this.getDealerDetails();
        this.setState({
            view: "table"
        });
    };
    onTransactionSearch = (value) => {
        if (!this.state.searchTransactionText || this.state.searchTransactionText.length < 2) {
            this.showMsg(" Please, enter atlest 2 characters", "danger");
            return
        }
        if (!this.state.searchTransactionText.match(orderIdSearch)) {
            this.showMsg(" Please, don't enter any special characters", "danger");
            return
        }
        this.getDealerDetails();
        this.setState({
            view: "table"
        })
    };
    onHistorySearch = (value) => {
        if (!this.state.searchHistory || this.state.searchHistory.length < 2) {
            this.showMsg(" Please, enter atlest 2 characters", "danger");
            return
        }
        if (!this.state.searchHistory.match(orderIdSearch)) {
            this.showMsg(" Please, don't enter any special characters", "danger");
            return
        }
        this.getHistoryData();
        this.setState({
            view: "table"
        })
    };
    resetFilters = () => {
        this.pager.pagerStart = 0;
        this.pager.sortBy = 0;
        this.pager1.pagerStart = 0;
        this.pager1.sortBy = 0;
        this.pager2.pagerStart = 0;
        this.pager2.sortBy = 0;
        this.setState({
            searchText: '',
            searchTransactionText: '',
            searchHistory: ''
        }, () => {
            this.getDealerDetails();
            this.getHistoryData()
        })
    };
    openOrderIdModel = (list) => {
        this.setState({
            selectedOrderList: list,
            orderIdView: true,
        })
    };
    toggleInvoicingEditButton = (editing, selectedInvoicingModel) => {
        const selectedValue = this.state.selectedInvoicingModel || selectedInvoicingModel;
        switch (editing) {
            case true:
                this.setState({
                    selectedInvoicingModel: selectedValue
                });
                this.sendUpdatedInvoicingModel(this.props.match.params.id, {type: selectedValue , value: this.state.invoicingValue});
                break;
            case false:
                this.setState({
                    isEditingInvoicingModal: true
                });
                break;
            default:
                break;
        }
    };
    handleInvoicingModel = (e) => {
        this.props.invoicingModelData.type = e.target.value;
        this.setState({
            selectedInvoicingModel: e.target.value,
            needValue: e.target.value === 'CREDIT' || e.target.value === 'INVOICE' || e.target.value === 'CREDIT_IN_DAYS'
        });
        /* if (e.target.value === 'CREDIT') {
            this.setState({
                needValue: true,
            });
            return
        }
        if (e.target.value === 'INVOICE') {
            this.setState({
                needValue: true,
            });
            return
        }
        if (e.target.value === 'PAYG' || e.target.value === 'DAILY_CREDIT' || e.target.value === 'CREDIT_IN_DAYS') {
            this.setState({
                needValue: false,
            });
        } */
    };
    handleInvoicingValue = (e) => {
        this.setState({
            invoicingValue: e.target.value
        })
    };
    sendUpdatedInvoicingModel = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.postRequest('dealers/' + id + '/invoiceModel', payload).then(response => {
            this.setState({
                showLoader: false,
                isEditingInvoicingModal: false,
                selectedInvoicingModel: this.props.invoicingModelData && this.props.invoicingModelData.type,
                invoicingValue: '',

            });
            if (response.data.success === true){
                this.showMsg(response["data"].data, "success");
                this.props.onGetDealerInvoicingModelData(this.props.match.params.id);
                return
            }
            if (response.data.success === false){
                this.showMsg(response["data"].data, "danger");
                this.props.onGetDealerInvoicingModelData(this.props.match.params.id);
            }
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.data.error_msg ? this.parseMessage(err.response.data.data.error_msg) : err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    };
    handleCancelEditDealer = () => {
        this.getDealerDetails();
        this.setState({
            isEditingDealer: false
        });
    };
    handleCancelEditInvoicingModal = () => {
        this.getDealerDetails();
        this.setState({
            isEditingInvoicingModal: false
        });
    };
    handleParentChange = (e)=>{
        this.setState({parentShare:e.target.value})
    }


        openConfirmationModal = (productId,dealerDetailsViewDataId) =>{
        this.setState({
            confirmationPopup:{status:true,productId:productId,dealerDetailsViewDataId:dealerDetailsViewDataId}
        })
    }
    closeConfirmationModal = () =>{
        this.setState({
            confirmationPopup:{status:false,productId:null,dealerDetailsViewDataId:null}
        })
    }
    onConfirm = () =>{
        this.setState({isModalProcess:true})
        const {productId,dealerDetailsViewDataId} = this.state.confirmationPopup;
        if(productId && dealerDetailsViewDataId){
            this.removeProduct(productId,dealerDetailsViewDataId)
        }else{
            this.setState({confirmationPopup:{status:false,productId:null,dealerDetailsViewDataId:null},isModalProcess:false})
        }
    }

    onExportTransactionClick = (data) => {
        AxiosReactClient.getRequest(`dealers/export/${this.props.match.params.id}`, data).then(response => {
            this.showMsg(response.data.data.message, 'success');
        }).catch(error => {
            this.showMsg(error.response?.data?.message, 'danger');
        });
    };

    onFilterTransactionClick = (data) => {
        this.setState({...this.state, isFilterTransactionView: true, filterTransactionParams: data});
        this.props.onGetFilteredViewData(this.props.match.params.id, {...data,
            transaction_start: this.filterTransactionPager.pagerStart});
    };
    onClearFilter = () => {
        this.setState({...this.state, isFilterTransactionView: false})
    }
    onTabClick = (e, data) => {
        e.preventDefault();
        if(this.state.selectedTab !=='transaction' && data.key ==='transaction'){
            this.setState({selectedTab: data.key, isFilterTransactionView: false});
        } else {
            this.setState({
                selectedTab: data.key
            });
        };
    }

    setExportDateRangeError = (value) => {
        let errorClass = String(value).includes('Please select To date') ? "exportToDateError" : "exportTransactionError"
        this.setState({...this.state, exportDateRangeError: value, exportDateErrorClass: errorClass})
    }

    render() {
        const {tabs,isModalProcess,confirmationPopup:{status}} = this.state;
        const {dealerDetailsViewData, dealerDetailsViewCardData, dealerDetailsViewCardLoading, brandListData, additionalContactListData, dealerHistoryData} = this.props;
        this.dealership = (this.props.dealerDetailsViewData && this.props.dealerDetailsViewData) ? this.props.dealerDetailsViewData : [];
        (this.props.policyCount !== null) && this.updatePagination(this.props.policyCount);
        (this.props.historyCount !== null) && this.updateHistoryPagination(this.props.historyCount);
        (this.props.transactionCount !== null) && this.updateTransactionPagination(this.props.transactionCount);
        (this.props.filteredTransactionCount !== null) && this.updateFilterTransactionPagination(this.props.filteredTransactionCount);
        return (
            <Fragment>
                  <Alert loading={isModalProcess} onCancel={this.closeConfirmationModal} onConfirm={this.onConfirm} show={status} title="Are you sure you want to delete this?"/>
                <Container fluid>
                    <CardHeader className="border-0 px-0 bg-transparent">
                        <Row>
                            <Col xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-0"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem aria-current="page" className="active">
                                        <h5 className="h5 text-primary d-inline">
                                            Dealers
                                        </h5>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" className="text-primary h5" onClick={(e) => {
                                            e.preventDefault();

                                            this.props.history.push('/admin/dealers/all')
                                        }}>
                                            All Dealers
                                        </a>
                                    </BreadcrumbItem>

                                    <BreadcrumbItem aria-current="page" className="active">
                                        <h5 className="h5 text-primary d-inline">
                                            Dealer Details
                                        </h5>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" xs="6">

                            </Col>
                        </Row>
                    </CardHeader>
                    <>
                        <Can I={permissions.DEALER.cards_show} a={permissions.DEALER.subject}>

                            <Row>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-white mb-0 dealer-card-title"
                                                    >
                                                        Policies Sold Premium
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold text-white mb-0">
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? dealerDetailsViewCardData.premium : 'N/A')}
                                                    </span>
                                                </div>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-white mb-0 dealer-card-title"
                                                    >
                                                        Exposure
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold text-white mb-0">
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? Number((dealerDetailsViewCardData.exposure).toFixed(2)) : 'N/A')}
                                                    </span>
                                                </div>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-white mb-0 dealer-card-title"
                                                    >
                                                        Available Credit / Total
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold text-white mb-0">
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? dealerDetailsViewCardData.avialableCredits : 'N/A')} / {" "}
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? dealerDetailsViewCardData.totalCredits : 'N/A')}
                                                    </span>
                                                </div>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-white mb-0 dealer-card-title"
                                                    >
                                                        Settled Claims / Total
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold text-white mb-0">
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? dealerDetailsViewCardData.settledClaims : 'N/A')} / {" "}
                                                        {dealerDetailsViewCardLoading ?
                                                            <Shimmer/> : (dealerDetailsViewCardData ? dealerDetailsViewCardData.totalClaims : 'N/A')}
                                                    </span>
                                                </div>

                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Can>
                        <Row className="card-wrapper">
                            <Col lg="12">
                                <Card>
                                    <CardHeader className="pb-0">
                                        <Row className="align-items-center">
                                            <Col xs="6">
                                                <div className="d-flex justify-content-between">
                                                    <h5 className="h5 mb-0 font-weight-400">{dealerDetailsViewData && dealerDetailsViewData.name} {" ("} {dealerDetailsViewData && dealerDetailsViewData.dealership_name} {")"}</h5>

                                                </div>
                                            </Col>
                                            <Col className="text-right" xs="6">
                                                <div className="d-flex justify-content-end">
                                                    <h5 className="text-muted mb-0 h5 my-auto mx-2">
                                                        ID: {this.props.match.params.id}
                                                    </h5>
                                                    <h5 className="h5 mb-0 font-weight-400 text-primary cursor-pointer my-auto mx-2"
                                                        hidden={this.state.hideDetails}>
                                                        <Button
                                                            color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                                            type="button" onClick={e => {
                                                            e.preventDefault();
                                                            this.toggleDealerEditButton(this.state.isEditingDealer)
                                                        }}>
                                                            {!this.state.isEditingDealer ? <>
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="fas fa-edit"/>
                                                                </span>
                                                                Edit </> : "Save"}
                                                        </Button>
                                                        {this.state.isEditingDealer && <Button color="primary"
                                                            outline
                                                            className="" type="button"
                                                            size="sm"
                                                            onClick={e => this.handleCancelEditDealer()}
                                                        >
                                                            X
                                                        </Button>}
                                                    </h5>
                                                </div>
                                            </Col>
                                            <Col xl="12" className="text-center m-0 p-0 cursor-pointer" onClick={e => {
                                                this.setState({
                                                    hideDetails: !this.state.hideDetails,
                                                })
                                            }}
                                                 title={this.state.hideDetails === true ? "Click to Expand" : "Click to Collapse"}>
                                                <Button className="text-center p-0" outline color="white" type="button">
                                                    <span
                                                        className="btn-inner--icon h3 text-primary font-weight-400 p-1">
                                                        <i className={"fas " + (this.state.hideDetails === true ? "fa-angle-down" : "fa-angle-up")}/>
                                                    </span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody hidden={this.state.hideDetails}>
                                        {!this.state.isEditingDealer ? <>
                                                <div className="d-flex justify-content-between flx-container pb-3">
                                                    <div className="flx-group">
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Name:
                                                    </span>
                                                            <span className="text-sm">
                                                            {dealerDetailsViewData && dealerDetailsViewData.name ? dealerDetailsViewData.name : "N/A"}
                                                        </span>
                                                        </div>
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Dealership:
                                                    </span>
                                                            <span className="text-sm">
                                                            {dealerDetailsViewData && dealerDetailsViewData.dealership_name ? dealerDetailsViewData.dealership_name : "N/A"}
                                                        </span>
                                                        </div>
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Email:
                                                    </span>
                                                            <span className="text-sm">
                                                            {dealerDetailsViewData && dealerDetailsViewData.email ? dealerDetailsViewData.email : "N/A"}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="flx-group">
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Mobile:
                                                    </span>
                                                            <span className="text-sm">
                                                            {dealerDetailsViewData && dealerDetailsViewData.mobile ? dealerDetailsViewData.mobile : "N/A"}
                                                        </span>
                                                        </div>
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Address:
                                                    </span>
                                                                <span className="text-sm">
                                                                {dealerDetailsViewData && dealerDetailsViewData.address && dealerDetailsViewData.address.address_line_1 ? dealerDetailsViewData.address.address_line_1 : "N/A"} {" "}
                                                                    {dealerDetailsViewData && dealerDetailsViewData.address && dealerDetailsViewData.address.address_line_2} {" "}
                                                                    {dealerDetailsViewData && dealerDetailsViewData.address && dealerDetailsViewData.address.city} {" "}
                                                                    {dealerDetailsViewData && dealerDetailsViewData.address && dealerDetailsViewData.address.state} {" "}
                                                                    {dealerDetailsViewData && dealerDetailsViewData.address && dealerDetailsViewData.address.pincode}
                                                            </span>
                                                        </div>
                                                        <div className="p-0 pb-2">
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            KAM:
                                                    </span>
                                                            <span className="text-sm">
                                                            {dealerDetailsViewData && dealerDetailsViewData.kam && dealerDetailsViewData.kam.name}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="flx-group">
                                                        <div className="p-0 pb-2">
                                                            <span className="text-sm font-weight-bold pr-3">
                                                                Partner:
                                                            </span>
                                                            <span className="text-sm">
                                                                {dealerDetailsViewData && dealerDetailsViewData.partner}
                                                            </span>
                                                        </div>
                                                        {dealerDetailsViewData?.type &&
                                                        <div className="p-0 pb-2">
                                                            <span className="text-sm font-weight-bold pr-3">
                                                                Type:
                                                            </span>
                                                                <span className="text-sm">
                                                                {dealerDetailsViewData && dealerDetailsViewData.type ? dealerDetailsViewData.type : "N/A"}
                                                            </span>
                                                        </div>}
                                                        {dealerDetailsViewData?.parent_id &&
                                                        <div className="p-0 pb-2">
                                                            <span className="text-sm font-weight-bold pr-3">
                                                                Parent Dealer ID:
                                                            </span>
                                                            <span className="text-sm">
                                                                {dealerDetailsViewData && dealerDetailsViewData.parent_id ? dealerDetailsViewData.parent_id : "N/A"}
                                                            </span>
                                                        </div>}
                                                        {dealerDetailsViewData?.parent_share &&
                                                        <div className="p-0 pb-2">
                                                            <span className="text-sm font-weight-bold pr-3">
                                                                Parent Share:
                                                            </span>
                                                            <span className="text-sm">
                                                                {dealerDetailsViewData && dealerDetailsViewData.parent_share ? `${dealerDetailsViewData.parent_share}%` : "N/A"}
                                                            </span>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <CardFooter className="px-0">
                                                    <div className="p-0 ">
                                                        {!this.state.isEditingInvoicingModal ? <>
                                                        <span className="text-sm font-weight-bold pr-3">
                                                            Invoicing Modal:
                                                        </span>
                                                                <span className="text-sm pr-4">
                                                                    {this.props.invoicingModelData && this.props.invoicingModelData.type}
                                                        </span>
                                                                {this.state.selectedInvoicingModel !== 'PAYG' && <>
                                                            <span className="text-sm font-weight-bold pr-3">
                                                                {this.props.invoicingModelData && (this.props.invoicingModelData.type === 'CREDIT' || this.props.invoicingModelData.type === 'DAILY_CREDIT' || this.props.invoicingModelData.type === 'CREDIT_IN_DAYS') ? 'Limit Available' : 'Amount Available'}
                                                        </span>
                                                                    <span className="text-sm pr-4">
                                                                        {this.props.invoicingModelData && this.props.invoicingModelData.value}
                                                                    </span>
                                                                </>}
                                                            </>
                                                            :
                                                            <>
                                                                <div className="d-flex justify-content-between">
                                                                <span className="text-sm font-weight-bold pr-3">
                                                                    Invoicing Modal
                                                                </span>
                                                                    <span className="text-sm">
                                                                    <label className=" form-control-label"
                                                                           htmlFor="currentModal">
                                                                        Current : {this.props.invoicingModelData && this.props.invoicingModelData.type} {' '}
                                                                        {this.props.invoicingModelData && this.props.invoicingModelData.type !== 'PAYG' && <> ( {(this.props.invoicingModelData && (this.props.invoicingModelData.type === 'CREDIT' || this.props.invoicingModelData.type === 'DAILY_CREDIT' || this.props.invoicingModelData.type === 'CREDIT_IN_DAYS') ? 'Limit Available' : 'Amount Available')} : {this.props.invoicingModelData && this.props.invoicingModelData.value} ) </>}
                                                                    </label>
                                                                </span>
                                                                </div>
                                                                <span className="text-sm">
                                                                <hr className="m-0 mt-3"/>
                                                                <div className="p-0 pb-2 pt-3">
                                                                    <FormGroup>
                                                                        <br/>
                                                                        <label className=" form-control-label"
                                                                               htmlFor="selectModal">
                                                                            Select Modal
                                                                        </label>
                                                                        <Input
                                                                            type="select"
                                                                            placeholder="Select Modal"
                                                                            value={this.props.invoicingModelData.type}
                                                                            onChange={this.handleInvoicingModel}
                                                                        >
                                                                            <option value=''>Choose here</option>
                                                                            <option value='CREDIT'>CREDIT</option>
                                                                            <option value='INVOICE'>INVOICE</option>
                                                                            <option value='PAYG'>PAYG</option>
                                                                            <option value='DAILY_CREDIT'>DAILY_CREDIT</option>
                                                                            <option value='CREDIT_IN_DAYS'>CREDIT_IN_DAYS</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                    {(this.state.selectedInvoicingModel === 'CREDIT' || this.state.selectedInvoicingModel === 'INVOICE' || this.state.selectedInvoicingModel === 'CREDIT_IN_DAYS') && 
                                                                    <FormGroup>
                                                                        <label className=" form-control-label"
                                                                               htmlFor="invoicingValue">
                                                                            Value
                                                                        </label>
                                                                        <Input
                                                                            type="text"
                                                                            placeholder="Enter Value"
                                                                            value={this.state.invoicingValue}
                                                                            onChange={this.handleInvoicingValue}
                                                                        />
                                                                    </FormGroup>
                                                                    }
                                                                </div>
                                                            </span>
                                                            </>}
                                                        <span className="text-sm">
                                                        <Button
                                                            color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                                            type="button"
                                                            {...((this.state.isEditingInvoicingModal && this.props.invoicingModelData.type === 'CREDIT_IN_DAYS' && this.state.invoicingValue < 1) && { disabled: true })}
                                                            onClick={e => {
                                                            e.preventDefault();
                                                            this.toggleInvoicingEditButton(this.state.isEditingInvoicingModal, this.props.invoicingModelData.type)
                                                        }}>
                                                            {!this.state.isEditingInvoicingModal ? <>
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="fas fa-edit"/>
                                                                </span>
                                                                Edit </> : "Save"}
                                                        </Button>
                                                        {this.state.isEditingInvoicingModal &&
                                                        <Button color="primary"
                                                               outline
                                                               className="" type="button"
                                                               size="sm"
                                                               onClick={e => this.handleCancelEditInvoicingModal()}
                                                            >X
                                                        </Button>}
                                                    </span>
                                                    </div>
                                                </CardFooter>
                                            </>
                                            :
                                            <div className=" flx-container">
                                                <div className="flx-group mr-3">
                                                    <div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Name">
                                                                Name
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Enter name"
                                                                value={this.state.nameValue}
                                                                onChange={this.handleNameChange}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Dealership">
                                                                Dealership
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Dealership"
                                                                value={this.state.dealershipValue}
                                                                onChange={this.handleDealershipChange}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Email">
                                                                Email
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Email"
                                                                value={this.state.emailValue}
                                                                onChange={this.handleEmailChange}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="flx-group mr-3">
                                                    <div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Mobile">
                                                                Mobile
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                placeholder="Mobile"
                                                                value={this.state.mobileValue}
                                                                onChange={this.handleMobileChange}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    {dealerDetailsViewData?.partner_data?.master_child?<div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Mobile">
                                                                Parent Share
                                                            </label>
                                                            <Input
                                                                type="text"
                                                                name="parent_share"
                                                                placeholder="Parent Share"
                                                                value={this.state.parentShare}
                                                                onChange={this.handleParentChange}
                                                            />
                                                        </FormGroup>
                                                    </div>:<></>}
                                                    
                                                    <div className="p-0 pb-2">
                                                        <FormGroup>
                                                            <label className=" form-control-label"
                                                                   htmlFor="Address">
                                                                Address
                                                            </label>
                                                            <Input
                                                                className="mb-2"
                                                                type="text"
                                                                placeholder="Address Line 1"
                                                                value={this.state.address_1_Value}
                                                                onChange={this.handleAddress_1_Change}
                                                            />
                                                            <Input
                                                                className="mb-2"
                                                                type="text"
                                                                placeholder="Address Line 2"
                                                                value={this.state.address_2_Value}
                                                                onChange={this.handleAddress_2_Change}
                                                            />
                                                            <Input
                                                                className="mb-2"
                                                                type="text"
                                                                placeholder="City"
                                                                value={this.state.address_3_Value}
                                                                onChange={this.handleAddress_3_Change}
                                                            />
                                                            <Input
                                                                className="mb-2"
                                                                type="text"
                                                                placeholder="State"
                                                                value={this.state.address_4_Value}
                                                                onChange={this.handleAddress_4_Change}
                                                            />
                                                            <Input
                                                                type="text"
                                                                placeholder="Pin Code"
                                                                value={this.state.address_5_Value}
                                                                onChange={this.handleAddress_5_Change}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="d-flex align-items-center justify-content-between pl-4 pr-4 mt-3">
                                                            <Input
                                                                type="checkbox"
                                                                name="view_commission"
                                                                checked={this.state.view_commission}
                                                                onChange={this.handleToggleChange}
                                                            />
                                                            <label className=" form-control-label mt-3 ml-2"
                                                                    htmlFor="view_commission">
                                                                View Commission
                                                            </label>
                                                        </FormGroup>
                                                        <FormGroup className="d-flex align-items-center justify-content-between pl-4 pr-4 mt-3">
                                                            <Input
                                                                type="checkbox"
                                                                name="cut_and_pay"
                                                                checked={this.state.cut_and_pay}
                                                                onChange={this.handleToggleChange}
                                                            />
                                                            <label className=" form-control-label mt-3 ml-2"
                                                                    htmlFor="cut_and_pay">
                                                                Cut & Pay Model
                                                            </label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12">
                                <Col xl="12">
                                    <Row className="flex-nowrap">
                                        <Col xl="10" lg="10" md="10" xs="12" className="p-0">
                                            <Row className="m-0 d-flex flex-row">
                                                {tabs.map((data, index) => (
                                                    <Col xl="2" lg="2" md="2" className="px-0"
                                                         key={"hjsdfae_srty" + index}>
                                                        <Card
                                                            className={"m-0 p-0 rounded-0 " + (this.state.selectedTab === data.key ? 'custom-active ' : 'bg-light')}
                                                            onClick={e => this.onTabClick(e, data)}>
                                                            <div
                                                                className="py-3 px-2 m-0 custom-hover text-center bttn custom-tabs">
                                                                <h6 className="m-0 text-uppercase">
                                                                    <span>{data.item}</span></h6>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <div>
                                    <Row>
                                        <Col xl="12">
                                            {this.state.selectedTab === "policies" ? <>
                                                    <div>
                                                        <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
                                                            <Col className="p-1 text-muted">
                                                                <small>
                                                                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                                                                        <input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                                                                               value={this.state.searchText}
                                                                               onKeyDown={e => {
                                                                                   (e.key === 'Enter') && this.onSearch(e.target.value)
                                                                               }}
                                                                               onChange={e => {
                                                                                   if (e.target.value === " ") {
                                                                                       this.setState({
                                                                                           view: "search-table"
                                                                                       })
                                                                                   }
                                                                                   this.setState({
                                                                                       searchText: e.target.value
                                                                                   })
                                                                               }}
                                                                               type="text" placeholder="ID / Name / Contact" aria-label="Search" />
                                                                        <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                                                           onClick={
                                                                               e => {
                                                                                   e.preventDefault();
                                                                                   this.onSearch(this.state.searchText)
                                                                               }}
                                                                        />
                                                                    </form>
                                                                </small>
                                                                {(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                                                                    {this.state.searchText}
                                                                    <span className="p-1 ml-2 cursor-pointer" onClick={
                                                                        e => {
                                                                            e.preventDefault();
                                                                            this.resetFilters();
                                                                            this.setState({
                                                                                view: "search-table",
                                                                                searchText: ""
                                                                            })
                                                                        }} >
                                                                    <i className="fa fa-times-circle" aria-hidden="true" />
                                                                </span>
                                                                </Badge>}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div>
                                                        <Card className="p-0 mb-1">
                                                            <CardBody className="pb-3 pt-0 px-0"
                                                                      style={{minHeight: "600px"}}>
                                                                <Table className="align-items-center table-flush" hover
                                                                       responsive>
                                                                    <thead className="thead-light">
                                                                    <tr>
                                                                        <th>ID</th>
                                                                        <th><b>Insured</b> / Proposer Name</th>
                                                                        <th>Contact</th>
                                                                        <th>Email</th>
                                                                        <th>Type / Product / Provider</th>
                                                                        <th>Premium</th>
                                                                        <th>Sum Insured</th>
                                                                        <th>Purchase Date</th>
                                                                        <th>Cover Note</th>
                                                                        <th>P.A.</th>
                                                                        <th className="text-right">Policy Document</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody style={{height: "466px"}}>
                                                                    {
                                                                        this.props.dealeDetailsViewLoading && <tr>
                                                                            <td colSpan="8">
                                                                                <Spinner></Spinner>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {!this.props.dealeDetailsViewLoading && this.props.dealerDetailsViewPolicies && this.props.dealerDetailsViewPolicies.length < 1 &&
                                                                    <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                                                        <td colSpan="12" className="text-center">
                                                                            <span className="text-muted">
                                                                                <b>
                                                                                    No Policies found
                                                                                            </b>
                                                                            </span>
                                                                        </td>
                                                                    </tr>}
                                                                    {!this.props.dealeDetailsViewLoading && this.props.dealerDetailsViewPolicies && this.props.dealerDetailsViewPolicies.map((data, index) => (
                                                                        <tr key={"hjsdfae_sdf" + index}>
                                                                            <td className="table-user">
                                                                                {data.id}
                                                                            </td>
                                                                            <td>
                                                                                <b>{data.insured_name ? data.insured_name : "N/A"}</b><br />
                                                                                {data.proposer_name ? data.proposer_name : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.mobile ? data.mobile : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.email ? data.email : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {(data.type) ? data.type : "N/A"} <br/>
                                                                                {(data.product) ? data.product : "N/A"}<br/>
                                                                                {(data.partner) ? data.partner : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {(data.premium) ? data.premium : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.sum_insured ? data.sum_insured : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className="text-muted">{data.purchase_date ? new Date(data.purchase_date.replace(" ", "T")).toDateString() : "N/A"}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className="badge badge-primary badge-pill cursor-pointer">{data.covernote ?
                                                                                    <a href={data.covernote}
                                                                                       className="text-primary"
                                                                                       target="_blank" rel="noreferrer">View</a> : "N/A"}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className="badge badge-primary badge-pill cursor-pointer">{data.pa_document ?
                                                                                    <a href={data.pa_document}
                                                                                       className="text-primary"
                                                                                       target="_blank" rel="noreferrer">View</a> : "N/A"}</span>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <span
                                                                                    className="badge badge-primary badge-pill cursor-pointer">{data.policy_document ?
                                                                                    <a href={data.policy_document}
                                                                                       className="text-primary"
                                                                                       target="_blank" rel="noreferrer">View</a> : "N/A"}</span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                            <CardFooter className="py-4">
                                                                <nav aria-label="...">
                                                                    <Pagination
                                                                        className="pagination justify-content-end mb-0"
                                                                        listClassName="justify-content-end mb-0"
                                                                    >
                                                                        <PaginationItem
                                                                            className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchPage(this.pager.pagerStart);
                                                                                }}
                                                                                tabIndex="-1"
                                                                            >
                                                                                <i className="fas fa-angle-left"/>
                                                                                <span className="sr-only">Previous</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                        {this.pager.pageArray.map(page => {
                                                                            return < PaginationItem key={'pager_' + page}
                                                                                                    hidden={this.isHidden(page)}
                                                                                                    className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                                                                <PaginationLink
                                                                                    href="#pablo"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.switchPage(page);
                                                                                    }}
                                                                                >
                                                                                    {page}
                                                                                </PaginationLink>
                                                                            </PaginationItem>
                                                                        })}
                                                                        <PaginationItem
                                                                            className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchPage(this.pager.pagerStart + 2);
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-angle-right"/>
                                                                                <span className="sr-only">Next</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                    </Pagination>
                                                                </nav>
                                                            </CardFooter>
                                                        </Card>
                                                    </div>
                                                </>
                                                : ""}
                                            {this.state.selectedTab === "transaction" ? <>
                                                    <div>
                                                        <Row className="bg-white m-0 py-2 removeBottomPadding" hidden={this.state.hideFilters}>
                                                            <Col sm={3} className="p-1 text-muted">
                                                                <small>
                                                                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                                                                        <input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                                                                               value={this.state.searchTransactionText}
                                                                               onKeyDown={e => {
                                                                                   (e.key === 'Enter') && this.onTransactionSearch(e.target.value)
                                                                               }}
                                                                               onChange={e => {
                                                                                   if (e.target.value === " ") {
                                                                                       this.setState({
                                                                                           view: "search-table"
                                                                                       })
                                                                                   }
                                                                                   this.setState({
                                                                                       searchTransactionText: e.target.value
                                                                                   })
                                                                               }}
                                                                               type="text" placeholder="Transaction ID" aria-label="Search" />
                                                                        <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                                                           onClick={
                                                                               e => {
                                                                                   e.preventDefault();
                                                                                   this.onTransactionSearch(this.state.searchTransactionText)
                                                                               }}
                                                                        />
                                                                    </form>
                                                                </small>
                                                                {(this.state.searchTransactionText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                                                                    {this.state.searchTransactionText}
                                                                    <span className="p-1 ml-2 cursor-pointer" onClick={
                                                                        e => {
                                                                            e.preventDefault();
                                                                            this.resetFilters();
                                                                            this.setState({
                                                                                view: "search-table",
                                                                                searchTransactionText: ""
                                                                            })
                                                                        }} >
                                                                    <i className="fa fa-times-circle" aria-hidden="true" />
                                                                </span>
                                                                </Badge>}
                                                            </Col>
                                                            <Col sm={9} >
                                                                <ExportTransactionSection setExportDateRangeError={this.setExportDateRangeError} onClearFilter={this.onClearFilter} onFilter={this.onFilterTransactionClick} onExport={this.onExportTransactionClick} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                        <Col sm={3} className="p-1 text-muted"></Col>
                                                        <Col sm={9}>
                                                        { this.state.exportDateRangeError &&
                                                            <span  className={this.state.exportDateErrorClass}>{this.state.exportDateRangeError}</span>}
                                                        </Col>
                                                        </Row>
                                                        
                                                    </div>
                                                    <div>
                                                        <Card className="p-0 mb-1">
                                                            <CardBody className="pb-3 pt-0 px-0"
                                                                      style={{minHeight: "600px"}}>
                                                                <Table className="align-items-center table-flush" hover
                                                                       responsive>
                                                                    <thead className="thead-light mx-0">
                                                                    <tr>
                                                                        <th>Index</th>
                                                                        <th>Transaction ID</th>
                                                                        <th>Amount</th>
                                                                        <th>Payment Source</th>
                                                                        <th>Date</th>
                                                                        <th className="text-right">Policies</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        this.props.dealeDetailsViewLoading && <tr>
                                                                            <td colSpan="8">
                                                                                <Spinner></Spinner>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {!this.state.isFilterTransactionView && !this.props.dealeDetailsViewLoading && this.props.dealerDetailsViewTransactions && this.props.dealerDetailsViewTransactions.length < 1 &&
                                                                    <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                                                        <td colSpan="12" className="text-center">
                                                                            <span className="text-muted">
                                                                                <b>
                                                                                    No Transactions found
                                                                                            </b>
                                                                            </span>
                                                                        </td>
                                                                    </tr>}
                                                                    
                                                                    {this.state.isFilterTransactionView && !this.props.dealerDetailsViewLoading && this.props.filteredTransactions && this.props.filteredTransactions.length < 1 &&
                                                                    <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                                                        <td colSpan="12" className="text-center">
                                                                            <span className="text-muted">
                                                                                <b>
                                                                                    No Transactions found between {this.state.filterTransactionParams.start_date} and  {this.state.filterTransactionParams.end_date}
                                                                                </b>
                                                                            </span>
                                                                        </td>
                                                                    </tr>}
                                                                    {!this.state.isFilterTransactionView && !this.props.dealeDetailsViewLoading && this.props.dealerDetailsViewTransactions && this.props.dealerDetailsViewTransactions.map((data, index) => (
                                                                        <tr key={"hjsdfae_sdf" + index}>
                                                                            <td className="table-user">
                                                                                {index + 1}
                                                                            </td>
                                                                            <td className="cursor-pointer" onClick={(e) => {
                                                                                e.preventDefault()
                                                                            }}>
                                                                                {data.razorpay_payment_id ? data.razorpay_payment_id : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.amount && !isNaN(data.amount) ? data.amount : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.method ? data.method : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.created_at ? data.created_at : "N/A"}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <Button
                                                                                    color="btn-round btn-icon btn btn-outline-link btn-sm"
                                                                                    type="button" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.openOrderIdModel(data.order_id)
                                                                                }}>
                                                                                    <span
                                                                                        className="btn-inner--icon mr-1">
                                                                                        <i className="fas fa-eye"/>
                                                                                    </span>
                                                                                    <span className="btn-inner--text">
                                                                                        View
                                                                                    </span>
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    
                                                                    {this.state.isFilterTransactionView && !this.props.dealerDetailsViewLoading && this.props.filteredTransactions && this.props.filteredTransactions.map((data, index) => (
                                                                        <tr key={"hjsdfae_sdf" + index}>
                                                                            <td className="table-user">
                                                                                {index + 1}
                                                                            </td>
                                                                            <td className="cursor-pointer" onClick={(e) => {
                                                                                e.preventDefault()
                                                                            }}>
                                                                                {data.razorpay_payment_id ? data.razorpay_payment_id : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.amount && !isNaN(data.amount) ? data.amount : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.method ? data.method : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {data.created_at ? data.created_at : "N/A"}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <Button
                                                                                    color="btn-round btn-icon btn btn-outline-link btn-sm"
                                                                                    type="button" onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.openOrderIdModel(data.order_id)
                                                                                }}>
                                                                                    <span
                                                                                        className="btn-inner--icon mr-1">
                                                                                        <i className="fas fa-eye"/>
                                                                                    </span>
                                                                                    <span className="btn-inner--text">
                                                                                        View
                                                                                    </span>
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                            <CardFooter className="py-4">
                                                                <nav aria-label="...">
                                                                    { !this.state.isFilterTransactionView && <Pagination
                                                                        className="pagination justify-content-end mb-0"
                                                                        listClassName="justify-content-end mb-0"
                                                                    >
                                                                        <PaginationItem
                                                                            className={this.pager2.pagerStart > 0 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchTransactionPage(this.pager2.pagerStart);
                                                                                }}
                                                                                tabIndex="-1"
                                                                            >
                                                                                <i className="fas fa-angle-left"/>
                                                                                <span className="sr-only">Previous</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                        {this.pager2.pageArray.map(page => {
                                                                            return < PaginationItem key={'pager_' + page}
                                                                                                    hidden={this.isHiddenT(page)}
                                                                                                    className={((this.pager2.pagerStart + 1) === page) ? "active" : ''}>
                                                                                <PaginationLink
                                                                                    href="#pablo"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.switchTransactionPage(page);
                                                                                    }}
                                                                                >
                                                                                    {page}
                                                                                </PaginationLink>
                                                                            </PaginationItem>
                                                                        })}
                                                                        <PaginationItem
                                                                            className={this.pager2.pagerEnd > this.pager2.pagerStart + 1 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchTransactionPage(this.pager2.pagerStart + 2);
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-angle-right"/>
                                                                                <span className="sr-only">Next</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                    </Pagination>}

                                                                    
                                                                    { this.state.isFilterTransactionView && <Pagination
                                                                        className="pagination justify-content-end mb-0"
                                                                        listClassName="justify-content-end mb-0"
                                                                    >
                                                                        <PaginationItem
                                                                            className={this.filterTransactionPager.pagerStart > 0 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchFilterTransactionPage(this.filterTransactionPager.pagerStart);
                                                                                }}
                                                                                tabIndex="-1"
                                                                            >
                                                                                <i className="fas fa-angle-left"/>
                                                                                <span className="sr-only">Previous</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                        {this.filterTransactionPager.pageArray.map(page => {
                                                                            return < PaginationItem key={'pager_' + page}
                                                                                                    hidden={this.isHiddenTFilter(page)}
                                                                                                    className={((this.filterTransactionPager.pagerStart + 1) === page) ? "active" : ''}>
                                                                                <PaginationLink
                                                                                    href="#pablo"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.switchFilterTransactionPage(page);
                                                                                    }}
                                                                                >
                                                                                    {page}
                                                                                </PaginationLink>
                                                                            </PaginationItem>
                                                                        })}
                                                                        <PaginationItem
                                                                            className={this.filterTransactionPager.pagerEnd > this.filterTransactionPager.pagerStart + 1 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchFilterTransactionPage(this.filterTransactionPager.pagerStart + 2);
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-angle-right"/>
                                                                                <span className="sr-only">Next</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                    </Pagination>}
                                                                </nav>
                                                            </CardFooter>
                                                        </Card>
                                                        <Modal
                                                            className="modal-dialog-centered"
                                                            size="sm"
                                                            isOpen={this.state.orderIdView}
                                                            toggle={() => {
                                                                this.setState({
                                                                    orderIdView: !this.state.orderIdView
                                                                })
                                                            }}
                                                        >
                                                            <div className="modal-body">
                                                                <Form role="form">
                                                                    <Row
                                                                        className="input-daterange datepicker align-items-center">
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <label className=" form-control-label"
                                                                                       htmlFor="amountCommission">
                                                                                    Order IDs
                                                                                </label>
                                                                                <hr className="m-0 mb-3"/>
                                                                                <div style={{
                                                                                    maxHeight: "300px",
                                                                                    overflow: "scroll"
                                                                                }}>
                                                                                    <Table
                                                                                        className="align-items-center table-flush"
                                                                                        hover responsive>
                                                                                        <tbody>
                                                                                        {this.state.selectedOrderList && this.state.selectedOrderList.map((value, index) => {
                                                                                            return (
                                                                                                <tr key={index}
                                                                                                    className="w-100 rounded-0">
                                                                                                    <td className="rounded-0">
                                                                                                        {index + 1}
                                                                                                    </td>
                                                                                                    <td className="rounded-0">
                                                                                                        {value}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        })}
                                                                                        </tbody>
                                                                                    </Table>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </div>
                                                            <div className="modal-footer date-modal-footer ">
                                                                <Button color="primary"
                                                                        className="ml-auto" type="button"
                                                                        size="sm"
                                                                        onClick={e => {
                                                                            this.setState({
                                                                                orderIdView: false
                                                                            })
                                                                        }}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </>
                                                : ""}
                                            {this.state.selectedTab === "products" ?
                                                <div>
                                                    <Card className="p-0 mb-1">
                                                        <CardBody className="py-3 px-0" style={{minHeight: "600px"}}>
                                                            <Table className="align-items-center table-flush" hover
                                                                   responsive>
                                                                <thead className="thead-light mx-0">
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Product</th>
                                                                    <th>Commission Model</th>
                                                                    <th>Commission Structure</th>
                                                                    <th>Policies Sold</th>
                                                                    <th>Premium Sourced</th>
                                                                    <th>Commission Earned</th>
                                                                    <th className="text-right">
                                                                        <Can I={permissions.DEALER.product_store}
                                                                             a={permissions.DEALER.subject}>
                                                                            <Button
                                                                                // disabled={dealerDetailsViewData && dealerDetailsViewData.business_category === "motor" && this.props.dealerDetailsViewProducts.length > 0}
                                                                                onClick={e => {
                                                                                e.preventDefault();
                                                                                this.openProductModel(
                                                                                    {
                                                                                        dealer_id: this.props.match.params.id,
                                                                                        addProduct_id: this.state.selectedProductAdd,
                                                                                        addCommission: this.state.productCommissionAdd,
                                                                                        is_flat_add: this.state.is_flat_add,
                                                                                        partner_id: dealerDetailsViewData?.partner_id
                                                                                    })
                                                                            }}
                                                                                color="btn-round btn-icon btn btn-outline-success btn-sm"
                                                                                type="button">
                                                                                    <span
                                                                                        className="btn-inner--icon mr-1">
                                                                                        <i className="fas fa-plus"/>
                                                                                    </span>
                                                                                <span className="btn-inner--text">
                                                                                        Add Product
                                                                                    </span>
                                                                            </Button>
                                                                        </Can>
                                                                    </th>
                                                                </tr>
                                                                </thead>

                                                                <tbody style={{height: "466px"}}>
                                                                {
                                                                    this.props.dealerDetailsViewLoading && <tr>
                                                                        <td colSpan="8">
                                                                            <Spinner></Spinner>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {!this.props.dealerDetailsViewLoading && this.props.dealerDetailsViewProducts && this.props.dealerDetailsViewProducts.length < 1 &&
                                                                <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                                                    <td colSpan="12" className="text-center">
                                                                            <span className="text-muted">
                                                                                <b>
                                                                                    No Products found
                                                                                            </b>
                                                                            </span>
                                                                    </td>
                                                                </tr>}

                                                                {this.props.dealerDetailsViewProducts && this.props.dealerDetailsViewProducts.map((product, index) => (
                                                                    <tr key={"hjsdfae_sdf" + index}>
                                                                        <td>
                                                                            {product.id ? product.id : "N/A"}
                                                                        </td>
                                                                        <td className="table-user">
                                                                            {product.name ? product.name : "N/A"} {' (' + (product.group_product === true ? 'G' : 'R') + ')'}<br />{"(" + product.insurance_provider + ")"}
                                                                        </td>
                                                                      <td>
                                                                        {product.transfer_pricing.length ? 'Transfer Pricing' : 'Post Paid'}
                                                                      </td>
                                                                        <td className="cursor-pointer" >
                                                                            {
                                                                              product.transfer_pricing.length ?
                                                                                  product.transfer_pricing.map((element) => {
                                                                                    let amount = element.value;
                                                                                    let title =  !!element.variations ? '('+element.variations+')' : ''
                                                                                    let transferPricingPayload = {
                                                                                      dealer_id : this.props.match.params.id,
                                                                                      product_id: product.id,
                                                                                      commission: amount,
                                                                                      is_flat: product.commission_structure.commission.is_flat,
                                                                                      transferPricingId: element.id,
                                                                                        isDynamicPricingEnabled: element.is_dynamic_pricing_enabled,
                                                                                        showDynamicPricing: true,
                                                                                        commissionModelTitle: 'Transfer Pricing ' + title
                                                                                    };
                                                                                    return <>
                                                                                      <div className={'d-flex justify-content-between'}>
                                                                                        {(element.variations ?  element.variations + " : " : "") + amount}
                                                                                        <div className={'my-1'}>
                                                                                          <Button color="btn-round btn-icon btn btn-link-primary btn-sm" type="button" onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.openCommissionModel(transferPricingPayload)
                                                                                          }}>
                                                                                        <span className="btn-inner--icon mr-1">
                                                                                            <i className="fas fa-edit" />
                                                                                        </span>
                                                                                            <span className="btn-inner--text">
                                                                                            Edit
                                                                                    </span>
                                                                                          </Button>
                                                                                        </div>

                                                                                      </div>
                                                                                    </>;
                                                                                  })   :
                                                                              (product.commission_structure ? (
                                                                                product.commission_structure.commission &&
                                                                                (product.commission_structure.commission.is_flat ?
                                                                                        <>
                                                                                          <div className={'d-flex justify-content-between'}>
                                                                                            Rs {product.commission_structure.commission.value}
                                                                                            <div>
                                                                                              <Button color="btn-round btn-icon btn btn-link-primary btn-sm" type="button" onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.openCommissionModel(
                                                                                                    {
                                                                                                      dealer_id: this.props.match.params.id,
                                                                                                      product_id: product.id,
                                                                                                      commission: product.commission_structure.commission.value,
                                                                                                      is_flat: product.commission_structure.commission.is_flat,
                                                                                                        showDynamicPricing: false,
                                                                                                        commissionModelTitle: 'Edit Commission'
                                                                                                    });
                                                                                              }}>
                                                                                              <span className="btn-inner--icon mr-1">
                                                                                                  <i className="fas fa-edit" />
                                                                                              </span>
                                                                                                  <span className="btn-inner--text"> Edit </span>
                                                                                              </Button>
                                                                                            </div>
                                                                                          </div>
                                                                                        </>:
                                                                                        <>
                                                                                        <div className={'d-flex justify-content-between'}>
                                                                                          {product.commission_structure.commission.value} %
                                                                                          <div>
                                                                                            <Button color="btn-round btn-icon btn btn-link-primary btn-sm" type="button" onClick={e => {
                                                                                              e.preventDefault();
                                                                                              this.openCommissionModel({
                                                                                                dealer_id: this.props.match.params.id,
                                                                                                product_id: product.id,
                                                                                                commission: product.commission_structure.commission.value,
                                                                                                is_flat: product.commission_structure.commission.is_flat,
                                                                                                  showDynamicPricing: false,
                                                                                                  commissionModelTitle: 'Edit Commission'
                                                                                              });
                                                                                            }}>
                                                                                            <span className="btn-inner--icon mr-1">
                                                                                                <i className="fas fa-edit" />
                                                                                            </span>
                                                                                              <span className="btn-inner--text"> Edit </span>
                                                                                            </Button>
                                                                                          </div>
                                                                                        </div>
                                                                                        </>
                                                                                )
                                                                            ) : 'N/A')
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {product.policies_sold ? product.policies_sold : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            {product.premium ? product.premium : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            <span className="cursor-pointer">{product.commission ? product.commission : "N/A"}</span>
                                                                        </td>
                                                                        <td className="text-right" >
                                                                            {/*<Can I={permissions.DEALER.product_update} a={permissions.DEALER.subject}>*/}
                                                                            {/*    <Button color="btn-round btn-icon btn btn-outline-danger btn-sm" type="button" onClick={e => {*/}
                                                                            {/*        e.preventDefault();*/}
                                                                            {/*        this.openProductDeleteModel(this.props.match.params.id, product.id)*/}
                                                                            {/*    }}>*/}
                                                                            {/*        <span className="btn-inner--icon mr-1">*/}
                                                                            {/*            <i className="fas fa-trash" />*/}
                                                                            {/*        </span>*/}
                                                                            {/*        <span className="btn-inner--text">*/}
                                                                            {/*            Delete*/}
                                                                            {/*        </span>*/}
                                                                            {/*    </Button>*/}
                                                                            {/*</Can>*/}
                                                                            {/*<Can I={permissions.DEALER.product_update} a={permissions.DEALER.subject}>*/}
                                                                            {/*    <Button color="btn-round btn-icon btn btn-outline-primary btn-sm" type="button" onClick={e => {*/}
                                                                            {/*        e.preventDefault();*/}
                                                                            {/*        this.openCommissionModel(this.props.match.params.id, product.id, product.commission_structure.commission.value, product.commission_structure.commission.is_flat)*/}
                                                                            {/*    }}>*/}
                                                                            {/*            <span className="btn-inner--icon mr-1">*/}
                                                                            {/*                <i className="fas fa-edit" />*/}
                                                                            {/*            </span>*/}
                                                                            {/*        <span className="btn-inner--text">*/}
                                                                            {/*                Edit*/}
                                                                            {/*        </span>*/}
                                                                            {/*    </Button>*/}
                                                                            {/*</Can>*/}
                                                                            <Button onClick={e => {this.openConfirmationModal(product.id,dealerDetailsViewData.id)}} close />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                    <Modal
                                                        className="modal-dialog-centered"
                                                        size="sm"
                                                        isOpen={this.state.showCommissionModel}
                                                        toggle={() => {
                                                            this.setState({
                                                                showCommissionModel: !this.state.showCommissionModel
                                                            })
                                                        }}
                                                    >
                                                        <div className="modal-body">
                                                            <Form role="form">
                                                                {!this.customLoader.updateCommissionLoader ?
                                                                    <Row
                                                                        className="input-daterange datepicker align-items-center">

                                                                        <Col>
                                                                            <FormGroup>
                                                                                <label className=" form-control-label"
                                                                                       htmlFor="amountCommission">
                                                                                    {this.state.commissionModelTitle}
                                                                                </label>
                                                                                <Input
                                                                                    type="text"
                                                                                    placeholder="Commission"
                                                                                    value={this.state.productCommission}
                                                                                    onChange={e => {
                                                                                        this.setState({
                                                                                            productCommission: e.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                            {!this.state.showDynamicPricing &&
                                                                                <div
                                                                                    className="custom-control custom-checkbox ">
                                                                                    <input
                                                                                        className="custom-control-input"
                                                                                        id="customCheck1"
                                                                                        type="checkbox"
                                                                                        checked={this.state.is_flat}
                                                                                        onChange={e => {
                                                                                            this.handleFlatCommissionChange(
                                                                                                e);
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="customCheck1"
                                                                                    >
                                                                                        Is
                                                                                        Flat
                                                                                    </label>
                                                                                </div>}
                                                                            {this.state.showDynamicPricing && <div
                                                                                    className="custom-control custom-checkbox ">
                                                                                    <input
                                                                                        className="custom-control-input"
                                                                                        id="customCheck2"
                                                                                        type="checkbox"
                                                                                        checked={this.state.isDynamicPricingEnabled}
                                                                                        onChange={e => {
                                                                                            this.handleChange(e);
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        className="custom-control-label"
                                                                                        htmlFor="customCheck2"
                                                                                    >
                                                                                        Is
                                                                                        Dynamic
                                                                                        Pricing
                                                                                    </label>
                                                                                </div>}

                                                                        </Col>
                                                                    </Row> :
                                                                    <div className="position-relative">
                                                                        {this.customLoader.updateCommissionLoader &&
                                                                        <Loader msg={"Updating"}/>}
                                                                    </div>
                                                                }
                                                            </Form>
                                                        </div>
                                                        <div className="modal-footer date-modal-footer ">
                                                            <Button
                                                                color="link"
                                                                data-dismiss="modal"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showCommissionModel: false
                                                                    })
                                                                }}
                                                            >
                                                                Close</Button>
                                                            <Button color="primary"
                                                                    className="ml-auto" type="button"
                                                                    size="sm"
                                                                    onClick={e => {
                                                                        this.onUpdateCommission()
                                                                    }}
                                                            >
                                                                Update</Button>
                                                        </div>
                                                    </Modal>

                                                    <Modal
                                                        className="modal-dialog-centered"
                                                        size="lg"
                                                        onClosed={e => {
                                                            this.setState({
                                                                selectedProductAdd: ''
                                                            })
                                                        }
                                                        }
                                                        isOpen={this.state.showProductModel}
                                                        toggle={() => {
                                                            this.setState({
                                                                showProductModel: !this.state.showProductModel
                                                            })
                                                        }}
                                                    >
                                                        <div className="modal-body">
                                                            <Form role="form">
                                                                {!this.customLoader.addProductLoader ? <>

                                                                        <FormGroup>
                                                                            <label className=" form-control-label"
                                                                                   htmlFor="amountCommission">
                                                                                Add Product
                                                                            </label>
                                                                            <Select2
                                                                                className="form-control"
                                                                                defaultValue=""
                                                                                options={{
                                                                                    placeholder: "Search"
                                                                                }}
                                                                                value={this.state.selectedProductAdd}
                                                                                data={this.state.products && this.state.products.map(({id, name, provider, premium}) => {
                                                                                    return {
                                                                                        id: id,
                                                                                        text: [name, ' ','(Plan - ', premium ? premium : 'NA', ', ', 'ID - ', id, ')']
                                                                                    }
                                                                                })}
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        selectedProductAdd: e.target.value

                                                                                    })
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <label className="form-control-label"
                                                                                   htmlFor="amountCommission">
                                                                                Add Commission
                                                                            </label>
                                                                            <Input
                                                                                type="text"
                                                                                placeholder="Commission"
                                                                                onChange={e => {
                                                                                    this.setState({
                                                                                        productCommissionAdd: e.target.value
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                        <div className="custom-control custom-checkbox ">
                                                                            <input
                                                                                className="custom-control-input"
                                                                                id="customCheck1111"
                                                                                type="checkbox"
                                                                                checked={this.state.is_flat_add}
                                                                                onChange={e => {
                                                                                    this.handleChangeAdd(e)
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className="custom-control-label"
                                                                                htmlFor="customCheck1111"
                                                                            >
                                                                                Is Flat
                                                                            </label>
                                                                        </div>

                                                                    </> :
                                                                    <div className="position-relative">
                                                                        {this.customLoader.addProductLoader &&
                                                                        <Loader msg={"Updating"}/>}
                                                                    </div>
                                                                }

                                                            </Form>
                                                        </div>
                                                        <div className="modal-footer date-modal-footer ">
                                                            <Button
                                                                color="link"
                                                                data-dismiss="modal"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showProductModel: false

                                                                    })
                                                                }}
                                                            >
                                                                Close</Button>
                                                            <Button color="primary"
                                                                    className="ml-auto" type="button"
                                                                    {...((!this.state.selectedProductAdd || this.state.productCommissionAdd === null) && { disabled: true })}
                                                                    size="sm"
                                                                    onClick={e => {
                                                                        this.onAddProduct()
                                                                    }}
                                                            >
                                                                Update</Button>
                                                        </div>
                                                    </Modal>
                                                    <Modal
                                                        className="modal-dialog-centered"
                                                        size="sm"
                                                        isOpen={this.state.confirmProductDeleteModel}
                                                        toggle={() => {
                                                            this.setState({
                                                                confirmProductDeleteModel: !this.state.confirmProductDeleteModel
                                                            })
                                                        }}
                                                    >
                                                        <div className="modal-body text-center">
                                                            <h5 className="mt-0 m-auto">
                                                                You really want to delete the Product ?
                                                            </h5>

                                                        </div>
                                                        <div className="modal-footer date-modal-footer ">
                                                            <Button
                                                                color="link"
                                                                data-dismiss="modal"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        confirmProductDeleteModel: false
                                                                    })
                                                                }}
                                                            >
                                                                Cancel</Button>
                                                            <Button color="primary"
                                                                    className="ml-auto" type="button"
                                                                    size="sm"
                                                                    onClick={e => {
                                                                        this.onDeleteProduct()
                                                                    }}
                                                            >
                                                                Confirm</Button>
                                                        </div>
                                                    </Modal>
                                                </div>
                                                : ""}
                                            {this.state.selectedTab === "additional" ?
                                                <>
                                                    <Card>
                                                        <div className="p-4 ">
                                                            <CardHeader className="d-flex justify-content-between">
                                                                <h5 className="my-auto mx-0">
                                                                    Contacts
                                                                </h5>
                                                                <h5 className="my-auto mx-0">
                                                                    <span onClick={e => {
                                                                        e.preventDefault();
                                                                        this.openContactModel(this.props.match.params.id, this.state.addContact, this.state.addContactCommission, this.state.storeName, this.state.is_promoter)
                                                                    }}>
                                                                        <Can
                                                                            I={permissions.DEALER.additional_number_store}
                                                                            a={permissions.DEALER.subject}>
                                                                            <Button
                                                                                color="btn-round btn-icon btn btn-outline-success btn-sm"
                                                                                type="button">
                                                                                <span className="btn-inner--icon mr-1">
                                                                                    <i className="fas fa-plus"/>
                                                                                </span>
                                                                                <span className="btn-inner--text">
                                                                                    Add Contact
                                                                            </span>
                                                                            </Button>
                                                                        </Can>
                                                                    </span>
                                                                </h5>
                                                            </CardHeader>
                                                            <CardBody className="py-0 px-0" style={{ minHeight: "600px" }}>
                                                                <Table className="align-items-center table-flush" hover responsive>
                                                                    <thead className="thead-light">
                                                                    <tr>
                                                                        <th>Index</th>
                                                                        <th>User ID</th>
                                                                        <th>Additional Contact</th>
                                                                        <th>Store Name</th>
                                                                        <th className="text-right">Delete Contact</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {this.props.additionalContactListLoading && <tr >
                                                                        <td colSpan="8">
                                                                            <Spinner></Spinner>
                                                                        </td>
                                                                    </tr>
                                                                    }
                                                                    {!this.props.additionalContactListLoading && additionalContactListData && additionalContactListData.length < 1 && <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                                                        <td colSpan="12" className="text-center">
                                                                                <span className="text-muted">
                                                                                    <b>
                                                                                        No Additional Contacts found
                                                                                    </b>
                                                                                </span>
                                                                        </td>
                                                                    </tr>}
                                                                    {!this.props.additionalContactListLoading && additionalContactListData && additionalContactListData.map((contact, index) => (
                                                                        <tr key={"hjsdfae_sdf" + index}>
                                                                            <td className="table-user">
                                                                                {index + 1}
                                                                            </td>
                                                                            <td>
                                                                                {contact.id ? contact.id : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {contact.mobile ? contact.mobile : "N/A"}
                                                                            </td>
                                                                            <td>
                                                                                {contact.store_name ? contact.store_name : "N/A"}
                                                                            </td>
                                                                            <td className="text-right">
                                                                                    <span>
                                                                                        <Can
                                                                                            I={permissions.DEALER.additional_number_delete}
                                                                                            a={permissions.DEALER.subject}>

                                                                                            <Button color="btn-round btn-icon btn btn-outline-danger btn-sm" type="button" onClick={e => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    selectedContact: contact.id,
                                                                                                    selected_Dealer: this.props.match.params.id
                                                                                                }, () => {
                                                                                                    this.openDeleteModel(this.props.match.params.id, contact.id, contact.mobile)
                                                                                                })
                                                                                            }}>
                                                                                                <span className="btn-inner--icon mr-1">
                                                                                                    <i className="fas fa-trash" />
                                                                                                </span>
                                                                                                <span
                                                                                                    className="btn-inner--text">
                                                                                                    Delete
                                                                                            </span>
                                                                                            </Button>
                                                                                        </Can>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </Table>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                    <Modal
                                                        className="modal-dialog-centered"
                                                        size="sm"
                                                        isOpen={this.state.confirmDeleteModel}
                                                        toggle={() => {
                                                            this.setState({
                                                                confirmDeleteModel: !this.state.confirmDeleteModel
                                                            })
                                                        }}
                                                    >
                                                        <div className="modal-body text-center">
                                                            <h5 className="mt-0 m-auto">
                                                                You really want to delete contact number <br /> <span className="h4"><b>{this.state.selectedMobile}</b> ?</span>
                                                            </h5>
                                                        </div>
                                                        <div className="modal-footer date-modal-footer ">
                                                            <Button
                                                                color="primary"
                                                                data-dismiss="modal"
                                                                type="button"
                                                                size="sm"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        confirmDeleteModel: false
                                                                    })
                                                                }}
                                                            >
                                                                Cancel</Button>
                                                            <Button color="primary"
                                                                    className="ml-auto" type="button"
                                                                    size="sm"
                                                                    onClick={e => {
                                                                        this.onDeleteContact()
                                                                    }}
                                                            >
                                                                Confirm</Button>
                                                        </div>
                                                    </Modal>
                                                    <Card>
                                                        <div className="p-4">
                                                            <CardHeader className="d-flex justify-content-between">
                                                                <h5 className="my-auto mx-0">
                                                                    Associated Brands :
                                                                </h5>
                                                                <h5 className="my-auto mx-0">
                                                                    <Button
                                                                        color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                                                        type="button" onClick={e => {
                                                                        e.preventDefault();
                                                                        this.toggleBrandsEditButton(this.state.isEditingBrands)
                                                                    }}>
                                                                        {!this.state.isEditingBrands ? <>
                                                                            <span className="btn-inner--icon mr-1">
                                                                                <i className="fas fa-edit"/>
                                                                            </span>
                                                                            Edit </> : "Save"}
                                                                    </Button>
                                                                </h5>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <h5>
                                                                    <FormGroup>
                                                                        {brandListData && brandListData.map((brand, index) => {
                                                                            return <Badge key={Math.random(100)}
                                                                                          id={"career-gray" + index}
                                                                                          className={`badge-md cursor-pointer tag-behaviour m-1 ` + (this.state.brandIdsList.includes(brand.id) ? " background-primary text-white " : (!this.state.isEditingBrands && ' d-none '))}
                                                                                          pill color="secondary"
                                                                                          onClick={e => {
                                                                                              e.preventDefault();
                                                                                              this.state.isEditingBrands && (this.state.brandIdsList.includes(brand.id) ? this.removeBrandElement(brand) : this.addBrandElement(brand))
                                                                                          }}
                                                                            >{brand.name}
                                                                                {this.state.isEditingBrands &&
                                                                                <span className="btn-inner--icon ml-1">
                                                                                    {(this.state.brandIdsList.includes(brand.id) ?
                                                                                        <i className="fas fa-times"/> :
                                                                                        <i className="fas fa-plus"/>)}
                                                                                </span>}
                                                                            </Badge>
                                                                        })}
                                                                    </FormGroup>
                                                                </h5>
                                                            </CardBody>
                                                        </div>
                                                    </Card>
                                                    <Modal
                                                        className="modal-dialog-centered"
                                                        size="sm"
                                                        isOpen={this.state.showContactModel}
                                                        toggle={() => {
                                                            this.setState({
                                                                showContactModel: !this.state.showContactModel
                                                            })
                                                        }}
                                                    >
                                                        <div className="modal-body">
                                                            {!this.customLoader.addContactLoader ?
                                                                <Form role="form">
                                                                    <Row
                                                                        className="input-daterange datepicker align-items-center">
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <label className=" form-control-label"
                                                                                       htmlFor="amountInput">
                                                                                    Store Name
                                                                                </label>
                                                                                <Input
                                                                                    type="text"
                                                                                    placeholder="Add Store Name"
                                                                                    value={this.state.storeName}
                                                                                    onChange={e => {
                                                                                        this.setState({
                                                                                            storeName: e.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <label className=" form-control-label"
                                                                                       htmlFor="amountInput">
                                                                                    Add Contact
                                                                                </label>
                                                                                <Input
                                                                                    type="text"
                                                                                    placeholder="Phone Number"
                                                                                    value={this.state.addContact}
                                                                                    onChange={e => {
                                                                                        this.setState({
                                                                                            addContact: e.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                            <div className="custom-control custom-checkbox ">
                                                                                <input
                                                                                    className="custom-control-input"
                                                                                    id="customCheck1122"
                                                                                    type="checkbox"
                                                                                    checked={this.state.is_promoter}
                                                                                    onChange={e => {
                                                                                        this.handleChangePromoter(e)
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor="customCheck1122"
                                                                                >
                                                                                    Is Promoter
                                                                                </label>
                                                                            </div>
                                                                            {this.state.is_promoter === true &&
                                                                            <FormGroup className="mt-3">
                                                                                <label className=" form-control-label"
                                                                                       htmlFor="amountInput">
                                                                                    Promoter Commission
                                                                                </label>
                                                                                <Input
                                                                                    type="text"
                                                                                    placeholder="Commission"
                                                                                    value={this.state.addContactCommission}
                                                                                    onChange={e => {
                                                                                        this.setState({
                                                                                            addContactCommission: e.target.value
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Form> :
                                                                <div className="position-relative">
                                                                    {this.customLoader.addContactLoader &&
                                                                    <Loader msg={"Updating"}/>}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="modal-footer date-modal-footer ">
                                                            <Button
                                                                color="link"
                                                                data-dismiss="modal"
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        showContactModel: false
                                                                    })
                                                                }}
                                                            >
                                                                Close</Button>
                                                            <Button color="primary"
                                                                    className="ml-auto" type="button"
                                                                    size="sm"
                                                                    onClick={e => {
                                                                        this.onAddContact()
                                                                    }}
                                                            >
                                                                Add</Button>
                                                        </div>
                                                    </Modal>
                                                </>
                                                : ""}
                                            {this.state.selectedTab === "history" ? <>
                                                    <div>
                                                        <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
                                                            <Col className="p-1 text-muted">
                                                                <small>
                                                                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                                                                        <input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                                                                               value={this.state.searchHistory}
                                                                               onKeyDown={e => {
                                                                                   (e.key === 'Enter') && this.onHistorySearch(e.target.value)
                                                                               }}
                                                                               onChange={e => {
                                                                                   if (e.target.value === " ") {
                                                                                       this.setState({
                                                                                           view: "search-table"
                                                                                       })
                                                                                   }
                                                                                   this.setState({
                                                                                       searchHistory: e.target.value
                                                                                   })
                                                                               }}
                                                                               type="text" placeholder="Search" aria-label="Search" />
                                                                        <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                                                           onClick={
                                                                               e => {
                                                                                   e.preventDefault();
                                                                                   this.onHistorySearch(this.state.searchHistory)
                                                                               }}
                                                                        />
                                                                    </form>
                                                                </small>
                                                                {(this.state.searchHistory !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                                                                    {this.state.searchHistory}
                                                                    <span className="p-1 ml-2 cursor-pointer" onClick={
                                                                        e => {
                                                                            e.preventDefault();
                                                                            this.resetFilters();
                                                                            this.setState({
                                                                                view: "search-table",
                                                                                searchHistory: ""
                                                                            })
                                                                        }} >
                                                                    <i className="fa fa-times-circle" aria-hidden="true" />
                                                                </span>
                                                                </Badge>}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div>
                                                        <Card className="p-0 mb-1">
                                                            <CardBody className="p-3" style={{minHeight: "600px"}}>
                                                                {
                                                                    this.props.dealerHistoryLoading &&
                                                                    <Row className="bg-light px-2 py-2 mb-3 mx-1"
                                                                         key={"hfgHGJFGJjwef_"}>
                                                                        <Col>
                                                                            <h5 className="m-0 font-weight-400 text-center">
                                                                                No History Found
                                                                            </h5>
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                {!this.props.dealerHistoryLoading && this.props.dealerHistoryData && this.props.dealerHistoryData.length < 1 &&
                                                                <Row className="bg-light px-2 py-2 mb-3 mx-1"
                                                                     key={"hfgjkhgwef_"}>
                                                                    <Col>
                                                                        <h5 className="m-0 font-weight-400 text-center">
                                                                            No History Found
                                                                        </h5>
                                                                    </Col>
                                                                </Row>
                                                                }
                                                                {!this.props.dealerHistoryLoading && dealerHistoryData && dealerHistoryData.map((history, index) => {
                                                                    return <Row className="bg-light px-2 py-2 mb-3 mx-1"
                                                                                key={"hfgjwef_" + index}>
                                                                        <Col>
                                                                            <h5 className="m-0 font-weight-400">
                                                                                <i className="fas fa-arrow-right pr-4"/>
                                                                                <b>{this.modifyStr(history.key)}</b> is
                                                                                changed
                                                                                from <b>'{history.old_value}'</b> to <b>'{history.new_value}'</b> by <b>{history.user_name}</b> on <b>{history.updated_at}</b> .
                                                                            </h5>
                                                                        </Col>
                                                                    </Row>
                                                                })}
                                                            </CardBody>
                                                            <CardFooter className="py-4">
                                                                <nav aria-label="...">
                                                                    <Pagination
                                                                        className="pagination justify-content-end mb-0"
                                                                        listClassName="justify-content-end mb-0"
                                                                    >
                                                                        <PaginationItem
                                                                            className={this.pager1.pagerStart > 0 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchHistoryPage(this.pager1.pagerStart);
                                                                                }}
                                                                                tabIndex="-1"
                                                                            >
                                                                                <i className="fas fa-angle-left"/>
                                                                                <span className="sr-only">Previous</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                        {this.pager1.pageArray.map(page => {
                                                                            return < PaginationItem key={'pager_' + page}
                                                                                                    hidden={this.isHiddenH(page)}
                                                                                                    className={((this.pager1.pagerStart + 1) === page) ? "active" : ''}>
                                                                                <PaginationLink
                                                                                    href="#pablo"
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        this.switchHistoryPage(page);
                                                                                    }}
                                                                                >
                                                                                    {page}
                                                                                </PaginationLink>
                                                                            </PaginationItem>
                                                                        })}
                                                                        <PaginationItem
                                                                            className={this.pager1.pagerEnd > this.pager1.pagerStart + 1 ? '' : 'disabled'}>
                                                                            <PaginationLink
                                                                                href="#pablo"
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    this.switchHistoryPage(this.pager1.pagerStart + 2);
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-angle-right"/>
                                                                                <span className="sr-only">Next</span>
                                                                            </PaginationLink>
                                                                        </PaginationItem>
                                                                    </Pagination>
                                                                </nav>
                                                            </CardFooter>
                                                        </Card>
                                                    </div>
                                                </>
                                                : ""}
                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                        </Row>
                    </>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        dealerDetailsViewLoading: state.dealer.dealerDetailsViewLoading,
        dealerDetailsViewCardLoading: state.dealer.dealerDetailsViewCardLoading,
        dealerViewProductListLoading: state.dealer.dealerViewProductListLoading,
        brandListLoading: state.dealer.brandListLoading,
        dealerBrandListLoading: state.dealer.dealerBrandListLoading,
        additionalContactListLoading: state.dealer.additionalContactListLoading,
        dealerHistoryLoading: state.dealer.dealerDetailsHistoryLoading,
        invoicingModelLoading: state.dealer.invoicingModelLoading,

        dealerDetailsViewData: state.dealer.dealerDetailsViewData,
        dealerDetailsViewPolicies: state.dealer.dealerDetailsViewPolicies,
        dealerDetailsViewTransactions: state.dealer.dealerDetailsViewTransactions,
        dealerDetailsViewProducts: state.dealer.dealerDetailsViewProducts,
        dealerDetailsViewCardData: state.dealer.dealerDetailsViewCardData,
        dealerViewProductListData: state.dealer.dealerViewProductListData,
        brandListData: state.dealer.brandListData,
        dealerBrandListData: state.dealer.dealerBrandListData,
        additionalContactListData: state.dealer.additionalContactListData,
        dealerHistoryData: state.dealer.dealerDetailsHistoryData,
        policyCount: state.dealer.policyCount,
        historyCount: state.dealer.historyCount,
        transactionCount: state.dealer.transactionCount,
        filteredTransactions: state.dealer.filteredTransactions,
        filteredTransactionCount: state.dealer.filteredTransactionCount,
        invoicingModelData: state.dealer.invoicingModelData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onGetDealerDetailsViewData: (id, params) => dispatch(actions.getDealerDetailsViewData(id, params)),
        onGetDealerDetailsViewCardData: (id) => dispatch(actions.getDealerDetailsViewCardData(id)),
        onGetFilteredViewData: (id, params) => dispatch(actions.getFilteredViewData(id, params)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetDealerViewProductListData: (data) => dispatch(actions.getDealerViewProductListData(data)),
        onGetBrandListData: (data) => dispatch(actions.getBrandListData(data)),
        onGetDealerBrandListData: (id) => dispatch(actions.getDealerBrandListData(id)),
        onGetAdditionalContactListData: (id) => dispatch(actions.getAdditionalContactListData(id)),
        onGetDealerHistoryData: (id, params) => dispatch(actions.getDealerDetailsHistoryData(id, params)),
        onGetDealerInvoicingModelData: (id) => dispatch(actions.getDealerInvoicingModelData(id)),
        onRemoveProductFromDealer: (id) => dispatch(actions.removeProductFromDealer(id))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(DealerView);