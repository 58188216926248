import React, {Component, Fragment} from 'react';
import Select2 from "react-select2-wrapper";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    CardFooter,
    Button,
    Input,
    Modal,
    Form
} from "reactstrap";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import Loader from "../../../components/CustomUi/Loader/Loader";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import {IndiaFirstLocation} from "../../../locations/indiaFirstLocations";
import { buildPartnerProductList } from "../../../helpers/partners";

const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const password_regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

class AddPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partner_id: '',
            loaderMsg: 'Wait...',
            partnerUserEmail: '',
            partnerUsername: '',
            password: '',
            confirmPassword: ''
        }
        this.partnerUsername = this.partnerUsername.bind(this);
        this.handlepartnerUserEmail = this.handlepartnerUserEmail.bind(this);

    }

    componentDidMount = () => {
        this.props.onGetPartnerFilterList()
        this.getProductList()
        this.getPartnerList()

    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: typeof msg === 'string' ? msg : Object.values(msg).map((data)=> {return data[0]}).join(","),
            title: "",
            type: type
        })
    };

    handlepartnerUserEmail = (event) => {
        this.setState({
            partnerUserEmail: event.target.value
        });
    }
    partnerUsername = (event) => {
      this.setState({
        partnerUsername: event.target.value
      });
  }
  
  password = (event) => {
    this.setState({
        password: event.target.value
    });
}
confirmPassword = (event) => {
    this.setState({
        confirmPassword: event.target.value
    });
}

  submit = (payload) => {

    let isValid = true;
        if (this.state.partner_id === '') {
            this.showMsg("Partner Name Cannot be Empty", "danger")
            isValid = false
        } else if (this.state.partnerUserEmail === '') {
            this.showMsg("Email Cannot be Empty", "danger")
            isValid = false
        }  else if (!this.state.partnerUserEmail.match(email_regx)) {
            this.showMsg("Please Enter Valid Email", "danger")
            isValid = false
        } else if(this.state.password === ''){
            this.showMsg("Password Cannot be Empty","danger")
            isValid = false
        }  else if (!this.state.password.match(password_regx)) {
            this.showMsg("Please Enter Valid Password", "danger")
            isValid = false
        } else if(this.state.password !== this.state.confirmPassword){
            this.showMsg("Password and Confirm password have to be same","danger")
            isValid = false
        }

        if (!isValid) {
            return false;
        }

        this.setState({
            loaderMsg: "Please Wait",
            showLoader: true
        }, () => {
            this.setState({
                showLoader: true
            });

            AxiosReactClient.postRequest('partners/createDashboard', payload).then(response => {
                this.setState({
                    showLoader: false,
                    partner_id: '',
                    partnerUserEmail: '',
                    partnerUsername: '',
                    password: '',
                    confirmPassword: ''
                });

                if (response["data"].data.message === "User already exists.") {
                    this.showMsg(response["data"].data.message, "danger");
                    return
                }
                if (response.data.success === true) {
                    this.showMsg("Partner Dashboard Credentials created Successfully", "success");
                    return
                }
                if (response.data.success === false) {
                    this.showMsg(response["data"].data.message, "danger");
                }
            })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    });

                    if (err.response) {
                        this.showMsg(err.response.data?.data?.error_msg ? err.response.data?.data?.error_msg : err.response.data?.message, "danger")
                    } else if (err.message) {
                        this.showMsg(err.message, "danger")
                    }
                });
        });

    }

    

  

    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            ...this.state,
            [name]:value
        })
        if(name === 'pinCode'){
            this.populateStateCity(value)
        }
        if (name === 'partnerId') {
            this.getProductList(value);
        }
        if (name === 'partner_id') {
            this.getPartnerList(value);
        }
    }

   
    cbProductList = (products = []) => {
        this.setState({ 'products': buildPartnerProductList(products), product_id: '' })
    }

    cbPartnerList = (partners = []) => {
        this.setState({ 'partners': buildPartnerProductList(partners), partners_id: '' })
    }


    getProductList = async () => {
        try {
           
            const response = await AxiosReactClient.getRequest('filter/product/list');

            const { data: { product } } = response.data;
            this.cbProductList(product);
        } catch (error) {
            alert('It looks like something went wrong. Please try again in a while.');
            this.cbProductList();
        }
    };

    getPartnerList = async () => {
        try {
           
            const response = await AxiosReactClient.getRequest('filter/partner/list');

            const { data: { partner } } = response.data;
            this.cbPartnerList(partner);
        } catch (error) {
            alert('It looks like something went wrong. Please try again in a while.');
            this.cbPartnerList();
        }
    };

    render() {
        return (
            <Fragment>
                <Container fluid>
                    {this.state.showLoader && <div className="loader-container">
                        <Loader msg={this.state.loaderMsg}/>
                    </div>}
                    <Row>
                        <Col xs="12">
                            <Card className="mt-4">
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h4 className="h4 pt-2 font-weight-400">
                                                Create Partner Dashboard
                                            </h4>
                                        </Col>
                                        
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Partners
                                        </label>

                                        <Select2 className=" form-control-label mt-3"
                                                 options={{placeholder:"Select Partner"}}
                                                 name="partner_id"
                                                 value={this.state.partner_id}
                                                 defaultValue=""
                                                 data={this.state.partners}
                                                 onChange={this.handleChange}
                                                //  disabled={!this.state.product_id}
                                        />

                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Email
                                        </label>

                                        <Input
                                            type="email"
                                            placeholder="Enter Email"
                                            value={this.state.partnerUserEmail}
                                            onChange={this.handlepartnerUserEmail}
                                        />
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Partner User Name
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter User Name"
                                            value={this.state.partnerUsername}
                                            onChange={this.partnerUsername}
                                        />

                                    <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Password (Password length should be of 8-14 characters with minimum one uppercase, lowercase, alphabets, numbers and special characters.)
                                        </label>
                                        <Input
                                            type="password"
                                            placeholder="Enter Password"
                                            value={this.state.password}
                                            onChange={this.password}
                                            minLength="6"
                                            maxLength="14"
                                        />

                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Confirm Password
                                        </label>
                                        <Input
                                            type="password"
                                            placeholder="Enter Confirm Password"
                                            value={this.state.confirmPassword}
                                            onChange={this.confirmPassword}
                                            minLength="6"
                                            maxLength="14"
                                        />
                                       
                                                                              
                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <h5 className="h5 mb-0 font-weight-400 text-primary cursor-pointer text-center">
                                        <Button
                                            block
                                            color="primary"
                                            size="lg"
                                            type="button" onClick={e => {
                                            e.preventDefault();
                                            this.submit({
                                                partner_id: this.state.partner_id,
                                                partner_user_mail: this.state.partnerUserEmail,
                                                partner_user_name: this.state.partnerUsername,
                                                password: this.state.password,
                                            })
                                        }}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-plus"/>
                                            </span>
                                            Create Partner Dashboard
                                        </Button>
                                    </h5>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        partnerFilterList: state.common.partnerFilterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPartnerFilterList: () => dispatch(actions.getPartnerFilterListData()),
        postCreatePartnerDashboard: (params) => dispatch(actions.postCreatePartnerDashboard(params)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPartner);