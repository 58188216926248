import React, { useEffect, useState } from 'react';
import { 
  errorMessageVehicleMaster, 
  initialVehicleMasterFilterState, 
  initialVehicleMasterFormState, 
  options, 
  touchFieldsVehicleMaster 
} from '../../../defaultValues/vehicleMaster';
import { notify, parseMessage } from '../../../helpers/utils';
import { buildPartnerProductList } from '../../../helpers/partners';
import { 
  buildAddEditVehicleMasterPayload, 
  // buildPartnerList, 
  buildSearchParams,
  buildSystemErrorMessage,
  shouldSkipValidation, 
  syncVehicleMasterData 
} from '../../../helpers/vehicle';
import { Validators } from '../../../messages/validators';
import AxiosReactClient from '../../../utilities/AxiosRestClient';
import AddEditVehicleMasterForm from './AddEditVehicleMasterForm';

const AddEditVehicleMaster = (props) => {
  const [list, setList] = useState({ fuelTypes: [], partners: [], products: [] });
  const [errorMessage, setErrorMessage] = useState(errorMessageVehicleMaster);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(initialVehicleMasterFormState); 
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSearchResultLoaded, setIsSearchResultLoaded] = useState(false);
  const [filter, setFilter] = useState(initialVehicleMasterFilterState);
  const [touchFields, setTouchFields] = useState(touchFieldsVehicleMaster);
  const [productId, setProductId] = useState('');

  useEffect(() => {
    // getPartnerList();
    getProductList(form.partner_id);
  }, []);

  /* useEffect(() => {
    getProductList(form.partner_id);
  }, [form.partner_id]); */

  useEffect(() => {
    validateRequired('vehicle_code');
  }, [form.vehicle_code]);

  useEffect(() => {
    validateRequired('make');
  }, [form.make]);

  useEffect(() => {
    validateRequired('model');
  }, [form.model]);

  useEffect(() => {
    validateRequired('variant');
  }, [form.variant]);

  useEffect(() => {
    validateRequiredConditional('make_code');
  }, [form.make_code]);

  useEffect(() => {
    validateRequiredConditional('model_code');
  }, [form.model_code]);

  useEffect(() => {
    validateRequiredConditional('variant_code');
  }, [form.variant_code]);

  useEffect(() => {
    validateRequired('carrying_capacity');
  }, [form.carrying_capacity]);

  useEffect(() => {
    validateRequired('no_of_wheels');
  }, [form.no_of_wheels]);

  useEffect(() => {
    validateRequired('cubic_capacity');
  }, [form.cubic_capacity]);
  
  useEffect(() => {
    setProductId(form.product_id);
    setList({ ...list, 'fuelTypes': form.product_id ? options.fuel[form.product_id] : [] });

    if (productId) {
      setFormState('fuel', '');
    }
  }, [form.product_id]);

  useEffect(() => {
    resetForm();
  }, [filter.selection]);

  const setFormState = function (name, value) {
    setForm({
      ...form,
      [name]: value
    });
  };

  const setErrorMessageState = (name, value) => {
    setErrorMessage({
      ...errorMessage,
      [name]: value
    });
  };

  const setTouchFieldsState = (name, value) => {
    setTouchFields({
      ...touchFields,
      [name]: value
    });
  };

  const validateRequired = (fieldName, skipValidation = false) => {
    if (form[fieldName] || skipValidation) {
      setFormState('hasError', false);
      setErrorMessageState(fieldName, '');
      setTouchFieldsState(fieldName, true);
    } else if (touchFields[fieldName]) {
      setErrorMessageState(fieldName, Validators[fieldName].required);
    }
  };

  const validateRequiredConditional = (fieldName) => {
    validateRequired(fieldName, shouldSkipValidation(form.product_id));
  };
  
  const handleChange = (event) => {
    setFormState(event.target.name, event.target.value);
  };

  const cbProductList = (products = []) => {
    setList({ ...list, products: buildPartnerProductList(products) });
  }

  const getProductList = async (partnerId) => {
    try {
      if (!partnerId) {
        cbProductList();
        return false;
      }

      const response = await AxiosReactClient.getRequest('filter/product/list', { partner_id: partnerId });

      const { data: { product } } = response.data;
      cbProductList(product);
    } catch (error) {
      cbProductList();
    }
  };

  /* const getPartnerList = async () => {
    try {
      const response = await AxiosReactClient.getRequest('filter/partner/list');

      const { data: { partner } } = response.data;
      setList({ ...list, partners: buildPartnerList(partner) });
    } catch (error) {
      buildPartnerProductList();
    }
  }; */

  const handleFilterChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const searchDetailsByVehicleCode = async () => {
    try {
      setIsSearchResultLoaded(false);
      const response = await AxiosReactClient.getRequest('admin/vehicle_model', buildSearchParams(filter));

      const { data: { vehicle_model } } = response.data;

      setForm(syncVehicleMasterData(vehicle_model));
      setFilter({ ...filter, result: vehicle_model});
      setIsSearchResultLoaded(true);
    } catch (error) {
      cbError(error.response.data);
    }
  };

  const onSubmitSearch = (event) => {
    event.preventDefault();

    searchDetailsByVehicleCode();
  };

  const cb = ({ message }) => {
    setIsFormSubmitted(false);
    resetForm();
    setFilter({ ...filter, search_vehicle_code: '', product: '', result: [] });

    notify(message);
  };

  const cbError = ({data: {error_msg}}) => {
    setIsFormSubmitted(false);

    const message =  parseMessage(error_msg);
    if (Array.isArray(message)) {
        setErrors(message);
    } else {
        let messageArray = [];
        messageArray.push(message);
        setErrors(messageArray);
    }
  };
    
  const submitVehicleMaster = async () => {
    try {
      setIsFormSubmitted(true);
      const url = 'admin/vehicle_model';
      const payload = buildAddEditVehicleMasterPayload(form);
      const response = form.id
        ? await AxiosReactClient.putRequest(`${url}/${form.id}`, payload)
        : await AxiosReactClient.postRequest(url, payload);
      
      cb(response.data);
    } catch (error) {
      cbError(error && error.response && error.response.data ? error.response.data : buildSystemErrorMessage());
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    submitVehicleMaster();
  };

  const resetForm = () => {
    setErrors([]);
    setTouchFields(touchFieldsVehicleMaster);
    setErrorMessage(errorMessageVehicleMaster);
    setFilter({ ...filter, search_vehicle_code: '', product: '', result: [] });
    setForm(initialVehicleMasterFormState);
    setProductId('');
    setIsSearchResultLoaded(false);
  };

  return ( 
    <AddEditVehicleMasterForm
      errorMessage={errorMessage}
      filter={filter}
      form={form}
      errors={errors}
      getProductList={getProductList}
      handleChange={handleChange}
      handleFilterChange={handleFilterChange}
      isFormSubmitted={isFormSubmitted}
      isSearchResultLoaded={isSearchResultLoaded}
      list={list}
      onSubmit={onSubmit}
      onSubmitSearch={onSubmitSearch}
      resetForm={resetForm}
    />
  );
}

export default AddEditVehicleMaster;
