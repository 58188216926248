import React from 'react';
import { Button } from 'reactstrap';

const OemMappingSearchFooter = (props) => {
    const {
        handleResetClick,
        handleSaveClick
    } = props;

    return (
        <>
            <Button
                name="btnSave"
                color="primary"
                onClick={handleSaveClick}
            >
                Save
            </Button>
            <Button
                name="btnReset"
                color="secondary"
                outline
                onClick={handleResetClick}
            >
                Reset
            </Button>
        </>
    );
};

export default OemMappingSearchFooter;