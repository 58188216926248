import * as permissions from '../../../config/Casl/permissions';
import Payments from "./Payments";
import Payout from "./Payout";
import Invoices from "./Invoices";
import Float from "./Float";
import OfflineMap from "./offlineMap";


const financeRoute = {
    collapse: true,
    name: "Finance",
    icon: "ni ni-money-coins text-primary",
    state: "financeCollapse",
    invisible: false,
    permission: permissions.FINANCE.menu,
    subject: permissions.FINANCE.subject,
    views: [{
        path: "/finance/payments",
        name: "Payments",
        component: Payments,
        layout: "/admin",
    },
    {
        path: "/finance/payout",
        name: "Payouts",
        component: Payout,
        layout: "/admin",
        permission: permissions.FINANCE.payout_list,
        subject: permissions.FINANCE.subject,
    },
    {
        path: "/finance/invoices",
        name: "Invoices",
        component: Invoices,
        layout: "/admin",
        permission: permissions.FINANCE.payout_list,
        subject: permissions.FINANCE.subject,
    },
    {
        path: "/finance/getFloatBalance",
        name: "Get Float Balance",
        component: Float,
        layout: "/admin",
        permission: permissions.FINANCE.payout_list,
        subject: permissions.FINANCE.subject,
    },
     {
        path: "/finance/offline",
        name: "Map Offline Payment",
        component: OfflineMap,
        layout: "/admin",
        permission: permissions.FINANCE.payout_list,
        subject: permissions.FINANCE.subject,
    },
    ]
};

export default financeRoute;