import React from 'react';
import { 
  Row, 
  Col 
} from 'reactstrap';

const AddMoreInputBox= (props) => {
  const { 
    form, 
    handleCoveredChange, 
    keyname,
    removeItem 
  } = props;

  return (
    form[keyname].map((item, index) => (
      <Row key={index}>
        <Col sm="11">
          <input className="form-control mb-3" type="text" name="title" placeholder="" fullwidth="true"  
            value={item.title} onChange={(event) => handleCoveredChange(event, index, keyname)} />
        </Col>
        <Col sm="1">
          {form[keyname].length > 1 &&
          <button 
            type="button"
            title="Remove Item" 
            className="btn btn-outline-primary btn-sm mt-2"
            onClick={() => removeItem(keyname, index)}>X
          </button>}
          {/* {form[keyname].length > 1 &&
          <span className="cursor-pointer" title="Remove Item"
            onClick={() => removeItem(keyname, index)}
          >
            <i className="fa fa-trash pt-3" aria-hidden="true"></i>
          </span>} */}
        </Col>
      </Row>
    ))
  );
};

export default AddMoreInputBox;