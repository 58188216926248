import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
} from "reactstrap";

const UnifiedPoliciesHeader = ({ title = "Unified Policies" }) => {
  return (
    <div className="header-body">
      <Row className="align-items-center py-4">
        <Col lg="8" xs="6">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listClassName="breadcrumb-links"
          >
            <BreadcrumbItem>
              <a href="#" onClick={(e) => e.preventDefault()}>
                <i className="fas fa-home" />
              </a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {title}
              </a>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>

        <Col className="text-right" lg="4" xs="6"></Col>
      </Row>
    </div>
  );
};

export default UnifiedPoliciesHeader;
