import React, { useContext, useReducer, useEffect, useState } from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import ExamStudyMaterialContext from "../../../context/agentExamContext";
import AgentExamReducer from "../../../reducers/agentExamReducer";
import useApi from "../../../hooks/useApi";
import { ReducerUtils } from "../../../constants/reducers";
import {
  buildDocumentTypeList,
  checkDocumentExistense,
  getUniqueListBy,
  redirectWithBlank,
  syncDocumentTypeList,
  toBase64,
} from "../../../helpers/utils";
import { Documents } from "../../../data/documents";
import DocumentUpload from "./DocumentUpload";
import AxiosRestClient from "../../../utilities/AxiosRestClient";

const DocumentModal = ({ toggleModal, agentId, isModalOpen }) => {
  const initialReducerState = useContext(ExamStudyMaterialContext);
  const [state, dispatch] = useReducer(AgentExamReducer, initialReducerState);
  const [agentDetails, setAgentDetails] = useState(null);
  const [photo, setPhoto] = useState({
    pancard: "",
    aadhaarFront: "",
    aadhaarBack: "",
    qualification: "",
    certificate: "",
    agreement: "",
    bank: ""
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [documentTypes, setDocumentTypes] = useState(buildDocumentTypeList());
  const details = useApi(`dealers/${agentId}`);

  useEffect(() => {
    if (details) {
      const { dealershipDetails } = details;

      dealershipDetails.documents = getUniqueListBy(
        dealershipDetails.documents,
        "document_type_id"
      );

      dispatchEvent(ReducerUtils.agent.details, {
        ...details,
        dealershipDetails,
      });

      setDocumentTypes([
        ...syncDocumentTypeList(
          [...buildDocumentTypeList()],
          checkDocumentExistense(dealershipDetails.documents)
        ),
      ]);

      setAgentDetails({ ...details, dealershipDetails });
    }
  }, [details]);

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const photoChangeHandler = (keyname, file) => {
    setPhoto({
      ...photo,
      [keyname]: file,
    });
  };

  const buildSubmitRequestPayload = () => {
    return {
      entity_id: agentId,
      entity_name: "dealer",
    };
  };

  const submitDocument = async (payload, keyname) => {
    await AxiosRestClient.postRequest(`v2/documents`, {
      ...buildSubmitRequestPayload(),
      ...payload,
      pages: [
        {
          data: await toBase64(photo[keyname]),
        },
      ],
    });
  };

  const submitAadhaarFrontDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_AADHAR_FRONT",
      },
      "aadhaarFront"
    );
  };

  const submitAadhaarBackDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_AADHAR_BACK",
      },
      "aadhaarBack"
    );
  };

  const submitPancardDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_PAN",
      },
      "pancard"
    );
  };

  const submitQualificatoinDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_EDUCATION_QUALIFICATION",
      },
      "qualification"
    );
  };

  const submitCertificateDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_GENERAL_EXAM_CERTIFICATE",
      },
      "certificate"
    );
  };

  const submitAgreementDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_AGREEMENT_CERTIFICATE",
      },
      "agreement"
    );
  };

  const submitBankDocument = async () => {
    await submitDocument(
      {
        type: "DOCUMENT_BANK_DETAILS_PROOF",
      },
      "bank"
    );
  };

  const submitDocumentHandler = async () => {
    setIsFormSubmitted(true);

    if (photo.aadhaarFront) {
      await submitAadhaarFrontDocument();
    }
    if (photo.aadhaarBack) {
      await submitAadhaarBackDocument();
    }
    if (photo.pancard) {
      await submitPancardDocument();
    }
    if (photo.qualification) {
      await submitQualificatoinDocument();
    }
    if (photo.bank) {
      await submitBankDocument();
    }
    if (photo.certificate) {
      await submitCertificateDocument();
    }
    if (photo.agreement) {
      await submitAgreementDocument();
    }

    setIsFormSubmitted(false);
    toggleModal("isDocumentModalOpen", false);
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={isModalOpen}
      toggle={() => toggleModal("isDocumentModalOpen", false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h4>KYC Documents, Certificate, and Agreement</h4>
      </div>
      <div className="modal-body ml-2 mr-4">
        {agentDetails &&
          agentDetails?.dealershipDetails?.documents?.length > 0 &&
          agentDetails?.dealershipDetails?.documents?.map((item, index) => {
            return item.pages.map((page, childIndex) => {
              return (
                childIndex === 0 && (
                  <Row key={`document_${index}`} className="mt-3">
                    <Col xs="7">
                      <label
                        className=" form-control-label mt-3"
                        htmlFor="dealer"
                      >
                        {Documents[item.document_type_id]?.name}
                      </label>
                    </Col>
                    <Col xs="5">
                      <Button
                        // color="primary"
                        // size="sm"
                        type="button"
                        onClick={() => redirectWithBlank(page.url)}
                        variant="outlined"
                        color="secondary"
                        size="large"
                        style={{ borderRadius: "2vh" }}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-download" />
                        </span>
                        {"Download"}
                      </Button>
                    </Col>
                  </Row>
                )
              );
            });
          })}
        {agentDetails &&
          documentTypes.map((item, index) => {
            return (
              <Row key={`document_${index}`} className="mt-3">
                <Col xs="7">
                  <label className=" form-control-label mt-3" htmlFor="dealer">
                    {Documents[item.document_type_id]?.name}
                  </label>
                </Col>
                <Col xs="5">
                  <DocumentUpload
                    title="Add Document"
                    photoChangeHandler={photoChangeHandler}
                    filename={item.filename}
                  />
                </Col>
              </Row>
            );
          })}
      </div>
      <div className="modal-footer date-modal-footer">
        {documentTypes?.length > 0 && (
          <Button
            color="primary"
            type="button"
            size="md"
            onClick={submitDocumentHandler}
            {...(isFormSubmitted && { disabled: true })}
          >
            Submit
          </Button>
        )}
        <Button
          color="secondary"
          type="button"
          size="md"
          onClick={() => toggleModal("isDocumentModalOpen", false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DocumentModal;
