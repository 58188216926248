import React from "react";
import { Card, CardBody, CardFooter, Col, Row, Table } from "reactstrap";
import Paginator from "./paginator";
import Spinner from "../../../../components/Spinner/Spinner";

const UnifiedPoliciesList = (props) => {
  const { handleClick, loader, list, pageSize, pagination } = props;

  return (
    <Card className="m-0 mt-1" style={{ minHeight: "300px" }}>
      <CardBody className="px-0 py-0">
        <div className="table-responsive">
          <Table className="align-items-center table-flush" hover responsive>
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Policy Number</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Issue Date</th>
                <th>Insurance Company</th>
                <th>Channel</th>
                <th>Insured Name</th>
                <th>Premium</th>
                <th>Product Category</th>
                {/* <th>Sub Category</th> */}
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {loader && (
                <tr>
                  <td colSpan="8">
                    <Spinner></Spinner>
                  </td>
                </tr>
              )}
              {!loader && !list.length && (
                <tr className="cursor-pointer">
                  <td colSpan="12" className="text-center">
                    <span className="text-muted">
                      <b>No Policy Data Found</b>
                    </span>
                  </td>
                </tr>
              )}

              {!loader &&
                list.length > 0 &&
                list
                  .slice(
                    pagination.currentPage * pageSize,
                    (pagination.currentPage + 1) * pageSize
                  )
                  .map((item, index) => {
                    return (
                      <tr key={index + "unified-policies"}>
                        <td className="table-user">{item?.id}</td>
                        <td>{item?.Policy_No}</td>
                        <td>{item?.Risk_start_date}</td>
                        <td>{item?.Risk_End_Date}</td>
                        <td>{item?.Policy_issue_date}</td>
                        <td>{item?.Insurer_name}</td>
                        <td>{item?.Channel_Online_Offline}</td>
                        <td>{item?.Insured_Name}</td>
                        <td>{item?.Net_Premium}</td>
                        <td>{`${item?.Product_category} (${item?.Product_sub_category})`}</td>
                        {/* <td>{item?.Product_sub_category}</td> */}
                        <td>{item?.Policy_Status}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </div>
      </CardBody>

      {!loader && list.length > 0 && list.length > pageSize && (
        <CardFooter className="py-4">
          <Paginator pagination={pagination} handleClick={handleClick} />
        </CardFooter>
      )}
    </Card>
  );
};

export default UnifiedPoliciesList;
