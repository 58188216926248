import React from 'react';
import {
  Button
} from 'reactstrap';
import { Utils } from '../../constants/utils';

const AddMoreButton = (props) => {
  const { 
    addItem, 
    form, 
    keyname 
  } = props;

  return (
    form[keyname].length < Utils.limit.maxlen[keyname] ?
      <Button color="primary"
      name={keyname}
      outline
      className="ml-auto px-4" 
      type="button"
      size="sm"
      { ...( !form[keyname][form[keyname].length - 1].title && { disabled: true } ) }
      onClick={addItem}
    >
      Add More
    </Button> : <></>
  );
};

export default AddMoreButton;