import * as actionTypes from './actionTypes/partnerTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'

// START
export const getPartnerFinanceListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_LIST_START,
    };
};
export const getProductFinanceFilterListStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_START,
    };
};
export const getPartnerFinanceFilterListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_START,
    };
};
export const getPartnerFinanceCardsStart = () => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_CARDS_START,
    };
};


// SUCCESS
export const getPartnerFinanceListSuccess = (data, count) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_LIST_SUCCESS,
        data: data,
        count: count
    };
};
export const getProductFinanceFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPartnerFinanceFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPartnerFinanceCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_CARDS_SUCCESS,
        data: data,
    };
};


// FAIL
export const getPartnerFinanceListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_LIST_FAIL,
        error: error
    };
};
export const getProductFinanceFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCT_FINANCE_FILTER_LIST_FAIL,
        error: error
    };
};
export const getPartnerFinanceFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_FILTER_LIST_FAIL,
        error: error
    };
};
export const getPartnerFinanceCardsFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_FINANCE_CARDS_FAIL,
        error: error
    };
};



// ACTION
export const getPartnerFinanceListData = (filter, params) => {
    return dispatch => {
        dispatch(getPartnerFinanceListStart(filter));
        AxiosReactClient.getRequest('partners/finance', params).then(response => {
                dispatch(getPartnerFinanceListSuccess(response.data.data.transactions, response.data.data.totalCount));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getPartnerFinanceListFail(err.response.data.error));
                else
                    dispatch(getPartnerFinanceListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getProductFinanceFilterListData = () => {
    return dispatch => {
        dispatch(getProductFinanceFilterListStart());
        AxiosReactClient.getRequest('filter/product/list').then(response => {
                dispatch(getProductFinanceFilterListSuccess(response.data.data.product));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getProductFinanceFilterListFail(err.response.data.error));
                else
                    dispatch(getProductFinanceFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerFinanceFilterListData = () => {
    return dispatch => {
        dispatch(getPartnerFinanceFilterListStart());
        AxiosReactClient.getRequest('filter/partner/list').then(response => {
                dispatch(getPartnerFinanceFilterListSuccess(response.data.data.partner));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerFinanceFilterListFail(err.response.data.error));
                else
                    dispatch(getPartnerFinanceFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPartnerFinanceCardsData = (params) => {
    return dispatch => {
        dispatch(getPartnerFinanceCardsStart());
        AxiosReactClient.getRequest('partners/finance/cards',params).then(response => {
                dispatch(getPartnerFinanceCardsSuccess(response.data.data.cards));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerFinanceCardsFail(err.response.data.error));
                else
                    dispatch(getPartnerFinanceCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};