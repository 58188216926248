import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router';
import {
	Card,
	Container,
	Row,
	Col,
	CardBody,
	CardTitle,
} from "reactstrap";
import AxiosRestClient from '../../../utilities/AxiosRestClient';
import DataSkeleton from "../../../components/CustomUi/DataSkeleton";
import {Images} from "../../../constants/images";

const MATCH_STRING_INSIDE_BRAC = /\s*\(.*?\)\s*/g

function ViewLeads({match, history}) {

	const [state, setState] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		if(match.params?.id){
			getDogDetails(match.params?.id);
		}
	},[])

	async function getDogDetails(id) {
		try{
			const res = await AxiosRestClient.getRequest(`v2/dog_insurance/${id}`);
			if(res?.data?.data){
				setState(res.data.data)
			}
			setLoading(false);
		}catch(error){
			setLoading(false)
			console.log(error)
		}
	}

	 function getDogAge(year, month){
		let age = "N/A";
		if(year){
			age = year+" year";
		}
		if(month){
			age=year?age+" "+month+" month":"0 year"+month+" month";
		}
		return age ==="N/A"&&loading?null:age;
	}

	function getAddress(e) {
		let add = [];
		if(e?.address1){
			add.push(e.address1);
		}
		if(e?.address2){
			add.push(e?.address2);
		}
		if(e?.city){
			add.push(e.city);
		}
		if(e?.state){
			add.push(e.state)
		}
		if(add instanceof Array && add.length>0){
			return add.join(", ")
		}
		return null;
	}

	function getAddOns(addons){
		if(addons){
			return addons.replace(MATCH_STRING_INSIDE_BRAC, ' ')
		}
		return addons;
	}

	function back(){
		history.push("/admin/pet/dog")
	}

	return (<>
		<Row>
			<Card className={"bg-primary w-100"} style={{ height: "200px" }} />
		</Row>
		<Container fluid className={"mt--9"}>
			<Row>
				<Card className={"w-100 p-4 h"}>
					<CardTitle>
						<Row className=" d-flex justify-content-start align--items-center">
							<Col md="6" xs="6" xl="6">
								<h1 className=" d-flex justify-content-start align-items-center">
									<div className="cursor-pointer" onClick={back}><img src={Images.backButton} alt="back"/></div>Dog Insurance
								</h1>
							</Col>
							<Col md="6" xs="6" xl="6" className="d-flex justify-content-end align-items-center">
								<h2 className="text-gray">
									<DataSkeleton data={state?.order?.amount?`₹ ${state?.order?.amount}`:(state?.premium?`₹ ${state?.premium}`:undefined)} loading={loading}/>
								</h2>
							</Col>
						</Row>
					</CardTitle>
					<hr className={"p-0 m-0"} />
					<CardBody>
						<h4 className={"text-gray"}>
							Dog Details
						</h4>
						<Row className={"mt-1"}>
							<Col lg="3" xs="6" className="mb-3 mt-3" >
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Name
								</h4>
								<DataSkeleton data={state?.dog_name} loading={loading}/>
							</Col>

							<Col lg="3" xs="6" mb="3" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Breed
								</h4>
								<DataSkeleton data={state?.dog_breed} loading={loading}/>
							</Col>

							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Age
								</h4>
								<DataSkeleton data={getDogAge(state?.dog_age_year, state?.dog_age_month)} loading={loading}/>
							</Col>

							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Mark
								</h4>
								<DataSkeleton data={state?.dog_mark} loading={loading}/>
							</Col>
							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Gender
								</h4>
									<DataSkeleton data={state?.dog_gender} loading={loading}/>
							</Col>

							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Dog Category
								</h4>
									<DataSkeleton data={state?.dog_category} loading={loading}/>
							</Col>
							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Is Vaccinated
								</h4>
									<DataSkeleton data={state?.is_vaccinated===true?"Yes":state?.is_vaccinated===false?"No":state?.is_vaccinated} loading={loading}/>
							</Col>
							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Spayed/Neutered
								</h4>
									<DataSkeleton data={state?.spayed_castrated} loading={loading}/>
							</Col>
							<Col lg="3" xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray fnt-12"}>
									Medical Conditions
								</h4>
									<DataSkeleton data={state?.defects_ailments} loading={loading}/>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<h4 className={"text-gray"}>
							Parent Details
						</h4>
						<Row className={"mt-1"}>
						<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h5 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									Salutation
								</h5>
								<DataSkeleton data={state?.gender} loading={loading}/>
							</Col>
							<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h5 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									Name
								</h5>
								<DataSkeleton data={state?.name} loading={loading}/>
							</Col>

							<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									Mobile
								</h4>
								<DataSkeleton data={state?.mobile} loading={loading}/>
							</Col>

							<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									Email
								</h4>
								<DataSkeleton data={state?.email} loading={loading}/>
							</Col>

							<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									DOB
								</h4>
								<DataSkeleton data={state?.customer_dob} loading={loading}/>
							</Col>

							<Col lg={"4"} md={"4"} xs="6" className="mt-3">
								<h4 className={"font-weight-400 mb-1 text-gray m-0 fnt-12"}>
									Address
								</h4>
								<DataSkeleton data={getAddress(state)} loading={loading}/>
							</Col>
						</Row>
					</CardBody>
					<CardBody>
						<h4 className={"text-gray mb-1"}>
							Add-ons
						</h4>
						<Row className={" mt-2"}>
							<Col lg={"4"} md={"4"}>
								<DataSkeleton data={state?.add_ons?getAddOns(state?.add_ons)?.split(",")?.map(ele=><>{ele}<br/></>):undefined} loading={loading}/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Row>
		</Container>
	</>
	);
}


export default withRouter(ViewLeads);