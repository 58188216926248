import React from 'react';
import { Input } from "reactstrap";

const OemMappingSearchListItem = (props) => {
    const {
        index,
        item,
        handleToggleCheckbox
    } = props;

    return (
        <tr key={item.id}>
            <td width="40%">
                <Input
                    type="checkbox"
                    value={item.checked}
                    onChange={() => handleToggleCheckbox(item, index)}
                    className="ml-1"
                />
                <div className="pl-4">{item.vehicle_make}</div>
            </td>
            <td width="40%">{item.product_name}</td>
            <td width="20%">{item.vehicle_type}</td>
        </tr>
    );
}

export default OemMappingSearchListItem;