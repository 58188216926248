import { ReducerUtils } from '../constants/reducers';

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.comparison.details:
      return {
        ...state,
        comparisonDetails: action.payload
      };

    case ReducerUtils.comparison.list:
      return {
        ...state,
        comparisonList: action.payload,
        comparisonListApi : action.payload
      };

    default:
      return state;
  }
}
    