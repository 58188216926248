import React, { useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Container,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosRestClient from "../../../utilities/AxiosRestClient";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import { buildSystemErrorMessage, parseMessage } from "../../../helpers/utils";

const qualificationList = [
  { id: 1, text: "Post Graduation" },
  { id: 2, text: "Graduation" },
  { id: 3, text: "12th" },
  { id: 4, text: "10th" },
  { id: 6, text: "Others" },
];

const KYCDetailsModal = ({ toggleModal, agentId, isModalOpen, cbSuccess }) => {
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState({
    account_number: "",
    aadhaar: "",
    beneficiary_name: "",
    cut_and_pay: true,
    dob: "",
    educational_qualification_id: "",
    gst: "",
    ifsc_code: "",
    is_profile_completed: true,
    is_prior_experience: false,
    is_whatsapp_comms_enable: false,
    pan: "",
    view_commission: true,
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const buildRequestPayload = () => {
    const {
      aadhaar,
      beneficiary_name,
      account_number,
      ifsc_code,
      cut_and_pay,
      dob,
      educational_qualification_id,
      gst,
      is_whatsapp_comms_enable,
      is_prior_experience,
      is_profile_completed,
      pan,
      view_commission,
    } = form;
    return {
      aadhaar,
      agent_type_id: 1,
      bank_detail: {
        beneficiary_name,
        account_number,
        ifsc_code,
      },
      cut_and_pay,
      dob,
      educational_qualification_id,
      gst,
      is_whatsapp_comms_enable,
      is_prior_experience,
      is_profile_completed,
      name_title_id: 1,
      pan,
      view_commission,
    };
  };

  const isValidDOB = (dob) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const diffYears = moment(currentDate).diff(moment(dob), "years");

    return diffYears >= 18;
  };

  const cbError = ({ data: { error_msg }, code = 400 }, modalKey = "") => {
    setIsFormSubmitted(false);

    const message = parseMessage(error_msg);
    if (Array.isArray(message)) {
      setErrors(message);
    } else {
      let messageArray = [];
      messageArray.push(message);
      setErrors(messageArray);
    }
  };

  const isValidInput = () => {
    let isValid = true;
    let errorMessage = null;
    const regex = {
      panNumber: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
      gstNumber: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      accountNumber: /^\d{9,18}$/,
      ifscCode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    };

    if (form.pan == "") {
      errorMessage = "PAN Cannot be Empty";
      isValid = false;
    } else if (form.pan && !regex.panNumber.test(form.pan)) {
      errorMessage = "Please enter valid PAN Number";
      isValid = false;
    } else if (form.dob == "") {
      errorMessage = "Date of Birth Cannot be Empty";
      isValid = false;
    } else if (form.dob && !isValidDOB(form.dob)) {
      errorMessage = "Please enter valid date of birth";
      isValid = false;
    } else if (form.aadhaar == "") {
      errorMessage = "Aadhaar Cannot be Empty";
      isValid = false;
    } else if (form.educational_qualification_id == "") {
      errorMessage = "Qualification Cannot be Empty";
      isValid = false;
    } else if (form.gst && !regex.gstNumber.test(form.gst)) {
      errorMessage = "Please enter valid 15 characters gst number";
      isValid = false;
    } else if (form.account_number == "") {
      errorMessage = "Account Number Cannot be Empty";
      isValid = false;
    } else if (
      form.account_number &&
      !regex.accountNumber.test(form.account_number)
    ) {
      errorMessage = "Please enter valid bank account number";
      isValid = false;
    } else if (form.beneficiary_name == "") {
      errorMessage = "Account Holder Name Cannot be Empty";
      isValid = false;
    } else if (form.ifsc_code == "") {
      errorMessage = "IFSC Cannot be Empty";
      isValid = false;
    } else if (form.ifsc_code && !regex.ifscCode.test(form.ifsc_code)) {
      errorMessage = "Please enter valid ifsc code";
      isValid = false;
    }

    if (!isValid) {
      setErrors([errorMessage]);
      return false;
    } else {
      setErrors([]);
    }

    return isValid;
  };

  const onSubmit = async () => {
    try {
      if (!isValidInput()) {
        return false;
      }

      setIsFormSubmitted(true);
      await AxiosRestClient.putRequest(
        `agents/${agentId}`,
        buildRequestPayload()
      );

      setIsFormSubmitted(false);

      cbSuccess("isCompleteKycOpen");
    } catch (error) {
      cbError(
        error && error.response && error.response.data
          ? error.response.data
          : buildSystemErrorMessage()
      );
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isModalOpen}
      toggle={() => toggleModal("isCompleteKycOpen", false)}
      backdrop="static"
    >
      <div className="modal-header">
        <h4>Complete KYC Details</h4>
      </div>
      <div className="modal-body ml-2 mr-4">
        <FormGroup>
          {errors.length > 0 &&
            errors.map((error) => {
              return (
                <Row>
                  <Col>
                    <div class="alert alert-danger">{error}</div>
                  </Col>
                </Row>
              );
            })}
          <Container fluid>
            <Row>
              <Col xs="6">
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  PAN Number
                </label>
                <Input
                  type="text"
                  placeholder="Enter PAN Number"
                  name="pan"
                  value={form.pan}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  DOB
                </label>
                <Input
                  type="date"
                  placeholder="Enter DOB"
                  name="dob"
                  value={form.dob}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  Aadhaar Number
                </label>
                <Input
                  type="text"
                  placeholder="Enter Aadhaar Number"
                  name="aadhaar"
                  value={form.aadhaar}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  Education Qualification
                </label>
                <Select2
                  className=" form-control"
                  defaultValue=""
                  options={{
                    placeholder: "Select Qualification",
                  }}
                  name="educational_qualification_id"
                  value={form.educational_qualification_id}
                  data={qualificationList}
                  onChange={handleChange}
                />
              </Col>
              <Col xs="6">
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  GST Number
                </label>
                <Input
                  type="text"
                  placeholder="Enter GST Number"
                  name="gst"
                  value={form.gst}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  Account Number
                </label>
                <Input
                  type="text"
                  placeholder="Enter Account Number"
                  name="account_number"
                  value={form.account_number}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  Account Name
                </label>
                <Input
                  type="text"
                  placeholder="Enter Account Name"
                  name="beneficiary_name"
                  value={form.beneficiary_name}
                  onChange={handleChange}
                />
                <label
                  className=" form-control-label mt-3 required"
                  htmlFor="dealer"
                >
                  IFSC Code
                </label>
                <Input
                  type="text"
                  placeholder="Enter IFSC Code"
                  name="ifsc_code"
                  value={form.ifsc_code}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </div>
      <div className="modal-footer date-modal-footer">
        <Button
          color="primary"
          type="button"
          size="md"
          onClick={onSubmit}
          {...(isFormSubmitted && { disabled: true })}
        >
          Submit
        </Button>

        <Button
          color="secondary"
          type="button"
          size="md"
          onClick={() => toggleModal("isCompleteKycOpen", false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default KYCDetailsModal;
