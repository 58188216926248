import React, { useContext, useEffect, useReducer, Fragment } from "react";
import {
  Card,
  Container,
  Label,
  Input,
  Row,
  Col,
  CardBody,
  CardHeader,
} from "reactstrap";
import ExamStudyMaterialContext from "../../../context/agentExamContext";
import AgentExamReducer from "../../../reducers/agentExamReducer";
import useApi from "../../../hooks/useApi";
import { ReducerUtils } from "../../../constants/reducers";
import Loader from "../../../components/CustomUi/Loader/Loader";

const rowStyle = {
  border: "1px solid #dee2e6",
  borderRadius: "20px",
  padding: "10px",
  margin: "20px 10px",
};

const AgentExamResult = (props) => {
  const initialReducerState = useContext(ExamStudyMaterialContext);
  const [state, dispatch] = useReducer(AgentExamReducer, initialReducerState);
  const result = useApi(`agents/${props.match.params.id}/result`, { exam_type_id: 1 });

  useEffect(
    () => dispatchEvent(ReducerUtils.agentExam.result, result),
    [result]
  );

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const renderQuestionChoice = (item, choice, innerKey) => {
    return (
      <div
        key={`choice_${innerKey}`}
        className={`pl-5 mt-2 ${
          choice.is_correct ? "text-muted" : "text-muted"
        }`}
      >
        <Label>
          <Input
            type="radio"
            value={choice.id}
            checked={choice.is_correct}
            disabled={!choice.is_correct}
          />{" "}
          &nbsp;&nbsp;
          {choice.choice_text}
        </Label>
      </div>
    );
  };

  const renderQuestion = (item, index) => {
    return (
      <Row key={`question_${item.id}`} style={rowStyle}>
        <Col xs="12">
          <Row>
            <Col>
              <p className=" text-muted">{`${index + 1}. ${
                item.question_text
              }`}</p>
            </Col>
          </Row>
          {item.question_choices.map((choice, innerKey) => {
            return renderQuestionChoice(item, choice, innerKey);
          })}
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card className="mt-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h4 className="h4 pt-2 font-weight-400">
                      Agent Exam Result
                    </h4>
                  </Col>
                  <Col xs="6"></Col>
                </Row>
              </CardHeader>

              {state?.examResult && (
                <CardBody>
                  <Row>
                    <Col xs="4" className="pl-6">
                      <span className="text-muted">Agent Name</span>
                    </Col>
                    <Col xs="4" className="pl-6">
                      <span className="text-muted">Exam Name</span>
                    </Col>
                    <Col xs="4" className="pl-6">
                      <span className="text-muted">Exam Result</span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="4" className="pl-6">
                      {result?.name}
                    </Col>
                    <Col xs="4" className="pl-6">
                      {result?.title?.replace("_", " ")}
                    </Col>
                    <Col xs="4" className="pl-6">
                      {result?.general_exam_result ? "Pass" : "Fail"}
                    </Col>
                  </Row>

                  {state?.examResult && (
                    <Container fluid>
                      {state?.examResult?.questions?.map((item, index) => {
                        return renderQuestion(item, index);
                      })}
                    </Container>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AgentExamResult;
