import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Row, Modal} from "reactstrap";
import { initialProductState } from "./defaultState";
import { mapping, options } from '../../../../defaultValues/comparison';
import { 
    buildCalculatePremiumPayload,
    buildShareComparisonPayload,
    getAge,
    isSharedDisabled,
    minimumSelectionRequired,
    renderSelectedPlans, 
    syncSelectionWithObject, 
    syncSelectionWithProducts,
    syncCalculatePremiumData 
} from '../../../../helpers/comparison';
import AxiosReactClient from "../../../../utilities/AxiosRestClient";
import Spinner from '../../../../components/Spinner/Spinner';

const ProductList = (props) => {
    const { form, isPremiumCalculated, product, toggleChecked } = props; 

    return Object.values(product).map((item, index) => {
        let productId = Object.keys(product)[index]
        return <div className={"w-100 cursor-pointer"} key={"sdf" + index}
                    onClick={(e) => {
                        toggleChecked(productId, item.checked)
                    }}
        >
            <div className={"d-flex justify-content-between flex-column "}>
                {productId !== "0" ? <>
                        <div className={" d-flex justify-content-between flex-column mx-2 rounded-15 cursor-pointer border-transparent  " + item.lightColor + (item.checked ? " border border-light" : " ")}>
                            {(productId > 0 && form.products[productId].premium > 0) ?
                            <div className={"mt--2 ml--2"}>
                                {item.checked ?
                                    <i className="fa fa-check-circle text-success fnt-22"
                                       aria-hidden="true"/>
                                    :
                                    <i className="fas fa-circle text-light fnt-22"
                                       aria-hidden="true"/>
                                }
                            </div> : <div className={"mt--2 ml--2"} style={{'minHeight': '24px'}}>&nbsp;</div>}
                            <div className={"p-3 align-self-center h-60"}>
                                <img src={item.img} className={"w-60"}/>
                            </div>
                            <div
                                className={"p-3 rounded-15 d-flex justify-content-center h-60 align-items-center " + item.darkColor}>
                                <h6 className={"fnt-12 font-weight-bold max-w-100 min-w-100 text-center"}>{item.title}</h6>
                            </div>
                        </div>
                        {!isPremiumCalculated &&
                        <div className={"d-flex justify-content-center mt-4"}>
                            {(productId > 0 && form.products[productId].premium > 0) 
                                ? (<h5>{`₹${form.products[productId].premium}/${mapping.paymentFrequency[form.payment_frequency]}`}</h5>)
                                : <h5 className="text-gray">Not Available</h5>}
                        </div>}
                        {isPremiumCalculated &&
                        <div className={"d-flex justify-content-center mt-4"}>Loading Premium...</div>}
                    </>
                    :
                    <div className={"empty-card"}>

                    </div>
                }
            </div>

            <div className={ "my-4 "}>
                <div
                    className={(productId === "0") ? ("bg-light py-2 px-4 text-left py-2 px-3 ") : "bg-light p-4 text-center "}>
                    <h6 className={"text-left fnt-18"}>
                        <b> {item?.details?.row0}</b>
                    </h6>
                </div>
                <div className={"my-4 mx-1 " + ((!(productId === "0") && !item.checked) ? "border rounded-10 overflow-hidden " : "border rounded-10 overflow-hidden border-transparent")}>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1 rounded-top")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            <b> {item?.details?.row1}</b>
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            <b> {item?.details?.row2}</b>
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            <b> {item?.details?.row3}</b>
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1 rounded-bottom")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            <b> {item?.details?.row4}</b>
                        </h6>
                    </div>
                </div>
            </div>

            <div className={"my-4"}>
                <div
                    className={(productId === "0") ? ("bg-light py-2 px-4 text-left py-2 px-3 ") : "bg-light p-4 text-center"}>
                    <h6 className={"text-left fnt-18"}>
                        <b>{item?.riderDetails?.row0}</b>
                    </h6>
                </div>
                <div className={"my-4 mx-1 " + ((!(productId === "0") && !item.checked) ? "border rounded-10 overflow-hidden " : "border rounded-10 overflow-hidden border-transparent")}>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1 rounded-top")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row1}</b>
                                :
                                <i className={item?.riderDetails?.row1 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row2}</b>
                                :
                                <i className={item?.riderDetails?.row2 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row3}</b>
                                :
                                <i className={item?.riderDetails?.row3 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row4}</b>
                                :
                                <i className={item?.riderDetails?.row4 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row5}</b>
                                :
                                <i className={item?.riderDetails?.row5 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row6}</b>
                                :
                                <i className={item?.riderDetails?.row6 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                    <div className={((item.checked && productId !== "0") ? item.lightColor : " ") + " " + ((productId === "0") ? "w-200 text-left py-2 px-3 m-1 " : "text-left py-2 px-3 m-1 rounded-bottom")}>
                        <h6 className={productId !== "0" ? "text-center fnt-16" : "fnt-16 font-weight-300"}>
                            {productId === "0" ?
                                <b className={" py-2"}>{item?.riderDetails?.row7}</b>
                                :
                                <i className={item?.riderDetails?.row7 + " icon-padding"}/>
                            }
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    });
};

const QuotationCompareProduct = (props) => {
    const [form, setForm] = useState({ sum_assured: '', policy_term: '', payment_frequency: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isPremiumCalculated, setIsPremiumCalculated] = useState(false);
    const [loader, setLoader] = useState(true);
    const [product, setProduct] = useState(initialProductState);
    const [quotationDetails, setQuotationDetails] = useState(null);
    const [shareMessage, setShareMessage] = useState(null);

    useEffect(() => {
        comparisonDetails();
    }, []);

    useEffect(() => {
        // Calculate premium if sum assured has been changed
        if (quotationDetails && form.sum_assured && quotationDetails.sum_assured !== form.sum_assured) {
            calculatePremium(form);
        }
    }, [form.sum_assured]);

    useEffect(() => {
        // Calculate premium if policy term has been changed
        if (quotationDetails && form.policy_term && quotationDetails.policy_term !== form.policy_term) {
            calculatePremium(form);
        }
    }, [form.policy_term]);

    useEffect(() => {
        // Calculate premium if payment frequency has been changed
        if (quotationDetails && form.payment_frequency && quotationDetails.payment_frequency !== form.payment_frequency) {
            calculatePremium(form);
        }
    }, [form.payment_frequency]);

    useEffect(() => {
        
    }, [form.products]);

    const comparisonDetails = async () => {
        const response = await AxiosReactClient.getRequest(`v2/comparisons/${props.match.params.id}`);
        const { data } = response.data;

        syncSelectionWithObject(initialProductState, data.products);
        setQuotationDetails(data);
        setForm(data);
        setLoader(false);
        setProduct(initialProductState);
    };

    const calculatePremium = async ({sum_assured, policy_term, payment_frequency}) => {
        setIsPremiumCalculated(true);
        setQuotationDetails({ sum_assured: '', policy_term: '', payment_frequency: '' });

        const payload = buildCalculatePremiumPayload(form);
        const response = await AxiosReactClient.putRequest(`v2/comparisons/${props.match.params.id}/premium`, {sum_assured, policy_term, payment_frequency});
        const { data } = response.data;
        
        syncCalculatePremiumData(product, data.products);
        setForm({ ...form, 'products': data.products });
        setIsPremiumCalculated(false);
    };

    const shareComparison = async () => {
        const payload = buildShareComparisonPayload(form, product);
        const response = await AxiosReactClient.putRequest(`v2/comparisons/${props.match.params.id}/share`, payload);
        const { message } = response.data;

        setIsOpen(true);
        setShareMessage(message);
    };

    const toggleChecked = (currentProductId, isChecked) => {
        if (isPremiumCalculated) {
            return false;   
        }
        
        const shallow = { ...product };
        
        Object.values(shallow).map((item, index) => {
            let productId = Object.keys(shallow)[index];
            if (productId === currentProductId) {
                item.checked = productId > 0 && form.products[productId].premium > 0 ? !isChecked : isChecked
            }
        });

        syncSelectionWithProducts(shallow, form.products);
        setProduct(shallow);
    };
      
    const setFormState = (name, value) => {
        setForm({ ...form, [name]: value });
    };
    
    const handleChange = (event) => {
        setFormState(event.target.name, event.target.value);
    };

    const handleFreqeuncyChange = (value) => {
        setFormState('payment_frequency', value);
    };

    const navigateTo = (pathname= '') => {
        if (!pathname) {
            pathname = localStorage.getItem('compareQuotation') ? '/admin/comparisons/create' : '/admin/comparisons';
        }
        
        props.history.push(pathname);
    };

    const doneClickHandler = () => {
        setIsOpen(false);
        navigateTo('/admin/comparisons');
    };

    return (
        loader ? <Spinner /> :
        <>
            <Row className={"bg-gradient-info p-4 "}>
                <Col lg={12}>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"px-3"}>
                            <i className="fa fa-arrow-left text-white fnt-16 cursor-pointer" aria-hidden="true" 
                                onClick={() => navigateTo()} 
                            />
                        </div>
                        <div>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <div className={"mr-3"}>
                                    {renderSelectedPlans(product) ? 
                                    <p> 
                                        <small style={{'color': 'cccccc'}}> 
                                            Selected Product(s):
                                        </small>&nbsp;<b>{renderSelectedPlans(product)}</b> 
                                    </p> : <h5> Please select minimum 1 policy to share the comparison </h5>}
                                </div>
                                <Button
                                    className="btn-icon btn-2"
                                    size="md"
                                    color={ isSharedDisabled(form, product) ? "secondary" : "primary" }
                                    onClick={shareComparison}
                                    {...(isSharedDisabled(form, product) && {disabled: true})}
                                >
                                    SHARE
                                </Button>
                            </div>
                            {/* <div className={"d-flex justify-content-between align-items-center"}>
                                <div className={"mr-3"}>
                                    {isSharedDisabled(form) && <h5> Please select minimum {minimumSelectionRequired(form)} policy to share the comparison </h5>}
                                    {!isSharedDisabled(form) && <p> 
                                        <small style={{'color': 'cccccc'}}> 
                                            {minimumSelectionRequired(form) > 1 ? 'Comparison between' : 'Selected product'} 
                                        </small>&nbsp;<b>{renderSelectedPlans(form)}</b> </p>}
                                </div>
                                <Button
                                    className="btn-icon btn-2"
                                    size="md"
                                    color={ isSharedDisabled(form) ? "secondary" : "primary" }
                                    {...(isSharedDisabled(form) && {disabled: true})}
                                    onClick={shareComparison}
                                >
                                    SHARE
                                </Button>
                            </div> */}
                        </div>
                    </div>
                </Col>
                <Col lg={12}>
                    <div className={"d-flex justify-content-between mt-5"}>
                        <Row className={"w-100"}>
                            <Col lg={3} className={"w-100 border-right"}>
                                <div className={"w-100 px-3 mb-3"}>
                                    <h3 className={"font-weight-bold text-white"}>
                                        {form.name}
                                    </h3>
                                    <h5 className={"text-white font-weight-300"}>
                                        {mapping.gender[form.gender]}, {getAge(form.dob)} yrs | {mapping.smoker[form.is_smoker]}
                                    </h5>
                                    <h5 className={"text-white font-weight-300 m-0"}>
                                        {mapping.occupation[form.occupation]} | {mapping.annualIncome[form.annual_income]}
                                    </h5>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className={"d-flex justify-content-around w-100 px-3"}>
                                    <div className="form-group w-100 mx-2">
                                        <label htmlFor="formControlInput1 " className={"text-white"}>Coverage Amount</label>
                                        <select
                                            name={"sum_assured"}
                                            className="form-control"
                                            id="formControlInput1"
                                            value={form.sum_assured}
                                            onChange={handleChange}
                                            {...(isPremiumCalculated && {disabled: true})}
                                        >
                                            {options.coverage.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mx-2">
                                        <label htmlFor="formControlInput1" className={"text-white"}>Policy Term</label>
                                        <select
                                            name={"policy_term"}
                                            className="form-control"
                                            id="formControlInput1"
                                            value={form.policy_term}
                                            onChange={handleChange}
                                            {...(isPremiumCalculated && {disabled: true})}
                                        >
                                            {options.policyTerm.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group w-100 mx-2">
                                        <label htmlFor="formControlInput1" className={"text-white"}>Payment </label>
                                        <div className="d-flex justify-content-start">
                                            <Button
                                                className="btn-icon btn-2"
                                                size="md"
                                                color={ form.payment_frequency === 'monthly' ? "primary" : 'secondary' }
                                                onClick={() => handleFreqeuncyChange('monthly')}
                                                {...(isPremiumCalculated && {disabled: true})}
                                            >
                                                <span className={"w-30 mr-2"}>
                                                    {form.payment_frequency === 'monthly' && <i className="fa fa-check " aria-hidden="true"/>}
                                                </span>
                                                Monthly
                                            </Button>
                                            <Button
                                                className="btn-icon btn-2 ml-2"
                                                size="md"
                                                color={ form.payment_frequency === 'yearly' ? "primary" : 'secondary' }
                                                onClick={() => handleFreqeuncyChange('yearly')}
                                                {...(isPremiumCalculated && {disabled: true})}
                                            >
                                                <span className={"w-30 mr-2"}>
                                                    {form.payment_frequency === 'yearly' && <i className="fa fa-check " aria-hidden="true"/>}
                                                </span>
                                                Yearly
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card >
                        <CardBody>
                            <div className={"d-flex justify-content-between"}>
                                <ProductList 
                                    form={form}
                                    isPremiumCalculated={isPremiumCalculated}
                                    product={product}
                                    toggleChecked={toggleChecked}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
            >
                <div className="modal-body text-left bg-light py-6">
                    <div className={"d-flex justify-content-center flex-column mb-2"}>
                        {props.exclamation &&
                        <div className={" m-auto align-items-center"}>
                            <i className="fa fa-exclamation-circle text-orange align-items-center fnt-60 opacity-5"
                            aria-hidden="true"/>
                        </div>
                        }
                        <div className={"py-4 m-auto"}>
                            <h4>{shareMessage}</h4>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <Button
                            color="primary"
                            outline
                            className="ml-2 align-self-center px-3"
                            type="button"
                            size="sm"
                            onClick={doneClickHandler}
                        >
                            Done
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default QuotationCompareProduct;