import React, {Component, Fragment} from 'react';
import Select2 from "react-select2-wrapper";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    CardFooter,
    Button,
    Input,
    Modal,
    Form,
    Table
} from "reactstrap";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import Loader from "../../../components/CustomUi/Loader/Loader";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import { buildPartnerProductList } from "../../../helpers/partners";

const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const password_regx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

class Float extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_id: '',

            receipt: []
        }
       

    }

    componentDidMount = () => {
        this.getProductList()

    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: typeof msg === 'string' ? msg : Object.values(msg).map((data)=> {return data[0]}).join(","),
            title: "",
            type: type
        })
    };

    

  submit = (payload) => {

    let isValid = true;
        

        if(this.state.product_id === ''){
            this.showMsg("Product Cannot be Empty","danger")
            isValid = false
        } 

        if (!isValid) {
            return false;
        }

        this.setState({
            loaderMsg: "Please Wait",
            showLoader: true
        }, () => {
            this.setState({
                showLoader: true
            });

            AxiosReactClient.getRequest('getFloatBalance', payload).then(response => {
                this.setState({
                    showLoader: false,
                    receipt: response.data.data.Receipt ?? []
                });

                if (response["data"].message === "No records found") {
                    this.showMsg(response["data"].message, "danger");
                    return
                } 
            })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    });

                    if (err.response) {
                        this.showMsg(err.response.data?.data?.error_msg ? err.response.data?.data?.error_msg : err.response.data?.message, "danger")
                    } else if (err.message) {
                        this.showMsg(err.message, "danger")
                    }
                });
        });

    }

    handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            ...this.state,
            [name]:value
        })
        
        if (name === 'partnerId') {
            this.getProductList(value);
        }
    }

   
    cbProductList = (products = []) => {
        this.setState({ 'products': buildPartnerProductList([
            {
                "id": 66,
                "name": "Motor Insurance (Future Generali)"
            }
            
          ]), product_id: 66 })

    }
    

    getProductList = async () => {
        try {
           
            const response = await AxiosReactClient.getRequest('filter/product/list');

            const { data: { product } } = response.data;
            this.cbProductList(product);
        } catch (error) {
            alert('It looks like something went wrong. Please try again in a while.');
            this.cbProductList();
        }
    };

    render() {
        return (
            <Fragment>
                <Container fluid>
                    {this.state.showLoader && <div className="loader-container">
                        <Loader msg={this.state.loaderMsg} />
                    </div>}

                    <Row>
                        <Col xs="12">
                            <Card className="mt-4">
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h4 className="h4 pt-2 font-weight-400">
                                                Get Float Balance
                                            </h4>
                                        </Col>

                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>


                                        <label className=" form-control-label mt-3 required"
                                            htmlFor="dealer">
                                            Product
                                        </label>

                                        <Select2 className=" form-control-label mt-3"
                                            options={{ placeholder: "Select Products" }}
                                            name="product_id"
                                            defaultValue=""
                                            value={this.state.product_id}
                                            onChange={this.handleChange}
                                            data={this.state.products}
                                        />

                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <h5 className="h5 mb-0 font-weight-400 text-primary cursor-pointer text-center">
                                        <Button
                                            block
                                            color="primary"
                                            size="lg"
                                            type="button" onClick={e => {
                                                e.preventDefault();
                                                this.submit({
                                                    product_id: this.state.product_id,
                                                })
                                            }}>
                                            <span className="btn-inner--icon mr-1">

                                            </span>
                                            Get Float Balance
                                        </Button>
                                    </h5>
                                </CardFooter>
                            </Card>

                        </Col>
                    </Row>
                    <Card className="m-0 mt-1" >
                        <CardBody className="px-0 py-0" style={{ minHeight: "708px" }}>
                            <div className=" table-responsive">
                                <Table className="align-items-center table-flush" hover responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Serial No</th>
                                            <th>Balance Amount</th>
                                            <th>Original Amount</th>
                                            <th>Receipt No</th>
                                            <th>Deposit Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.receipt.map((data, index) => (
                                            <tr onClick={(e) => {
                                                e.preventDefault()
                                            }} key={index}>
                                                <td>{data.SerialNo}</td>
                                                <td>{data.BalanceAmt}</td>
                                                <td>{data.OriginalAmt}</td>
                                                <td>{data.ReceiptNo}</td>
                                                <td>{data.DepositDt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>


                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        partnerFilterList: state.common.partnerFilterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Float);