export const Documents = {
  24: {
    name: "Bank Cancelled Cheque",
  },
  25: {
    name: "Aadhaar Front Side",
  },
  26: {
    name: "Aadhaar Back Side",
  },
  59: {
    name: "PAN Card",
  },
  75: {
    name: "Education Qualification",
  },
  76: {
    name: "General Exam Certificate",
  },
  78: {
    name: "Agreement",
  },
};

export const BusinessType = [
  {
    id: 1,
    text: "POSP",
    slug: "posp",
    is_active: "1",
  },
  {
    id: 2,
    text: "MISP",
    slug: "misp",
    is_active: "1",
  },
  {
    id: 3,
    text: "Dealer POSP",
    slug: "dealer_posp",
    is_active: "1",
  },
];
