export const Utils = {
  limit: {
    age: {
      max: 55,
      min: 18
    },
    digits: {
      age: 2,
      annual_income: 8,
      contact: 10,
      dependents: 2,
      mobile: 10,
      name: 30,
      nominee_mobile: 10,
      pincode: 6,
      policy_tenure: 2,
      premium_tenure: 2,
      sum_insured: 8,
      policy_premium: 7,
      dog_name: 30,
      dog_mark: 30,
      vehicle_code: 25,
      ex_showroom_price: 8,
      cubic_capacity: 5,
      make: 100,
      model: 100,
      variant: 100,
      search_vehicle_code: 25,
      make_code: 100,
      model_code: 100,
      variant_code: 100,
    },
    maxlen: {
      covered: 5,
      not_covered: 5
    }   
  },
  otherPolicies: {
    text: {
      lifeInsurance: 'Life Insurance'
    },
    status: {
      policyReadManual: 'Policy Read (Manual)',
      policyReadAutomatic: 'Policy Read (Automatic)',
      policyDetailsSubmitted: 'Details Submitted',
      policyDocumentsUploaded: 'Documents Uploaded'
    }
  },
  products: {
    48: {
      plan: 'ICICI Life Insurance'
    },
    52: {
      plan: 'IndiaFirst Life e-Term Plan'
    },
    58: {
      plan: 'PNB MetLife Complete Care Plus'
    }
  }
};