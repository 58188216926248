import React from 'react';
import { Row, Col } from 'reactstrap';

const ErrorMessage = (props) => {
    const { errors } = props;

    return (
        errors.length > 0 &&
            <div className="w-100 text-danger mb-5">
                {errors.map((item, key) => {
                    return (
                        <Row key={key}>
                            <Col>{item}</Col>
                        </Row>
                    );
                })}
            </div>
    );
};

export default ErrorMessage;