export const initialManualPolicyDetails = {
  // Personal Details Fields
  policy_holder_name: '',
  age: '',
  gender: '',
  marital_status: '',
  dependents: '',
  pincode: '',
  city: '',
  state: '',
  address_line_1: '',
  address_line_2: '',
  landmark: '',
  contact: '',
  email: '',
  occupation: '',
  company_name: '',
  annual_income: '',

  // Policy Details Fields
  policy_number: '',
  sum_insured: '',
  issued_date: '',
  expiry_date: '',
  policy_tenure: '',
  policy_premium: '',
  premium_tenure: '',
  plan_option_name: '',
  insurance_provider: '',
  payout_option: '',
  renewal_date: '',
  next_payment_date: '',

  // Nominee Details Fields
  nominee_name: '',
  nominee_relation: '',
  nominee_mobile: '',
  nominee_email: '',

  // Coverages
  covered: [
    { 
      title: '' 
    }
  ],
  not_covered: [
    { 
      title: '' 
    }
  ],

  // Error handling
  hasError: false
};

export const initialOtherPolicyReducerState = {
  othePolicyDetails: null,
  otherPolicesList: []
};

export const initialUnifiedPolicyReducerState = {
  othePolicyDetails: null,
  otherPolicesList: [],
  unifiedPolicesList: []
};

export const options = {
  gender: [
    {title: "Male", value: "male"},
    {title: "Female", value: "female"}
  ],
  maritalStatus: [
    {title: "Single", value: "single"},
    {title: "Married", value: "married"},
    {title: "Divorced", value: "divorced"},
    {title: "Widowed", value: "widowed"}
  ],
  relation: [
    {title: "Spouse", value: "spouse"},
    {title: "Father", value: "father"},
    {title: "Mother", value: "mother"},
    {title: "Brother", value: "brother"},
    {title: "Sister", value: "sister"},
    {title: "Son", value: "son"},
    {title: "Daughter", value: "daughter"},
    {title: "Other", value: "other"}
  ],
  occupation: [
    {title: 'Salaried', value:"salaried"},
    {title: 'Self Employed', value:"self_employed"},
    {title: 'Retired', value:"retired"},
    {title: 'Professional', value:"professional"},
    {title: 'Others', value:"others"}
  ]
};

export const errorMessagePolicyDetails = {
  age: '',
  email: '',
  mobile: '',
  contact: '',
  nominee_email: '',
  nominee_mobile: '',
  policy_holder_name: ''
};

export const touchFieldsPolicyDetails = {
  age: false,
  email: false,
  mobile: false,
  contact: false,
  nominee_email: false,
  nominee_mobile: false,
  policy_holder_name: false
};