import * as actionTypes from './actionTypes/financeTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getPayoutListStart = () => {
    return {
        type: actionTypes.GET_PAYOUT_LIST_START
    };
};
export const getPayoutHistoryStart = () => {
    return {
        type: actionTypes.GET_PAYOUT_HISTORY_START
    };
};
export const getPayoutProductListStart = () => {
    return {
        type: actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_START
    };
};
export const getPayoutStatusListStart = () => {
    return {
        type: actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_START
    };
};
export const getPayoutCardsStart = () => {
    return {
        type: actionTypes.GET_PAYOUT_CARDS_START
    };
};

//SUCCESS
export const getPayoutListSuccess = (data, total) => {
    return {
        type: actionTypes.GET_PAYOUT_LIST_SUCCESS,
        data: data,
        total: total
    };
};
export const getPayoutHistorySuccess = (data) => {
    return {
        type: actionTypes.GET_PAYOUT_HISTORY_SUCCESS,
        data: data
    };
};
export const getPayoutProductListSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPayoutStatusListSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPayoutCardsSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYOUT_CARDS_SUCCESS,
        data: data
    };
};

//FAIL
export const getPayoutListFail = (error) => {
    return {
        type: actionTypes.GET_PAYOUT_LIST_FAIL,
        error: error
    };
};
export const getPayoutHistoryFail = (error) => {
    return {
        type: actionTypes.GET_PAYOUT_HISTORY_FAIL,
        error: error
    };
};
export const getPayoutProductListFail = (error) => {
    return {
        type: actionTypes.GET_PAYOUT_PRODUCT_FILTER_LIST_FAIL,
        error: error
    };
};
export const getPayoutStatusListFail = (error) => {
    return {
        type: actionTypes.GET_PAYOUT_STATUS_FILTER_LIST_FAIL,
        error: error
    };
};
export const getPayoutCardsFail = (error) => {
    return {
        type: actionTypes.GET_PAYOUT_CARDS_FAIL,
        error: error
    };
};


//ACTIONS
export const getPayoutListData = (params) => {
    return dispatch => {
        dispatch(getPayoutListStart());
        AxiosReactClient.getRequest('finance/payout/index/', params).then(response => {
            dispatch(getPayoutListSuccess(response.data.data.payoutArray, response.data.data.totalRecord));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPayoutListFail(err.response.data.error));
                else
                    dispatch(getPayoutListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPayoutHistoryData = (dealer_id, params) => {
    return dispatch => {
        dispatch(getPayoutHistoryStart());
        AxiosReactClient.getRequest('finance/payout/' + dealer_id, params).then(response => {
            dispatch(getPayoutHistorySuccess(response.data.data.payoutArray));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPayoutHistoryFail(err.response.data.error));
                else
                    dispatch(getPayoutHistoryFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPayoutProductListData = (params) => {
    return dispatch => {
        dispatch(getPayoutProductListStart());
        AxiosReactClient.getRequest('finance/payout/products/', params).then(response => {
            dispatch(getPayoutProductListSuccess(response.data.data.productsArray));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPayoutProductListFail(err.response.data.error));
                else
                    dispatch(getPayoutProductListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPayoutStatusListData = (params) => {
    return dispatch => {
        dispatch(getPayoutStatusListStart());
        AxiosReactClient.getRequest('finance/payout/status', params).then(response => {
            dispatch(getPayoutStatusListSuccess(response.data.data));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPayoutStatusListFail(err.response.data.error));
                else
                    dispatch(getPayoutStatusListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getPayoutCardsData = (params) => {
    return dispatch => {
        dispatch(getPayoutCardsStart());
        AxiosReactClient.getRequest('finance/payout/cards', params).then(response => {
            dispatch(getPayoutCardsSuccess(response.data.data));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getPayoutCardsFail(err.response.data.error));
                else
                    dispatch(getPayoutCardsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};