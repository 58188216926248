import React, {
  useContext,
  useEffect,
  useReducer,
  Fragment,
  useState,
} from "react";
import {
  Button,
  Card,
  Container,
  Label,
  Input,
  Modal,
  Row,
  Col,
  CardBody,
  CardHeader,
} from "reactstrap";
import ExamStudyMaterialContext from "../../../context/agentExamContext";
import AgentExamReducer from "../../../reducers/agentExamReducer";
import useApi from "../../../hooks/useApi";
import { ReducerUtils } from "../../../constants/reducers";
import Loader from "../../../components/CustomUi/Loader/Loader";
import {
  redirectWithBlank,
  renderAgentCurrentStatus,
  renderAgentNextActionItem,
} from "../../../helpers/utils";
import { Documents } from "../../../data/documents";
import AxiosRestClient from "../../../utilities/AxiosRestClient";

const rowStyle = {
  border: "1px solid #dee2e6",
  borderRadius: "20px",
  padding: "10px",
  margin: "20px 10px",
};

const AgentDetails = (props) => {
  const initialReducerState = useContext(ExamStudyMaterialContext);
  const [state, dispatch] = useReducer(AgentExamReducer, initialReducerState);
  const details = useApi(`dealers/${props.match.params.id}`);
  const [modal, setModal] = useState({ confirm: false });
  const [buttonActionName, setButtonActionName] = useState(
    "active_inactive_request"
  );
  const [isActive, setIsActive] = useState(false);

  useEffect(
    () => dispatchEvent(ReducerUtils.agent.details, details),
    [details]
  );

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const handleButtonClick = ({
    id,
    is_active,
    is_active_for_sell,
    general_exam_result,
    profile_created_at,
  }) => {
    if (!general_exam_result && profile_created_at) {
      navigateTo(`/admin/agents/${id}/exam`);
    } else {
      setIsActive(is_active);
      setButtonActionName(
        is_active_for_sell ? "active_inactive_request" : "approve_request"
      );
      toggleModal("confirm", true);
    }
  };

  const navigateTo = (url) => {
    props.history.push(url);
  };

  const toggleModal = (key = "confirm", value = false) => {
    setModal({
      ...modal,
      [key]: value,
    });
  };

  const handleConfirmClick = (action = "") => {
    action === "yes"
      ? buttonActionName === "approve_request"
        ? submitApprovalRequest()
        : submitActiveInactiveRequest({ is_active: !isActive })
      : toggleModal();
  };

  const submitActiveInactiveRequest = async (payload) => {
    await AxiosRestClient.putRequest(
      `dealers/${props.match.params.id}/status`,
      payload
    );

    toggleModal();
    navigateTo(`/admin/agents/all`);
  };

  const submitApprovalRequest = async () => {
    await AxiosRestClient.putRequest(`agents/${props.match.params.id}`, {
      is_active_for_sell: true,
    });

    toggleModal();
    navigateTo(`/admin/agents/all`);
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card className="mt-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h4 className="h4 pt-2 font-weight-400">Agent Details</h4>
                  </Col>
                  <Col xs="6"></Col>
                </Row>
              </CardHeader>

              {state?.agentDetails && (
                <CardBody>
                  <h3 className="h3 pt-2 font-weight-400">Basic Details</h3>
                  <Container fluid>
                    <Row>
                      <Col xs="6">
                        <label className=" form-control-label" htmlFor="dealer">
                          Full Name
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.name}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Mobile Number
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.mobile}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Email
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.email}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          POSP Code
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.posp_id ?? "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Business Type
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.business_type ?? "-"}
                        </div>
                      </Col>
                      <Col xs="6">
                        <label className=" form-control-label" htmlFor="dealer">
                          Address
                        </label>
                        <div>{`${state?.agentDetails?.dealershipDetails?.address?.address_line_1}, ${state?.agentDetails?.dealershipDetails?.address?.city}, ${state?.agentDetails?.dealershipDetails?.address?.state}, ${state?.agentDetails?.dealershipDetails?.address?.pincode}`}</div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Is Whatsapp Comms Enabled?
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails
                            ?.is_whatsapp_comms_enable
                            ? "Yes"
                            : "No"}
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  <h4 className="h4 pt-2 font-weight-400">KYC Details</h4>
                  <Container fluid>
                    <Row>
                      <Col xs="6">
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          PAN Number
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.pan ?? "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Date of Birth
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.dob ?? "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Aadhaar Number
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.aadhaar ??
                            "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          GST Number
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.gst ?? "-"}
                        </div>
                      </Col>
                      <Col xs="6">
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Account Number
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.neft
                            ?.account_number ?? "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          Account Name
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.neft
                            ?.account_name ?? "-"}
                        </div>
                        <label
                          className=" form-control-label mt-3"
                          htmlFor="dealer"
                        >
                          IFSC Code
                        </label>
                        <div>
                          {state?.agentDetails?.dealershipDetails?.neft
                            ?.ifsc_code ?? "-"}
                        </div>
                      </Col>
                    </Row>
                  </Container>

                  {state?.agentDetails?.dealershipDetails?.documents?.length >
                    0 && (
                    <>
                      <h4 className="h4 pt-2 font-weight-400">
                        KYC Documents, Certificate, and Agreement
                      </h4>
                      <Container fluid>
                        <Row>
                          {state?.agentDetails?.dealershipDetails?.documents?.map(
                            (item) => {
                              return item.pages.map((page, index) => {
                                return (
                                  <Col xs="6" key={`pages_${index}`}>
                                    <label
                                      className=" form-control-label mt-3"
                                      htmlFor="dealer"
                                    >
                                      {Documents[item.document_type_id]?.name}
                                    </label>
                                    <div>
                                      <Button
                                        color="primary"
                                        size="sm"
                                        type="button"
                                        onClick={() =>
                                          redirectWithBlank(page.url)
                                        }
                                      >
                                        <span className="btn-inner--icon mr-1">
                                          <i className="fas fa-download" />
                                        </span>
                                        {"Download"}
                                      </Button>
                                    </div>
                                  </Col>
                                );
                              });
                            }
                          )}
                        </Row>
                      </Container>

                      <h4 className="h4 pt-2 font-weight-400">
                        Additional Details
                      </h4>
                      <Container fluid>
                        <Row>
                          <Col xs="6">
                            <label
                              className=" form-control-label mt-3"
                              htmlFor="dealer"
                            >
                              Is Prior Selling Experience?
                            </label>
                            <div>
                              {state?.agentDetails?.dealershipDetails
                                ?.is_prior_experience
                                ? "Yes"
                                : "No"}
                            </div>
                            <label
                              className=" form-control-label mt-3"
                              htmlFor="dealer"
                            >
                              Current Status
                            </label>
                            <div>
                              <span
                                className={`badge badge-pill ${
                                  renderAgentCurrentStatus(
                                    state?.agentDetails?.dealershipDetails
                                  )["className"]
                                }`}
                                style={{ fontSize: "0.75rem" }}
                              >
                                {
                                  renderAgentCurrentStatus(
                                    state?.agentDetails?.dealershipDetails
                                  )["status"]
                                }
                              </span>
                            </div>
                            {renderAgentNextActionItem(
                              state?.agentDetails?.dealershipDetails
                            )["slug"] === "approve" && (
                              <>
                                <label
                                  className=" form-control-label mt-3"
                                  htmlFor="dealer"
                                >
                                  Action Item
                                </label>
                                <div>
                                  <Button
                                    color={`btn-round btn-icon btn btn-primary btn-sm`}
                                    size="sm"
                                    type="button"
                                    onClick={() =>
                                      handleButtonClick(
                                        state?.agentDetails?.dealershipDetails
                                      )
                                    }
                                  >
                                    <span className="btn-inner--text">
                                      {
                                        renderAgentNextActionItem(
                                          state?.agentDetails?.dealershipDetails
                                        )["text"]
                                      }
                                    </span>
                                  </Button>
                                </div>
                              </>
                            )}
                          </Col>
                          <Col xs="6">
                            <label
                              className=" form-control-label mt-3"
                              htmlFor="dealer"
                            >
                              Exam Score
                            </label>
                            <div>
                              {state?.agentDetails?.dealershipDetails
                                ?.general_exam_score ?? "-"}
                            </div>
                            <label
                              className=" form-control-label mt-3"
                              htmlFor="dealer"
                            >
                              Exam Result
                            </label>
                            <div>
                              {state?.agentDetails?.dealershipDetails
                                ?.general_exam_result
                                ? "Pass"
                                : state?.agentDetails?.dealershipDetails
                                    ?.general_exam_result === false
                                ? "Fail"
                                : "-"}
                            </div>

                            {state?.agentDetails?.dealershipDetails
                              ?.general_exam_result && (
                              <>
                                <label
                                  className=" form-control-label mt-3"
                                  htmlFor="dealer"
                                >
                                  Detailed Exam Result
                                </label>
                                <div>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    type="button"
                                    onClick={() =>
                                      props.history.push(
                                        `/admin/agents/${state?.agentDetails?.dealershipDetails?.id}/exam-result`
                                      )
                                    }
                                  >
                                    <span className="btn-inner--icon mr-1">
                                      <i className="fas fa-eye" />
                                    </span>
                                    {"View"}
                                  </Button>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal.confirm}
        toggle={toggleModal}
        backdrop="static"
      >
        <div className="modal-header">Confirm Dialog</div>
        <div className="modal-body">
          <Row>
            <Col xs={12}>Do you really want to perform this action?</Col>
          </Row>
        </div>
        <div className="modal-footer date-modal-footer ">
          <Button
            color="primary"
            type="button"
            size="sm"
            onClick={() => handleConfirmClick("yes")}
          >
            Yes
          </Button>
          <Button
            color="primary"
            type="button"
            size="sm"
            onClick={() => handleConfirmClick("no")}
          >
            No
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AgentDetails;
