import React from "react";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Button, Col, Input, Row } from "reactstrap";
import { MultiSelect } from "react-multi-select-component";
import { isUnifiedPoliciesFilterSelected } from "../../../../helpers/otherPolicyDetails";

const UnifiedPoliciesFilter = (props) => {
  const {
    filter,
    changeDateHandler,
    clickHandler,
    changeHandler,
    clearFilterHandler,
    options,
    isSubmitted: { apply = false, clear = false },
  } = props;

  const isFromDateValid = function (current) {
    return current.isBefore(moment());
  };

  const isToDateValid = function (current) {
    return (
      current.isBefore(moment()) &&
      current.isSameOrAfter(moment(filter.selectedFromDate)) &&
      current.isBefore(moment(filter.selectedFromDate).add(3, "months"))
    );
  };

  return (
    <>
      <Row className="bg-white mt-5">
        <Col md={2} className="text-left vAlignMiddle">
          Apply Filters:
        </Col>
      </Row>
      <Row className="bg-white m-0 py-2">
        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>{"Insurance Company"}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiSelect
                options={options.insurers}
                value={filter.selectedInsurerName}
                onChange={(value) =>
                  changeHandler(value, "selectedInsurerName")
                }
                labelledBy={"Insurer"}
              />
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>{"Product Category"}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiSelect
                options={options.productCategories}
                value={filter.selectedProductCategory}
                onChange={(value) =>
                  changeHandler(value, "selectedProductCategory")
                }
                labelledBy={"Product Category"}
              />
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>{"Product Sub Category"}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiSelect
                options={options.productSubCategories}
                value={filter.selectedProductSubCategory}
                onChange={(value) =>
                  changeHandler(value, "selectedProductSubCategory")
                }
                labelledBy={"Product Sub Category"}
              />
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted"></Col>
      </Row>

      <Row className="bg-white m-0 py-2">
        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>{"Channel"}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiSelect
                options={options.channels}
                value={filter.selectedChannel}
                onChange={(value) => changeHandler(value, "selectedChannel")}
                labelledBy={"Channel"}
              />
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>{"Business Type"}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <MultiSelect
                options={options.platforms}
                value={filter.selectedPlatform}
                onChange={(value) => changeHandler(value, "selectedPlatform")}
                labelledBy={"Platform"}
              />
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <Row>
                <Col>
                  <label>{"Issue Date (From)"}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReactDatetime
                    name="selectedFromDate"
                    inputProps={{}}
                    value={filter.selectedFromDate}
                    timeFormat={false}
                    onChange={(value) =>
                      changeDateHandler("selectedFromDate", value)
                    }
                    closeOnSelect={true}
                    isValidDate={isFromDateValid}
                    dateFormat="YYYY-MM-DD"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <label>{"Issue Date (To)"}</label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ReactDatetime
                    name="selectedToDate"
                    inputProps={{
                      disabled: !filter.selectedFromDate,
                    }}
                    value={filter.selectedToDate}
                    timeFormat={false}
                    onChange={(value) =>
                      changeDateHandler("selectedToDate", value)
                    }
                    closeOnSelect={true}
                    isValidDate={isToDateValid}
                    dateFormat="YYYY-MM-DD"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="mt-2">
              Note: Maximum 3 months Issue Date filter allowed
            </Col>
          </Row>
        </Col>

        <Col md={3} className="p-1 px-3 text-muted">
          <Row>
            <Col>
              <label>&nbsp;</label>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                color="primary"
                //outline
                size="md"
                type="button"
                onClick={clickHandler}
                {...((!filter.selectedFromDate ||
                  !filter.selectedToDate ||
                  apply) && { disabled: true })}
              >
                Submit
              </Button>

              <Button
                color="danger"
                outline
                size="md"
                type="button"
                onClick={clearFilterHandler}
                {...((!isUnifiedPoliciesFilterSelected(filter) || clear) && {
                  disabled: true,
                })}
              >
                <i className="fas fa-times-circle"/> Clear Filters
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UnifiedPoliciesFilter;
