import * as permissions from '../../../config/Casl/permissions';
import CreateQuotation from "./createQuotation";
import AllQuotations from "./allQuotations";
import QuotationCompareProduct from "./Comparison/product";
import QuotationCompareList from "./Comparison/list";
import QuotationCompareForm from "./Comparison/form";

const quotationsRoute = {
    path: "/quotation",
    collapse: true,
    name: "Quotations",
    icon: "ni ni-badge text-primary",
    state: "quotationCollapse",
    permission: permissions.POLICY.menu,
    subject: permissions.POLICY.subject,
    views: [
        {
            path: "/comparisons/create",
            name: "Quotation Comparision Form",
            component: QuotationCompareForm,
            layout: "/admin"
        },
        {
            path: "/comparisons/:id",
            name: "Quotation Comparison Details",
            component: QuotationCompareProduct,
            layout: "/admin"
        },
        {
            path: "/comparisons",
            name: "Comparisons",
            component: QuotationCompareList,
            layout: "/admin",
            permission: permissions.COMPARISONS.list,
            subject: permissions.COMPARISONS.subject
        },
        {
            path: "/quotation/create-quotation",
            name: "Create Quotation",
            component: CreateQuotation,
            layout: "/admin",
            permission: permissions.POLICY.list,
            subject: permissions.POLICY.subject
        },
        {
            path: "/quotation",
            name: "Quotations",
            component: AllQuotations,
            layout: "/admin",
            permission: permissions.POLICY.list,
            subject: permissions.POLICY.subject
        },
    ]
};
export default quotationsRoute;