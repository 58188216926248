import { ReducerUtils } from '../constants/reducers';
import { compareValues } from '../helpers/utils';
import { mapping } from '../defaultValues/lead';

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.leads.list:
      return {
        ...state,
        leadsList: action.payload,
        leadsListApi : action.payload
      };
    
    case ReducerUtils.leads.details:
      return {
        ...state,
        leadDetails : action.payload
      };

    case ReducerUtils.leads.issuePolicy:
      const [...leadsList] = state.leadsList;

      leadsList.map(item => {
        if (action.payload.id == item.id) {
          item.policy_number = action.payload.policy_number;
          item.premium = action.payload.premium;
          item.start_date = action.payload.start_date;
          item.end_date = action.payload.end_date;
          item.payment_status = action.payload.payment_status;
          item.payment_status_text = mapping.paymentStatus[action.payload.payment_status];
          item.remarks = action.payload.remarks;
          item.status = 'policy_issued';
          item.status_text = mapping.status[item.status];
        }
      });

      return {
        ...state,
        leadsList: leadsList
      };

    case ReducerUtils.leads.search:
      const query = action.payload;
      
      if (query) {
        const filteredList = state.leadsListApi.filter(item => {
          return (
            (item.name && item.name.toLowerCase().includes(query.toLowerCase()))
            ||
            (item.mobile && item.mobile.toLowerCase().includes(query.toLowerCase()))
            ||
            (item.dog_name && item.dog_name.toLowerCase().includes(query.toLowerCase()))
            ||
            (item.source_id && item.source_id.toLowerCase().includes(query.toLowerCase()))
            ||
            (item.start_date && item.start_date.toLowerCase().includes(query.toLowerCase()))
            ||
            (item.policy_number && item.policy_number.toLowerCase().includes(query.toLowerCase())) 
          );
        });

        return {
          ...state,
          leadsList: filteredList || []
        };
      } else {
        return {
          ...state,
          leadsList: [ ...state.leadsListApi ]
        };
      }

    case ReducerUtils.leads.sort:
      const shallowCopy = [...state.leadsList];
      const sortedList = shallowCopy.sort(compareValues(action.payload.sortBy, action.payload.sortType));        

      return {
        ...state,
        leadsList: sortedList
      };

    case ReducerUtils.leads.create:
      const updatedList = [action.payload, ...state.leadsListApi];

      return {
        leadsList: updatedList,
        leadsListApi : updatedList
      };

    case ReducerUtils.leads.filter:
      const filter = action.payload;
      
      if (filter) {
        const filteredList = state.leadsListApi.filter(item => {
          return ( item.status === filter );
        });

        return {
          ...state,
          leadsList: filteredList || []
        };
      } else {
        return {
          ...state,
          leadsList: [ ...state.leadsListApi ]
        };
      }

    default:
      return state;
  }
}
    