import React, {Component, Fragment} from 'react';
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Modal, Row} from "reactstrap";
import {IndiaFirstLocation} from "../../../locations/indiaFirstLocations";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import moment from "moment";

const mobileRegex = /^([0]|\+91)?[6789]\d{9}$/;

class CycleQuotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPaymentLinkModal: false,
            monthlyPremium: '',
            annualPremium: '',
            quotationCreated: false,
            paymentLink: '',
            title: 'mr',
            name: '',
            email: '',
            dob: '',
            mobile: null,
            mobileErrorMsg: '',
            pincode: '',
            cityName: '',
            stateName: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',

            titleError: false,
            titleErrorText: 'Please select title',

            nameError: false,
            nameErrorText: 'Please enter the full name. If the name has only one word, then repeat it. Eg: Ravi Ravi',

            dobError: false,
            dobErrorText: 'This field cannot be empty',

            mobileError: false,
            mobileErrorText: 'Please enter correct mobile number',

            emailError: false,
            emailErrorText: 'Invalid Email',

            pincodeError: false,
            pincodeErrorText: 'Pincode should be of 6 digits',

            addressLine1Error: false,
            addressLine1ErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            addressLine2Error: false,
            addressLine2ErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            landmarkError: false,
            landmarkErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            cycleBrand: '',
            cycleBrandError: false,
            cycleBrandErrorText: 'Please enter brand name',
            cycleModel: '',
            cycleModelError: false,
            cycleModelErrorText: 'Please enter model name',
            cycleColor: '',
            cycleColorError: false,
            cycleColorErrorText: 'Please enter color',
            cycleFrameNumber: '',
            cycleFrameNumberError: '',
            cycleFrameNumberErrorText: 'Please enter frame number',
            invoiceNumber: '',
            invoiceNumberError: false,
            invoiceNumberErrorText: 'Please enter invoice number',
            invoiceAmount: '',
            invoiceAmountError: false,
            invoiceAmountErrorText: 'Please enter invoice amount upto 2 lakhs',
            invoiceDate: '',
            invoiceDateError: false,
            invoiceDateErrorText: 'Please select invoice date (max 1 year old) and no future date',
            file: '',
            fileError: false,
            fileErrorText: 'Please upload invoice',
            fileName: '',
            orderId: '',
            premium: ''
        }
    }

    isEmailValid(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    isNameValid(name) {
        const re = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
        return re.test(name);
    }

    nat = (n) => {
        return /^[1-9]*\d*$/.test(n);
    };

    onTitleChange = (e, type) => {
        if (type === 'self') {
            this.setState({
                title: e.target.value,
                titleError: false
            })
        }
        if (type === 'spouse') {
            this.setState({
                spouseTitle: e.target.value,
                spouseTitleError: false
            })
        }
        if (type === 'child1') {
            this.setState({
                child1Title: e.target.value,
                child1TitleError: false
            })
        }
        if (type === 'child2') {
            this.setState({
                child2Title: e.target.value,
                child2TitleError: false
            })
        }
    }

    onRelationShipChange = (e) => {
        this.setState({
            nomineeRelation: e.target.value,
            nomineeRelationError: false
        })
    }
    onValueChange = (e, key) => {
        if (key === 'pincode') {
            this.populateStateCity(e.target.value)
        }
        this.setState({
            [key]: e.target.value,
            [key + 'Error']: false
        })
    }
    populateStateCity = (pin) => {
        let cityState = new IndiaFirstLocation().get(pin);
        if (cityState) {
            this.setState({
                stateName: cityState[1],
                cityName: cityState[0]
            })
        } else {
            this.setState({
                stateName: '',
                cityName: ''
            })
        }
    }

    onCreateQuotationClick = () => {
        if (this.state.title === '') {
            this.setState({
                titleError: true
            })
            return
        }
        if (this.state.name === '' || !this.isNameValid(this.state.name)) {
            this.setState({
                nameError: true,
            })
            return
        }
        if (this.state.email === '' || !this.isEmailValid(this.state.email)) {
            this.setState({
                emailError: true,
            })
            return
        }
        if (this.state.mobile === null || !this.state.mobile.match(mobileRegex)) {
            this.setState({
                mobileError: true,
            })
            return
        }
        if (this.state.dob === '') {
            this.setState({
                dobError: true,
            })
            return
        }

        if (this.state.pincode === '') {
            this.setState({
                pincodeError: true,
            })
            return
        }
        if (this.state.pincode.length < 6 || this.state.pincode.length > 6) {
            this.setState({
                pincodeError: true,
            })
            return
        }
        if (this.state.addressLine1 === '' || this.state.addressLine1.length < 2) {
            this.setState({
                addressLine1Error: true,
            })
            return
        }
        if (this.state.cycleBrand === '' || this.state.cycleBrand.length < 2) {
            this.setState({
                cycleBrandError: true,
            })
            return
        }
        if (this.state.cycleModel === '' || this.state.cycleModel.length < 2) {
            this.setState({
                cycleModelError: true,
            })
            return
        }
        if (this.state.cycleColor === '' || this.state.cycleColor.length < 2) {
            this.setState({
                cycleColorError: true,
            })
            return
        }
        if (this.state.cycleFrameNumber === '') {
            this.setState({
                cycleFrameNumberError: true,
            })
            return
        }
        if (this.state.invoiceNumber === '' || this.state.invoiceNumber.length < 2) {
            this.setState({
                invoiceNumberError: true,
            })
            return
        }
        if (this.state.invoiceAmount === '' || this.state.invoiceAmount > 200000) {
            this.setState({
                invoiceAmountError: true,
            })
            return
        }
        if (this.state.invoiceDate === '' || moment(this.state.invoiceDate).isAfter()) {
            this.setState({
                invoiceDateError: true,
            })
            return
        }
        if (this.state.file === '' || this.state.file.length < 2) {
            this.setState({
                fileError: true,
            })
            return
        }

        let file = {};
        this.calculatePremium({
            "title": this.state.title,
            "name": this.state.name,
            "email": this.state.email,
            "dob": moment(this.state.dob).format('DD/MM/YYYY'),
            "mobile": this.state.mobile,
            "pincode": this.state.pincode,
            "city": this.state.cityName,
            "state": this.state.stateName,
            "address_line_1": this.state.addressLine1,
            "address_line_2": this.state.addressLine2,
            "response_brand_id": "23",
            "response_custom_brand": this.state.cycleBrand,
            "response_model": this.state.cycleModel,
            "response_color": this.state.cycleColor,
            "response_invoice_number": this.state.invoiceNumber,
            "response_invoice_amount": this.state.invoiceAmount,
            "response_invoice_date": moment(this.state.invoiceDate).format('DD/MM/YYYY'),
            "file_invoice": this.state.file,
            "channel": "admin",
            "response_frame_no": this.state.cycleFrameNumber,
    })
    }


    calculatePremium = (payload) => {
        this.setState({
            showLoader: true,
        });
        AxiosReactClient.postRequest('products/22/create_quotation', payload)
            .then((res) => {
                console.log(res)
                this.setState({
                    showLoader: false,
                    orderId: res.data.data.order_id,
                    premium: res.data.data.amount,
                    quotationCreated: true
                });
            })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    onClickCreatePaymentLink = () => {
        this.onClickCreate()
    }
    onClickCreate = () => {
        let payload = {};
        payload = {
            "entity": "order",
            "entity_id": this.state.orderId,
            "is_monthly": false,
            "is_recurring": false
        }
        this.setState({
            showLoader: true,
        });
        AxiosReactClient.postRequest('payments/link', payload)
            .then((res) => {
                console.log(res)
                this.setState({
                    showLoader: false,
                    paymentLink: res.data.data.url
                });
            })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }


    fileToBase64 = (file) => {
        return new Promise((resolve) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    detectPdf(url) {
        let fileType = url.split(".").slice(-1)[0];
        return fileType === 'pdf'
    }

    detectImage = (e) => {
        this.setState({
            imageAsset: URL.createObjectURL(e.target.files[0]),
        })
        e.target.files[0] &&
        this.fileToBase64(e.target.files[0]).then((result) => {
                this.setState({
                    file: result,
                    fileError:false
                })
            if (!this.refs.fileUploader) {
                this.refs.fileUploader.value = e.target.value;
            }

        });
    };



    render() {
        return (
            <Fragment>
                {this.state.showLoader &&
                <Loader msg={"Please Wait..."}/>
                }
                <div className={"container-fluid"}>
                    <Row>
                        <Col lg={6}>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="formControlInput1">Title</label>
                                    <select
                                        className="form-control"
                                        id="formControlInput1"
                                        defaultChecked={this.state.title}
                                        onChange={(e) => {
                                            this.onTitleChange(e, 'self')
                                        }}>
                                        <option value={'mr'}>Mr</option>
                                        <option value={'ms'}>Ms</option>
                                        <option value={'mrs'}>Mrs</option>
                                    </select>
                                    {this.state.titleError &&
                                    <span className="text-danger">{this.state.titleErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput2">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput2"
                                        placeholder="Full Name"
                                        defaultValue={this.state.name}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'name')
                                        }}
                                    />
                                    {this.state.nameError &&
                                    <span className="text-danger">{this.state.nameErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput3">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="formControlInput3"
                                        placeholder="xyz@email.com"
                                        defaultValue={this.state.email}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'email')
                                        }}
                                    />
                                    {this.state.emailError &&
                                    <span className="text-danger">{this.state.emailErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput33">Mobile</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formControlInput33"
                                        placeholder=""
                                        defaultValue={this.state.mobile}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'mobile')
                                        }}
                                    />
                                    {this.state.mobileError &&
                                    <span className="text-danger">{this.state.mobileErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput4">Date of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="formControlInput4"
                                        placeholder="dd/mm/yyyy"
                                        defaultValue={this.state.dob}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'dob')
                                        }}
                                        max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        min={moment()
                                            .subtract(56, "years")
                                            .add(1, "days").format("YYYY-MM-DD")}

                                    />
                                    {this.state.dobError &&
                                    <span className="text-danger">{this.state.dobErrorText}</span>
                                    }
                                </div>

                                <div>
                                    <h3 className={'my-3'}><u>Address Details</u></h3>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput5">Pincode</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formControlInput5"
                                        defaultValue={this.state.pincode}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'pincode')
                                        }}
                                    />
                                    {this.state.pincodeError &&
                                    <span className="text-danger">{this.state.pincodeErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput6">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput6"
                                        defaultValue={this.state.cityName}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cityName')
                                        }}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput7">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput7"
                                        defaultValue={this.state.stateName}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'stateName')
                                        }}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput8">Address line 1</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput8"
                                        defaultValue={this.state.addressLine1}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'addressLine1')
                                        }}
                                    />
                                    {this.state.addressLine1Error &&
                                    <span className="text-danger">{this.state.addressLine1ErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput9">Address line 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput9"
                                        onChange={(e) => {
                                            this.onValueChange(e, 'addressLine2')
                                        }}
                                        defaultValue={this.state.addressLine2}
                                    />
                                    {this.state.addressLine2Error &&
                                    <span className="text-danger">{this.state.addressLine2ErrorText}</span>
                                    }
                                </div>

                                <div>
                                    <h3 className={'my-3'}><u>Cycle Details</u></h3>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput12">Cycle Brand</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput12"
                                        defaultValue={this.state.cycleBrand}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cycleBrand')
                                        }}
                                    />
                                    {this.state.cycleBrandError &&
                                    <span className="text-danger">{this.state.cycleBrandErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput13">Cycle Model</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput13"
                                        defaultValue={this.state.cycleModel}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cycleModel')
                                        }}
                                    />
                                    {this.state.cycleModelError &&
                                    <span className="text-danger">{this.state.cycleModelErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput12">Cycle Color</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput12"
                                        defaultValue={this.state.cycleColor}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cycleColor')
                                        }}
                                    />
                                    {this.state.cycleColorError &&
                                    <span className="text-danger">{this.state.cycleColorErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput12">Cycle Frame Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput12"
                                        defaultValue={this.state.cycleFrameNumber}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cycleFrameNumber')
                                        }}
                                    />
                                    {this.state.cycleFrameNumberError &&
                                    <span className="text-danger">{this.state.cycleFrameNumberErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput13">Invoice Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput13"
                                        defaultValue={this.state.invoiceNumber}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'invoiceNumber')
                                        }}
                                    />
                                    {this.state.invoiceNumberError &&
                                    <span className="text-danger">{this.state.invoiceNumberErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput12">Invoice Amount (Max 2 lakhs)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput12"
                                        defaultValue={this.state.invoiceAmount}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'invoiceAmount')
                                        }}
                                        max={"200000"}
                                    />
                                    {this.state.invoiceAmountError &&
                                    <span className="text-danger">{this.state.invoiceAmountErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput13">Invoice Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="formControlInput4"
                                        placeholder="dd/mm/yyyy"
                                        defaultValue={this.state.invoiceDate}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'invoiceDate')
                                        }}
                                        max={moment().subtract(0, "years").format("YYYY-MM-DD")}
                                        min={moment()
                                            .subtract(1, "years")
                                            .add(1, "days")
                                            .format("YYYY-MM-DD")}

                                    />
                                    {this.state.invoiceDateError &&
                                    <span className="text-danger">{this.state.invoiceDateErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput13">Upload</label><br/>
                                <input
                                    type="file"
                                    accept="image/*,.pdf"
                                    id="file"
                                    ref="fileUploader"
                                    onChange={this.detectImage}
                                />
                                    {this.state.fileError &&
                                    <span className="text-danger">{this.state.fileErrorText}</span>
                                    }
                                </div>
                                {this.state.quotationCreated && <>
                                    <div className={'border rounded-10 p-4'}>
                                        <div className={'d-flex justify-content-center'}>
                                            <h3>Your Premium is: </h3>
                                        </div>
                                        <div className={'d-flex justify-content-center mt-4'}>
                                            <h4>{this.state.premium}</h4>
                                        </div>
                                        <div className={'d-flex justify-content-center mt-4'}>
                                            {this.state.paymentLink === '' ?
                                                <button
                                                    className={'btn btn-icon btn-primary btn-lg'}
                                                    type={'button'}
                                                    onClick={() => {
                                                        this.onClickCreatePaymentLink()
                                                    }}
                                                >
                                                    <b>Create Payment Link</b>
                                                </button>
                                                :
                                                <div className={'d-flex justify-content-center mt-4'}>
                                                    <h4>Payment Link:
                                                        <span className={'text-primary cursor-pointer'}
                                                              onClick={() => {
                                                                  window.open(this.state.paymentLink)
                                                              }}
                                                        > <u>{this.state.paymentLink}</u></span></h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                                }
                                {!this.state.quotationCreated &&
                                <div className={'d-flex justify-content-center mt-4'}>
                                    <button
                                        className={'btn btn-icon btn-primary btn-lg'}
                                        type={'button'}
                                        onClick={() => {
                                            this.onCreateQuotationClick()
                                        }}
                                    >
                                        <b>Create Quotation</b>
                                    </button>
                                </div>
                                }
                            </form>
                        </Col>
                    </Row>

                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {


    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CycleQuotation);