import * as actionTypes from './actionTypes/settingTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getPermissionsStart = () => {
    return {
        type: actionTypes.GET_PERMISSIONS_START
    };
};

export const getRolesStart = () => {
    return {
        type: actionTypes.GET_ROLES_START
    };
};
export const getRoleIdStart = () => {
    return {
        type: actionTypes.GET_ROLE_ID_START
    };
};
export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START
    };
};
export const getUserInfoStart = () => {
    return {
        type: actionTypes.GET_USERS_INFO_START
    };
};
export const getUserPermissionsStart = () => {
    return {
        type: actionTypes.GET_USERS_PERMISSIONS_START
    };
};


// SUCCESS
export const getPermissionsSuccess = (data) => {
    return {
        type: actionTypes.GET_PERMISSIONS_SUCCESS,
        data: data
    };
};

export const getRolesSuccess = (data) => {
    return {
        type: actionTypes.GET_ROLES_SUCCESS,
        data: data
    };
};
export const getRoleIdSuccess = (data) => {
    return {
        type: actionTypes.GET_ROLE_ID_SUCCESS,
        data: data
    };
};
export const getUsersSuccess = (data, count) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        data: data,
        count: count
    };
};
export const getUserInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_INFO_SUCCESS,
        data: data
    };
};
export const getUserPermissionsSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_PERMISSIONS_SUCCESS,
        data: data
    };
};



// FAIL
export const getPermissionsFail = (error) => {
    return {
        type: actionTypes.GET_PERMISSIONS_FAIL,
        error: error
    };
};

export const getRolesFail = (error) => {
    return {
        type: actionTypes.GET_ROLES_FAIL,
        error: error
    };
};

export const getRoleIdFail = (error) => {
    return {
        type: actionTypes.GET_ROLE_ID_FAIL,
        error: error
    };
};
export const getUsersFail = (error) => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        error: error
    };
};
export const getUserInfoFail = (error) => {
    return {
        type: actionTypes.GET_USERS_INFO_FAIL,
        error: error
    };
};
export const getUserPermissionsFail = (error) => {
    return {
        type: actionTypes.GET_USERS_PERMISSIONS_FAIL,
        error: error
    };
};




// ACTION
export const getPermissionsData = () => {
    return dispatch => {
        dispatch(getPermissionsStart());
        let params = {}
        AxiosReactClient.getRequest('permissions', params).then(response => {
                dispatch(getPermissionsSuccess(response.data.data));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getPermissionsFail(err.response.data.error));
                else
                    dispatch(getPermissionsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const getRolesData = () => {
    return dispatch => {
        dispatch(getRolesStart());
        let params = {}
        AxiosReactClient.getRequest('roles', params).then(response => {
                dispatch(getRolesSuccess(response.data.data));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getRolesFail(err.response.data.error));
                else
                    dispatch(getRolesFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getRoleIdData = (id) => {
    return dispatch => {
        dispatch(getRoleIdStart());
        let params = {}
        AxiosReactClient.getRequest('roles/' + id, params).then(response => {
                dispatch(getRoleIdSuccess(response.data.data));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getRoleIdFail(err.response.data.error));
                else
                    dispatch(getRoleIdFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getUsersForListing = (payload) => {
    return dispatch => {
        dispatch(getUsersStart());
        AxiosReactClient.getRequest('v2/users', payload).then(response => {
                dispatch(getUsersSuccess(response.data.data.users, response.data.data.count));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getUsersFail(err.response.data.error));
                else
                    dispatch(getUsersFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getUserInfoForViewing = (id) => {
    return dispatch => {
        dispatch(getUserInfoStart());
        AxiosReactClient.getRequest('v2/users/' + id).then(response => {
                dispatch(getUserInfoSuccess(response.data.data.users));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getUserInfoFail(err.response.data.error));
                else
                    dispatch(getUserInfoFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};
export const getUserPermissions = (id) => {
    return dispatch => {
        dispatch(getUserPermissionsStart());
        AxiosReactClient.getRequest('users/' + id + "/permissions").then(response => {
                dispatch(getUserPermissionsSuccess(response.data.data.Permissions));
            })
            .catch(err => {
                if (err.response)
                    dispatch(getUserPermissionsFail(err.response.data.error));
                else
                    dispatch(getUserPermissionsFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};