import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Modal
} from 'reactstrap';
import ReactDatetime from "react-datetime";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

// Import all custom default exports
import PartnersReducer from '../../../reducers/partnersReducer';
import Spinner from '../../../components/Spinner/Spinner';
import useApi from '../../../hooks/useApi';
import AxiosRestClient from '../../../utilities/AxiosRestClient';

// Import all third party named exports
import { CSVLink } from "react-csv";

// Import all custom named exports
import { ReducerUtils } from '../../../constants/reducers';
import { buildCsvHeaders, rebuildAPIResponse, isUpdateButtonDisabled, buildUpdateFloatAmountPayload, renderData, isValidDate } from '../../../helpers/partners';

const Paginator = ({ pagination, handleClick }) => {
  return (
    <Pagination aria-label="Page navigation example"
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0">
      
      <PaginationItem disabled={pagination.currentPage <= 0}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>

      {[...Array(pagination.pagesCount)].map((page, i) => 
        <PaginationItem active={i === pagination.currentPage} key={i}>
          <PaginationLink onClick={e => handleClick(e, i)} href="#">
            {i + 1}
          </PaginationLink>
        </PaginationItem>
      )}

      <PaginationItem disabled={pagination.currentPage >= pagination.pagesCount - 1}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage + 1)}
          next
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

const PartnersList = (props) => {
  const pageSize = 25;
  const csvHeaders = buildCsvHeaders();

  // Call useApi hook to fetch other policies list
  const partnersList = useApi('v2/partners', {}, false, [])
  
  const [state, dispatch] = useReducer(PartnersReducer, { partnersList: [], partnersListApi: [] });
  const [filter, setFilter] = useState({ searchText: '', sortBy: '', sortType: 'desc' });
  const [loader, setLoader] = useState(true);
  const [pagination, setPagination] = useState({ currentPage: 0, pagesCount: Math.ceil(partnersList.length / pageSize) });
  const [modal, setModal] = useState({ isOpen: false });
  const [form, setForm] = useState({ id: '', utrn: '', date_of_fund_transfer: '', amount: '', hasError: false });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  
  useEffect(() => {
    dispatchEvent(ReducerUtils.partners.list, partnersList); //rebuildAPIResponse(list)
    partnersList.length ? setLoader(false) : setTimeout(() => { setLoader(false) }, 10000);
  }, [partnersList]);

  useEffect(() => {
    setPagination({ ...pagination, 'pagesCount': Math.ceil(state.partnersList.length / pageSize)});
  }, [state.partnersList])

  useEffect(() => {
    if (form.date_of_fund_transfer) {
      const m = moment(form.date_of_fund_transfer, 'DD-MM-YYYY');
      setForm({ ...form, hasError: !m.isValid() });
    } else {
      setForm({ ...form, hasError: true });
    }
  }, [form.date_of_fund_transfer]);
  
  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setPagination({ ...pagination, currentPage: index });
  };

  const handleSearch = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
    dispatchEvent(ReducerUtils.otherPolicies.search, event.target.value);
    setPagination({ ...pagination, currentPage: 0 });
  };

  const handleColumnSort = (sortBy) => {
    const sortType = filter.sortType === 'desc' ? 'asc': 'desc';

    setFilter({ ...filter, 'sortType': sortType, 'sortBy': sortBy });
    dispatchEvent("SORT_LIST", { sortBy: sortBy, sortType: sortType });
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const handleDateChange = (date, keyname) => {
    setForm({
      ...form,
      [keyname]: date
    });
  };

  const openModal = (item) => {
    const modalObject = {
      id: item.id,
      clientId: item.client_id,
      utrn: '',
      amount: '',
      date_of_fund_transfer: '',
      hasError: false
    };

    setIsFormSubmitted(false);
    setForm(modalObject);
    setModal({...modal, isOpen: true });
  };

  const updateAmount = async () => {
    const payload = buildUpdateFloatAmountPayload(form);
    const response = await AxiosRestClient.putRequest(`v2/partners/${form.id}/clients/${form.clientId}/float`, payload);
    const { data } = response.data;

    dispatchEvent(ReducerUtils.partners.updateFloatAmount, { id: form.id, new_float_amount: data.new_float_amount });
    setModal({...modal, isOpen: false });
    setIsFormSubmitted(false);
  };
  
  const onSubmit = (event) => {
    event.preventDefault();

    setIsFormSubmitted(true);
    updateAmount();
  };

  return (
    <Container fluid>
      <div className="header-body">
        <Row className="align-items-center py-4">
          <Col lg="8" xs="6">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <a href="#" onClick={e => e.preventDefault()}>
                  <i className="fas fa-home"/>
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={e => {
                  e.preventDefault();
                }}>
                  Partners
                </a>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="text-right" lg="4" xs="6"></Col>
        </Row>
      </div>

      <div>
        <Row className="bg-white m-0 py-2">
          <Col lg={3} md={5} className="p-1 px-3 text-muted">
            <small className="form-inline md-form mr-3 mb-4 d-inline">
              <div className="form-group border justify-content-between rounded-5">
                <input type="text" 
                  name="searchText" 
                  className="form-control flex-grow-1 border-0" 
                  placeholder="Search by Partner Name" 
                  aria-label="search" 
                  aria-describedby="basic-addon1" 
                  value={filter.searchText}
                  onChange={handleSearch}
                />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text cursor-pointer border-0 mr-2" id="basic-addon1">
                      <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"/>
                    </span>
                  </div>
                </div>
              </div>
            </small>
          </Col>
          <Col className="text-right mt-3">
            {state.partnersList.length > 0 && 
            <CSVLink
              data={state.partnersList} 
              headers={csvHeaders}
              filename={"partners-list.csv"}
              target="_blank"
            >
              <Button color="primary " outline size="sm" type="button">Export</Button>
            </CSVLink>}
          </Col>
        </Row>
      </div>
      <Card className="m-0 mt-1">
        <CardBody className="px-0 py-0">
          <div className="table-responsive">
            <Table className="align-items-center table-flush" hover responsive>
              <thead className="thead-light">
                <tr>
                  <th className={(filter.sortBy === 'id' ? 'text-primary' : '')} 
                    onClick={() => handleColumnSort('id')}>
                    ID <i className="fas fa-sort ml-2"/>
                  </th>
                  <th className={(filter.sortBy === 'name' ? 'text-primary' : '')} 
                    onClick={() => handleColumnSort('name')}>
                    Partner Name <i className="fas fa-sort ml-2"/>
                  </th>
                  <th className={(filter.sortBy === 'float_amount' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('float_amount')}>
                    Float Amount <i className="fas fa-sort ml-2"/>
                  </th>
                  <th>Data</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loader &&
                <tr>
                  <td colSpan="8">
                    <Spinner></Spinner>
                  </td>
                </tr>}
                {(!loader && !state.partnersList.length) &&
                <tr className="cursor-pointer">
                  <td colSpan="12" className="text-center">
                    <span className="text-muted">
                      <b>
                        No Partner Data found
                      </b>
                    </span>
                  </td>
                </tr>}

                {(!loader && state.partnersList.length > 0) && 
                  state.partnersList
                  .slice(
                    pagination.currentPage * pageSize,
                    (pagination.currentPage + 1) * pageSize
                  )
                  .map((item, index) => {
                  return <tr key={index + 'hgshdsh'}>
                    <td className="table-user">{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.float_amount}</td>
                    <td>{ReactHtmlParser(renderData(item))}</td>
                    <td>
                      {item.client_id && <Button color="primary " outline size="sm" type="button" onClick={() => openModal(item)}>Update Float Amount</Button>}
                    </td>
                  </tr>;
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        
        {(!loader && state.partnersList.length > 0 && state.partnersList.length > pageSize) && 
        <CardFooter className="py-4">
          <Paginator 
            pagination={pagination}
            handleClick={handleClick}
          />
        </CardFooter>}

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal.isOpen}
          toggle={() => {
              setModal({ ...modal, isOpen: !modal.isOpen })
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <div className={"modal-header"}>
            <h4>Update Float Amount</h4>
            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                setModal({ ...modal, isOpen: false })
            }}/>
          </div>
          <hr className={"text-dark m-0 p-0"}/>

          <div className="modal-body text-left">
            <form
              onSubmit={onSubmit}
              >
              <Row>
                <Col xl="12">
              
                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Partner Name</label>
                      <select className="form-control" name="partner_name" fullwidth="true" 
                        value={form.id} onChange={handleChange} disabled={true}>
                        {state.partnersList.map((item, index) => (
                          <option value={item.id} key={index}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">UTRN</label>
                      <input className="form-control" type="text" name="utrn" placeholder="" fullwidth="true" 
                        value={form.utrn} onChange={handleChange} />
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Date of Fund Transfer</label>
                      <ReactDatetime
                        closeOnSelect={true} 
                        dateFormat="DD-MM-YYYY"
                        onChange={date => {handleDateChange(date, 'date_of_fund_transfer')}}
                        timeFormat={false} 
                        value={form.date_of_fund_transfer}
                        isValidDate={ (current) =>isValidDate(current, 'date_of_fund_transfer') }
                      />
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Amount Transferred</label>
                      <input className="form-control" type="number" name="amount" placeholder="" fullwidth="true" 
                        value={form.amount} onChange={handleChange} />
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4 mt-4"}>
                    <div className="form-group w-100 px-2 text-right">
                      <button className="btn btn-primary" type="submit"
                        { ...( (isFormSubmitted || isUpdateButtonDisabled(form)) && { disabled: true } ) }
                      >
                        {isFormSubmitted ? <Spinner /> : 'Update'}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>

        </Modal>
      </Card>
    </Container>
  );
};

export default PartnersList;