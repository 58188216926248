import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
// import Select2 from "react-select2-wrapper";
import { ADD_SLUG, EDIT_SLUG, isBagicDealer, isSearchResultNotFound, isSubmitButtonDisabled, validateInputLength } from '../../../helpers/vehicle';
import { options } from '../../../defaultValues/vehicleMaster';
import Spinner from '../../../components/Spinner/Spinner';
import Can from '../../../config/Casl/Can';
import * as permissions from '../../../config/Casl/permissions';
import Notification from '../../../components/CustomUi/Notify/Notification';

const AddEditVehicleMasterForm = (props) => {
  const {
    list, 
    errorMessage,
    form,
    errors,
    getProductList,
    handleChange,
    isFormSubmitted,
    isSearchResultLoaded,
    onSubmit,
    onSubmitSearch,
    resetForm,
    filter,
    handleFilterChange
  } = props;

  return (
    <>
      <Container fluid>
        <Row>
            <Col xs="12">
              <Card className="mt-4">
                <CardHeader>
                  <Row>
                    <Col xs="10">
                      <h4 className="h4 pt-2 font-weight-400">
                        Add/Update Vehicle Master
                      </h4>
                    </Col>
                    <Col xs="2">
                      {filter.selection === ADD_SLUG && <Button
                        className="btn-icon btn-2"
                        size="md"
                        outline
                        color="primary"
                        onClick={resetForm}>
                        RESET FIELDS
                        <span className="btn-inner--icon ml-2">
                          <i className="fas fa-retweet" aria-hidden="true"/>
                        </span>
                      </Button>}
                    </Col>
                  </Row>

                  <Can I={permissions.VEHICLES.master_update} a={permissions.VEHICLES.subject}>
                    <Row>
                      <Col xs="12">
                        <Row onChange={handleFilterChange} className="ml-5 mt-3">
                          <Col xs="2">
                            <label><input type="radio" value={ADD_SLUG} name="selection" defaultChecked={true} /> Add Vehicle Master</label>
                          </Col>
                          <Col xs="2">
                            <label><input type="radio" value={EDIT_SLUG} name="selection" /> Update Vehicle Master</label>
                          </Col>
                        </Row>

                        {filter.selection === EDIT_SLUG && 
                        <form onSubmit={onSubmitSearch}>
                          <Row className="ml-5">
                            <Col xs="2">
                              <div className="w-100 mt-3">
                                <select
                                  name="product"
                                  className="form-control"
                                  id="formControlInput1"
                                  value={filter.product}
                                  onChange={handleFilterChange}
                                  >
                                  <option value={''}>Select Product</option>
                                  {list.products.map((item) => {
                                    return <option key={item.id} value={item.id}>{item.text}</option>
                                  })}
                                </select>
                              </div>
                            </Col>
                            <Col xs="2">
                              <div className="form-group mt-3">
                                <input
                                  name="search_vehicle_code"
                                  type="text"
                                  className="form-control"
                                  id="formControlInput2"
                                  placeholder="Enter Vehicle Code"
                                  value={filter.search_vehicle_code}
                                  onChange={handleFilterChange}
                                  onInput={validateInputLength}
                                  {...(!filter.product && { disabled: true })}
                                />
                              </div>
                            </Col>
                            <Col xs="2">
                              <div className="form-group mt-3">
                                <Button className="btn-icon btn-2" size="md" contained={"true"} color="primary" type="submit" 
                                  {...((!filter.search_vehicle_code || !filter.product) && {disabled: true })}>
                                  <span className="btn-inner--icon">
                                      Search
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>}
                      </Col>
                    </Row>
                  </Can>
                </CardHeader>

                <CardBody>
                {isSearchResultNotFound(filter, isSearchResultLoaded) ?
                  <Row className="ml-5">
                    <Col xs="12" className="text-danger">
                        It seems that no data found within vehicle master for the requested input.
                    </Col>
                  </Row> : 
                  <FormGroup>
                    <form onSubmit={onSubmit}>
                      <div className="w-100">
                        <label htmlFor="formControlInput1" className="required">Partner</label>
                        <select
                          name="partner_id"
                          className="form-control"
                          id="formControlInput1"
                          value={form.partner_id}
                          onChange={handleChange}
                          {...({ disabled: true })}>
                          <option value={''}>Select</option>
                          {options.partners.map((item) => {
                            return <option key={item.value} value={item.value}>{item.title}</option>
                          })}
                        </select>
                        {errorMessage.partner_id && <small className="text-danger">{errorMessage.partner_id}</small>}
                      </div>

                      {/* <label className="form-control-label mt-3 required" htmlFor="dealer">
                        Product
                      </label>

                      <Select2 className=" form-control-label mb-5"
                        options={{placeholder:"Select"}}
                        name="product_id"
                        value={form.product_id}
                        defaultValue=""
                        data={list.products}
                        onChange={handleChange}
                        disabled={!form.partner_id}
                      /> */}

                      <div className="w-100 mt-3">
                        <label htmlFor="formControlInput1" className="required">Product</label>
                        <select
                          name="product_id"
                          className="form-control"
                          id="formControlInput1"
                          value={form.product_id}
                          onChange={handleChange}
                          // {...({ disabled: true })}
                          >
                          <option value={''}>Select</option>
                          {list.products.map((item) => {
                            return <option key={item.id} value={item.id}>{item.text}</option>
                          })}
                        </select>
                        {errorMessage.product_id && <small className="text-danger">{errorMessage.product_id}</small>}
                      </div>

                      {list.products.length > 0 ? <>
                        <div className="form-group mt-3">
                            <label htmlFor="formControlInput2" className="required">Vehicle Code (Case Sensitive)</label>
                            <input
                            name="vehicle_code"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Vehicle Code"
                            value={form.vehicle_code}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            {...(filter.selection === EDIT_SLUG && {disabled: true })}
                            />
                            {errorMessage.vehicle_code && <small className="text-danger">{errorMessage.vehicle_code}</small>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Make</label>
                            <input
                            name="make"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Make"
                            value={form.make}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.make && <small className="text-danger">{errorMessage.make}</small>}
                        </div>

                        {isBagicDealer (form.product_id) && 
                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Make Code</label>
                            <input
                            name="make_code"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Make Code"
                            value={form.make_code}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.make_code && <small className="text-danger">{errorMessage.make_code}</small>}
                        </div>}

                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Model</label>
                            <input
                            name="model"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Model"
                            value={form.model}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.model && <small className="text-danger">{errorMessage.model}</small>}
                        </div>

                        {isBagicDealer (form.product_id) && 
                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Model Code</label>
                            <input
                            name="model_code"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Model Code"
                            value={form.model_code}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.model_code && <small className="text-danger">{errorMessage.model_code}</small>}
                        </div>}

                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Variant</label>
                            <input
                            name="variant"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Variant"
                            value={form.variant}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.variant && <small className="text-danger">{errorMessage.variant}</small>}
                        </div>

                        {isBagicDealer (form.product_id) && 
                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Variant Code</label>
                            <input
                            name="variant_code"
                            type="text"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Variant Code"
                            value={form.variant_code}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.variant_code && <small className="text-danger">{errorMessage.variant_code}</small>}
                        </div>}

                        <div className="form-group w-100">
                            <label htmlFor="formControlInput1" className="required">Carrying Capacity</label>
                            <select
                            name="carrying_capacity"
                            className="form-control"
                            id="formControlInput1"
                            value={form.carrying_capacity}
                            onChange={handleChange} >
                            <option value={''}>Select</option>
                            {options.carryingCapacity.map((item) => {
                                return <option key={item.value} value={item.value}>{item.title}</option>
                            })}
                            </select>
                            {errorMessage.carrying_capacity && <small className="text-danger">{errorMessage.carrying_capacity}</small>}
                        </div>

                        <div className="form-group w-100">
                            <label htmlFor="formControlInput1" className="required">No. of Wheels</label>
                            <select
                            name="no_of_wheels"
                            className="form-control"
                            id="formControlInput1"
                            value={form.no_of_wheels}
                            onChange={handleChange} >
                            <option value={''}>Select</option>
                            {options.noOfWheels.map((item) => {
                                return <option key={item.value} value={item.value}>{item.title}</option>
                            })}
                            </select>
                            {errorMessage.no_of_wheels && <small className="text-danger">{errorMessage.no_of_wheels}</small>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="formControlInput2" className="required">Cubic Capacity</label>
                            <input
                            name="cubic_capacity"
                            type="number"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Cubic Capacity"
                            value={form.cubic_capacity}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                            {errorMessage.cubic_capacity && <small className="text-danger">{errorMessage.cubic_capacity}</small>}
                        </div>

                        <div className="form-group w-100">
                            <label htmlFor="formControlInput1" className="required">Fuel Type</label>
                            <select
                            name="fuel"
                            className="form-control"
                            id="formControlInput1"
                            value={form.fuel}
                            onChange={handleChange} 
                            disabled={!form.product_id} >
                            <option value={''}>Select</option>
                            {list.fuelTypes.map((item) => {
                                return <option key={item.value} value={item.value}>{item.title}</option>
                            })}
                            </select>
                            {errorMessage.fuel && <small className="text-danger">{errorMessage.fuel}</small>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="formControlInput2">Ex-Showroom Price</label>
                            <input
                            name="ex_showroom_price"
                            type="number"
                            className="form-control"
                            id="formControlInput2"
                            placeholder="Enter Ex-showroom Price"
                            value={form.ex_showroom_price}
                            onChange={handleChange}
                            onInput={validateInputLength}
                            />
                        </div>

                        {errors.length > 0 && 
                          <div className="w-100 text-danger mb-5">
                          {errors.map((item, key) => {
                            return (
                              <Row key={key}>
                                  <Col>{item}</Col>
                              </Row>
                            );
                          })} 
                        </div>}
                        
                        <div className={"w-100"}>
                            <Button className="btn-icon btn-2 w-100" size="md" contained={"true"} color="primary" type="submit"
                            {...((isSubmitButtonDisabled(form) || isFormSubmitted) && {disabled: true})}
                            >
                            <span className="btn-inner--icon">
                                {isFormSubmitted ? <Spinner /> : 'Submit'}
                            </span>
                            </Button>
                        </div>
                      </> : 
                      <Button className="btn-icon btn-2 mt-3" size="md" contained={"true"} color="primary" type="button" onClick={() => getProductList(form.partner_id)}>
                        <span className="btn-inner--icon">
                            Fetch Products
                        </span>
                    </Button>}
                    </form>
                  </FormGroup>}
                </CardBody>
              </Card>
            </Col>
        </Row>
        <Notification />
      </Container>
      
    </>
  );
}

export default AddEditVehicleMasterForm;