import React from "react";
import { Button, Col } from "reactstrap";
import { CSVLink } from "react-csv";

const UnifiedPoliciesExport = (props) => {
  const { csvHeaders, filename, list } = props;

  return (
    <Col className="text-right mt-3">
      {filename && list.length > 0 && (
        <CSVLink
          data={list}
          headers={csvHeaders}
          filename={filename}
          target="_blank"
        >
          <Button color="primary" size="md" type="button" >
            <i className="fas fa-download"/> {`Download Policies (${list.length})`}
          </Button>
        </CSVLink>
      )}
    </Col>
  );
};

export default UnifiedPoliciesExport;
