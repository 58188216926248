import * as actionTypes from './actionTypes/dashboardTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'




// START
export const getDashboardPoliciesStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_START,
        filter: filter
    };
};
export const getDashboardLeadsStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_LEADS_START,
        filter: filter
    };
};
export const getDashboardClaimsStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_START,
        filter: filter
    };
};
export const getDashboardRevinueStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_REVINUE_START,
        filter: filter
    };
};
export const getDashboardDealersStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEALERS_START,
        filter: filter
    };
};
export const getDashboardPoliciesLinegraphStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_START,
        filter: filter
    };
};
export const getDashboardClaimsBarchartStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_START,
        filter: filter
    };
};
export const getDashboardProductListStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_START,
        filter: filter
    };
};
export const getDashboardDealerListStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEALER_LIST_START,
        filter: filter
    };
};
export const getDashboardClaimsPiechartStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_START,
        filter: filter
    };
};
export const getDashboardCombinedPoliciesLinegraphStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_START,
        filter: filter
    };
};
export const getDashboardProductListRevenueStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_START,
        filter: filter
    };
};
export const getDashboardClaimPartnerViewStart = (filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_START,
        filter: filter
    };
};





// SUCCESS
export const getDashboardPoliciesSuccess = (count, total, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_SUCCESS,
        count: count,
        total: total,
        filter: filter
    };
};

export const getDashboardLeadsSuccess = (count, total, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_LEADS_SUCCESS,
        count: count,
        total: total,
        filter: filter
    };
};
export const getDashboardClaimsSuccess = (count, total, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_SUCCESS,
        count: count,
        total: total,
        filter: filter
    };
};
export const getDashboardRevinueSuccess = (count, total, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_REVINUE_SUCCESS,
        count: count,
        total: total,
        filter: filter
    };
};
export const getDashboardDealersSuccess = (count, total, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEALERS_SUCCESS,
        count: count,
        total: total,
        filter: filter
    };
};
export const getDashboardPoliciesLinegraphSuccess = (data, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardClaimsBarchartSuccess = (data, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardProductListSuccess = (data, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardDealerListSuccess = (data, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEALER_LIST_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardClaimsPiechartSuccess = (data, filter) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardCombinedPoliciesLinegraphSuccess = (data, filter) => {

    return{
        type: actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_SUCCESS,
        data: data,
        filter: filter,
    };
};
export const getDashboardProductListRevenueSuccess = (data, filter) => {
    
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_SUCCESS,
        data: data,
        filter: filter
    };
};
export const getDashboardClaimPartnerViewSuccess = (data, filter) => {

    return {
        type: actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_SUCCESS,
        data: data,
        filter: filter
    };
};





// FAIL
export const getDashboardPoliciesFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_FAIL,
        error: error
    };
};

export const getDashboardLeadsFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_DASHBOARD_LEADS_FAIL,
        error: error
    };
};
export const getDashboardClaimsFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_FAIL,
        error: error
    };
};
export const getDashboardRevinueFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_DASHBOARD_REVINUE_FAIL,
        error: error
    };
};
export const getDashboardDealersFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_DASHBOARD_DEALERS_FAIL,
        error: error
    };
};
export const getDashboardPoliciesLinegraphFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_FAIL,
        error: error
    };
};
export const getDashboardClaimsBarchartFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_FAIL,
        error: error
    };
};
export const getDashboardProductListFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_FAIL,
        error: error
    };
};
export const getDashboardDealerListFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_DEALER_LIST_FAIL,
        error: error
    };
};
export const getDashboardClaimsPiechartFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_FAIL,
        error: error
    };
};
export const getDashboardCombinedPoliciesLinegraphFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_FAIL,
        error: error,
    };
};
export const getDashboardProductListRevenueFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_FAIL,
        error: error
    };
};
export const getDashboardClaimPartnerViewFail = (error) => {
    return {
        type: actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_FAIL,
        error: error
    };
};





// ACTION
export const getDashboardPoliciesCount = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardPoliciesStart(filter));
        // console.log(dateParams);

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { date: filter, startDate: dateParams.startDate, endDate: dateParams.endDate }
            // console.log(params);
        }

        AxiosReactClient.getRequest('dashboard/policy/count', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardPoliciesSuccess(response.data.data.policyCount, response.data.data.totalCount, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardPoliciesFail(err.response.data.error));
                else
                    dispatch(getDashboardPoliciesFail({ code: 502, message: err.message }));
            });
    };
};

export const getDashboardLeadsCount = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardLeadsStart(filter));

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/lead/count', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardLeadsSuccess(response.data.data.leadCount, response.data.data.totalLeadCount, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardLeadsFail(err.response.data.error));
                else
                    dispatch(getDashboardLeadsFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardClaimsCount = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardClaimsStart(filter));

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/claim/count', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardClaimsSuccess(response.data.data.claimCount, response.data.data.totalClaimCount, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardClaimsFail(err.response.data.error));
                else
                    dispatch(getDashboardClaimsFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardRevinueCount = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardRevinueStart(filter));

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/revenue', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardRevinueSuccess(response.data.data.filterRevenue, response.data.data.totalRevenue, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardRevinueFail(err.response.data.error));
                else
                    dispatch(getDashboardRevinueFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardDealersCount = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardDealersStart(filter));

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/dealer/count', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardDealersSuccess(response.data.data.activeDealerCount, response.data.data.totalDealerCount, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardDealersFail(err.response.data.error));
                else
                    dispatch(getDashboardDealersFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardPoliciesLinegraphData = (filter) => {
    return dispatch => {
        dispatch(getDashboardPoliciesLinegraphStart(filter));

        let params = {};
        AxiosReactClient.getRequest('dashboard/policy/lineGraph', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardPoliciesLinegraphSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardPoliciesLinegraphFail(err.response.data.error));
                else
                    dispatch(getDashboardPoliciesLinegraphFail({ code: 502, message: err.message }));
            });
    };
};



export const getDashboardClaimsBarChartData = (filter) => {
    return dispatch => {
        dispatch(getDashboardClaimsBarchartStart(filter));

        let params = {};
        AxiosReactClient.getRequest('dashboard/claim/barGraph', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardClaimsBarchartSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardClaimsBarchartFail(err.response.data.error));
                else
                    dispatch(getDashboardClaimsBarchartFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardProductListData = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardProductListStart(filter));
        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/product/list', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardProductListSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardProductListFail(err.response.data.error));
                else
                    dispatch(getDashboardProductListFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardDealerListData = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardDealerListStart(filter));
        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/dealer/list', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardDealerListSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardDealerListFail(err.response.data.error));
                else
                    dispatch(getDashboardDealerListFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardClaimsPieChartData = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardClaimsPiechartStart(filter));

        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/claim/statusGraph', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardClaimsPiechartSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardClaimsPiechartFail(err.response.data.error));
                else
                    dispatch(getDashboardClaimsPiechartFail({ code: 502, message: err.message }));
            });
    };
};

export const getDashboardCombinedPoliciesLinegraphData = (filter) => {
    return dispatch => {
        dispatch(getDashboardCombinedPoliciesLinegraphStart(filter));

        let params = {};
        AxiosReactClient.getRequest('dashboard/product/sales', params).then(response => {
            // console.log(response.data,filter);
            dispatch(getDashboardCombinedPoliciesLinegraphSuccess(response.data.data, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardCombinedPoliciesLinegraphFail(err.response.data.error));
                else
                    dispatch(getDashboardCombinedPoliciesLinegraphFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardProductListRevenueData = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardProductListRevenueStart(filter));
        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('dashboard/product/revenue', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardProductListRevenueSuccess(response.data.data.productList, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardProductListRevenueFail(err.response.data.error));
                else
                    dispatch(getDashboardProductListRevenueFail({ code: 502, message: err.message }));
            });
    };
};
export const getDashboardClaimPartnerViewData = (filter, dateParams) => {
    return dispatch => {
        dispatch(getDashboardClaimPartnerViewStart(filter));
        let params = { date: filter };
        if (filter === "all") {
            params = {};
        } else if (filter === 'custom') {
            params = { ...params, ...dateParams }
        }
        AxiosReactClient.getRequest('', params).then(response => {
            // console.log(response.data);
            dispatch(getDashboardClaimPartnerViewSuccess(response.data.data.productList, filter));
        })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getDashboardClaimPartnerViewFail(err.response.data.error));
                else
                    dispatch(getDashboardClaimPartnerViewFail({ code: 502, message: err.message }));
            });
    };
};