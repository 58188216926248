export const initialVehicleMasterFormState = {
  cubic_capacity: '',
  ex_showroom_price: '',
  fuel: '',
  hasError: false,
  id: '',
  make: '',
  model: '',
  no_of_wheels: '',
  partner_id: 41,
  product_id: '',
  carrying_capacity: '',
  vehicle_code: '',
  variant: '',
  make_code: '',
  model_code: '',
  variant_code: ''
};

export const errorMessageVehicleMaster = {
  cubic_capacity: '',
  ex_showroom_price: '',
  fuel: '',
  make: '',
  model: '',
  no_of_wheels: '',
  partner_id: '',
  product_id: '',
  carrying_capacity: '',
  vehicle_code: '',
  variant: '',
  make_code: '',
  model_code: '',
  variant_code: ''
};

export const touchFieldsVehicleMaster = {
  cubic_capacity: false,
  ex_showroom_price: false,
  fuel: false,
  make: false,
  model: false,
  no_of_wheels: false,
  partner_id: false,
  product_id: false,
  carrying_capacity: false,
  vehicle_code: false,
  variant: false,
  make_code: false,
  model_code: false,
  variant_code: false
};

export const initialVehicleMasterFilterState = {
  search_vehicle_code: '', 
  selection: 'add',
  product: '',
  result: []
};

export const options = {
  fuel: {
    61: [
      {title: "Battery", value: "B"},
      {title: "CNG", value: "C"},
      {title: "Diesel", value: "D"},
      {title: "Petrol", value: "P"}
    ],
    64: [
      {title: "Battery", value: "Battery"},
      {title: "CNG", value: "CNG"},
      {title: "Diesel", value: "Diesel"},
      {title: "Electric", value: "Electric"},
      {title: "LPG", value: "LPG"},
      {title: "Petrol", value: "Petrol"}
    ],
    66: [
      {title: "CNG", value: "C"},
      {title: "Diesel", value: "D"},
      {title: "Electric", value: "E"},
      {title: "LPG", value: "L"},
      {title: "Petrol", value: "P"}
    ]
  },
  carryingCapacity: [
    {title: "2 Persons", value: 2},
    {title: "3 Persons", value: 3},
    {title: "4 Persons", value: 4},
    {title: "5 Persons", value: 5},
    {title: "6 Persons", value: 6},
    {title: "7 Persons", value: 7},
    {title: "8 Persons", value: 8},
    {title: "9 Persons", value: 9},
    {title: "10 Persons", value: 10}
  ],
  noOfWheels: [
    {title: 2, value: 2},
    {title: 3, value: 3},
    {title: 4, value: 4}
  ],
  partners: [
    {title: "Motor Product", value: 41},
  ]
};

export const mapping = {
  no_of_wheels: {
    "TWO": 2,
    "THREE": 3,
    "FOUR": 4
  }
};