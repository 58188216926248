import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
import "./assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

import App from "./App";
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './Store/reducers'
import thunk from 'redux-thunk';
import Notify from "./components/CustomUi/Notify/Notify";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));
const app = (
  <Provider store={store}>
      <BrowserRouter>
          <App />
          <Notify />
      </BrowserRouter>
  </Provider>
);
ReactDOM.render(app,document.getElementById("root"));
