import Dealers from "./Dealers.jsx";
import DealerPolicies from "./Policies.jsx";
import Policy from "./Policy";
import * as permissions from '../../../config/Casl/permissions';
import dealerView from "./DealerView.jsx";
import AddAgent from "./AddAgent";

const dealerRoute = {
    collapse: true,
    name: "Dealers",
    icon: "ni ni-badge text-primary",
    state: "dealersCollapse",
    permission: permissions.DEALER.menu,
    subject: permissions.DEALER.subject,
    views: [
        {
            path: "/dealers/addAgent",
            name: "Add Dealer",
            component: AddAgent,
            layout: "/admin",
            permission: permissions.DEALER.create,
            subject: permissions.DEALER.subject
        },
        {
            path: "/dealers/all",
            name: "All Dealers",
            component: Dealers,
            layout: "/admin",
            permission: permissions.DEALER.list,
            subject: permissions.DEALER.subject
        },
        {
            path: "/dealers/policies",
            name: "All Policies",
            component: DealerPolicies,
            layout: "/admin",
        },
        {
            path: "/dealers/:id",
            name: "Dealer View",
            component: dealerView,
            layout: "/admin",
        },
        {
            path: "/dealers/policies/:id",
            name: "Policy",
            component: Policy,
            layout: "/admin",
        }
    ]
};

export default dealerRoute;