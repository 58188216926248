import * as actionTypes from '../actions/actionTypes/dashboardTypes';
import { updateObject } from '../utility';

const initialState = {
    error: null,
    dashPoliciesLoading: false,
    dashLeadsLoading: false,
    dashClaimsLoading: false,
    dashRevinueLoading: false,
    dashDealersLoading: false,
    dashPolicyLinegraphLoading: false,
    dashClaimBarchartLoading: false,
    dashProductListLoading: false,
    dashDealerListLoading: false,
    dashClaimPiechartLoading: false,
    dashCombinedPolicyLinegraphLoading: false,
    dashProductListRevenueLoading: false,
    dashClaimPartnerViewLoading: false,

    dashPoliciesCount: 0,
    dashPoliciesTotalCount: 0,
    dashLeadsCount: 0,
    dashLeadsTotalCount: 0,
    dashClaimsCount: 0,
    dashClaimsTotalCount: 0,
    dashRevinueCount: 0,
    dashRevinueTotalCount: 0,
    dashDealersCount: 0,
    dashDealersTotalCount: 0,
    dashPolicyLinegraphData: {},
    dashClaimBarchartData: {},
    dashProductListData: {},
    dashDealerListData: {},
    dashClaimPiechartData: {},
    dashCombinedPolicyLinegraphData: {},

    dashProductListRevenueData: null,
    dashClaimPartnerViewData: null,

    dashPoliciesFilter: 'today',
    dashLeadsFilter: 'today',
    dashClaimsFilter: 'all',
    dashRevinueFilter: 'today',
    dashDealersFilter: 'today',
    dashPoliciesLinegraphFilter: 'month',
    dashClaimsBarchartFilter: 'month',
    dashProductListFilter: 'today',
    dashDealerListFilter: 'today',
    dashClaimsPiechartFilter: 'all',
    dashCombinedPoliciesLinegraphFilter: 'month',
    dashProductListRevenueFilter: 'all',
    dashClaimPartnerViewFilter: 'all',
};

//  START 
const dashPoliciesStart = (state, action) => {
    return updateObject(state, { error: null, dashPoliciesLoading: true });
};
const dashLeadsStart = (state, action) => {
    return updateObject(state, { error: null, dashLeadsLoading: true });
};
const dashClaimsStart = (state, action) => {
    return updateObject(state, { error: null, dashClaimsLoading: true });
};
const dashRevinueStart = (state, action) => {
    return updateObject(state, { error: null, dashRevinueLoading: true });
};
const dashDealersStart = (state, action) => {
    return updateObject(state, { error: null, dashDealersLoading: true });
};
const dashPolicyLinegraphStart = (state, action) => {
    return updateObject(state, { error: null, dashPolicyLinegraphLoading: true });
};
const dashClaimBarchartStart = (state, action) => {
    return updateObject(state, { error: null, dashClaimBarchartLoading: true });
};
const dashProductListStart = (state, action) => {
    return updateObject(state, { error: null, dashProductListLoading: true });
};
const dashDealerListStart = (state, action) => {
    return updateObject(state, { error: null, dashDealerListLoading: true });
};
const dashClaimPiechartStart = (state, action) => {
    return updateObject(state, { error: null, dashClaimPiechartLoading: true });
};
const dashCombinedPolicyLinegraphStart = (state, action) => {
    return updateObject(state, {error: null, dashCombinedPolicyLinegraphLoading: true});
};
const dashProductListRevenueStart = (state, action) => {
    return updateObject(state, { error: null, dashProductListRevenueLoading: true });
};
const dashClaimPartnerViewStart = (state, action) => {
    return updateObject(state, { error: null, dashClaimPartnerViewLoading: true });
};



//  SUCCESS 

const dashPoliciesSuccess = (state, action) => {
    return updateObject(state, {
        dashPoliciesFilter: action.filter,
        dashPoliciesCount: action.count,
        dashPoliciesTotalCount: action.total,
        error: null,
        dashPoliciesLoading: false
    });
};
const dashLeadsSuccess = (state, action) => {
    return updateObject(state, {
        dashLeadsFilter: action.filter,
        dashLeadsCount: action.count,
        dashLeadsTotalCount: action.total,
        error: null,
        dashLeadsLoading: false
    });
};
const dashClaimsSuccess = (state, action) => {
    return updateObject(state, {
        dashClaimsFilter: action.filter,
        dashClaimsCount: action.count,
        dashClaimsTotalCount: action.total,
        error: null,
        dashClaimsLoading: false
    });
};
const dashRevinueSuccess = (state, action) => {
    return updateObject(state, {
        dashRevinueFilter: action.filter,
        dashRevinueCount: action.count,
        dashRevinueTotalCount: action.total,
        error: null,
        dashRevinueLoading: false
    });
};
const dashDealersSuccess = (state, action) => {
    return updateObject(state, {
        dashDealersFilter: action.filter,
        dashDealersCount: action.count,
        dashDealersTotalCount: action.total,
        error: null,
        dashDealersLoading: false
    });
};
const dashPoliciesLinegraphSuccess = (state, action) => {
    return updateObject(state, {
        dashPoliciesLinegraphFilter: action.filter,
        dashPolicyLinegraphData: action.data,
        error: null,
        dashPoliciesLinegraphLoading: false
    });
};
const dashClaimsBarchartSuccess = (state, action) => {
    return updateObject(state, {
        dashClaimsBarchartFilter: action.filter,
        dashClaimBarchartData: action.data,
        error: null,
        dashClaimsBarchartLoading: false
    });
};
const dashProductListSuccess = (state, action) => {
    return updateObject(state, {
        dashProductListFilter: action.filter,
        dashProductListData: action.data,
        error: null,
        dashProductListLoading: false
    });
};
const dashDealerListSuccess = (state, action) => {
    return updateObject(state, {
        dashDealerListFilter: action.filter,
        dashDealerListData: action.data,
        error: null,
        dashDealerListLoading: false
    });
};
const dashClaimsPiechartSuccess = (state, action) => {
    return updateObject(state, {
        dashClaimsPiechartFilter: action.filter,
        dashClaimPiechartData: action.data,
        error: null,
        dashClaimPiechartLoading: false
    });
};
const dashCombinedPoliciesLinegraphSuccess = (state, action) => {
    return updateObject(state, {
        dashCombinedPoliciesLinegraphFilter: action.filter,
        dashCombinedPolicyLinegraphData: action.data,
        error: null,
        dashCombinedPolicyLinegraphLoading: false
    });
};
const dashProductListRevenueSuccess = (state, action) => {
    return updateObject(state, {
        dashProductListRevenueFilter: action.filter,
        dashProductListRevenueData: action.data,
        error: null,
        dashProductListRevenueLoading: false
    });
};
const dashClaimPartnerViewSuccess = (state, action) => {
    return updateObject(state, {
        dashClaimPartnerViewFilter: action.filter,
        dashClaimPartnerViewData: action.data,
        error: null,
        dashClaimPartnerViewLoading: false
    });
};


//  FAIL 

const dashPoliciesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashPoliciesLoading: false
    });
};
const dashLeadsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashLeadsLoading: false
    });
};
const dashClaimsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashClaimsLoading: false
    });
};
const dashRevinueFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashRevinueLoading: false
    });
};
const dashDealersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashDealersLoading: false
    });
};

const dashPoliciesLinegraphFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashPoliciesLinegraphLoading: false
    });
};

const dashClaimsBarchartFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashClaimsBarchartLoading: false
    });
};
const dashProductListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashProductListLoading: false
    });
};
const dashDealerListFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashDealerListLoading: false
    });
};
const dashClaimsPiechartFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashClaimPiechartLoading: false
    });
};
const dashCombinedPoliciesLinegraphFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashCombinedPolicyLinegraphLoading: false
    });
};
const dashProductListRevenueFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashProductListRevenueLoading: false
    });
};
const dashClaimPartnerViewFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        dashClaimPartnerViewLoading: false
    });
};




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_POLICIES_START: return dashPoliciesStart(state, action);
        case actionTypes.GET_DASHBOARD_LEADS_START: return dashLeadsStart(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_START: return dashClaimsStart(state, action);
        case actionTypes.GET_DASHBOARD_REVINUE_START: return dashRevinueStart(state, action);
        case actionTypes.GET_DASHBOARD_DEALERS_START: return dashDealersStart(state, action);
        case actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_START: return dashPolicyLinegraphStart(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_START: return dashClaimBarchartStart(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_START: return dashProductListStart(state, action);
        case actionTypes.GET_DASHBOARD_DEALER_LIST_START: return dashDealerListStart(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_START: return dashClaimPiechartStart(state, action);
        case actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_START: return dashCombinedPolicyLinegraphStart(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_START: return dashProductListRevenueStart(state, action);
        case actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_START: return dashClaimPartnerViewStart(state, action);

        case actionTypes.GET_DASHBOARD_POLICIES_SUCCESS: return dashPoliciesSuccess(state, action);
        case actionTypes.GET_DASHBOARD_LEADS_SUCCESS: return dashLeadsSuccess(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_SUCCESS: return dashClaimsSuccess(state, action);
        case actionTypes.GET_DASHBOARD_REVINUE_SUCCESS: return dashRevinueSuccess(state, action);
        case actionTypes.GET_DASHBOARD_DEALERS_SUCCESS: return dashDealersSuccess(state, action);
        case actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_SUCCESS: return dashPoliciesLinegraphSuccess(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_SUCCESS: return dashClaimsBarchartSuccess(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_SUCCESS: return dashProductListSuccess(state, action);
        case actionTypes.GET_DASHBOARD_DEALER_LIST_SUCCESS: return dashDealerListSuccess(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_SUCCESS: return dashClaimsPiechartSuccess(state, action);
        case actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_SUCCESS: return dashCombinedPoliciesLinegraphSuccess(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_SUCCESS: return dashProductListRevenueSuccess(state, action);
        case actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_SUCCESS: return dashClaimPartnerViewSuccess(state, action);

        case actionTypes.GET_DASHBOARD_POLICIES_FAIL: return dashPoliciesFail(state, action);
        case actionTypes.GET_DASHBOARD_LEADS_FAIL: return dashLeadsFail(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_FAIL: return dashClaimsFail(state, action);
        case actionTypes.GET_DASHBOARD_REVINUE_FAIL: return dashRevinueFail(state, action);
        case actionTypes.GET_DASHBOARD_DEALERS_FAIL: return dashDealersFail(state, action);
        case actionTypes.GET_DASHBOARD_POLICIES_LINEGRAPH_FAIL: return dashPoliciesLinegraphFail(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_BARCHART_FAIL: return dashClaimsBarchartFail(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_FAIL: return dashProductListFail(state, action);
        case actionTypes.GET_DASHBOARD_DEALER_LIST_FAIL: return dashDealerListFail(state, action);
        case actionTypes.GET_DASHBOARD_CLAIMS_PIECHART_FAIL: return dashClaimsPiechartFail(state, action);
        case actionTypes.GET_DASHBOARD_COMBINED_POLICIES_LINEGRAPH_FAIL: return dashCombinedPoliciesLinegraphFail(state, action);
        case actionTypes.GET_DASHBOARD_PRODUCT_LIST_REVENUE_FAIL: return dashProductListRevenueFail(state, action);
        case actionTypes.GET_DASHBOARD_CLAIM_PARTNER_VIEW_FAIL: return dashClaimPartnerViewFail(state, action);
        default:
            return state;
    }
};

export default reducer;