import moment from "moment";
import { Utils } from "../constants/utils";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

export const compareValues = (key, order = "asc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  };
};

// Convert date format to string
export const convertDateToString = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

// Download document/file
export const downloadFile = (url) => {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = extractFileName(url);
  link.dispatchEvent(new MouseEvent("click"));
};

// Validate input field length
export const validataInputLength = (event) => {
  let elName = event.target.name;
  let elValue = event.target.value;

  if (elValue.length > Utils.limit.digits[elName]) {
    event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
  }
};

export const convertToArray = (list) => {
  let array = [];

  list.map((item) => {
    array.push(item.url);
  });

  return array;
};

// Convert url to base64 encoded data
const convertUrlToBase64EncodedData = async (url) => {
  // Fetch the url and parse the response stream as a blob
  const blobData = await fetch(url).then((response) => response.blob());

  // create a new file from the blob object
  return new File([blobData], "filename.jpg");
};

// Extract filename from url
export const extractFileName = (filePath) => {
  return filePath ? filePath.replace(/^.*[\\\/]/, "") : null;
};

// Download multiple files as zip file,
export const downloadZipFile = async (
  urls,
  zipFileName = "policy-documents.zip"
) => {
  const zip = new JSZip();
  let files = [];

  urls.map((url) => {
    files.push({
      dataUrl: convertUrlToBase64EncodedData(url),
      filename: extractFileName(url),
    });
  });

  files.map((item) => {
    zip.file(item.filename, item.dataUrl, { base64: true });
  });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, zipFileName);
  });
};

export const downloadZipFileExample = async () => {
  const sampleUrl =
    "https://i.picsum.photos/id/358/200/300.jpg?hmac=-67HJ1NCrWtZ_ZrX9NaG90kmRdhaN-tkC3A1EoGev3A";

  // Fetch the image and parse the response stream as a blob
  const imageBlob = await fetch(sampleUrl).then((response) => response.blob());

  // create a new file from the blob object
  const imgData = new File([imageBlob], "filename.jpg");

  // Copy-pasted from JSZip documentation
  var zip = new JSZip();

  var img = zip.folder("images");
  img.file("smile1.gif", imgData, { base64: true });
  img.file("smile2.gif", imgData, { base64: true });

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "other_polices.zip");
  });
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const parseMessage = (message) => {
  if (typeof message === "string") {
    return message;
  } else if (Array.isArray(message) && message.length) {
    let errors = [];

    const output = message.map((item) => [...errors, item.msg]);

    return output.join(", ");
  } else {
    let errors = [];

    for (const [key, value] of Object.entries(message)) {
      errors.push(value);
    }

    return errors;
  }
};

export const notify = (message) => {
  toast.success(message);
};

export const notifyError = (message) => {
  toast.error(message);
};

export const redirectWithBlank = (url) => {
  var a = document.createElement("a");
  a.target = "_blank";
  a.href = url;
  a.click();
};

export const redirectWithImage = (url) => {
  let data = `${url}`;
  let w = window.open("about:blank");
  let image = new Image();
  image.src = data;
  setTimeout(function () {
    w.document.write(image.outerHTML);
  }, 0);
};

export const convertDateToYYYYMMDD = (dateString) => {
  if (dateString === "Invalid date") {
    return dateString;
  }

  let convertedDate = convertDateToString(dateString);

  if (convertedDate !== dateString) {
    const dateArray = dateString.split("-");

    convertedDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }

  return convertedDate;
};

export const getUtf8Bytes = (str) => {
  // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
  // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
  var utf8Bytes = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    function (match, p1) {
      return String.fromCharCode("0x" + p1);
    }
  );

  return btoa(utf8Bytes);
};

export const downloadPDF = (data) => {
  var byteCharacters = atob(getUtf8Bytes(data));
  var byteNumbers = new Array(byteCharacters.length);

  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  var fileURL = URL.createObjectURL(file);

  window.open(fileURL);
};

export const validateEmail = (inputText) => {
  var mailformat =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (mailformat.test(inputText)) {
    return true;
  } else {
    return false;
  }
};

export const renderAgentCurrentStatus = ({
  is_active = false,
  is_active_for_sell = null,
  general_exam_result = null,
  profile_created_at = null,
} = {}) => {
  let status = "Registered";
  let className = "badge-secondary";

  if (!is_active) {
    status = "Inactive";
    className = "badge-danger";
  } else if (general_exam_result) {
    if (is_active_for_sell) {
      status = "Active";
      className = "badge-success";
    } else {
      status = "Approval Pending";
      className = "badge-info";
    }
  } else if (profile_created_at) {
    status = "KYC Done";
    className = "badge-primary";
  }

  return {
    status,
    className,
    slug: status.replaceAll(" ", "_").toLowerCase(),
  };
};

export const renderAgentNextActionItem = ({
  is_active = false,
  is_active_for_sell = null,
  general_exam_result,
  profile_created_at,
}) => {
  let text = "Complete KYC";

  if (!is_active && general_exam_result) {
    text = "Make Active";
  } else if (is_active && general_exam_result) {
    text = is_active_for_sell ? "Make Inactive" : "Approve";
  } else if (!general_exam_result && profile_created_at) {
    text = "Take Exam";
  }

  return {
    text,
    slug: text.replaceAll(" ", "_").toLowerCase(),
  };
};

export const isAgentExamSubmitButtonDisabled = (questions) => {
  let attemptedQuestionCount = 0;

  questions.map((item) => {
    item.question_choices.map((choice) => {
      if (choice.checked) {
        ++attemptedQuestionCount;
      }
    });
  });

  return questions.length !== attemptedQuestionCount;
};

export const buildSubmitAgentExamPayload = (questions) => {
  let payload = {
    exam_type_id: 1,
    selected_questions: [],
  };

  questions.map((question) => {
    let object = {
      selected_question_id: question.id,
      selected_choice_id: "",
    };

    question.question_choices.map((choice) => {
      if (choice.checked) {
        object.selected_choice_id = choice.id;
      }
    });

    payload.selected_questions.push(object);
  });

  return payload;
};

export const reBuildAgentList = (list, selectedStatus = null) => {
  list.map((item) => {
    let current = renderAgentCurrentStatus(item);
    item.status_text = current.status;
    item.staus_slug = current.slug;
    item.class_name = current.className;

    let next = renderAgentNextActionItem(item);
    item.next_action_item = next.text;
    item.next_action_item_slug = next.slug;
    item.next_action_item_class_name = next.className;
  });

  return list;
};

export const reBuildQuestionList = (questions) => {
  questions.map((item) => {
    item.question_choices.map((choice) => {
      choice.checked = false;
    });
  });

  return questions;
};

export const buildDocumentTypeList = () => {
  return [
    {
      document_type_id: 25,
      name: "Aadhaar Front Side",
      slug: "DOCUMENT_AADHAR_FRONT",
      filename: "aadhaarFront",
    },
    {
      document_type_id: 26,
      name: "Aadhaar Back Side",
      slug: "DOCUMENT_AADHAR_BACK",
      filename: "aadhaarBack",
    },
    {
      document_type_id: 59,
      name: "PAN Card",
      slug: "DOCUMENT_PAN",
      filename: "pancard",
    },
    {
      document_type_id: 75,
      name: "Education Qualification",
      slug: "DOCUMENT_EDUCATION_QUALIFICATION",
      filename: "qualification",
    },
    {
      document_type_id: 24,
      name: "Bank Cancelled Cheque",
      slug: "DOCUMENT_BANK_DETAILS_PROOF",
      filename: "bank",
    },
    {
      document_type_id: 76,
      name: "General Exam Certificate",
      slug: "DOCUMENT_GENERAL_EXAM_CERTIFICATE",
      filename: "certificate",
    },
    {
      document_type_id: 78,
      name: "Agreement",
      slug: "DOCUMENT_AGREEMENT_CERTIFICATE",
      filename: "agreement",
      visible: false,
    },
  ];
};

export const toBase64 = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const checkDocumentExistense = (documents) => {
  const object = {};

  documents.map((item) => {
    object[item.document_type_id] = true;
  });

  return object;
};

export const syncDocumentTypeList = (list, document) => {
  let filteredList = [];

  list.map((item) => {
    if (!document[item.document_type_id]) {
      filteredList.push(item);
    }
  });

  return filteredList;
};

export const buildSystemErrorMessage = () => {
  return {
    data: {
      error_msg:
        "It looks like something went wrong. Please try again in a while.",
    },
  };
};