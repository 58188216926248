import PolicyView from "./PolicyView";
import PartnerPolicies from "./Policies";
import Reports from "./Reports";
import PartnerClaims from "./Claims";
import PartnerDealersListing from "./Dealers";
import PartnerFinance from "./Finance";
import PartnerListing from "./Listing";
import AddPartner from "./AddPartner";
import * as permissions from '../../../config/Casl/permissions';
import AddPartnerDashboard from "./AddPartnerDashboard";
import AddEditVehicleMaster from "./AddEditVehicleMaster";
import OemMapping from "./OemMapping";

const partnerRoute = {
    collapse: true,
    name: "Partners",
    icon: "ni ni-user-run text-primary",
    state: "partnersCollapse",
    permission: permissions.PARTNERS.menu,
    subject: permissions.PARTNERS.subject,
    // invisible: true,
    views: [{
        path: "/partners/policies/:id",
        name: "Policy",
        component: PolicyView,
        layout: "/admin",
        invisible: true,
    },
    {
        path: "/partners/policies",
        name: "Policies",
        component: PartnerPolicies,
        layout: "/admin",
        permission: permissions.PARTNERS.policy_list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/reports",
        name: "Reports",
        component: Reports,
        layout: "/admin",
        invisible: true
    },
    // {
    //     path: "/partners/claims/:id",
    //     name: "Claim",
    //     component: ClaimView,
    //     layout: "/admin",
    //     invisible: true
    // },
    {
        path: "/partners/claims",
        name: "Claims",
        component: PartnerClaims,
        layout: "/admin",
        permission: permissions.PARTNERS.claim_list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/dealers",
        name: "Dealers",
        component: PartnerDealersListing,
        layout: "/admin",
        permission: permissions.PARTNERS.dealer_list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/finance",
        name: "Finance",
        component: PartnerFinance,
        layout: "/admin",
        permission: permissions.PARTNERS.finance_list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/listing",
        name: "Listing",
        component: PartnerListing,
        layout: "/admin",
        permission: permissions.PARTNERS.list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/addPartner",
        name: "Onboard Partner",
        component: AddPartner,
        layout: "/admin",
        permission: permissions.PARTNERS.list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/addPartnerDashboard",
        name: "Add Partner Dashboard",
        component: AddPartnerDashboard,
        layout: "/admin",
        permission: permissions.PARTNERS.list,
        subject: permissions.PARTNERS.subject
    },
    {
        path: "/partners/add-edit-vehicle-master",
        name: "Add/Update Vehicle Master",
        component: AddEditVehicleMaster,
        layout: "/admin",
        permission: permissions.VEHICLES.master_store,
        subject: permissions.VEHICLES.subject
    },
    {
        path: "/partners/oem-mapping",
        name: "Partner OEM Mapping",
        component: OemMapping,
        layout: "/admin",
        permission: permissions.PARTNERS.list,
        subject: permissions.PARTNERS.subject
    }]
};

export default partnerRoute;