import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../Store/actions';
import AxiosRestClient from '../../utilities/AxiosRestClient';
import ReactDatetime from "react-datetime";
import Shimmer from '../../components/CustomUi/Shimmer/Shimmer';
import Spinner from "../../components/Spinner/Spinner";
import CustomDropdown from '../Filters/CustomDropdown';
import Can from '../../config/Casl/Can';
import * as permissions from '../../config/Casl/permissions';
import {
    Card,
    Modal,
    CardBody,
    Badge,
    Row,
    Col,
    Form,
    FormGroup,
    Button,
    Pagination,
    PaginationItem,
    CardFooter,
    PaginationLink,
    Table,
} from 'reactstrap';
import moment from 'moment';

class PromoterPayout extends Component {
    reactDatetimeRef;
    previousMonthDate = new Date(new Date().getFullYear(), new Date().getMonth());
    constructor(props) {
        super(props)
        this.state = {
            selectedPromoterId: null,
            view: "search-table",
            searchText: "",
            openDateDialog: false,
            openMonthDialog: false,
            showPromoterSinglePaymentDialog: false,
            showMultiplePromoterPayoutModel: false,
            hideProductDropdown: true,
            singlePayoutLoader: false,
            bulkPayoutLoader: false,
            statusFilter: '',
            exposureFilter: '',
            promoterModal: false,
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            selectedDate: this.previousMonthDate,
            selectedPromoterForSinglePayout: null,
            exposureFilters: [{ name: 'Yes', id: 'yes' }, { name: 'No', id: 'no' }]
        }
        this.reactDatetimeRef = React.createRef();
        this.productFilter = React.createRef();
        props.onGetPayoutProductListData()
        props.onGetPayoutStatusListData({})
        this.getPromoterPayout();
    }
    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
        productId: '',
    }
    getPromoterPayout = () => {
        this.props.onGetPayoutListData({ month: this.state.selectedDate.getMonth() + 1, year: this.state.selectedDate.getFullYear(), start: this.pager.pagerStart, product: this.pager.productId, status: this.state.statusFilter, exposure: this.state.exposureFilter, type: "promoter", search: this.state.searchText })
    }
    initiateSinglePayment = (payout_id) => {
        this.setState({
            singlePayoutLoader: true
        })
        AxiosRestClient.postRequest('finance/payout/initiate/' + payout_id, {}).then(response => {
            this.showMsg(response.data.data[0], "success")
            this.setState({
                showPromoterSinglePaymentDialog: false,
                singlePayoutLoader: false
            })
            this.getPromoterPayout();
        })
            .catch(err => {
                this.setState({
                    singlePayoutLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                }
                else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    initiateBulkPayment = () => {
        this.setState({
            bulkPayoutLoader: true
        })
        AxiosRestClient.postRequest('finance/payout/bulkInitiate', { month: new Date(this.state.selectedDate).getMonth() + 1, year: new Date(this.state.selectedDate).getFullYear(), type: "promoter" }).then(response => {
            this.showMsg(response.data.data[0], "success")
            this.setState({
                showMultiplePromoterPayoutModel: false,
                bulkPayoutLoader: false
            })
            this.getPromoterPayout();
        })
            .catch(err => {
                this.setState({
                    bulkPayoutLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                }
                else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    searchPromoter = () => {
        if (this.state.searchText.length < 3) {
            this.showMsg("Please enter min 3 characters...", "danger")
            return
        }
        this.getPromoterPayout();
        this.setState({
            view: "search"
        })
    }
    openPromoterDialog = (promoter_id) => {
        this.setState({
            promoterModal: true,
            selectedPromoterId: promoter_id
        }, () => { this.props.onGetPayoutHistoryData(promoter_id) })
    }
    getMonthYear = (date) => {
        return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
    }
    handleReactDatetimeChange(e) {
        this.pager.pagerStart = 0;
        this.setState({
            selectedDate: e._d
        }, () => {
            this.getPromoterPayout();
        })
    }
    validDateFilter = (current) => {
        return (current.isAfter(new Date("Tue May 01 2018 11:40:44 GMT+0530 (India Standard Time)")) && (moment(moment(current).format('YYYY-MM-DD 23:59:59')).isSameOrBefore(moment(new Date()).format('YYYY-MM-DD 23:59:59'))));
    };
    updatePagination = (total) => {
        this.pager.pageArray = [];
        this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
        for (let index = 0; index < this.pager.pagerEnd; index++) {
            this.pager.pageArray.push(index + 1)
        }
    }
    switchPage = (page) => {
        if (((this.pager.pagerStart + 1) !== page)) {
            this.pager.pagerStart = page - 1
            this.getPromoterPayout();
        }
    }
    productChanged = (productId) => {
        this.pager.pagerStart = 0;
        this.pager.productId = productId;
        this.getPromoterPayout();
    }
    statusChanged = (status) => {
        this.setState({
            statusFilter: status
        }, () => {
            this.getPromoterPayout();
        })
        this.pager.pagerStart = 0;
    }
    exposureChanged = (exposure) => {
        this.setState({
            exposureFilter: exposure
        }, () => {
            this.getPromoterPayout();
        })
        this.pager.pagerStart = 0;
    }
    isHidden = (page) => {
        var start = this.pager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
                return true;
            } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside = (e) => {
        let classArray = [];
        if (e.path) {
            e.path.forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        } else {
            e.composedPath().forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        }
        if (!classArray.includes('reactDateTimeCustomClass')) {
            this.setState({
                openDateDialog: false,
                openMonthDialog: false
            })
        }
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    refreshFilters = () => {
        this.pager.pagerStart = 0
        this.pager.productId = ''
        this.setState({
            selectedDate: this.previousMonthDate,
            statusFilter: '',
            exposureFilter: '',
            searchText: '',
        }, () => {
            this.getPromoterPayout();
        })
    }
    render() {
        (this.props.promoterTotalCount !== null) && this.updatePagination(this.props.promoterTotalCount)
        return (<>
            <Fragment>
                <div className="mt-2">
                    <Row className="bg-white m-0 py-2" >
                        <Col className="p-1 px-3 text-muted" lg="4" sm="12">
                            <small>
                                <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                                    <input className="ml-2 bg-white border-0 pr-2 text-muted"
                                        value={this.state.searchText}

                                        onKeyDown={e => {
                                            (e.key === 'Enter') ? this.searchPromoter() : this.setState({
                                                searchText: e.target.value
                                            })
                                        }}
                                        onChange={e => {
                                            if (e.target.value === "") {
                                                this.setState({
                                                    view: "search-table"
                                                })
                                            }
                                            this.setState({
                                                searchText: e.target.value
                                            })
                                        }}
                                        type="text" placeholder="Search" aria-label="Search" />
                                    <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                        onClick={
                                            e => {
                                                e.preventDefault()
                                                this.searchPromoter()
                                            }}
                                    />

                                </form>
                            </small>

                            {(this.state.searchText !== "") && (this.state.view === "search") && <Badge color="primary" pill>
                                {this.state.searchText}
                                <span className="p-1 ml-2 cursor-pointer" onClick={
                                    e => {
                                        e.preventDefault()
                                        this.setState({
                                            view: "search-table",
                                            searchText: ""
                                        }, () => {
                                            this.getPromoterPayout();
                                        })
                                    }} >
                                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                                </span>
                            </Badge>}
                        </Col>
                        <Col className="p-1 text-right px-3" lg="8" sm="12">
                            <form className="form-inline md-form d-inline filter-form" action="javascript:void(-1)">
                                <div className="d-inline  cursor-pointer" onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                        openDateDialog: true,
                                        hideProductDropdown: true,
                                    })
                                }}>
                                    <img
                                        className="icon-17"
                                        src={require("../../assets/img/icons/calender.svg")} alt="" />
                                    <span className="text-muted ml-1 span-12 ">{this.getMonthYear(this.state.selectedDate)}

                                        <i className="fa fa-angle-down ml-1" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <ReactDatetime
                                    ref={this.reactDatetimeRef}
                                    className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                                    open={this.state.openDateDialog}
                                    onFocus={e => {
                                        this.setState({
                                            openDateDialog: true
                                        })
                                    }}
                                    onBlur={e => {
                                        this.setState({
                                            openDateDialog: false
                                        })
                                    }}
                                    disableCloseOnClickOutside={false}
                                    inputProps={{
                                        placeholder: "Month"
                                    }}
                                    value={this.state.selectedDate}
                                    dateFormat="MMM-YYYY"
                                    timeFormat={false}
                                    onChange={e => {
                                        this.handleReactDatetimeChange(e)
                                        this.setState({
                                            openDateDialog: false
                                        })
                                    }}
                                    isValidDate={this.validDateFilter}
                                />
                            </form>
                            {this.props.promoterProductList &&
                                <Can I={permissions.FINANCE.payout_products} a={permissions.FINANCE.subject}>
                                    <CustomDropdown name="Product" selectedId={this.pager.productId} handleSelection={id => { this.productChanged(id) }} options={this.props.promoterProductList.map(({ id, name, type, insurance_provider }) => { return { id: id, name: name + ' (' + type + ')' + ' ' + insurance_provider } })} />
                                </Can>
                            }
                            <Can I={permissions.FINANCE.payout_status} a={permissions.FINANCE.subject}>
                                <CustomDropdown name="Status" selectedId={this.state.statusFilter} handleSelection={status => { this.statusChanged(status) }} options={this.props.payoutStatusListData} />
                            </Can>
                            <CustomDropdown name="Exposure" selectedId={this.state.exposureFilter} handleSelection={exp => { this.exposureChanged(exp) }} options={this.state.exposureFilters} />
                            <Button color="secondary " outline size="sm" type="button" onClick={e => {
                                e.preventDefault()
                                this.refreshFilters()
                            }}>
                                Clear
                            </Button>
                            <Can I={permissions.FINANCE.payout_bulkInitiate} a={permissions.FINANCE.subject}>
                                <Button color="success" size="sm" type="button" onClick={e => {
                                    e.preventDefault()
                                    this.setState({
                                        showMultiplePromoterPayoutModel: true
                                    })
                                }}>
                                    Bulk Payout
                                </Button>
                            </Can>
                        </Col>
                    </Row>
                </div>
                <Card>
                    <CardBody className="p-0" style={{ minHeight: "508px" }}>
                        <Table className="align-items-center table-flush" hover responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>Name</th>
                                    <th>Policies Sold</th>
                                    <th>Commission</th>
                                    <th>Dealer Exposure</th>
                                    <th>Status</th>
                                    <th>RazorPay Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ height: "466px" }}>
                                {this.props.promoterPayoutLoading && <tr >
                                    <td colSpan="4">
                                        <Spinner/>
                                    </td>
                                </tr>}
                                {!this.props.promoterPayoutLoading && this.props.promoterPayoutList && this.props.promoterPayoutList.length < 1 && <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                    <td colSpan="6" className="text-center">
                                        <span className="text-muted">
                                            <b>
                                                No Promoter payout found
                                            </b>
                                        </span>
                                    </td>
                                </tr>}
                                {!this.props.promoterPayoutLoading && this.props.promoterPayoutList && this.props.promoterPayoutList.map((promoter, index) => {
                                    return <tr key={"key_" + index} className={promoter.is_cleared ? "" : "table- warning"} >
                                        <td className="table-user">
                                            <b className="cursor-pointer" onClick={(e) => { e.preventDefault(); this.openPromoterDialog(promoter.dealer_user_id) }}>{promoter.mobile} <br /><span className="text-muted font-weight-light ">{promoter.dealership_name}</span> </b>
                                        </td>
                                        <td>
                                            <span className="text-muted">{promoter.policy_sold}</span>
                                        </td>
                                        <td>
                                            <span className="text-muted">{'₹ '+ promoter.amount}</span>
                                        </td>
                                        <td>
                                            <span className={((promoter.exposure == "YES") ? "text-danger font-weight-bold" : "text-muted ")}>{promoter.exposure}</span>
                                        </td>
                                        <td>
                                            <span className={"font-weight-600 " + (promoter.status == "Payout Failed" && "text-danger") + " " + (promoter.status == "Payout Reversed" && "text-danger") + " " + (promoter.status == "Payout Blocked" && "text-danger") + " " + (promoter.status == "Payout Cleared" && "text-success") + " " + (promoter.status == "Payout Processing" && "text-primary") + " " + (promoter.status == "Payout Pending" && "text-yellow")} >{promoter.status}</span>
                                        </td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>
                                            {promoter.status_description ? promoter.status_description : "No description"}
                                        </td>
                                        <td>
                                            <Can I={permissions.FINANCE.payout_initiate} a={permissions.FINANCE.subject}>
                                                <Button color="secondary" hidden={promoter.exposure === 'YES' || (!["Payout Reversed","Payout Pending"].includes(promoter.status)) ? true : false} outline size="sm" type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedPromoterForSinglePayout: promoter
                                                        }, () => {
                                                            this.setState({
                                                                showPromoterSinglePaymentDialog: true,
                                                            })
                                                        })
                                                    }}>
                                                    PAY
                                                </Button>
                                            </Can>
                                        </td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                            <Pagination
                                className="pagination justify-content-end mb-0"
                                listClassName="justify-content-end mb-0"
                            >
                                <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                    <PaginationLink
                                        href="#pablo"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.switchPage(this.pager.pagerStart);
                                        }}
                                        tabIndex="-1"
                                    >
                                        <i className="fas fa-angle-left" />
                                        <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                </PaginationItem>
                                {this.pager.pageArray.map(page => {
                                    return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(page);
                                            }}
                                        >
                                            {page}
                                        </PaginationLink>
                                    </PaginationItem>
                                })}
                                <PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                    <PaginationLink
                                        href="#pablo"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.switchPage(this.pager.pagerStart + 2);
                                        }}
                                    >
                                        <i className="fas fa-angle-right" />
                                        <span className="sr-only">Next</span>
                                    </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </nav>
                    </CardFooter>
                </Card>
                <Can I={permissions.FINANCE.payout_history} a={permissions.FINANCE.subject}>
                    <Modal
                        style={{ maxWidth: "90%" }}
                        className="modal-dialog-centered"
                        isOpen={this.state.promoterModal}
                        toggle={() => this.setState({
                            promoterModal: !this.state.promoterModal
                        })}
                    >
                        <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                                Promoter Payout History
                          </h6>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.setState({
                                        promoterModal: false
                                    })
                                }}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Table className="align-items-center table-flush" hover responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Month</th>
                                        <th>Policies Cleared</th>
                                        <th>Policies Sold</th>
                                        <th>Commission</th>
                                        <th>Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.promoterHistoryLoading && <tr key={"key_1234_sd"} >
                                        <td colSpan="6">
                                            <Spinner></Spinner>
                                        </td>
                                    </tr>}
                                    {!this.props.promoterHistoryLoading && this.props.promoterHistoryList && (this.props.promoterHistoryList.length < 1) && <tr key={"key1_dsaduwe823"} className="cursor-pointer">
                                        <td colSpan="6" className="text-center">
                                            <span className="text-muted">
                                                <b>
                                                    No payout history found
                                            </b>
                                            </span>
                                        </td>
                                    </tr>}
                                    {this.props.promoterHistoryList && this.props.promoterHistoryList.map((promoter, index) => {
                                        return <tr key={"key_1234_" + index} >
                                            <td className="table-user">
                                                <b  >
                                                    {promoter.mobile}<br /><span className="text-muted font-weight-light ">{promoter.dealership_name}</span>
                                                </b>
                                            </td>
                                            <td>
                                                <span className="text-muted">{promoter.mobile}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted">{this.getMonthYear(promoter.month)}</span>
                                            </td>
                                            <td>
                                                <span className={(promoter.policy_cleared === promoter.policy_sold) ? "text-muted " : " text-danger"}>{promoter.policy_cleared}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted">{promoter.policy_sold}</span>
                                            </td>
                                            <td>
                                                <span className="text-muted">₹{promoter.amount}</span>
                                            </td>
                                            <td>
                                                <label className="custom-toggle">
                                                    <input
                                                        checked={promoter.policy_cleared} type="checkbox" onChange={e => {
                                                        }} />
                                                    <span
                                                        className="custom-toggle-slider rounded-circle"
                                                        data-label-off="No"
                                                        data-label-on="Yes"
                                                    />
                                                </label>
                                            </td>
                                        </tr>
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    this.setState({
                                        promoterModal: false
                                    })
                                }}
                            >
                                Close
                        </Button>
                        </div>
                    </Modal>
                </Can>
                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.showPromoterSinglePaymentDialog}
                    toggle={() => {
                        this.setState({
                            showPromoterSinglePaymentDialog: !this.state.showPromoterSinglePaymentDialog
                        })
                    }}
                >
                    <div className="modal-body">
                        <Form role="form">
                            <Row className="input-daterange datepicker align-items-center">
                                <Col xs={12} sm={6}>
                                    <label className=" form-control-label">
                                        Name
                                    </label>
                                    <p>{this.state.selectedPromoterForSinglePayout ? this.state.selectedPromoterForSinglePayout.dealer_name : ''}</p>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <FormGroup>
                                        <label className=" form-control-label">
                                            Exposure
                                        </label>
                                        <p>
                                            {this.state.selectedPromoterForSinglePayout && this.state.selectedPromoterForSinglePayout.exposure}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Row className="w-100">
                            <Col>
                                <Button
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        this.setState({
                                            showPromoterSinglePaymentDialog: false
                                        })
                                    }}>
                                    Close
                                </Button>
                            </Col>
                            <Col>
                                {this.state.singlePayoutLoader ? <Shimmer /> : <Button color="primary"
                                    className="ml-auto" type="button"
                                    onClick={() => {
                                        this.initiateSinglePayment(this.state.selectedPromoterForSinglePayout.payout_id)
                                    }}
                                    size="sm"
                                >
                                    Make Payment
                                    </Button>}
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.showMultiplePromoterPayoutModel}
                    toggle={() => this.setState({
                        showMultiplePromoterPayoutModel: !this.state.showMultiplePromoterPayoutModel
                    })}
                >
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            Promoter Payout - {this.getMonthYear(this.state.selectedDate)}
                        </h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({
                                showMultiplePromoterPayoutModel: false
                            })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.bulkPayoutLoader ? <p className="text-center pl-4">Initiating</p> :
                            <p>Initiate dealer payout</p>}
                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Row className="w-100">
                            {this.state.bulkPayoutLoader ?
                                <Col className="text-center">
                                    <Shimmer />
                                </Col>
                                : <>
                            <Col className="text-left">
                                <Button
                                    className="ml-auto"
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        this.setState({
                                            showMultiplePromoterPayoutModel: false
                                        })
                                    }}
                                >
                                    Close
                                </Button>
                            </Col> </>}
                            <Col className="text-right">
                                <Button
                                    color="success"
                                    type="button"
                                    onClick={() => {
                                        this.initiateBulkPayment()
                                    }}
                                >
                                    Make Payment
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Fragment>
        </>
        )
    }
}
const mapStateToProps = state => {
    return {
        promoterPayoutLoading: state.finance.payoutListLoading,
        promoterHistoryLoading: state.finance.payoutHistoryLoading,
        promoterProductListLoading: state.finance.productListLoading,

        promoterPayoutList: state.finance.payoutListData,
        promoterTotalCount: state.finance.totalRecords,
        promoterHistoryList: state.finance.payoutHistoryData,
        promoterProductList: state.finance.productListData,
        payoutStatusListData: state.finance.payoutStatusListData,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetPayoutListData: (params) => dispatch(actions.getPayoutListData(params)),
        onGetPayoutHistoryData: (id) => dispatch(actions.getPayoutHistoryData(id, {})),
        onGetPayoutProductListData: (params) => dispatch(actions.getPayoutProductListData(params)),
        onGetPayoutStatusListData: (params) => dispatch(actions.getPayoutStatusListData(params)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoterPayout);