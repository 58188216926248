import { ReducerUtils } from '../constants/reducers';
import { compareValues } from '../helpers/utils';

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.otherPolicies.details:
      return {
        ...state,
        othePolicyDetails: action.payload
      };

    case ReducerUtils.otherPolicies.list:
      return {
        ...state,
        otherPolicesList: action.payload,
        otherPolicesListApi : action.payload
      };

    case ReducerUtils.otherPolicies.search:
      const query = action.payload;
      
      if (query) {
        const filteredList = state.otherPolicesListApi.filter(item => {
          return (
            (item.policy_holder_name && item.policy_holder_name.toLowerCase().includes(query.toLowerCase())) ||
            (item.product && item.product.toLowerCase().includes(query.toLowerCase())) || 
            (item.policy_number && item.policy_number.toLowerCase().includes(query.toLowerCase())) || 
            (item.insurance_provider && item.insurance_provider.toLowerCase().includes(query.toLowerCase()))
          );
        });

        return {
          ...state,
          otherPolicesList: filteredList || []
        };
      } else {
        return {
          ...state,
          otherPolicesList: [ ...state.otherPolicesListApi ]
        };
      }

    case ReducerUtils.otherPolicies.sort:
      const shallowCopy = [...state.otherPolicesList];
      const sortedList = shallowCopy.sort(compareValues(action.payload.sortBy, action.payload.sortType));        

      return {
        ...state,
        otherPolicesList: sortedList
      };

    default:
      return state;
  }
}
    