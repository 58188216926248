import React, {Component, Fragment} from 'react';
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Modal, Row} from "reactstrap";
import {IndiaFirstLocation} from "../../../locations/indiaFirstLocations";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import moment from "moment";

const mobileRegex = /^([0]|\+91)?[6789]\d{9}$/;

class ReligareQuotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPaymentLinkModal: false,
            nomineeRelationOptions: [
                {title: "", value: ""},
                {title: "Spouse", value: "spouse"},
                {title: "Father", value: "father"},
                {title: "Mother", value: "mother"},
                {title: "Brother", value: "brother"},
                {title: "Sister", value: "sister"},
                {title: "Son", value: "son"},
                {title: "Daughter", value: "daughter"},
                {title: "Grand Mother", value: "grand_mother"},
                {title: "Grand Father", value: "grand_father"},
            ],
            monthlyPremium: '',
            annualPremium: '',
            quotationCreated: false,
            paymentLink: '',
            title: 'mr',
            name: '',
            email: '',
            dob: '',
            nomineeRelation: '',
            nomineeName: '',
            nomineeMobile: '',
            nomineeDob: '',
            spouse: false,
            spouseTitle: 'mr',
            spouseName: '',
            spouseDob: "",
            child1: false,
            child1Title: 'mr',
            child1Name: '',
            child1Dob: "",
            child2: false,
            child2Title: 'mr',
            child2Name: '',
            child2Dob: "",
            mobile: null,
            mobileErrorMsg: '',
            pincode: '',
            cityName: '',
            stateName: '',
            addressLine1: '',
            addressLine2: '',
            landmark: '',
            orderId: '',
            isMonthly: false,

            titleError: false,
            titleErrorText: 'Please select title',

            nameError: false,
            nameErrorText: 'Please enter the full name. If the name has only one word, then repeat it. Eg: Ravi Ravi',

            dobError: false,
            dobErrorText: 'This field cannot be empty',

            mobileError: false,
            mobileErrorText: 'Please enter correct mobile number',

            spouseTitleError: false,
            spouseNameError: false,

            spouseDobError: false,
            spouseDobErrorText: 'This field cannot be empty',

            child1TitleError: false,
            child1NameError: false,

            child1DobError: false,
            child1DobErrorText: 'This field cannot be empty',

            child2TitleError: false,
            child2NameError: false,

            child2DobError: false,
            child2DobErrorText: 'This field cannot be empty',

            emailError: false,
            emailErrorText: 'Invalid Email',

            pincodeError: false,
            pincodeErrorText: 'Pincode should be of 6 digits',

            addressLine1Error: false,
            addressLine1ErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            addressLine2Error: false,
            addressLine2ErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            landmarkError: false,
            landmarkErrorText: 'This field cannot be empty and should be atleast 3 characters long',

            nomineeRelationError: false,
            nomineeRelationErrorText: 'Please select nominee relation',

            nomineeNameError: false,

            nomineeMobileError: false,

            nomineeDobError: false,
            nomineeDobErrorText: 'This field cannot be empty',

            sumAssured: '300000',
            sumAssuredError: '',
            sumAssuredErrorText: 'Please select a Sum Assured'
        }
    }

    isEmailValid(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    isNameValid(name) {
        const re = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
        return re.test(name);
    }

    nat = (n) => {
        return /^[1-9]*\d*$/.test(n);
    };

    onTitleChange = (e, type) => {
        if (type === 'self') {
            this.setState({
                title: e.target.value,
                titleError: false
            })
        }
        if (type === 'spouse') {
            this.setState({
                spouseTitle: e.target.value,
                spouseTitleError: false
            })
        }
        if (type === 'child1') {
            this.setState({
                child1Title: e.target.value,
                child1TitleError: false
            })
        }
        if (type === 'child2') {
            this.setState({
                child2Title: e.target.value,
                child2TitleError: false
            })
        }
        if (type === 'sumAssured') {
            this.setState({
                sumAssured: e.target.value,
                sumAssuredError: false
            })
        }
    }

    onRelationShipChange = (e) => {
        this.setState({
            nomineeRelation: e.target.value,
            nomineeRelationError: false
        })
    }
    onValueChange = (e, key) => {
        if (key === 'pincode') {
            this.populateStateCity(e.target.value)
        }
        this.setState({
            [key]: e.target.value,
            [key + 'Error']: false
        })
    }
    populateStateCity = (pin) => {
        let cityState = new IndiaFirstLocation().get(pin);
        if (cityState) {
            this.setState({
                stateName: cityState[1],
                cityName: cityState[0]
            })
        } else {
            this.setState({
                stateName: '',
                cityName: ''
            })
        }
    }

    appendMembers = () => {
        let members = {};
        if (this.state.spouse) {
            members['spouse'] = {
                'dob': moment(this.state.spouseDob).format('DD/MM/YYYY'),
                'name': this.state.spouseName,
                'title': this.state.spouseTitle,
            }
            members = {...members}
        }
        if (this.state.child1) {
            members['child1'] = {
                'dob': moment(this.state.child1Dob).format('DD/MM/YYYY'),
                'name': this.state.child1Name,
                'title': this.state.child1Title,
            }
            members = {...members}
        }
        if (this.state.child2) {
            members['child2'] = {
                'dob': moment(this.state.child2Dob).format('DD/MM/YYYY'),
                'name': this.state.child2Name,
                'title': this.state.child2Title,
            }
            members = {...members}
        }
        return members
    }

    onCreateQuotationClick = () => {
        if (this.state.title === '') {
            this.setState({
                titleError: true
            })
            return
        }
        if (this.state.name === '' || !this.isNameValid(this.state.name)) {
            this.setState({
                nameError: true,
            })
            return
        }
        if (this.state.email === '' || !this.isEmailValid(this.state.email)) {
            this.setState({
                emailError: true,
            })
            return
        }
        if (this.state.mobile === null || !this.state.mobile.match(mobileRegex)) {
            this.setState({
                mobileError: true,
            })
            return
        }
        if (this.state.dob === '') {
            this.setState({
                dobError: true,
            })
            return
        }

        if (this.state.pincode === '') {
            this.setState({
                pincodeError: true,
            })
            return
        }
        if (this.state.pincode.length < 6 || this.state.pincode.length > 6) {
            this.setState({
                pincodeError: true,
            })
            return
        }
        if (this.state.addressLine1 === '' || this.state.addressLine1.length < 2) {
            this.setState({
                addressLine1Error: true,
            })
            return
        }
        if (this.state.nomineeRelation === '') {
            this.setState({
                nomineeRelationError: true,
            })
            return
        }
        if (this.state.nomineeName === '' || !this.isNameValid(this.state.nomineeName)) {
            this.setState({
                nomineeNameError: true,
            })
            return
        }
        if (this.state.nomineeMobile === '' || !this.state.nomineeMobile.match(mobileRegex)) {
            this.setState({
                nomineeMobileError: true,
            })
            return
        }
        if (this.state.nomineeDob === '') {
            this.setState({
                nomineeDobError: true,
            })
            return
        }
        if (this.state.sumAssured === '') {
            this.setState({
                sumAssuredError: true,
            })
            return
        }
        if (this.state.spouse && this.state.spouseTitle === '') {
            this.setState({
                spouseTitleError: true,
            })
            return
        }
        if (this.state.spouse && this.state.spouseName === '') {
            this.setState({
                spouseNameError: true,
            })
            return
        }
        if (this.state.spouse && !this.isNameValid(this.state.spouseName)) {
            this.setState({
                spouseNameError: true,
            })
            return
        }
        if (this.state.spouse && this.state.spouseDob === '') {
            this.setState({
                spouseDobError: true,
            })
            return
        }
        if (this.state.child1 && this.state.child1Title === '') {
            this.setState({
                child1TitleError: true,
            })
            return
        }
        if (this.state.child1 && this.state.child1Name === '') {
            this.setState({
                child1NameError: true,
            })
            return
        }
        if (this.state.child1 && !this.isNameValid(this.state.child1Name)) {
            this.setState({
                child1NameError: true,
            })
            return
        }
        if (this.state.child1 && this.state.child1Dob === '') {
            this.setState({
                child1DobError: true,
            })
            return
        }
        if (this.state.child2 && this.state.child2Title === '') {
            this.setState({
                child2TitleError: true,
            })
            return
        }
        if (this.state.child2 && this.state.child2Name === '') {
            this.setState({
                child2NameError: true,
            })
            return
        }
        if (this.state.child2 && !this.isNameValid(this.state.child2Name)) {
            this.setState({
                child2NameError: true,
            })
            return
        }
        if (this.state.child2 && this.state.child2Dob === '') {
            this.setState({
                child2DobError: true,
            })
            return
        }

        let members = {}
        members = this.appendMembers()
        this.calculatePremium({
            "response_members": members,
            "title": this.state.title,
            "name": this.state.name,
            "email": this.state.email,
            "dob": moment(this.state.dob).format('DD/MM/YYYY'),
            "nominee_relation_id": this.state.nomineeRelation,
            "nominee_name": this.state.nomineeName,
            "nominee_mobile": this.state.nomineeMobile,
            "response_nominee_dob": moment(this.state.nomineeDob).format('DD/MM/YYYY'),
            "mobile": this.state.mobile,
            "pincode": this.state.pincode,
            "city": this.state.cityName,
            "state": this.state.stateName,
            "address_line_1": this.state.addressLine1,
            "address_line_2": this.state.addressLine2,
            "response_landmark": this.state.landmark,
            "response_sum_assured_health": this.state.sumAssured,
            "response_sum_assured_home": "0",
            "response_sum_assured_life": "0",
            "response_health_product_id": 49
        })
    }


    calculatePremium = (payload) => {
        this.setState({
            showLoader: true,
        });
        AxiosReactClient.postRequest('issuePolicy/51', payload)
            .then((res) => {
                console.log(res)
                this.setState({
                    showLoader: false,
                    orderId: res.data.data.order_id,
                    monthlyPremium: res.data.data.monthly_premium,
                    annualPremium: res.data.data.annual_premium,
                    quotationCreated: true
                });
            })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    onClickCreatePaymentLink = () => {
        this.setState({
            openPaymentLinkModal: true
        })
    }
    onClickCreate = (premium, discount) => {
        let payload = {};
        payload = {
            "entity": "order",
            "entity_id": this.state.orderId,
            "is_monthly": this.state.isMonthly,
            "is_recurring": false
        }
        this.setState({
            showLoader: true,
        });
        AxiosReactClient.postRequest('payments/link', payload)
            .then((res) => {
                console.log(res)
                this.setState({
                    showLoader: false,
                    openPaymentLinkModal: false,
                    paymentLink: res.data.data.url
                });
            })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }


    render() {
        return (
            <Fragment>
                {this.state.showLoader &&
                <Loader msg={"Please Wait..."}/>
                }
                <div className={"container-fluid"}>
                    <Row>
                        <Col lg={6}>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="formControlInput1">Title</label>
                                    <select
                                        className="form-control"
                                        id="formControlInput1"
                                        defaultChecked={this.state.title}
                                        onChange={(e) => {
                                            this.onTitleChange(e, 'self')
                                        }}>
                                        <option value={'mr'}>Mr</option>
                                        <option value={'ms'}>Ms</option>
                                    </select>
                                    {this.state.titleError &&
                                    <span className="text-danger">{this.state.titleErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput2">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput2"
                                        placeholder="Full Name"
                                        defaultValue={this.state.name}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'name')
                                        }}
                                    />
                                    {this.state.nameError &&
                                    <span className="text-danger">{this.state.nameErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput3">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="formControlInput3"
                                        placeholder="xyz@email.com"
                                        defaultValue={this.state.email}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'email')
                                        }}
                                    />
                                    {this.state.emailError &&
                                    <span className="text-danger">{this.state.emailErrorText}</span>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="formControlInput33">Mobile</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formControlInput33"
                                        placeholder=""
                                        defaultValue={this.state.mobile}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'mobile')
                                        }}
                                    />
                                    {this.state.mobileError &&
                                    <span className="text-danger">{this.state.mobileErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput4">Date of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="formControlInput4"
                                        placeholder="dd/mm/yyyy"
                                        defaultValue={this.state.dob}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'dob')
                                        }}
                                        max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        min={moment()
                                            .subtract(56, "years")
                                            .add(1, "days").format("YYYY-MM-DD")}

                                    />
                                    {this.state.dobError &&
                                    <span className="text-danger">{this.state.dobErrorText}</span>
                                    }
                                </div>

                                <div>
                                    <h3 className={'my-3'}><u>Address Details</u></h3>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput5">Pincode</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="formControlInput5"
                                        defaultValue={this.state.pincode}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'pincode')
                                        }}
                                    />
                                    {this.state.pincodeError &&
                                    <span className="text-danger">{this.state.pincodeErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput6">City</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput6"
                                        defaultValue={this.state.cityName}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'cityName')
                                        }}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput7">State</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput7"
                                        defaultValue={this.state.stateName}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'stateName')
                                        }}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput8">Address line 1</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput8"
                                        defaultValue={this.state.addressLine1}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'addressLine1')
                                        }}
                                    />
                                    {this.state.addressLine1Error &&
                                    <span className="text-danger">{this.state.addressLine1ErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput9">Address line 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput9"
                                        onChange={(e) => {
                                            this.onValueChange(e, 'addressLine2')
                                        }}
                                        defaultValue={this.state.addressLine2}
                                    />
                                    {this.state.addressLine2Error &&
                                    <span className="text-danger">{this.state.addressLine2ErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput10">Landmark</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput10"
                                        defaultValue={this.state.landmark}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'landmark')
                                        }}
                                    />
                                    {this.state.landmarkError &&
                                    <span className="text-danger">{this.state.landmarkErrorText}</span>
                                    }
                                </div>

                                <div>
                                    <h3 className={'my-3'}><u>Nominee Details</u></h3>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput11">Nominee Relationship</label>
                                    <select
                                        className="form-control"
                                        id="formControlInput11"
                                        defaultChecked={this.state.nomineeRelation}
                                        onChange={(e) => {
                                            this.onRelationShipChange(e)
                                        }}>
                                        {this.state.nomineeRelationOptions.map((option, index) => {
                                            return <option value={option.value} key={index}>{option.title}</option>
                                        })}
                                    </select>
                                    {this.state.nomineeRelationError &&
                                    <span className="text-danger">{this.state.nomineeRelationErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput12">Nominee Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput12"
                                        defaultValue={this.state.nomineeName}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'nomineeName')
                                        }}
                                    />
                                    {this.state.nomineeNameError &&
                                    <span className="text-danger">{this.state.nameErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput13">Nominee Mobile</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formControlInput13"
                                        defaultValue={this.state.nomineeMobile}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'nomineeMobile')
                                        }}
                                    />
                                    {this.state.nomineeMobileError &&
                                    <span className="text-danger">{this.state.mobileErrorText}</span>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="formControlInput14">Nominee Date of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="formControlInput14"
                                        placeholder="dd/mm/yyyy"
                                        defaultValue={this.state.nomineeDob}
                                        onChange={(e) => {
                                            this.onValueChange(e, 'nomineeDob')
                                        }}
                                        max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                        min={moment()
                                            .subtract(56, "years")
                                            .add(1, "days").format("YYYY-MM-DD")}
                                    />
                                    {this.state.nomineeDobError &&
                                    <span className="text-danger">{this.state.nomineeDobErrorText}</span>
                                    }
                                </div>
                                <hr/>
                                <div className="form-group">
                                    <label htmlFor="formControlInput18">Sum Assured</label>
                                    <select
                                        className="form-control"
                                        id="formControlInput18"
                                        defaultChecked={this.state.sumAssured}
                                        onChange={(e) => {
                                            this.onTitleChange(e, 'sumAssured')
                                        }}>
                                        <option value={'300000'}>300000</option>
                                        <option value={'400000'}>400000</option>
                                        <option value={'500000'}>500000</option>
                                    </select>
                                    {this.state.sumAssuredError &&
                                    <span className="text-danger">{this.state.sumAssuredErrorText}</span>
                                    }
                                </div>
                                <hr/>
                                <div>
                                    <h3 className={'my-3'}><u>Family Details</u></h3>
                                </div>

                                <div className={'d-flex justify-content-between'}>
                                    <h4 className={'my-3'}><u>Spouse Details</u></h4>
                                    <div className={'ml-auto py-2'}>
                                        <button
                                            className="btn btn-icon btn-outline-primary btn-sm"
                                            type="button"
                                            onClick={(e) => {
                                                this.setState({
                                                    spouse: !this.state.spouse
                                                })
                                            }}
                                        >
                                            <span className="btn-inner--icon">
                                                {!this.state.spouse ?
                                                    <i className="ni ni-fat-add"/>
                                                    :
                                                    <i className="ni ni-fat-delete"/>
                                                }
                                                </span>
                                        </button>
                                    </div>
                                </div>
                                {this.state.spouse && <>
                                    <div className="form-group">
                                        <label htmlFor="formControlInput15">Title</label>
                                        <select
                                            className="form-control"
                                            id="formControlInput15"
                                            defaultChecked={this.state.spouseTitle}
                                            onChange={(e) => {
                                                this.onTitleChange(e, 'spouse')
                                            }}>
                                            <option value={'mr'}>Mr</option>
                                            <option value={'ms'}>Ms</option>
                                        </select>
                                        {this.state.spouseTitleError &&
                                        <span className="text-danger">{this.state.titleErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput16">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formControlInput16"
                                            defaultValue={this.state.spouseName}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'spouseName')
                                            }}
                                        />
                                        {this.state.spouseNameError &&
                                        <span className="text-danger">{this.state.nameErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput17">Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="formControlInput17"
                                            placeholder="dd/mm/yyyy"
                                            defaultValue={this.state.spouseDob}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'spouseDob')
                                            }}
                                            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                                            min={moment()
                                                .subtract(56, "years")
                                                .add(1, "days").format("YYYY-MM-DD")}
                                        />
                                        {this.state.spouseDobError &&
                                        <span className="text-danger">{this.state.spouseDobErrorText}</span>
                                        }
                                    </div>
                                </>
                                }

                                <div className={'d-flex justify-content-between'}>
                                    <h4 className={'my-3'}><u>Child 1 Details</u></h4>
                                    <div className={'ml-auto py-2'}>
                                        <button
                                            className="btn btn-icon btn-outline-primary btn-sm"
                                            type="button"
                                            onClick={(e) => {
                                                this.setState({
                                                    child1: !this.state.child1
                                                })
                                            }}
                                        >
                                            <span className="btn-inner--icon">
                                                {!this.state.child1 ?
                                                    <i className="ni ni-fat-add"/>
                                                    :
                                                    <i className="ni ni-fat-delete"/>
                                                }
                                                </span>
                                        </button>
                                    </div>
                                </div>
                                {this.state.child1 && <>
                                    <div className="form-group">
                                        <label htmlFor="formControlInput18">Title</label>
                                        <select
                                            className="form-control"
                                            id="formControlInput18"
                                            defaultChecked={this.state.child1Title}
                                            onChange={(e) => {
                                                this.onTitleChange(e, 'child1')
                                            }}>
                                            <option value={'mr'}>Mr</option>
                                            <option value={'ms'}>Ms</option>
                                        </select>
                                        {this.state.child1TitleError &&
                                        <span className="text-danger">{this.state.titleErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput19">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formControlInput19"
                                            defaultValue={this.state.child1Name}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'child1Name')
                                            }}
                                        />
                                        {this.state.child1NameError &&
                                        <span className="text-danger">{this.state.nameErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput20">Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="formControlInput20"
                                            placeholder="dd/mm/yyyy"
                                            defaultValue={this.state.child1Dob}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'child1Dob')
                                            }}
                                            max={moment().subtract(3, "months").format("YYYY-MM-DD")}
                                            min={moment()
                                                .subtract(26, "years")
                                                .add(1, "days").format("YYYY-MM-DD")}
                                        />
                                        {this.state.child1DobError &&
                                        <span className="text-danger">{this.state.child1DobErrorText}</span>
                                        }
                                    </div>
                                </>
                                }

                                <div className={'d-flex justify-content-between'}>
                                    <h4 className={'my-3'}><u>Child 2 Details</u></h4>
                                    <div className={'ml-auto py-2'}>
                                        <button
                                            className="btn btn-icon btn-outline-primary btn-sm"
                                            type="button"
                                            onClick={(e) => {
                                                this.setState({
                                                    child2: !this.state.child2
                                                })
                                            }}
                                        >
                                            <span className="btn-inner--icon">
                                                {!this.state.child2 ?
                                                    <i className="ni ni-fat-add"/>
                                                    :
                                                    <i className="ni ni-fat-delete"/>
                                                }
                                                </span>
                                        </button>
                                    </div>
                                </div>
                                {this.state.child2 && <>
                                    <div className="form-group">
                                        <label htmlFor="formControlInput21">Title</label>
                                        <select
                                            className="form-control"
                                            id="formControlInput21"
                                            defaultChecked={this.state.child2Title}
                                            onChange={(e) => {
                                                this.onTitleChange(e, 'child2')
                                            }}>
                                            <option value={'mr'}>Mr</option>
                                            <option value={'ms'}>Ms</option>
                                        </select>
                                        {this.state.child2TitleError &&
                                        <span className="text-danger">{this.state.titleErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput22">Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="formControlInput22"
                                            defaultValue={this.state.child2Name}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'child2Name')
                                            }}
                                        />
                                        {this.state.child2NameError &&
                                        <span className="text-danger">{this.state.nameErrorText}</span>
                                        }
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="formControlInput23">Date of Birth</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="formControlInput23"
                                            placeholder="dd/mm/yyyy"
                                            defaultValue={this.state.child2Dob}
                                            onChange={(e) => {
                                                this.onValueChange(e, 'child2Dob')
                                            }}
                                            max={moment().subtract(3, "months").format("YYYY-MM-DD")}
                                            min={moment()
                                                .subtract(26, "years")
                                                .add(1, "days").format("YYYY-MM-DD")}
                                        />
                                        {this.state.child2DobError &&
                                        <span className="text-danger">{this.state.child2DobErrorText}</span>
                                        }
                                    </div>
                                </>
                                }
                                {this.state.quotationCreated && <>
                                    <div className={'border rounded-10 p-4'}>
                                        <div className={'d-flex justify-content-center'}>
                                            <h3>Your Premium is: </h3>
                                        </div>
                                        <div className={'d-flex justify-content-center mt-4'}>
                                            <h4>Monthly: {this.state.monthlyPremium}</h4>
                                        </div>
                                        <div className={'d-flex justify-content-center mt-4'}>
                                            <h4>Annually: {this.state.annualPremium}</h4>
                                        </div>
                                        <div className={'d-flex justify-content-center mt-4'}>
                                            {this.state.paymentLink === '' ?
                                                <button
                                                    className={'btn btn-icon btn-primary btn-lg'}
                                                    type={'button'}
                                                    onClick={() => {
                                                        this.onClickCreatePaymentLink()
                                                    }}
                                                >
                                                    <b>Create Payment Link</b>
                                                </button>
                                                :
                                                <div className={'d-flex justify-content-center mt-4'}>
                                                    <h4>Payment Link:
                                                        <span className={'text-primary cursor-pointer'}
                                                              onClick={() => {
                                                                  window.open(this.state.paymentLink)
                                                              }}
                                                        > <u>{this.state.paymentLink}</u></span></h4>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                                }
                                {!this.state.quotationCreated &&
                                <div className={'d-flex justify-content-center mt-4'}>
                                    <button
                                        className={'btn btn-icon btn-primary btn-lg'}
                                        type={'button'}
                                        onClick={() => {
                                            this.onCreateQuotationClick()
                                        }}
                                    >
                                        <b>Create Quotation</b>
                                    </button>
                                </div>
                                }
                            </form>
                        </Col>
                    </Row>

                </div>

                <Modal
                    className="modal-dialog-centered"
                    size="md"
                    isOpen={this.state.openPaymentLinkModal}
                    toggle={() => {
                        this.setState({
                            openPaymentLinkModal: !this.state.openPaymentLinkModal
                        })
                    }}
                >
                    <div className={"modal-header"}>
                        <h4>Create Payment Link</h4>
                        <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                            this.setState({
                                openPaymentLinkModal: false
                            })
                        }}/>
                    </div>
                    <hr className={"text-dark m-0 p-0"}/>

                    <div className="modal-body text-center">
                        {this.state.showLoader ?
                            <div className={"mh-200"}>
                                <Loader msg={"Please Wait..."}/>
                            </div>
                            :
                            <Card className={"m-0 bg-transparent shadow-none"}>
                                <CardBody className={"p-4"}>
                                    <div className={'d-flex'}>
                                        <h4>Select Mode</h4>
                                    </div>
                                    <div className={"d-flex justify-content-around"}>
                                        <div
                                            className={'border rounded-10 shadow-1 p-2 w-200 cursor-pointer pb-3 ' + (this.state.selectedPremium === 'monthly' ? 'bg-light' : '')}
                                            onClick={() => {
                                                this.setState({
                                                    selectedPremium: 'monthly',
                                                    isMonthly: true,
                                                    selectedPremiumValue: this.state.monthlyPremium
                                                })
                                            }}
                                        >
                                            <div className={"d-flex justify-content-start pl-3 pt-3"}>
                                                <i className={"ni ni-money-coins text-left " + (this.state.selectedPremium === 'monthly' ? 'text-primary' : '')}/>
                                            </div>
                                            <div className={"d-flex justify-content-center p-0 m-0"}>
                                                <h3 className={this.state.selectedPremium === 'monthly' ? 'text-primary' : ''}>Monthly</h3>
                                            </div>
                                            <h4 className={this.state.selectedPremium === 'monthly' ? 'text-primary' : ''}>{this.state.monthlyPremium}</h4>
                                        </div>
                                        <div
                                            className={'border rounded-10 shadow-1 p-2 w-200 cursor-pointer pb-3 ' + (this.state.selectedPremium === 'annual' ? 'bg-light' : '')}
                                            onClick={() => {
                                                this.setState({
                                                    selectedPremium: 'annual',
                                                    isMonthly: false,
                                                    selectedPremiumValue: this.state.annualPremium
                                                })
                                            }}
                                        >
                                            <div className={"d-flex justify-content-start pl-3 pt-3"}>
                                                <i className={"ni ni-money-coins text-left " + (this.state.selectedPremium === 'annual' ? 'text-primary' : '')}/>
                                            </div>
                                            <div className={"d-flex justify-content-center p-0 m-0"}>
                                                <h3 className={this.state.selectedPremium === 'annual' ? 'text-primary' : ''}>Annually</h3>
                                            </div>
                                            <h4 className={this.state.selectedPremium === 'annual' ? 'text-primary' : ''}>{this.state.annualPremium}</h4>
                                        </div>
                                    </div>

                                    {/*<div className={"pt-3"}>*/}
                                    {/*    <div className={"d-flex justify-content-start"}>*/}
                                    {/*        <label htmlFor="formControlInput22">Discount</label>*/}
                                    {/*    </div>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="formControlInput222"*/}
                                    {/*        defaultValue={this.state.child2Name}*/}
                                    {/*        onChange={(e) => {*/}
                                    {/*            this.onValueChange(e, 'discount')*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </CardBody>
                            </Card>
                        }
                    </div>
                    <div className="modal-footer date-modal-footer ">
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.setState({
                                    openPaymentLinkModal: false,
                                })
                            }}
                        >
                            Close</Button>
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            size="sm"
                            onClick={() => {
                                this.onClickCreate(this.state.selectedPremiumValue, this.state.discount)
                            }}
                        >
                            Create</Button>
                    </div>
                </Modal>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        quotationListLoading: state.policies.quotationListLoading,
        quotationListData: state.policies.quotationListData,
        quotationTotalCount: state.policies.quotationTotalCount,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPolicyQuotationList: (filter, params) => dispatch(actions.getPolicyQuotationList(filter, params)),


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReligareQuotation);