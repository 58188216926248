export const initialBooleanObject = {
    deletionSubmitted: false,
    formSubmitted: false,
    mappingLoaded: false,
    partnerLoaded: false,
    searchResult: false,
    searchSubmitted: false,
    showAlert: false
};

export const initialFormState = {
    mappedList: [],
    partnerId: '',
    searchText: '',
    searchResult: []
};

export const errorMessageObject = {
    partnerId: ''
};

export const touchFieldsObject = {
    partnerId: false
};
