import * as permissions from '../../../config/Casl/permissions';
import DogInsuranceListing from "./Listing";
import CustomerDetails from './CustomerDetails';
import DogDetails from './DogDetails';
import PetDocuments from "./documents";
import SuccessPage from './SuccessPage';
import LeadDetails from "./viewLead";

const petRoute = {
    collapse: true,
    name: "Pet Insurance",
    icon: "ni ni-money-coins text-primary",
    state: "petCollapse",
    invisible: false,
    permission: permissions.DOG_INSURANCE.menu,
    subject: permissions.DOG_INSURANCE.subject,
    views: [
        {
            path: "/pet/dog/:id/success",
            component: SuccessPage,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subjectubject: permissions.DOG_INSURANCE.subject
        },
        {
            path: "/pet/dog/:id/documents",
            component: PetDocuments,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subjectubject: permissions.DOG_INSURANCE.subject
        },
        {
            path: "/pet/dog/:id/customer",
            component: CustomerDetails,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subjectubject: permissions.DOG_INSURANCE.subject
        },
        {
            path: "/pet/dog/:id/dog",
            component: DogDetails,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subjectct: permissions.DOG_INSURANCE.subject
        },
        {
            path: "/pet/dog/:id",
            component: LeadDetails,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subject: permissions.DOG_INSURANCE.subject
        },
        {
            path: "/pet/dog",
            name: "Dog Insurance",
            component: DogInsuranceListing,
            layout: "/admin",
            permission: permissions.DOG_INSURANCE.list,
            subject: permissions.DOG_INSURANCE.subject
        },
    ]
};

export default petRoute;