import React from "react";
import { Badge } from "reactstrap";

const CustomFilterBadge = ({ color = "success", list = [], title, visible = false }) => {
  return (
    (list.length > 0 || visible) && (
      <Badge color={color} className="p-3" pill={true}>
        <i className="fas fa-check-circle"/> {title}
      </Badge>
    )
  );
};

export default CustomFilterBadge;
