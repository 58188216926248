import React, { useContext, useState, useEffect, useReducer, useRef, Fragment } from "react";
import {
	Card,
	Container,
	CardBody,
	CardFooter,
	Button,
  Progress,
  Row,
  Col,
  Modal 
} from "reactstrap";
import axios from 'axios';

import {Divider} from "semantic-ui-react";
import { options } from "../../../defaultValues/lead";

// Import all custom default exports
import useApi from '../../../hooks/useApi';
import LeadsReducer from '../../../reducers/leadsReducer';
import LeadContext from '../../../context/leadContext';
import Loader from '../../../components/CustomUi/Loader/Loader';
import AxiosRestClient from '../../../utilities/AxiosRestClient';
import { ReducerUtils } from '../../../constants/reducers';
import Connection from "../../../config/Connection";
import Notification from '../../../components/CustomUi/Notify/Notification';
import { notify, redirectWithBlank, redirectWithImage } from '../../../helpers/utils';
import { columnSelectionComplete } from "@syncfusion/ej2-grids";

const PetDocuments = (props) => {
  let leadDetailsMapping = {};
  let staticPhotos = options.photos;

  const inputFile1 = useRef(null);
  const inputFile2 = useRef(null);
  const inputFile3 = useRef(null);
  const inputFile4 = useRef(null);
  const inputFile5 = useRef(null);

  const { 
    handleCustomerDetails,
  } = props;

  // Call useApi custom hook to fetch lead details
  const leadDetails = useApi(`v2/dog_insurance/${props.match.params.id}`);
  
  const initialLeadReducerState = useContext(LeadContext);
  const [state, dispatch] = useReducer(LeadsReducer, initialLeadReducerState);
  const [active, setActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [selectedSequence, setSelectedSequence] = useState('');
  const [photos, setPhotos] = useState([]);
  const [lastUploadedPhoto, setLastUploadedPhoto] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [modal, setModal] = useState({ isPhotosVerified: false });
  const [localImages, setLocalImages] = useState({ 1: '', 2: '', 3: '', 4: '', 5: '' });
  const [delay, setDelay] = useState(0);

  useEffect(() => {
    dispatchEvent(ReducerUtils.leads.details, leadDetails);
  }, [leadDetails]);

  useEffect(() => {
    // console.log('invoked me...');
  }, [photos, localImages]);

  useEffect(() => {
    // console.log(`change in counter, current = ${delay}`);
  }, [delay])

  useEffect(() => {
    if (state.leadDetails) {
      state.leadDetails.photo.map((photo, index) => {
        leadDetailsMapping[photo.id] = photo;
      });

      let lastId = '';
      staticPhotos.map((photo, index) => {
        if (leadDetailsMapping[photo.sequence]) {
          photo.is_uploaded = true;
          photo.url = localImages[photo.sequence] ? localImages[photo.sequence] : leadDetailsMapping[photo.sequence].url;
          lastId = photo.sequence;
        } else {
          photo.is_uploaded = false;
        }
      });

      setLastUploadedPhoto(lastId);
      setPhotos(staticPhotos);
    }
  }, [state.leadDetails]);

  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const onButtonClick = (event, id) => {
    setSelectedSequence(id);

    // `current` points to the mounted file input element
    if (id === 1) {
      inputFile1.current.click();
    }
    if (id === 2) {
      inputFile2.current.click();
    }
    if (id === 3) {
      inputFile3.current.click();
    }
    if (id === 4) {
      inputFile4.current.click();
    }
    if (id === 5) {
      inputFile5.current.click();
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (event) {
          resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleChange = async (event) => {
    const id = event.target.id;
    const result = await fileToBase64(event.target.files[0]);
    uploadFile(result, id);
  };
  
  const cbError = ({ data: { error_msg }, data }) => {
    setUploading(false);
    console.log(`error message = ${error_msg}`);
    // setError(error_msg);
  };

  const fetchDetails = async () => {
    const response = await AxiosRestClient.getRequest(`v2/dog_insurance/${props.match.params.id}`);
    const { data } = response.data;

    dispatchEvent(ReducerUtils.leads.details, data);
  };

  const cbUpload = ({ data, status, message }, id, base64) => {
    setUploading(false);
    setUploadPercent(0);
    // notify(message);

    const { ...shallowCopy } = localImages;
    shallowCopy[id] = base64;
    setLocalImages(shallowCopy);
    fetchDetails();
    
    // This hack has been introduced in order to render recently uploaded image
    setTimeout(() => {
      setDelay(parseInt(delay) + 1);
    }, 1000);
  };

  const uploadFile = async (base64, id) => {
    try {
      setUploading(true);
      const payload = {
        file: base64,
        source_id:"other",
        id
      };
      const url = `${Connection.getResturl()}v2/dog_insurance/photo/${props.match.params.id}`;
      const response = await axios.put(url, payload, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadPercent(percentCompleted);
        }
      });

      cbUpload(response.data, id, base64);
    } catch (error) {
      cbError(error.response.data);
    }
  };

  const cbVerifyPhotos = ({ data, status, message }) => {
    setIsFormSubmitted(false);
    notify(message);
    dispatchEvent(ReducerUtils.leads.details, { ...state.leadDetails, status: data.status })
  };

  const verfifyPhotos = async () => {
    try {
      setIsFormSubmitted(true);
      const response = await AxiosRestClient.postRequest(`v2/dog_insurance/verification`, { id: props.match.params.id });
      cbVerifyPhotos(response.data);
    } catch (error) {
      cbError(error.response.data);
    }
  };

  const openModal = (keyName = 'isPhotosVerified') => {
    setModal({...modal, [keyName]: true });
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  const handleProceed = () => {
    openModal();
  };

  const handleDone = async () => {
    setIsFormSubmitted(true);
    const response = await AxiosRestClient.putRequest(`v2/dog_insurance/donedetail/${props.match.params.id}`, { is_admin: true });
    const { status, message } = response.data;
    
    setIsFormSubmitted(false);
    status ? navigateTo(`/admin/pet/dog`) : alert(message);
  };

  const handleView = (event, item) => {
		localImages[item.sequence] ? redirectWithImage(localImages[item.sequence]) : redirectWithBlank(item.url);
    
		event.preventDefault();
		event.stopPropagation();
	};
	
	return (
		<Fragment>
			<Container fluid>
				<Card className="m-0 mt-1">
					<CardBody className="">
						<div className="">
							<div className={"mb-2"}>
								<h3>Upload Pictures</h3>
                {state.leadDetails && state.leadDetails.status === 'documents_verified' && 
                <p className="text-success p-10">Pictures have been verified.</p>}
							</div>

							<div className={"d-flex m-3 mt-6 justify-content-between"}>
								{photos.map((item, index) => {
									return <div className={"mx-3 shadow d-flex justify-content-center align-items-center flex-column"} key={index} >
										<div className={(item.is_uploaded || item.sequence === lastUploadedPhoto + 1 
                      || (!state.leadDetails.photo.length && item.sequence === 1)) ? "" : "opacity-1 pointer-event"}>
											<div className={"d-flex justify-content-center align-items-center py-3"}>
												<img src={item.image} />
                        {/* {item.is_uploaded && <img src={item.image} style={{ width: '185px', height: '102px' }} />} */}
											</div>
											<Divider className={""}/>
                      <div className={"d-flex justify-content-center align-items-center p-1 px-4"}>
                        {item.is_uploaded ? 
                        <small className="text-primary" onClick={(event) => handleView(event, item)} style={{ cursor: 'pointer'}}>
													View Picture
												</small> : <small className={"p-2"}>&nbsp;</small>}
                      </div>
											<div className={"d-flex justify-content-center align-items-center p-3 px-4"}>
												<p>
													<b>{item.title}</b>{" "}of dog
												</p>
											</div>
											<div className={"d-flex justify-content-center align-items-center border-dashed mh-100 m-2"}
                        onClick={(event) => onButtonClick(event, item.sequence)} style={{ cursor: 'pointer'}}>
												<div className={"mh-40"}>
													{!item.is_uploaded && <h6> <span> Click </span> to upload </h6>}
                          {item.is_uploaded && <h6> Re-upload </h6>}
                          {(uploading && selectedSequence === item.sequence) && <Progress value={uploadPercent} />}
												</div>
											</div>
										</div>
                    {item.sequence === 1 && <input type='file' id={`${item.sequence}`} ref={inputFile1} style={{display: 'none'}} onChange={handleChange} accept="image/*" />}
                    {item.sequence === 2 && <input type='file' id={`${item.sequence}`} ref={inputFile2} style={{display: 'none'}} onChange={handleChange} accept="image/*" />}
                    {item.sequence === 3 && <input type='file' id={`${item.sequence}`} ref={inputFile3} style={{display: 'none'}} onChange={handleChange} accept="image/*" />}
                    {item.sequence === 4 && <input type='file' id={`${item.sequence}`} ref={inputFile4} style={{display: 'none'}} onChange={handleChange} accept="image/*" />}
                    {item.sequence === 5 && <input type='file' id={`${item.sequence}`} ref={inputFile5} style={{display: 'none'}} onChange={handleChange} accept="image/*" />}
									</div>
								})}
							</div>

						</div>
					</CardBody>
          
          <Notification />

					
          <CardFooter className="py-4">
            <div className="text-right">
              
            {(state.leadDetails && state.leadDetails.photo.length >0 && state.leadDetails.status === 'documents_uploaded') && 
              <Button className="shadow-none mt-3" 
                size="md"
                contained={"true"}
                color={"primary"}
                type="button" 
                onClick={verfifyPhotos}
              >
                Verify Pictures
              </Button>}

              {(state.leadDetails && state.leadDetails.status === 'documents_verified') &&
              <Button className="shadow-none mt-3" 
                size="md"
                contained={"true"}
                color={"primary"}
                type="button" 
                onClick={handleProceed}
              >
                Proceed
              </Button>}
            </div>
					</CardFooter>

          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={modal.isPhotosVerified}
            toggle={() => {
              setModal({ ...modal, isPhotosVerified: !modal.isPhotosVerified })
            }}
            backdrop={'static'}
            keyboard={false}
          >
            <div className={"modal-header"}>
              <h4>Thanks</h4>
              <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                setModal({ ...modal, isPhotosVerified: false })
              }}/>
            </div>
            <hr className={"text-dark m-0 p-0"}/>

            <div className="modal-body text-left">
                <Row>
                  <Col xl="12">
                    <div className={"d-flex justify-content-around px-4"}>
                      <h3>Pictures verified successfully.</h3>
                    </div>

                    <div className={"d-flex justify-content-around px-4 mt-4"}>
                      <div className="form-group w-100 px-2 text-right">
                        <button className="btn btn-primary" type="button" 
                          onClick={handleDone}
                          { ...( isFormSubmitted && { disabled: true } ) }
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
            </div>
          </Modal>

				</Card>
			</Container>
		</Fragment>
	);
};

export default PetDocuments;