
// Colors
var colors = {
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529"
    },
    theme: {
        default: "#172b4d",
        primary: "#5e72e4",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340"
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
    pieTheme: {
        "APPROVED": "#2dce89",
        "IN PROGRESS": "#5e72e4",
        "NEW": "#11cdef",
        "REJECTED": "#f5365c"
    }
};

// let parsePoliciesLineGraphData = (data, filter) => {
//     // console.log(data[filter] ? data[filter] : 'empty'); 
//     return data ? {
//         labels: data[filter] ? Object.keys(data[filter]) : [],

//         datasets: [
//             {
//                 label: "Policies",
//                 data: data[filter] ? Object.values(data[filter]) : [],
//             },
//             
//         ]
//     } : {}
// }


let parseCombinedPoliciesLineGraphData = (cycleData, mobileData, bikerData, filter) => {
    // console.log(bikerData,filter); 
    return cycleData ? {
        labels: cycleData[filter] ? Object.keys(cycleData[filter]) : [],

        datasets: [
            {
                label: "Cycle ",
                data: cycleData[filter] ? Object.values(cycleData[filter]) : [],
                fill: false,
                backgroundColor: colors.theme.primary,
                borderColor: colors.theme.primary,
            },
            {
                label: "Mobile ",
                data: mobileData[filter] ? Object.values(mobileData[filter]) : [],
                fill: false,
                backgroundColor: colors.theme.success,
                borderColor: colors.theme.success,
            },
            {
                label: "Biker ",
                data: bikerData[filter] ? Object.values(bikerData[filter]) : [],
                fill: false,
                backgroundColor: colors.theme.warning,
                borderColor: colors.theme.warning,
            },
        ]
    } : {}
}
let parseClaimsBarChartData = (data, filter) => {
    // console.log(data);
    var claimData = data['claim'];
    var claimAmount = data['cycleClaim'];

    // console.log(claimData ? Object.values(claimData[filter]) : '');

    return data ? {
        labels: claimData ? Object.keys(claimData[filter]) : [],

        datasets: data['claim'] ? [
            {
                label: "Claims",
                data: claimData[filter] ? Object.values(claimData[filter]) : [],
            }, {
                label: "Cycle Claims",
                data: claimAmount[filter] ? Object.values(claimAmount[filter]) : [],
                backgroundColor: colors.theme.success
            },
        ] : []
    } : {}
}
let parseClaimsPieChartData = (data) => {
    // console.log(data);
    var claimPieData = data['claimStatus'];

    // console.log(claimData ? Object.values(claimData[filter]) : '');

    return {
        labels: claimPieData ? Object.keys(claimPieData) : [],
        datasets: [
            {
                data: claimPieData ? Object.values(claimPieData) : [],
                backgroundColor: claimPieData ? [
                    colors.pieTheme[Object.keys(claimPieData)[0]],
                    colors.pieTheme[Object.keys(claimPieData)[1]],
                    colors.pieTheme[Object.keys(claimPieData)[2]],
                    colors.pieTheme[Object.keys(claimPieData)[3]],
                ] : [],
                label: "Dataset 1"
            }
        ]
    }
}

let POLICIES_LINE_GRAPH_OPTIONS = {
    options: {
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: colors.gray[700],
                        zeroLineColor: colors.gray[700]
                    },
                    ticks: {
                        callback: function (value) {
                            return value;
                            // if (!(value % 10)) {
                            //     // return "$" + value + "k";
                            // }
                        }
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    var yLabel = item.yLabel;
                    var content = "";

                    if (data.datasets.length > 1) {
                        content += label;
                    }

                    content += yLabel;
                    return content;
                }
            }
        }
    },
};

// Example 2 of Chart inside src/views/dashboards/Dashboard.jsx and src/views/dashboards/Alternative.jsx and src/views/pages/Charts.jsx
let CLAIMS_BAR_CHART_OPTIONS = {
    options: {
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[200]
                    },
                    ticks: {
                        callback: function (value) {
                            if (!(value % 2)) {
                                return value;
                                //return '$' + value + 'k'
                            }
                        }
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    var yLabel = item.yLabel;
                    var content = "";
                    if (data.datasets.length > 1) {
                        content += label;
                    }
                    content += " " + yLabel;
                    return content;
                }
            }
        }
    }
};
// Example 6 of Chart inside src/views/pages/Charts.jsx
let CLAIMS_PIE_CHART_OPTIONS = {
    options: {
        responsive: true,
        legend: {
            position: "top"
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    }
};

module.exports = {
    POLICIES_LINE_GRAPH_OPTIONS,
    CLAIMS_BAR_CHART_OPTIONS,
    CLAIMS_PIE_CHART_OPTIONS,
    //parsePoliciesLineGraphData,
    parseClaimsBarChartData,
    parseClaimsPieChartData,
    parseCombinedPoliciesLineGraphData,
}