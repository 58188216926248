import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input, Row, Table } from "reactstrap";
import OemMappingSearch from './OemMappingSearch';
import OemMappingSearchList from './OemMappingSearchList';
import NoDataFound from './NoDataFound';

const OemMappingAdd = (props) => {
    const {
        boolean,
        form,
        errors,
        handleChange,
        handleResetClick,
        handleSaveClick,
        handleToggleCheckbox,
        onSubmitSearch
    } = props;

    return (
        <>
            <Card>
                <CardHeader>
                    Update OEM Mapping
                </CardHeader>

                <CardBody>

                    <OemMappingSearch
                        boolean={boolean}
                        form={form}
                        handleChange={handleChange}
                        handleResetClick={handleResetClick}
                        onSubmitSearch={onSubmitSearch}
                    />

                    {
                        boolean.searchResult && (form.searchResult.length > 0 ?
                            <OemMappingSearchList
                                form={form}
                                errors={errors}
                                handleSaveClick={handleSaveClick}
                                handleResetClick={handleResetClick}
                                handleToggleCheckbox={handleToggleCheckbox}
                            />
                            :
                            <NoDataFound
                                title={"No make found as search result"}
                            />)
                    }
                </CardBody>
            </Card>
        </>
    );
}

export default OemMappingAdd;