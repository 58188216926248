// import health_logo from '../assets/img/verification/Health_2x.png'
// import life_logo from '../assets/img/verification/Life_2x.png'
// import house_logo from '../assets/img/verification/house_2x.png'
// import pa_logo from '../assets/img/verification/pa.svg'

export const Products = {
    19: {
        provider: "",
        name: "Cyclist Insurance"
    },
    20: {
        provider: "",
        name: "Daily Cash Plan"
    },
    21: {
        provider: "",
        name: "Backpack Insurance"
    },
    22: {
        provider: "",
        name: "Cycle Insurance"
    },
    24: {
        provider: "",
        name: "Daily Cash Plan"
    },
    25: {
        provider: "",
        name: "Best Price Assurance"
    },
    26: {
        provider: "",
        name: "Daily Commute Insurance"
    },
    27: {
        provider: "",
        name: "Chashma Insurance"
    },
    28: {
        provider: "",
        name: "Biker Insurance"
    },
    29: {
        provider: "",
        name: "Instant transfer guarantee"
    },
    30: {
        provider: "",
        name: "Personal Accident"
    },
    31: {
        provider: "",
        name: "Kids Cycle Assurance"
    },
    32: {
        provider: "",
        name: "Chashma Care"
    },
    33: {
        provider: "",
        name: "Cycle Insurance"
    },
    35: {
        provider: "",
        name: "Mobile Screen Only Damage"
    },
    36: {
        provider: "",
        name: "Mobile/Tablet Comprehensive Damage"
    },
    37: {
        provider: "",
        name: "Electricity Payment Assurance"
    },
    38: {
        provider: "",
        name: "Home Appliance Extended Warranty"
    },
    39: {
        provider: "",
        name: "Mosquito Insurance"
    },
    40: {
        provider: "",
        name: "Cyclist Insurance"
    },
    41: {
        provider: "",
        name: "Mobile Screen Protect"
    },
    42: {
        provider: "",
        name: "Personal Accident"
    },
    43: {
        provider: "",
        name: "Apple Watch Damage Protection"
    },
    44: {
        provider: "",
        name: "Macbook Comprehensive Damage"
    },
    45: {
        provider: "",
        name: "Macbook Extended Warranty"
    },
    46: {
        provider: "",
        name: "iWatch Extended Warranty"
    },

    48: {
        provider: 'ICICI Life Insurance',
        name: 'Life Insurance',
        // image: life_logo
    },
    49: {
        provider: 'Care Health Insurance',
        name: 'Health Insurance',
        // image: health_logo
    },
    51: {
        provider: 'Toffee Plan',
        name: 'The Toffee Plan',
    },
    52: {
        provider: 'IndiaFirst Life e-Term Plan',
        name: 'Life Insurance',
        // image: life_logo
    },
    53: {
        provider: 'TATA AIG Medicare Protect',
        name: 'Health Insurance',
        // image: health_logo
    },
    54: {
        provider: 'TATA AIG HomeSecure',
        name: 'Home Insurance',
        // image: house_logo
    },
    55: {
        provider: 'Tata Health Top Up',
        name: 'Health Top Up',
        // image: health_logo
    },
    56: {
        provider: 'Tata AIG Accidental Shield',
        name: 'Personal Accident',
        // image: pa_logo
    },
    58: {
        provider: 'PNB MetLife Complete Care Plus',
        name: 'Life Insurance',
        // image: pa_logo
    },
    '59_increasing_sa': {
        provider: 'IndiaFirst Increasing Sum Assured',
        name: 'Life Insurance',
        // image: pa_logo
    },
    '59_return_of_premium': {
        provider: 'IndiaFirst Return of Premium',
        name: 'Life Insurance',
        // image: pa_logo
    },
    '59_accelerated_critical_illness': {
        provider: 'IndiaFirst Accelerated Critical Illness',
        name: 'Life Insurance',
        // image: pa_logo
    },
    60: {
        provider: 'Bajaj Allianz',
        name: 'Dog Insurance'
    },
    61: {
        provider: "Bajaj Allianz",
        name: "Motor Insurance (BAGIC)"
    },
    64: {
        provider: "Go Digit",
        name: "Motor Insurance (GoDigit)"
    },
    66: {
        provider: "Future Generali",
        name: "Motor Insurance (Future Generali)"
    },
    67: {
        provider: "ICICI Lombard",
        name: "Mobile/Tablet Comprehensive Damage"
    },
    68: {
        provider: "ICICI Lombard General Insurance",
        name: "International Travel Insurance"
    },
    69: {
        provider: "ICICI Lombard General Insurance",
        name: "Domestic Travel Insurance"
    },
    70: {
        provider: "ICICI Lombard General Insurance",
        name: "International Student Travel Insurance"
    },
    71: {
        provider: "Toffee Insurance",
        name: "Cancellation For Any Reason - International"
    },
    75: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    76: {
        provider: "Toffee Insurance",
        name: "RSA"
    },
    77: {
        provider: "Toffee Insurance",
        name: "RSA Plus PA"
    },
    83: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    84: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    85: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    78: {
        provider: "Toffee Insurance",
        name: "Extended Warranty"
    },
    79: {
        provider: "Toffee Insurance",
        name: "Lending (Dummy)"
    },
    80: {
        provider: "Stashfin",
        name: "Lending (Stashfin)"
    },
    81: {
        provider: "L&T Finance",
        name: "Lending (L&T Finance)"
    },
    82: {
        provider: "Cashfree",
        name: "Lending (Cashfree)"
    },
    86: {
        provider: "Toffee Insurance",
        name: "Extended Warranty (Tractor)"
    },
    87: {
        provider: "Toffee Insurance",
        name: "RSA Plus Pa (3W)"
    },
    89: {
        provider: "Toffee Insurance",
        name: "Extended Warranty Plan 5310 (EV)"
    },
    90: {
        provider: "Toffee Insurance",
        name: "Extended Warranty (Upto 150CC)"
    },
    94: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    95: {
        provider: "Toffee Insurance",
        name: "CPA Plus Medical Assistance"
    },
    96: {
        provider: "Toffee Insurance",
        name: "Extended Warranty Plan 4500 (EV)"
    },
    97: {
        provider: "Toffee Insurance",
        name: "CPA Plan 3500"
    },
    98: {
        provider: "Toffee Insurance",
        name: "CPA Plan 3250"
    },
    99: {
        provider: "Toffee Insurance",
        name: "CPA Plan 2800"
    },
    100: {
        provider: "Across Assist",
        name: "CPA Plan 800"
    },
    101: {
        provider: "Across Assist",
        name: "CPA Plan 900"
    },
    102: {
        provider: "Across Assist",
        name: "CPA Plan 1000"
    },
    103: {
        provider: "Across Assist",
        name: "CPA Plan 1050"
    },
    104: {
        provider: "Across Assist",
        name: "CPA Plan 1200"
    },
    105: {
        provider: "Across Assist",
        name: "CPA Plan 1300"
    },
    106: {
        provider: "Across Assist",
        name: "CPA Plan 1400"
    },
    107: {
        provider: "Across Assist",
        name: "CPA Plan 1500"
    },
    108: {
        provider: "Across Assist",
        name: "CPA Plan 1600"
    },
    109: {
        provider: "Across Assist",
        name: "CPA Plan 1700"
    },
    110: {
        provider: "Across Assist",
        name: "CPA Plan 1900"
    },
    111: {
        provider: "Across Assist",
        name: "CPA Plan 2000"
    },
    112: {
        provider: "Across Assist",
        name: "CPA Plan 4250"
    },
    113: {
        provider: "Across Assist",
        name: "CPA Plan 4700"
    },
    114: {
        provider: "Across Assist",
        name: "CPA Plan 5200"
    },
    115: {
        provider: "Across Assist",
        name: "CPA Plan 5400"
    },
    116: {
        provider: "Across Assist",
        name: "CPA Plan 5700"
    },
    117: {
        provider: "Across Assist",
        name: "CPA Plan 6100"
    },
    118: {
        provider: "Across Assist",
        name: "CPA Plan 6700"
    },
    119: {
        provider: "Across Assist",
        name: "CPA Plan 4000"
    },
    120: {
        provider: "Across Assist",
        name: "CPA Plan 3800"
    },
    121: {
        provider: "Across Assist",
        name: "CPA Plan 2300"
    },
    122: {
        provider: "Across Assist",
        name: "CPA Plan 2500"
    },
    123: {
        provider: "Across Assist",
        name: "CPA Plan 2700"
    },
    124: {
        provider: "Across Assist",
        name: "CPA Plan 2100"
    },
    125: {
        provider: "Across Assist",
        name: "CPA Plan 3000"
    },
    126: {
        provider: "Across Assist",
        name: "RSA Plan 750"
    },
    127: {
        provider: "Across Assist",
        name: "RSA Plan 700"
    },
    128: {
        provider: "Across Assist",
        name: "RSA Plan 899"
    },
    129: {
        provider: "Across Assist",
        name: "RSA Plan 799"
    },
}