import { ReducerUtils } from "../constants/reducers";

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.agentExam.studyMaterial:
      return {
        ...state,
        studyMaterial: action.payload,
      };

    case ReducerUtils.agentExam.questions:
      return {
        ...state,
        examQuestions: action.payload,
      };

    case ReducerUtils.agentExam.result:
      return {
        ...state,
        examResult: action.payload,
      };
    
      case ReducerUtils.agent.details:
        return {
          ...state,
          agentDetails: action.payload,
        };

    default:
      return state;
  }
}
