import React, { useContext, useEffect, useReducer, Fragment } from "react";
import { Button, Card, Container, Row, Col, CardBody, CardHeader } from "reactstrap";

// Import all custom default exports
import AgentExamContext from "../../../context/agentExamContext";
import ExamStudyMaterialReducer from "../../../reducers/agentExamReducer";
import useApi from "../../../hooks/useApi";

// Import all third party named exports
// Import here if any

// Import all custom named exports
import { ReducerUtils } from "../../../constants/reducers";
import { redirectWithBlank } from "../../../helpers/utils";

const AgentExamStudyMaterial = (props) => {
  const initialReducerState = useContext(AgentExamContext);
  const [state, dispatch] = useReducer(
    ExamStudyMaterialReducer,
    initialReducerState
  );

  // Call useApi hook to fetch other policy details
  const details = useApi(`exam_study_material`);

  useEffect(() => {
    dispatchEvent(ReducerUtils.agentExam.studyMaterial, details);
  }, [details]);

  const dispatchEvent = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col xs="12">
            <Card className="mt-4">
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h4 className="h4 pt-2 font-weight-400">
                      Agent Study Material
                    </h4>
                  </Col>
                  <Col xs="6"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                {state?.studyMaterial?.length && (
                  <Container fluid>
                    {state?.studyMaterial?.map((item, index) => {
                      return (
                        <Row className="mt-5" key={index}>
                          <Col xs="6">
                            <Row>
                              <Col>{item.title}</Col>
                            </Row>
                            <Row>
                              <Col><span className="text-muted">{item.description}</span></Col>
                            </Row>
                          </Col>
                          <Col xs="3">
                            {/* <span
                              className="text-primary cursor-pointer"
                              onClick={() => redirectWithBlank(item.link)}
                            >
                              Download
                            </span> */}

                            <Button
                                  color="btn-round btn-icon btn btn-primary btn-md"
                                  size="md"
                                  type="button"
                                  onClick={() => redirectWithBlank(item.link)}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-download" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Download
                                  </span>
                                </Button>
                          </Col>
                        </Row>
                      );
                    })}
                  </Container>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AgentExamStudyMaterial;
