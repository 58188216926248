import * as actionTypes from './actionTypes/navbarTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getUserDataStart = () => {
    return {
        type: actionTypes.GET_USER_DATA_START
    };
};


// SUCCESS
export const getUserDataSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_DATA_SUCCESS,
        data: data
    };
};



// FAIL
export const getUserDataFail = (error) => {
    // console.log(error);
    return {
        type: actionTypes.GET_USER_DATA_FAIL,
        error: error
    };
};




// ACTION
export const getUserData = () => {
    return dispatch => {
        dispatch(getUserDataStart());
        let params = {}
        AxiosReactClient.getRequest('user/me', params).then(response => {
            dispatch(getUserDataSuccess(response.data));
        })
            .catch(err => {
                // console.log(err);
                if (err.response)
                    dispatch(getUserDataFail(err.response.data.error));
                else
                    dispatch(getUserDataFail({ code: 502, message: err.message }));
            });
    };
};