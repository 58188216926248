import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import * as actions from '../../../Store/actions';
import Loader from '../../../components/CustomUi/Loader/Loader';
import AxiosReactClient from '../../../utilities/AxiosRestClient';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import Spinner from "../../../components/Spinner/Spinner";
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import CustomDateSelect from '../../../components/Filters/CustomDateSelect';
import {
    Breadcrumb,
    BreadcrumbItem,
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    FormGroup,
    Form,
    Modal,
    Container,
    Row,
    Col
} from "reactstrap";

import Can from '../../../config/Casl/Can'
import * as permissions from '../../../config/Casl/permissions';
import ability from '../../../config/Casl/ability';

const orderIdSearch = /^[ a-zA-Z&0-9_-]*$/;

class DealersListing extends Component {
    previousMonthDate = (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1)

    constructor(props) {
        super(props);
        this.state = {
            hideFilters: false,
            dealerFilter: '',
            cardFilter: 'all',
            startDateFilter: '',
            endDateFilter: '',
            searchText: "",
            view: "dealer-table",
            openMonthDialog: false,
            selectedKam: '',
            selectedDealer: null,
            showLoader: false,
            selectedCardsFilterDate: '',
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            selectedDate: (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
        };
        props.onGetDealerDealerCardsData({})
        this.getDealers()
        props.onGetKamListData()
    }

    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
        dealerId: '',
        sortBy: 0
    }
    updatePagination = (total) => {
        this.pager.pageArray = [];
        this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
        for (let index = 0; index < this.pager.pagerEnd; index++) {
            this.pager.pageArray.push(index + 1)
        }
    }
    switchPage = (page) => {
        if (((this.pager.pagerStart + 1) !== page)) {
            this.pager.pagerStart = page - 1
            this.getDealers()
        }
    }
    getDealers = () => {
        this.props.onGetDealerDealerListData({}, {
            start: this.state.searchText != '' ? 0 : this.pager.pagerStart,
            sortBy: this.pager.sortBy,
            dealer_search: this.state.searchText,
            startDate: this.state.startDateFilter ? new Date(this.state.startDateFilter).toDateString() : '',
            endDate: this.state.endDateFilter ? new Date(this.state.endDateFilter).toDateString() : '',
            date: this.state.endDateFilter ? 'custom' : '',
            is_agent: 0
        })
    }
    isHidden = (page) => {
        var start = this.pager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
                return true;
            } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    resetFilters = () => {
        this.pager.pagerStart = 0
        this.pager.sortBy = 0
        this.setState({
            dealerFilter: '',
            searchText: '',
            startDateFilter: '',
            endDateFilter: '',
        }, () => {
            this.getDealers()
        })
    }
    onSearch = (value) => {
        if (!this.state.searchText || this.state.searchText.length < 2) {
            this.showMsg(" Please, enter atlest 2 characters", "danger")
            return
        }
        if (!this.state.searchText.match(orderIdSearch)) {
            this.showMsg(" Please, don't enter any special characters", "danger")
            return
        }
        this.getDealers()
        this.setState({
            view: "table"
        })
    }
    onRefreshAllCards = (filter) => {
        this.setState({
            cardFilter: filter,
            selectedCardsFilterDate: ''
        })
        this.props.onGetDealerDealerCardsData({})
    }
    handleClickOutside = (e) => {
        let classArray = [];
        if (e.path) {
            e.path.forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        } else {
            e.composedPath().forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        }
        if (!classArray.includes('reactDateTimeCustomClass')) {
            this.setState({
                openMonthDialog: false
            })
        }

    }

    handleReactMonthFilterChange(e) {
        this.setState({
            cardFilter: 'custom',
            selectedCardsFilterDate: e._d
        })
        this.props.onGetDealerDealerCardsData({month: e._d.getMonth() + 1, year: e._d.getFullYear()})
    }

    getMonthYear = (date) => {
        return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
    }
    validDateMonthFilter = (current) => {
        var thisMonth = new Date();
        return (current.isBefore(thisMonth));
    };
    sortByColumn = (column_no) => {
        this.pager.sortBy = column_no;
        this.getDealers()
    }
    openKamModel = (dealer_id, kam_id) => {
        this.setState({
            showKamModel: true,
            selectedDealer: dealer_id,
            selectedKam: kam_id
        })
    }
    onUpdateKam = (id, kam_id) => {
        this.sendUpdatedKam(id, {id: kam_id})
    }
    sendUpdatedKam = (id, payload) => {
        this.setState({
            showLoader: true
        })
        AxiosReactClient.putRequest(`dealers/` + id + `/kam`, payload).then(response => {
            this.setState({
                showLoader: false,
                showKamModel: false

            })
            this.showMsg("Kam updated successfully", "success")
            this.getDealers()
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    dateChangeFilter = (date, type) => {

        switch (type) {
            case 'startDate':
                this.setState({
                    startDateFilter: date
                }, () => {
                    this.getDealers()
                })

                break;
            case 'endDate':
                this.setState({
                    endDateFilter: date
                }, () => {
                    this.getDealers()
                })
                break;
            default:
                break;
        }
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }

    render() {
        this.dealerList = this.props.dealerDealerListData ? this.props.dealerDealerListData : [];
        this.dealerCards = this.props.dealerDealerCardsData ? this.props.dealerDealerCardsData : "";
        (this.props.dealerTotalCount !== null) && this.updatePagination(this.props.dealerTotalCount)

        return (<>
                <Fragment>
                    <Container fluid>
                        <CardHeader className="border-0 px-0 bg-transparent ">
                            <Row>
                                <Col xs="6">
                                    <Breadcrumb
                                        className="d-none d-md-inline-block ml-md-0"
                                        listClassName="breadcrumb-links breadcrumb-dark"
                                    >
                                        <BreadcrumbItem>
                                            <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                                                <i className="fas fa-home"/>
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" className="text-primary h5"
                                               onClick={e => e.preventDefault()}>
                                                Dealers
                                            </a>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <a href="#pablo" className="text-primary h5"
                                               onClick={e => e.preventDefault()}>
                                                All
                                            </a>
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <div className="d-inline">

                                        {/* <Badge
                                            color={this.state.cardFilter === 'all' ? 'primary' : 'secondary'}
                                            href="#pablo"
                                            pill
                                            onClick={e => {
                                                e.preventDefault();
                                                this.onRefreshAllCards('all')
                                            }}
                                        >
                                            All</Badge> */}

                                        <Badge
                                            color={this.state.cardFilter === 'custom' ? 'primary' : 'secondary'}
                                            href="#pablo"
                                            pill
                                            onClick={e => {
                                                e.preventDefault();
                                                this.setState({
                                                    openMonthDialog: true,
                                                })
                                            }}
                                        >
                                            {
                                                this.state.cardFilter === 'custom' ? this.getMonthYear(this.state.selectedCardsFilterDate) : "Select Month"

                                            }   </Badge>

                                        <ReactDatetime
                                            className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                                            open={this.state.openMonthDialog}
                                            onFocus={e => {
                                                this.setState({
                                                    openMonthDialog: true
                                                })
                                            }}
                                            onBlur={e => {
                                                this.setState({
                                                    openMonthDialog: false
                                                })
                                            }}
                                            disableCloseOnClickOutside={false}
                                            inputProps={{
                                                placeholder: "Month"
                                            }}
                                            value={this.state.selectedCardsFilterDate}
                                            dateFormat="MMM-YYYY"
                                            timeFormat={false}
                                            onChange={e => {
                                                this.handleReactMonthFilterChange(e)
                                                this.setState({
                                                    openMonthDialog: false
                                                })
                                            }}
                                            isValidDate={this.validDateMonthFilter}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Can I={permissions.DEALER.cards} a={permissions.DEALER.subject}>
                            <Row>
                                <Col md="6" xl="3">
                                    <Card className="bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted text-white mb-0"
                                                    >
                                                        Active / Total Dealers
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold text-white mb-0">
                                                    {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.totalActiveDealers :
                                                        <Shimmer/>} / {" "}
                                                        {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.totalDealers :
                                                            <Shimmer/>}
                                                </span>
                                                </div>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(new Date()) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted text-white mb-0"
                                                    >
                                                        Cycle / Mobile Dealers
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold text-white mb-0">
                                                    {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.cycleDealers :
                                                        <Shimmer/>} / {" "}
                                                        {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.mobileDealers :
                                                            <Shimmer/>}
                                                </span>
                                                </div>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(new Date()) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className="bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted text-white mb-0"
                                                    >
                                                        Total Policies
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold text-white mb-0">
                                                    {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.policiesSold :
                                                        <Shimmer/>}
                                                </span>
                                                </div>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(new Date()) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="6" xl="3">
                                    <Card className=" bg-gradient-primary card-stats">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted text-white mb-0"
                                                    >
                                                        Cycle / Mobile Policies
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold text-white mb-0">
                                                    {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.cyclePolicy :
                                                        <Shimmer/>} / {" "}
                                                        {(!this.props.dealerDealerCardsLoading && this.dealerCards) ? this.dealerCards.mobilePolicy :
                                                            <Shimmer/>}
                                                </span>
                                                </div>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                            <span className="text-nowrap text-light">
                                                {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(new Date()) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                                            </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Can>
                        <Row className="px-3">
                            <Col xs={12} sm={6} className="pl-0">
                                <h5 className="text-primary h5 font-weight-400">All Dealers</h5>
                            </Col>
                            <Col className="pr-0 pb-1">
                                <div
                                    className="float-right"
                                >
                                    <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button"
                                            onClick={e => {
                                                this.setState({
                                                    hideFilters: !this.state.hideFilters
                                                })
                                            }}>
                                    <span className="btn-inner--icon">
                                        <i className="fas fa-filter"></i>
                                    </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
                                <Col className="p-1 px-3 text-muted">
                                    <small>
                                        <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline"
                                              action="javascript:void(-1)">
                                            <input className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                                                   value={this.state.searchText}
                                                   onKeyDown={e => {
                                                       (e.key === 'Enter') && this.onSearch(e.target.value)
                                                   }}
                                                   onChange={e => {
                                                       if (e.target.value == " ") {
                                                           this.setState({
                                                               view: "dealer-table"
                                                           })
                                                       }
                                                       this.setState({
                                                           searchText: e.target.value
                                                       })
                                                   }}
                                                   type="text" placeholder="ID / Name / Dealership / Kam"
                                                   aria-label="Search"/>
                                            <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                               onClick={
                                                   e => {
                                                       e.preventDefault()
                                                       this.onSearch(this.state.searchText)
                                                   }}
                                            />
                                        </form>
                                    </small>
                                    {(this.state.searchText !== "") && (this.state.view === "table") &&
                                    <Badge color="primary" pill>
                                        {this.state.searchText}
                                        <span className="p-1 ml-2 cursor-pointer" onClick={
                                            e => {
                                                e.preventDefault()
                                                this.resetFilters()
                                                this.setState({
                                                    view: "dealer-table",
                                                    searchText: ""
                                                })
                                            }}>
                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                    </span>
                                    </Badge>}
                                </Col>
                                <Col className="p-1 text-right px-3">
                                    <CustomDateSelect selectedDate={this.state.startDateFilter} dateChanged={date => {
                                        this.dateChangeFilter(date, 'startDate')
                                    }} name="From Date"></CustomDateSelect>
                                    <CustomDateSelect selectedDate={this.state.endDateFilter} dateChanged={date => {
                                        this.dateChangeFilter(date, 'endDate')
                                    }} name="To Date"></CustomDateSelect>
                                    <Button color="secondary " outline size="sm" type="button" onClick={e => {
                                        e.preventDefault()
                                        this.resetFilters()
                                    }}>
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <Card className="m-0 mt-1">
                            <CardBody className="px-0 py-0" style={{height: "2066px", overflowY: "scroll"}}>
                                <div className=" table-responsive">
                                    <Table className="align-items-center table-flush" hover responsive>
                                        <thead className="thead-light">
                                        <tr>
                                            <th>Dealer ID</th>
                                            <th>Dealer / Dealership</th>
                                            {/* <th>Exposure</th> */}
                                            <th>Partner Name</th>
                                            <th className={"cursor-pointer " + (this.pager.sortBy === 0 && ' text-primary')}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.sortByColumn(0)
                                                }}>Onboarded On <i className="fas fa-sort"/></th>
                                            {/* <th className={"cursor-pointer " + (this.pager.sortBy === 1 && ' text-primary')}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.sortByColumn(1)
                                                }}>Policies sold <i className="fas fa-sort"/></th> */}
                                            {/* <th>Credit (Available / Total)</th> */}
                                            {/* <th className={"cursor-pointer " + (this.pager.sortBy === 2 && ' text-primary')}
                                                onClick={e => {
                                                    e.preventDefault()
                                                    this.sortByColumn(2)
                                                }}>Commission earned <i className="fas fa-sort"/></th> */}
                                            <th className="text-right">Key Account Manager (KAM)</th>
                                        </tr>
                                        </thead>
                                        <tbody style={{height: "466px"}}>
                                        {
                                            this.props.dealerDealerListLoading && <tr>
                                                <td colSpan="12">
                                                    <Spinner></Spinner>
                                                </td>
                                            </tr>
                                        }
                                        {!this.props.dealerDealerListLoading && this.props.dealerDealerListData && this.props.dealerDealerListData.length < 1 &&
                                        <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                                            <td colSpan="12" className="text-center">
                                                <span className="text-muted">
                                                    <b>
                                                        No Dealers Data found
                                                    </b>
                                                </span>
                                            </td>
                                        </tr>}
                                        {!this.props.dealerDealerListLoading && this.dealerList.map((dealer, index) => (
                                            <tr key={"hjsdfae_sdf" + index}>
                                                <td className="table-user" >
                                                    {dealer.id}
                                                    {dealer.parent_id &&
                                                    <>
                                                        <br />
                                                        <span className="text-primary cursor-pointer"
                                                              onClick={(e) => {
                                                                    e.preventDefault()
                                                                    this.props.history.push("/admin/dealers/" + dealer.parent_id)
                                                                }}>
                                                            PARENT DEALER ID - {dealer.parent_id}
                                                        </span>
                                                    </>}
                                                </td>
                                                {ability.can(permissions.DEALER.show, permissions.DEALER.subject) ?
                                                    <td className="cursor-pointer" onClick={(e) => {
                                                        e.preventDefault()
                                                        this.props.history.push("/admin/dealers/" + dealer.id)
                                                    }} title={"Click here for details about " + dealer.name}>
                                                        <span className="text-primary"> <b>{dealer.name}</b></span><br/>
                                                        {dealer.dealership_name}
                                                    </td> : <td>{dealer.name} <br/>
                                                        {dealer.dealership_name}
                                                    </td>
                                                }
                                                {/* <td>
                                                    {dealer.exposure}
                                                </td> */}
                                                <td>
                                                    {dealer.partner && dealer.partner.name}
                                                </td>
                                                <td>
                                                    <span className="text-muted">{dealer.onboarded_on}</span>
                                                </td>
                                                {/* <td>
                                                    {dealer.total_sales}
                                                </td> */}
                                                {/* <td>
                                                    {(dealer.available_credit) ? dealer.available_credit : "0"} / {" "}
                                                    {(dealer.total_credit) ? dealer.total_credit : "0"}
                                                </td>
                                                <td>
                                                    {dealer.commission}
                                                </td> */}
                                                <td className="text-right">
                                                    <span
                                                        className="text-muted pr-3 text-left">{dealer && dealer.kam_name}</span>
                                                    <Can I={permissions.DEALER.kam_update}
                                                         a={permissions.DEALER.subject}>
                                                        <Button
                                                            color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                                            size="sm" type="button"

                                                            onClick={e => {
                                                                this.openKamModel(dealer.id, dealer.kam_id)
                                                            }}
                                                        >
                                                            <span className="btn-inner--icon mr-1">
                                                                <i className="fas fa-edit"/>
                                                            </span>
                                                            <span className="btn-inner--text">
                                                                Edit
                                                        </span>
                                                        </Button>
                                                    </Can>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.switchPage(this.pager.pagerStart);
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left"/>
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {this.pager.pageArray.map(page => {
                                            return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)}
                                                                    className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.switchPage(page);
                                                    }}
                                                >
                                                    {page}
                                                </PaginationLink>
                                            </PaginationItem>
                                        })}
                                        <PaginationItem
                                            className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.switchPage(this.pager.pagerStart + 2);
                                                }}
                                            >
                                                <i className="fas fa-angle-right"/>
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav>
                            </CardFooter>
                        </Card>
                        <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={this.state.showKamModel}
                            toggle={() => {
                                this.setState({
                                    showKamModel: !this.state.showKamModel
                                })
                            }}
                        >
                            <div className="modal-body">
                                {!this.state.showLoader ?
                                    <Form role="form">
                                        <Row className="input-daterange datepicker align-items-center">
                                            <Col>
                                                <FormGroup>
                                                    <label className=" form-control-label"
                                                           htmlFor="amountInput">
                                                        Edit Kam
                                                    </label>
                                                    <Select2
                                                        className="form-control"
                                                        defaultValue=""
                                                        options={{
                                                            placeholder: "Search"
                                                        }}
                                                        value={this.state.selectedKam}
                                                        data={this.props.kamListData && this.props.kamListData.map(({id, name}) => {
                                                            return {id: id, text: name}
                                                        })}
                                                        onChange={e => {
                                                            this.setState({
                                                                selectedKam: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form> :
                                    <div className="position-relative">
                                        {this.state.showLoader && <Loader msg={"Updating"}/>}
                                    </div>
                                }
                            </div>
                            <div className="modal-footer date-modal-footer ">
                                <Button
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        this.setState({
                                            showKamModel: false
                                        })
                                    }}
                                >
                                    Close
                                </Button>
                                <Button color="primary"
                                        className="ml-auto" type="button"
                                        size="sm"
                                        onClick={e => {
                                            this.onUpdateKam(this.state.selectedDealer, this.state.selectedKam)
                                        }}
                                >
                                    Update
                                </Button>
                            </div>
                        </Modal>
                    </Container>
                </Fragment>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        dealerDealerListLoading: state.dealer.dealerDealerListLoading,
        dealerDealerCardsLoading: state.dealer.dealerDealerCardsLoading,
        kamListLoading: state.dealer.kamListLoading,

        dealerDealerListData: state.dealer.dealerDealerListData,
        dealerTotalCount: state.dealer.totalDealerListCount,
        dealerDealerCardsData: state.dealer.dealerDealerCardsData,
        kamListData: state.dealer.kamListData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetDealerDealerListData: (filter, params) => dispatch(actions.getDealerDealerListData(filter, params)),
        onGetDealerDealerCardsData: (data) => dispatch(actions.getDealerDealerCardsData(data)),
        onGetKamListData: (id, data) => dispatch(actions.getKamListData(id, data)),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DealersListing);