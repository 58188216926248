import React, {Component, Fragment} from 'react';
import ReactDatetime from "react-datetime";
import {
    Card,
    PaginationItem,
    Pagination,
    PaginationLink,
    Container,
    Row,
    Col,
    BreadcrumbItem,
    Breadcrumb,
    Badge,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Table
} from "reactstrap";
import CustomDropdown from '../../../components/Filters/CustomDropdown';
import CustomDateSelect from '../../../components/Filters/CustomDateSelect';
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
import {withRouter} from "react-router-dom";

class AllQuotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            }
        this.getQuotations()
    }

    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
        sortBy: 0
    }
    getQuotations = () => {
        // this.props.onGetQuotationsListData({}, {
        //     page: this.pager.pagerStart,
        //     sortBy: this.pager.sortBy,
        // })
    }

    updatePagination = (total) => {
        this.pager.pageArray = [];
        this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
        for (let index = 0; index < this.pager.pagerEnd; index++) {
            this.pager.pageArray.push(index + 1)
        }
    }
    switchPage = (page) => {
        if (((this.pager.pagerStart + 1) !== page)) {
            this.pager.pagerStart = page - 1
            this.getPolicies()
        }
    }
    isHidden = (page) => {
        var start = this.pager.pagerStart + 1;
        if (page > start) {
            if ((page - start) > 2 && (start === 1)) {
                return true;
            } else if ((start !== 1) && ((page - start) > 1)) {
                return true;
            }
        } else {
            if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
                return true;
            } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
                return true;
            }
        }
        return false
    }


    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        const {quotationListData, quotationListLoading} = this.props;
        (this.props.quotationTotalCount !== null) && this.updatePagination(this.props.quotationTotalCount)
        return (
            <Fragment>

                <Container fluid>

                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="8" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block "
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => {
                                            e.preventDefault()
                                        }}>
                                            Quotations
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>

                        </Row>
                    </div>

                   <div className={"d-flex justify-content-end mb-4"}>
                       <button
                           className={'btn btn-icon btn-primary btn-lg'}
                           type={'button'}
                           onClick={(e) => {
                               e.preventDefault();
                               this.props.history.push('/admin/quotation/create-quotation')
                           }}
                       >
                           <b>Create Quotation</b>
                       </button>
                   </div>
                    <Card className="m-0 mt-1" style={{height: "1708px", overflowY: "scroll"}}>
                        <CardBody className="px-0 py-0">
                            <div className="table-responsive">
                                <Table className="align-items-center table-flush" hover responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Policy ID</th>
                                        <th>Product</th>
                                        <th>Provider</th>
                                        <th>Insured Name</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Purchased Date</th>
                                        <th>Premium</th>
                                        <th className="text-right">Status</th>
                                        {/*<th className="text-right">Actions</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {quotationListLoading &&
                                    <tr>
                                        <td colSpan="12">
                                            <Spinner></Spinner>
                                        </td>
                                    </tr>
                                    }
                                    {!quotationListLoading && quotationListData && quotationListData.length < 1 &&
                                    <tr className="cursor-pointer">
                                        <td colSpan="12" className="text-center">
                                                <span className="text-muted">
                                                    <b>
                                                        No policy Data found
                                                    </b>
                                                </span>
                                        </td>
                                    </tr>}
                                    {quotationListData && quotationListData.map((item, index) => {
                                        return <tr key={index + 'hgshdsh'}>
                                            <td className="table-user">
                                                <a className="font-weight-bold"
                                                   href="#"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       this.props.history.push("/admin/policies/" + item.id)
                                                   }
                                                   }>
                                                    {item.id}</a>
                                            </td>
                                            <td>
                                                {item.product_name}
                                            </td>
                                            <td>
                                                {item.provider_name}
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>
                                                {item.start_date}
                                            </td>
                                            <td>
                                                {item.end_date}
                                            </td>
                                            <td>
                                                {item.policy_creation_ts}
                                            </td>
                                            <td>
                                                {item.premium}
                                            </td>
                                            <td className="text-right">
                                                {item.status}
                                            </td>
                                            {/*<td className="text-right">*/}
                                            {/*    Action*/}
                                            {/*</td>*/}
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter className="py-4">
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart);
                                            }}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left"/>
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {this.pager.pageArray.map(page => {
                                        return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)}
                                                                className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.switchPage(page);
                                                }}
                                            >
                                                {page}
                                            </PaginationLink>
                                        </PaginationItem>
                                    })}
                                    <PaginationItem
                                        className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart + 2);
                                            }}
                                        >
                                            <i className="fas fa-angle-right"/>
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </CardFooter>
                    </Card>

                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        quotationListLoading: state.policies.quotationListLoading,
        quotationListData: state.policies.quotationListData,
        quotationTotalCount: state.policies.quotationTotalCount,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        // onGetQuotationListData: (filter, params) => dispatch(actions.getQuotationListData(filter, params)),


    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllQuotations));