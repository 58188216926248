import React, { useContext, useEffect } from 'react';
import {
  Row,
  Col
} from 'reactstrap';
import ReactDatetime from "react-datetime";
import AddMoreInputBox from '../../../components/AddMoreCoverage/InputBox';
import AddMoreButton from '../../../components/AddMoreCoverage/Button';
import OtherPolicyContext from '../../../context/otherPolicyContext';
import Spinner from '../../../components/Spinner/Spinner';
import { isValidDate } from '../../../helpers/otherPolicyDetails';

const OtherPolicyForm = (props) => {
  const {
    addItem,
    errorMessage,
    form,
    handleCoveredChange,
    handleDateChange,
    handleInputChange,
    isFormSubmitted,
    onSubmit,
    options,
    setForm,
    removeItem,
    validataInputLength
  } = props;
  const { state: { othePolicyDetails = {} } } = useContext(OtherPolicyContext);

  useEffect(() => {
    if (othePolicyDetails.responses) {
      setForm({
        ...form,
        policy_holder_name: othePolicyDetails.responses.policy_holder_name || '',
        age: othePolicyDetails.responses.age || '',
        gender: othePolicyDetails.responses.gender || '',
        marital_status: othePolicyDetails.responses.marital_status || '',
        dependents: othePolicyDetails.responses.dependents || '',
        pincode: othePolicyDetails.responses.pincode || '',
        city: othePolicyDetails.responses.city || '',
        state: othePolicyDetails.responses.state || '',
        address_line_1: othePolicyDetails.responses.address_line_1 || '',
        address_line_2: othePolicyDetails.responses.address_line_2 || '',
        landmark: othePolicyDetails.responses.landmark || '',
        contact: othePolicyDetails.responses.contact || '',
        email: othePolicyDetails.responses.email || '',
        occupation: othePolicyDetails.responses.occupation || '',
        company_name: othePolicyDetails.responses.company_name || '',
        annual_income: othePolicyDetails.responses.annual_income || '',

        // Policy Details Fields
        policy_number: othePolicyDetails.responses.policy_number || '',
        sum_insured: othePolicyDetails.responses.sum_insured || '',
        issued_date: othePolicyDetails.responses.issued_date || '',
        expiry_date: othePolicyDetails.responses.expiry_date || '',
        policy_tenure: othePolicyDetails.responses.policy_tenure || '',
        policy_premium: othePolicyDetails.responses.policy_premium || '',
        premium_tenure: othePolicyDetails.responses.premium_tenure || '',
        plan_option_name: othePolicyDetails.responses.plan_option_name || '',
        insurance_provider: othePolicyDetails.responses.insurance_provider || '',
        payout_option: othePolicyDetails.responses.payout_option || '',
        renewal_date: othePolicyDetails.responses.renewal_date || '',
        next_payment_date: othePolicyDetails.responses.next_payment_date || '',

        // Nominee Details Fields
        nominee_name: othePolicyDetails.responses.nominee_name || '',
        nominee_relation: othePolicyDetails.responses.nominee_relation || '',
        nominee_mobile: othePolicyDetails.responses.nominee_mobile || '',
        nominee_email: othePolicyDetails.responses.nominee_email || '',

        // Covered Details
        covered: othePolicyDetails.responses.covered.length ? othePolicyDetails.responses.covered : [{title: ''}],
        not_covered: othePolicyDetails.responses.not_covered.length ? othePolicyDetails.responses.not_covered : [{title: ''}]
      });
    }
  }, [othePolicyDetails]);

  return (
    <>
      <Row>
        <Col xl="12" className="fsize16">
          Fill the details from the Policy document on the left
        </Col>
      </Row>
      <hr className={"p-0 m-24"}/>
      <form
        onSubmit={onSubmit}
      >
        <Row>
          <Col xl="12">
            <div className={"py-3 pl-2"}>
              <h4 className={"text-gray text-bold"}>
                PERSONAL DETAILS
              </h4>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Policyholder Name</label>
                <input className="form-control" type="text" name="policy_holder_name" placeholder="" fullwidth="true" 
                  value={form.policy_holder_name} onChange={handleInputChange} />
                {errorMessage.policy_holder_name && <small className="text-danger">{errorMessage.policy_holder_name}</small>}
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Age</label>
                <input className="form-control" type="number" name="age" placeholder="" fullwidth="true" 
                  value={form.age} onChange={handleInputChange} />
                {errorMessage.age && <small className="text-danger">{errorMessage.age}</small>}
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Gender</label>
                <select className="form-control" name="gender" fullwidth="true" value={form.gender} onChange={handleInputChange}>
                  <option value={""}>Select</option>
                  {options.gender.map((item, index) => (
                    <option value={item.value} key={index}>{item.title}</option>
                  ))}
                </select>
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Marital Status</label>
                <select className="form-control" name="marital_status" fullwidth="true" value={form.marital_status} onChange={handleInputChange}>
                  <option value={""}>Select</option>
                  {options.maritalStatus.map((item, index) => (
                    <option value={item.value} key={index}>{item.title}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Dependents</label>
                <input className="form-control" type="number" name="dependents" placeholder="" fullwidth="true" 
                  value={form.dependents} onChange={handleInputChange} onInput={validataInputLength} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Pincode</label>
                <input className="form-control" type="number" name="pincode"  placeholder="" fullwidth="true" value={form.pincode} 
                  onChange={handleInputChange} onInput={validataInputLength} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">City</label>
                <input className="form-control" type="text" name="city" placeholder="" fullwidth="true" 
                  value={form.city} onChange={handleInputChange} 
                  disabled={true} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">State</label>
                <input className="form-control" type="text" name="state" placeholder="" fullwidth="true" 
                  value={form.state} onChange={handleInputChange} 
                  disabled={true} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Address Line1</label>
                <input className="form-control" type="text" name="address_line_1" placeholder="" fullwidth="true" 
                  value={form.address_line_1} onChange={handleInputChange} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Address Line2</label>
                <input className="form-control" type="text" name="address_line_2" placeholder="" fullwidth="true" 
                  value={form.address_line_2} onChange={handleInputChange} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Landmark</label>
                <input className="form-control" type="text" name="landmark" placeholder="" fullwidth="true" 
                  value={form.landmark} onChange={handleInputChange} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Contact</label>
                <input className="form-control" type="number" name="contact" placeholder="" fullwidth="true" 
                  value={form.contact} onChange={handleInputChange} onInput={validataInputLength} />
                {errorMessage.contact && <small className="text-danger">{errorMessage.contact}</small>}
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Email</label>
                <input className="form-control" type="email" name="email" placeholder="" fullwidth="true"  
                  value={form.email} onChange={handleInputChange} />
                {errorMessage.email && <small className="text-danger">{errorMessage.email}</small>}
              </div>
              <div className="form-group w-100 px-2">
                {/* <label htmlFor="example-text-input" className="form-control-label">Occupation</label>
                <input className="form-control" type="text" name="occupation" placeholder="" fullwidth="true" 
                  value={form.occupation} onChange={handleInputChange} /> */}
                <label htmlFor="example-text-input" className="form-control-label">Occupation</label>
                <select className="form-control" name="occupation" fullwidth="true" value={form.occupation} onChange={handleInputChange}>
                  <option value={""}>Select</option>
                  {options.occupation.map((item, index) => (
                    <option value={item.value} key={index}>{item.title}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Company Name (if mentioned)</label>
                <input className="form-control" type="text" name="company_name" placeholder="" fullwidth="true" 
                  value={form.company_name} onChange={handleInputChange} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Annual Income</label>
                <input className="form-control" type="number" name="annual_income" placeholder="" 
                  value={form.annual_income} fullwidth="true" onChange={handleInputChange} onInput={validataInputLength} />
              </div>
            </div>

            <hr className={"p-0 py-1"}/>
            
            <div className={"py-3 pl-2"}>
              <h4 className={"text-gray text-bold"}>
                POLICY DETAILS
              </h4>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Policy Number</label>
                <input className="form-control" type="text" name="policy_number" placeholder="" fullwidth="true" 
                  value={form.policy_number} onChange={handleInputChange} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Sum Assured</label>
                <input className="form-control" type="number" name="sum_insured" placeholder="" fullwidth="true" 
                  value={form.sum_insured} onChange={handleInputChange} onInput={validataInputLength} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Issued Date</label>
                <ReactDatetime 
                  closeOnSelect={true}
                  dateFormat="DD-MMM-YYYY"
                  onChange={date => {handleDateChange(date, 'issued_date')}}
                  timeFormat={false}
                  value={form.issued_date}
                  isValidDate={ (current) =>isValidDate(current, 'issued_date') } 
                />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Expiry Date</label>
                <ReactDatetime
                  closeOnSelect={true} 
                  dateFormat="DD-MMM-YYYY"
                  onChange={date => {handleDateChange(date, 'expiry_date')}}
                  timeFormat={false} 
                  value={form.expiry_date}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Policy Tenure</label>
                <input className="form-control" type="number" name="policy_tenure" placeholder="" fullwidth="true" 
                  value={form.policy_tenure} onChange={handleInputChange} onInput={validataInputLength} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Policy Premium</label>
                <input className="form-control" type="number" name="policy_premium" placeholder="" fullwidth="true" 
                  value={form.policy_premium} onChange={handleInputChange} onInput={validataInputLength} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Payment Tenure</label>
                <input className="form-control" type="number" name="premium_tenure" placeholder="" fullwidth="true" 
                  value={form.premium_tenure} onChange={handleInputChange} onInput={validataInputLength} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Plan Option Name</label>
                <input className="form-control" type="text" name="plan_option_name" placeholder="" fullwidth="true" 
                  value={form.plan_option_name} onChange={handleInputChange} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Insurance Provider</label>
                <input className="form-control" type="text" name="insurance_provider" placeholder="" fullwidth="true" 
                  value={form.insurance_provider} onChange={handleInputChange} />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Payout Option</label>
                <input className="form-control" type="text" name="payout_option" placeholder="" fullwidth="true" 
                  value={form.payout_option} onChange={handleInputChange} />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Renewal Date</label>
                <ReactDatetime
                  closeOnSelect={true} 
                  dateFormat="DD-MMM-YYYY"
                  onChange={date => {handleDateChange(date, 'renewal_date')}}
                  timeFormat={false} 
                  value={form.renewal_date}
                />
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Next Payment Date</label>
                <ReactDatetime
                  closeOnSelect={true} 
                  dateFormat="DD-MMM-YYYY"
                  onChange={date => {handleDateChange(date, 'next_payment_date')}}
                  timeFormat={false} 
                  value={form.next_payment_date}
                />
              </div>
            </div>

            <hr className={"p-0 py-1"}/>

            <div className={"py-3 pl-2"}>
              <h4 className={"text-gray text-bold"}>
                NOMINEE DETAILS
              </h4>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Name</label>
                <input className="form-control" type="text" name="nominee_name" placeholder="" fullwidth="true" 
                  value={form.nominee_name} onChange={handleInputChange} />
                {errorMessage.nominee_name && <small className="text-danger">{errorMessage.nominee_name}</small>}
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Relation</label>
                <select className="form-control" name="nominee_relation" fullwidth="true" value={form.nominee_relation} onChange={handleInputChange}>
                  <option value={""}>Select</option>
                  {options.relation.map((item, index) => (
                    <option value={item.value} key={index}>{item.title}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Phone Number</label>
                <input className="form-control" type="number" name="nominee_mobile" placeholder="" fullwidth="true" 
                  value={form.nominee_mobile} onInput={validataInputLength} onChange={handleInputChange} />
                {errorMessage.nominee_mobile && <small className="text-danger">{errorMessage.nominee_mobile}</small>}
              </div>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">Email</label>
                <input className="form-control" type="email" name="nominee_email" placeholder="" fullwidth="true" 
                  value={form.nominee_email} onChange={handleInputChange} />
                {errorMessage.nominee_email && <small className="text-danger">{errorMessage.nominee_email}</small>}
              </div>
            </div>

            <hr className={"p-0 py-1"}/>

            <div className={"py-3 pl-2"}>
              <h4 className={"text-light text-bold"}>
                COVERAGES
              </h4>
            </div>
            
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">What's Covered?</label>
                <AddMoreInputBox 
                  form={form}
                  handleCoveredChange={handleCoveredChange}
                  keyname={'covered'}
                  removeItem={removeItem}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2 text-right">
                <AddMoreButton 
                  addItem={addItem}
                  form={form}
                  keyname={'covered'}
                />
              </div>
            </div>

            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2">
                <label htmlFor="example-text-input" className="form-control-label">What's Not Covered?</label>
                <AddMoreInputBox 
                  form={form}
                  handleCoveredChange={handleCoveredChange}
                  keyname={'not_covered'}
                  removeItem={removeItem}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-around px-4"}>
              <div className="form-group w-100 px-2 text-right">
                <AddMoreButton 
                  addItem={addItem}
                  form={form}
                  keyname={'not_covered'}
                />
              </div>
            </div>

            <div className={"d-flex justify-content-around px-4 mt-4"}>
              <small>Note: In order to submit the details it is expected to fill the fields like (Policy Number, Sum Assured, Policy Tenure, Policy Premium and Insurance Provider), but not mandatory.</small>
            </div>

            <div className={"d-flex justify-content-around px-4 mt-4"}>
              <div className="form-group w-100 px-2 text-right">
                <button className="btn btn-primary" type="submit"
                  { ...( (form.hasError || isFormSubmitted) && { disabled: true } ) }
                  >
                  {isFormSubmitted ? <Spinner /> : 'Submit Details'}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default OtherPolicyForm;