import { ReducerUtils } from "../constants/reducers";
import { compareValues } from "../helpers/utils";

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.unifiedPolicies.list:
      return {
        ...state,
        unifiedPolicesList: action.payload,
        unifiedPolicesListApi: action.payload,
      };

    case ReducerUtils.unifiedPolicies.search:
      const query = action.payload;

      if (query) {
        const filteredList = state.unifiedPolicesListApi.filter((item) => {
          return (
            (item.Insured_Name &&
              item.Insured_Name
                .toLowerCase()
                .includes(query.toLowerCase())) ||
            (item.Policy_No &&
              item.Policy_No.toLowerCase().includes(query.toLowerCase()))
          );
        });

        return {
          ...state,
          unifiedPolicesList: filteredList || [],
        };
      } else {
        return {
          ...state,
          unifiedPolicesList: [...state.unifiedPolicesListApi],
        };
      }

    case ReducerUtils.unifiedPolicies.sort:
      const shallowCopy = [...state.unifiedPolicesList];
      const sortedList = shallowCopy.sort(
        compareValues(action.payload.sortBy, action.payload.sortType)
      );

      return {
        ...state,
        unifiedPolicesList: sortedList,
      };

    default:
      return state;
  }
}
