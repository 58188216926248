import React, { useContext, useEffect } from 'react';
import { Card, CardBody, CardFooter, Button, FormGroup, Input } from "reactstrap";
import ReactDatetime from "react-datetime";
import LeadContext from '../../../context/leadContext';
import { options } from '../../../defaultValues/lead';
import moment from 'moment';
import { convertDateToYYYYMMDD } from '../../../helpers/utils';

const CustomerDetailsForm = (props) => {
  const { 
    errorMessage,
    form,
    handleChange,
    updateDetails,
    handleDateChange,
    isCustomerDetailsNextButtonDisabled,
    isFormSubmitted,
    isValidDate,
    setForm,
    validateInputLength 
  } = props;
  const { state: { leadDetails = {} } } = useContext(LeadContext);

  useEffect(() => {
    if (leadDetails) {
      setForm({
        ...form,
        gender: leadDetails.gender || '',
        name: leadDetails.name || '',
        dob: leadDetails.customer_dob ? moment(convertDateToYYYYMMDD(leadDetails.customer_dob)).format('YYYY-MM-DD') : '',
        mobile: leadDetails.mobile || '',
        email: leadDetails.email || '',
        pincode: leadDetails.pincode || '',
        city: leadDetails.city || '',
        state: leadDetails.state || '',
        addressLine1: leadDetails.address1 || '',
        addressLine2: leadDetails.address2 || ''
      });
    }
  }, [leadDetails]);
  
  return (
    <Card className="m-0 mt-1">
      <CardBody className="">
        <div className="">
          <div className={"mb-2"}>
            <h3>Fill in details of customer</h3>
          </div>
          <FormGroup>
            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  What's your customer gender?
                </label>
              </div>
              <div className={""}>
                <select
                className="form-control"
                name="gender"
                value={form.gender}
                onChange={handleChange}>
                <option value={""}>Select</option>
                {options.genderCustomer.map((item, index) => (
                  <option value={item.value} key={index}>{item.title}</option>
                ))}
              </select>
              {errorMessage.gender && <small className="text-danger">{errorMessage.gender}</small>}
              </div>
            </div>

            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  What's your customer full name?
                </label>
              </div>
              <div className={""}>
                <Input
                  label="Full Name"
                  id="outlined-margin-none"
                  name={"name"}
                  className={""}
                  fullwidth={"true"}
                  value={form.name}
                  onInput={validateInputLength}
                  onChange={handleChange}
                  placeholder={'Customer Name'}
                />
                {errorMessage.name && <small className="text-danger">{errorMessage.name}</small>}
              </div>
            </div>

            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  What's your customer date of birth?
                </label>
              </div>
              <div className={""}>
                <ReactDatetime 
                  closeOnSelect={true}
                  dateFormat="YYYY-MM-DD"
                  onChange={date => {handleDateChange(date, 'dob')}}
                  timeFormat={false}
                  defaultValue={form.dob}
                  value={form.dob}
                  isValidDate={ (current) =>isValidDate(current, 'dob') }
                  // minDate={moment().subtract(500, "years")}
                  // maxDate={moment().subtract(18, "years")}
                  viewMode={'years'}
                  inputProps={{readOnly: true}}
                />
              </div>
            </div>
            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  What is your customer mobile number?
                </label>
              </div>
              <div className={""}>
                <Input
                  label="10 digit mobile number"
                  id="outlined-margin-none"
                  name="mobile"
                  type={'number'}
                  className={""}
                  fullwidth={"true"}
                  value={form.mobile}
                  onInput={validateInputLength}
                  onChange={handleChange}
                  placeholder={'Mobile Number'}
                />
                {errorMessage.mobile && <small className="text-danger">{errorMessage.mobile}</small>}
              </div>
            </div>
            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  What is your customer email address?
                </label>
              </div>
              <div className={""}>
                <Input
                  label="Email address"
                  id="outlined-margin-none"
                  name="email"
                  type={'text'}
                  className={""}
                  fullwidth={"true"}
                  value={form.email}
                  onChange={handleChange}
                  placeholder={'Email'}
                />
                {errorMessage.email && <small className="text-danger">{errorMessage.email}</small>}
              </div>
            </div>
            <div className={""}>
              <div>
                <label className=" form-control-label mt-3" htmlFor="dealer">
                  Pin Code?
                </label>
              </div>
              <div className={"my-1"}>
                <Input
                  label="Pin Code"
                  id="dogPincode"
                  name="pincode"
                  type={'text'}
                  className={""}
                  fullwidth={"true"}
                  value={form.pincode}
                  onInput={validateInputLength}
                  onChange={handleChange}
                  placeholder={'Pincode'}
                />
                {errorMessage.pincode && <small className="text-danger">{errorMessage.pincode}</small>}
              </div>
              <div className={"d-flex my-2 w-100"}>
                <div className={"w-100 mr-2"}>
                  <Input
                    label="City"
                    id="city"
                    name="city"
                    type={'text'}
                    className={""}
                    fullwidth={"true"}
                    defaultValue={form.city}
                    onChange={handleChange}
                    placeholder={'City'}
                    disabled={true}
                  />
                </div>
                <div className={"w-100"}>
                  <Input
                    label="State"
                    id="state"
                    name="state"
                    type={'text'}
                    className={""}
                    fullwidth={"true"}
                    defaultValue={form.state}
                    onChange={handleChange}
                    placeholder={'State'}
                    disabled={true}
                  />
                </div>
              </div>
              <div className={"my-2"}>
                <Input
                  label="Address Line 1"
                  id="dogAddressLine1"
                  name="addressLine1"
                  type={'text'}
                  className={""}
                  fullwidth={"true"}
                  value={form.addressLine1}
                  onChange={handleChange}
                  placeholder={'Address Line 1'}
                />
                {errorMessage.addressLine1 && <small className="text-danger">{errorMessage.addressLine1}</small>}
              </div>
              <div className={"my-2"}>
                <Input
                  label="Address Line 2"
                  id="dogAddressLine2"
                  name="addressLine2"
                  type={'text'}
                  className={""}
                  fullwidth={"true"}
                  value={form.addressLine2}
                  onChange={handleChange}
                  placeholder={'Address Line 2'}
                />
              </div>
            </div>
          </FormGroup>
        </div>
      </CardBody>
      <CardFooter className="py-4">
        <div className="text-right">
          <Button className="shadow-none mt-3" 
            size="md"
            contained={"true"}
            color={"primary"}
            type="button" 
            onClick={updateDetails}
            { ...( (isCustomerDetailsNextButtonDisabled(form, errorMessage) || isFormSubmitted) && { disabled: true } ) }
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CustomerDetailsForm;