
import frontDog from '../assets/img/dogIllustrations/frontDog.svg';
import backDog from '../assets/img/dogIllustrations/backDog.svg';
import rightDog from '../assets/img/dogIllustrations/rightDog.svg';
import leftDog from '../assets/img/dogIllustrations/leftDog.svg';
import backButton from '../assets/img/icons/back-icon.png';

export const Images = {
     frontDog,
    backDog, rightDog, leftDog,backButton
}
