import React, {Component, Fragment, useRef} from 'react';
import Select2 from "react-select2-wrapper";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    CardFooter,
    Button,
    Input,
    Modal,
    Form
} from "reactstrap";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import Loader from "../../../components/CustomUi/Loader/Loader";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import {IndiaFirstLocation} from "../../../locations/indiaFirstLocations";
import { buildPartnerProductList,buildMultiPartnerProductList } from "../../../helpers/partners";
import  {MultiSelect}  from "react-multi-select-component";
import { Label,Checkbox } from 'semantic-ui-react'

const contact_regx = /^[6789]\d{9}$/;
const email_regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const invoicingModalList = ['DAILY_CREDIT','CREDIT','PAYG','INVOICE', 'CREDIT_IN_DAYS'];

class AddAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            agentMobile: '',
            agentName: '',
            loaderMsg: 'Wait...',
            showCsvAddModel: false,
            csvFile: [],
            isParentEnable:false,
            showMobileListModal: false,
            mobileList: [],
            showModalLoader: false,
            agentEmail: '',
            invoicingModal: '',
            partnerId: '',
            parent_id:"",
            parent_share: '',
            pan: '',
            gst:'',
            view_commission: false,
            dealershipName:'',
            additionalNumber1:'',
            addressLine1:'',
            addressLine2:'',
            city:'',
            state:'',
            pinCode:'',
            accountNumber:'',
            beneficiaryName:'',
            ifscCode:'',
            invoicingValue: '',
            productId: [],
            products: [],
            parent: [],
            cut_and_pay: true,
        }
        this.handleAgentMobile = this.handleAgentMobile.bind(this);
        this.handleAgentName = this.handleAgentName.bind(this);
        this.handleAgentEmail = this.handleAgentEmail.bind(this);
        this.uploadCSV = this.uploadCSV.bind(this);
        this.selectRef = React.createRef(null)
    }

    componentDidMount = () => {
        this.props.onGetPartnerFilterList();
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };

    handleAgentMobile = (event) => {
        this.setState({
            agentMobile: event.target.value
        });
    }
    handleAgentName = (event) => {
        this.setState({
            agentName: event.target.value
        });
    }
    handleAgentEmail = (event) => {
        this.setState({
            agentEmail: event.target.value
        });
    }

    openCsvModal = () => {
        this.setState({
            showCsvAddModel: true
        });
    }
    submit = (payload) => {

        let isValid = true;
        if (this.state.agentName === '') {
            this.showMsg("Name Cannot be Empty", "danger")
            isValid = false
        } else if (this.state.agentMobile === '') {
            this.showMsg("Mobile Cannot be Empty", "danger")
            isValid = false
        } else if (!this.state.agentMobile.match(contact_regx)) {
            this.showMsg("Please Enter Valid Agent Mobile", "danger")
            isValid = false
        } else if (this.state.agentEmail === '') {
            this.showMsg("Email Cannot be Empty", "danger")
            isValid = false
        }  else if (!this.state.agentEmail.match(email_regx)) {
            this.showMsg("Please Enter Valid Email", "danger")
            isValid = false
        } else if(this.state.additionalNumber1 !== '' && !this.state.additionalNumber1.match(contact_regx)){
            this.showMsg("Please Enter Valid Mobile Number 2","danger")
            isValid = false
        } else if(this.state.invoicingModal === ''){
            this.showMsg("Invoicing Model Cannot be Empty","danger")
            isValid = false
        }  else if(this.state.partnerId === ''){
            this.showMsg("Partner Cannot be Empty","danger")
            isValid = false
        } else if (this.state.invoicingModal === 'CREDIT_IN_DAYS' && !this.state.invoicingValue) {
            this.showMsg("Credit Days Cannot be Empty","danger")
            isValid = false
        }  else if(this.state.productId == '' ){
            this.showMsg("Product Cannot be Empty","danger")
            isValid = false
        } else if(this.state.parent_share != '' && isNaN(this.state.parent_share)){
            this.showMsg("Parent Share Should Be Number","danger")
            isValid = false
        }  else if(this.state.parent_share != ''&&this.state.parent_share > 100){
            this.showMsg("Please Enter Valid Parent Share","danger")
            isValid = false
        }   else if(this.state.parent_share != '' && this.state.parent_share < 0){
            this.showMsg("Please Enter Valid Parent Share","danger")
            isValid = false
        }

        payload.product_id=payload.product_id.map(e=>e.value)

        if (!isValid) {
            return false;
        }

        this.setState({
            loaderMsg: "Please Wait",
            showLoader: true
        }, () => {
            this.setState({
                showLoader: true
            });
            AxiosReactClient.postRequest('dealers', payload).then(response => {
                this.setState({
                    showLoader: false,
                    agentName: '',
                    agentMobile: '',
                    agentEmail: '',
                    invoicingModal: '',
                    partnerId: '',
                    pan: '',
                    gst:'',
                    dealershipName:'',
                    parent_id:'',
                    parent_share:'',
                    additionalNumber1:'',
                    addressLine1:'',
                    addressLine2:'',
                    city:'',
                    state:'',
                    pinCode:'',
                    accountNumber:'',
                    beneficiaryName:'',
                    ifscCode:'',
                    view_commission:false,
                    invoicingValue: '',
                    productId: [],
                    cut_and_pay: true
                });
                if (response["data"].data.message === "Agent already exists.") {
                    this.showMsg(response["data"].data.message, "danger");
                    return
                }
                if (response.data.success === true) {
                    this.showMsg(response["data"].data.message, "success");
                    return
                }
                if (response.data.success === false) {
                    this.showMsg(response["data"].data.message, "danger");
                }
            })
                .catch(err => {
                    this.setState({
                        showLoader: false
                    });
                    if (err.response) {
                        this.showMsg(err.response.data?.data?.error_msg ? err.response.data?.data?.error_msg : err.response.data?.message, "danger")
                    } else if (err.message) {
                        this.showMsg(err.message, "danger")
                    }
                });
        });

    }

    uploadCSV(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                csvFile: file,
            });
        };
        reader.readAsDataURL(file);
    }

    onSubmitBulk = () => {
        const payload = new FormData();
        payload.append("csv", this.state.csvFile);
        this.setState({
            loaderMsg: "Please Wait...",
            showModalLoader: true
        });
        AxiosReactClient.postRequest('dealers/bulk', payload).then(response => {
            this.setState({
                showModalLoader: false,
                showCsvAddModel: false,
            });
            if (response.data["data"]["failed_data"].length > 0) {
                this.setState({
                    showMobileListModal: true,
                    mobileList: response.data["data"]["failed_data"]
                })
            }
            if (response.data.success === true) {
                this.showMsg(response["data"].data.message, "success");
                return
            }
            if (response.data.success === false) {
                this.showMsg(response["data"].data.message, "danger");
            }
        })
            .catch(err => {

                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    handleToggleChange=(e)=>{
        const name = e.target.name;
        const value = e.target.checked;
         if(name){
            this.setState({
                ...this.state,
                [name]:value
            })
        }
    }

    handleChange = (e) => {
        // e.preventDefault()
        const name = e.target.name
        const value = e.target.value;
        if (name == 'parent_id'&&value==0) {
            this.setState({parent_id:""})
            return false
        }
      
        if(name){
            this.setState({
                ...this.state,
                [name]:value
            })
        }
        if(name === 'pinCode'){
            this.populateStateCity(value)
        }
        if (name === 'partnerId') {
            const newState = {parent_id:""}
            const selectedPartner = this.props.partnerFilterList.find(e=>parseInt(e.id)===parseInt(value));
            if(selectedPartner&&selectedPartner?.data?.master_child){
                newState.isParentEnable = true;
                if(this.state.partnerId){
                    this.getParentList(this.state.partnerId);
                }
            }else{
                newState.isParentEnable = false;
                newState.parent_share=""
            }
            this.setState({...newState},()=>{
                this.getProductList(value);
            })
        }

        if (name === 'parent_id'&&this.state.partnerId) {
            const selectedParent = this.state.parent.find(e=>e.id==value);
            this.setState({parent_share:selectedParent?.parent_share || ""});
            this.getProductList(this.state.partnerId,value);
        }
    }

    populateStateCity = (pin) => {
        let cityState = new IndiaFirstLocation().get(pin);
        if (cityState) {
            this.setState({
                city: cityState[0],
                state: cityState[1]
            })
        } else {
            this.setState({
                city: '',
                state: ''
            })
        }
    }

    cbProductList = (products = []) => {
        const productsArray=[];
        const partnerSlug = this.props?.partnerFilterList instanceof Array ? this.props.partnerFilterList.find(e=>e.id==this.state.partnerId)?.slug:null;
        const processProductList = buildMultiPartnerProductList(products);
        if(partnerSlug==="travel-portal-partner"){
            productsArray.push(...processProductList);
        }
        this.setState({ 'products': processProductList, productId: [...productsArray] },()=>{
            // console.log(this.state)
        })
    }
    
    getProductList = async (partnerId,dealer_id) => {
        try {
            if (!partnerId) {
                this.cbProductList();
                return false;
            }
            const query = { partner_id: partnerId}
            if(dealer_id){
                query.dealer_id = dealer_id;
            }

            const response = await AxiosReactClient.getRequest('filter/product/list', { ...query});

            const { data: { product } } = response.data;
            this.cbProductList(product);
        } catch (error) {
            // alert('It looks like something went wrong. Please try again in a while.');
            this.cbProductList();
        }
    };

    getParentList = async (partnerId) => {
        try {
  
            const response = await AxiosReactClient.getRequest('v2/admin/parent_dealer/'+partnerId);
            
            if(response?.data?.data?.items){
                const parentSelect = response.data.data.items.map((e)=>{
                    return {
                        text:`${e.dealership_name} (${e.id})`,
                        id:e.id,
                        parent_share:e?.parent_share
                    }
                })
                this.setState({parent:parentSelect})
            }
        } catch (error) {
                //    this.showMsg(response["data"].data.message, "success");
            // alert('It looks like something went wrong. Please try again in a while.');
            console.log(error)
        }
    };

    onSelect = (e)=>{
        this.setState({productId:e})
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    {this.state.showLoader && <div className="loader-container">
                        <Loader msg={this.state.loaderMsg}/>
                    </div>}
                    <Row>
                        <Col xs="12">
                            <Card className="mt-4">
                                <CardHeader>
                                    <Row>
                                        <Col xs="6">
                                            <h4 className="h4 pt-2 font-weight-400">
                                                Add Agent/Dealer
                                            </h4>
                                        </Col>
                                        <Col xs="6">
                                            <h4 className="h4 font-weight-400 text-primary cursor-pointer text-right">
                                                <Button
                                                    color="btn-round btn-icon btn btn-outline-primary btn-sm"
                                                    type="button"
                                                    onClick={() => {
                                                        this.openCsvModal()
                                                    }}
                                                >
                                                            <span className="btn-inner--icon mr-1">
                                                               <i className="fas fa-plus"/>
                                                            </span>
                                                    Bulk Add
                                                </Button>
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <label className=" form-control-label required"
                                               htmlFor="dealer">
                                            Full Name
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Name"
                                            value={this.state.agentName}
                                            onChange={this.handleAgentName}
                                        />
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Mobile Number (Primary)
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Number (Primary)"
                                            value={this.state.agentMobile}
                                            onChange={this.handleAgentMobile}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Mobile Number 2
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Mobile Number 2"
                                            name="additionalNumber1"
                                            value={this.state.additionalNumber1}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Email
                                        </label>
                                        <Input
                                            type="email"
                                            placeholder="Enter Email"
                                            value={this.state.agentEmail}
                                            onChange={this.handleAgentEmail}
                                        />
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Invoicing Model
                                        </label>
                                        <Select2
                                            className=" form-control"
                                            defaultValue=""
                                            options={{
                                                placeholder: "Select Invoicing Model"
                                            }}
                                            name="invoicingModal"
                                            value={this.state.invoicingModal}
                                            data={invoicingModalList}
                                            onChange={this.handleChange}
                                        />

                                        {this.state.invoicingModal === 'CREDIT_IN_DAYS' && 
                                        <>
                                            <label className=" form-control-label mt-3 required"
                                                htmlFor="dealer">
                                                Credit Days
                                            </label>
                                            <Input
                                                className=" form-control"
                                                type="number"
                                                name="invoicingValue"
                                                placeholder="Enter Credit Days"
                                                value={this.state.invoicingValue}
                                                onChange={this.handleChange}
                                            />
                                        </>}
                                        <label className=" form-control-label mt-3 required"
                                               htmlFor="dealer">
                                            Partner
                                        </label>

                                        <Select2 className=" form-control-label mt-3"
                                                 options={{placeholder:"Select Partner"}}
                                                 name="partnerId"
                                                 value={this.state.partnerId}
                                                 defaultValue=""
                                                 data={this.props.partnerFilterList ? this.props.partnerFilterList.sort((a,b) =>{ return a.name.localeCompare(b.name)}).map(({id,name,slug})=>{
                                                     return {id:id,text:name+' ('+id+')',slug:slug}
                                                 }): []}
                                                 onChange={this.handleChange}
                                                       
                                        />
                                        {this.state.isParentEnable?<><label className=" form-control-label mt-3"
                                               htmlFor="parent">
                                            Parent
                                        </label>

                                        <Select2
                                            className="form-control"
                                            defaultValue=""
                                            options={{
                                                placeholder: "Select Parent"
                                            }}
                                            name="parent_id"
                                            value={this.state.parent_id}
                                            data={[{ text: 'None of these', id: 0 },...this.state.parent]}
                                            onChange={this.handleChange}
                                                  disabled={!this.state.partnerId}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="parentShare">
                                            Parent Share (%)
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Parent Share"
                                            name="parent_share"
                                            value={this.state.parent_share}
                                            onChange={this.handleChange}
                                        /></>:<></>}


                                        <label className="form-control-label mt-3 required"
                                               htmlFor="productId">
                                            Product
                                        </label>
                                        <MultiSelect className="form-control-label"
                                            name="productId"
                                            multiple
                                            labelledBy="Select"
                                            hasSelectAll={true}
                                            ref={this.selectRef}
                                            valueRenderer={(items)=>{
                                                return items instanceof Array && items.map(item=>{
                                                    return <Label>
                                                        {item.label}
                                                    </Label>
                                                })
                                            }}
                                            value={this.state.productId}
                                            defaultValue={[]}
                                            options={this.state.products}
                                            onChange={this.onSelect}
                                            disabled={!this.state.partnerId}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Dealership Name
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Dealership Name"
                                            name="dealershipName"
                                            value={this.state.dealershipName}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Pin Code
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Pin Code"
                                            name="pinCode"
                                            value={this.state.pinCode}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            City
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter City"
                                            value={this.state.city}
                                            name="city"
                                            onChange={this.handleChange}
                                            readOnly={true}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            State
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter State"
                                            value={this.state.state}
                                            name="state"
                                            onChange={this.handleChange}
                                            readOnly={true}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Address Line 1
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Address Line 1"
                                            value={this.state.addressLine1}
                                            name="addressLine1"
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Address Line 2
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Address Line 2"
                                            value={this.state.addressLine2}
                                            name="addressLine2"
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            PAN Number
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter PAN Number"
                                            name="pan"
                                            value={this.state.pan}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            GST Number
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter GST Number"
                                            name="gst"
                                            value={this.state.gst}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Account Number
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Account Number"
                                            name="accountNumber"
                                            value={this.state.accountNumber}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            Account Name
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Account Name"
                                            name="beneficiaryName"
                                            value={this.state.beneficiaryName}
                                            onChange={this.handleChange}
                                        />
                                        <label className=" form-control-label mt-3"
                                               htmlFor="dealer">
                                            IFSC Code
                                        </label>
                                        <Input
                                            type="text"
                                            placeholder="Enter IFSC Code"
                                            name="ifscCode"
                                            value={this.state.ifscCode}
                                            onChange={this.handleChange}
                                        />
                                        <Row className="d-flex align-items-center justify-content-between pl-5 pr-4 mt-3">
                                            <Input
                                                type="checkbox"
                                                name="view_commission"
                                                checked={this.state.view_commission}
                                                onChange={this.handleToggleChange}
                                            />
                                            <label className=" form-control-label mt-3 ml-2"
                                                    htmlFor="view_commission">
                                                View Commission
                                            </label>
                                        </Row>
                                        <Row className="d-flex align-items-center justify-content-between pl-5 pr-4 mt-3">
                                            <Input
                                                type="checkbox"
                                                name="cut_and_pay"
                                                checked={this.state.cut_and_pay}
                                                onChange={this.handleToggleChange}
                                            />
                                            <label className=" form-control-label mt-3 ml-2"
                                                    htmlFor="cut_and_pay">
                                                Cut & Pay Model
                                            </label>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                                <CardFooter>
                                    <h5 className="h5 mb-0 font-weight-400 text-primary cursor-pointer text-center">
                                        <Button
                                            block
                                            color="primary"
                                            size="lg"
                                            type="button" onClick={e => {
                                            e.preventDefault();
                                            this.submit({
                                                mobile: this.state.agentMobile, 
                                                name: this.state.agentName, 
                                                email: this.state.agentEmail, 
                                                invoicing_modal: this.state.invoicingModal,
                                                invoicing_value: this.state.invoicingValue,
                                                partner_id: this.state.partnerId,
                                                parent_id:this.state.parent_id,
                                                parent_share:this.state.parent_share,
                                                view_commission:this.state.view_commission,
                                                additional_number_1: this.state.additionalNumber1,
                                                pan: this.state.pan,
                                                gst: this.state.gst,
                                                dealership_name: this.state.dealershipName,
                                                address:{
                                                    address_line_1: this.state.addressLine1,
                                                    address_line_2: this.state.addressLine2,
                                                    city: this.state.city,
                                                    state: this.state.state,
                                                    pincode:this.state.pinCode
                                                },
                                                bank_detail:{
                                                    beneficiary_name: this.state.beneficiaryName,
                                                    account_number:this.state.accountNumber,
                                                    ifsc_code: this.state.ifscCode
                                                },
                                                product_id: this.state.productId,
                                                cut_and_pay: this.state.cut_and_pay,
                                            })
                                        }}>
                                            <span className="btn-inner--icon mr-1">
                                                <i className="fas fa-plus"/>
                                            </span>
                                            Add Agent/Dealer
                                        </Button>
                                    </h5>
                                </CardFooter>
                            </Card>

                            <Modal
                                className="modal-dialog-centered"
                                size="md"
                                isOpen={this.state.showCsvAddModel}
                                toggle={() => {
                                    this.setState({
                                        showCsvAddModel: !this.state.showCsvAddModel
                                    })
                                }}
                            >
                                {this.state.showModalLoader && <div className="loader-container">
                                    <Loader msg={this.state.loaderMsg}/>
                                </div>}
                                <div className="modal-body">
                                    <Form role="form">
                                        <Row
                                            className="input-daterange datepicker align-items-center">
                                            <Col>
                                                <h4>
                                                    Please add a CSV file for Bulk Agent registration (Pls use this for Toffee Plan Partner Agent registration only)
                                                </h4>
                                                <label className=" form-control-label mt-3 mb-3"
                                                       htmlFor="dealer">
                                                    File format should be <b>Name, Mobile</b> only
                                                </label>
                                                <form action="." encType="multipart/form-data">
                                                    <input type="file" onChange={this.uploadCSV} color="primary"
                                                           className="bg-light txt-white p-1 h4"
                                                           size="lg"
                                                    />
                                                </form>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="modal-footer date-modal-footer ">
                                    <Button
                                        color="link"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                showCsvAddModel: false
                                            })
                                        }}
                                    >
                                        Close</Button>
                                    <Button color="primary"
                                            className="ml-auto" type="button"
                                            size="sm"
                                            onClick={() => {
                                                this.onSubmitBulk()
                                            }}
                                    >
                                        Add File
                                    </Button>
                                </div>
                            </Modal>

                            <Modal
                                className="modal-dialog-centered"
                                size="md"
                                isOpen={this.state.showMobileListModal}
                                toggle={() => {
                                    this.setState({
                                        showMobileListModal: !this.state.showMobileListModal
                                    })
                                }}
                            >
                                <div className="modal-body">
                                    <Form role="form">
                                        <Row
                                            className="input-daterange datepicker align-items-center">
                                            <Col>
                                                <h4>
                                                    Following List of contacts are incorrect, please use correct numbers
                                                    and upload again
                                                </h4>
                                                <hr/>
                                                <table>
                                                    <tr style={{borderBottom: '1px solid #cccccc'}}>
                                                        <th style={{width: '50px', textAlign: 'justify'}}>No.</th>
                                                        <th style={{width: '100px', textAlign: 'justify'}}>Name</th>
                                                        <th style={{width: '100px', textAlign: 'justify'}}>Mobile</th>
                                                    </tr>
                                                    {this.state.mobileList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td style={{width: '50px', textAlign: 'justify'}}>
                                                                    {index + 1}
                                                                </td>
                                                                <td style={{width: '100px', textAlign: 'justify'}}>
                                                                    {item[0]}
                                                                </td>
                                                                <td style={{width: '100px', textAlign: 'justify'}}>
                                                                    {item[1]}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </table>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="modal-footer date-modal-footer ">
                                    <Button
                                        color="link"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => {
                                            this.setState({
                                                showMobileListModal: false
                                            })
                                        }}
                                    >
                                        Close</Button>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        partnerFilterList: state.common.partnerFilterData,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPartnerFilterList: () => dispatch(actions.getPartnerFilterListData()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAgent);