import {
    combineReducers
} from 'redux';
import auth from './auth';
import navbar from './adminNavbar';
import dashboard from './dashboard';
import provider from './providers';
import roles from './rolesPermissions';
import dealer from './dealer';
import common from './common';
import partnerPolicies from './partnerPolicies';
import partnerDealer from './partnerDealers';
import partnerFinance from './partnerFinance';
import partnerClaim from './partnerClaims';
import finance from './finance';
import policies from './policies';
import invoices from './invoices';
import config from "./config";

export default combineReducers({
    auth,
    dashboard,
    navbar,
    provider,
    roles,
    dealer,
    common,
    partnerPolicies,
    partnerDealer,
    partnerFinance,
    partnerClaim,
    finance,
    policies,
    invoices,
    config
});