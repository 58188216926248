import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button
} from "reactstrap";
import CustomCarousel from '../../../components/CustomCarousel/otherPolicy';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { convertToArray, downloadFile, downloadZipFile, scrollTop } from '../../../helpers/utils';
import { extractTypeAndUrl } from '../../../helpers/otherPolicyDetails';

// Document Viewer Component
const DocViewer = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  return (
    <div>
      <iframe
        src={"https://docs.google.com/viewer?url=" + src + "&embedded=true"}
        title="file"
        width="100%"
        height="800"
      ></iframe>
    </div>
  );
};

// Image Viewer Component
const ImageViewer = ({ items }) => {
  return (
    <div className="other-policy-documents">
      <CustomCarousel 
        items={items}
      />
    </div>
  );
};

// PDF Viewer Component
const PDFViewer = ({ url }) => {
  const [pagination, setPagination] = useState({ numPages: null, pageNumber: 1 });
  const [scale, setScale] = useState({ current: 1 });

  useEffect(() => {
    // console.log(`current scale = ${scale.current}`);
  }, [scale.current]);

  const descreseScale = () => {
    setScale({ ...scale, current: scale.current <= 1 ? 1 : scale.current - 0.5 });
  };

  const resetDefaultScale = () => {
    setScale({ ...scale, current: 1 });
    scrollTop();
  };

  const increaseScale = () => {
    setScale({ ...scale, current: scale.current >= 5 ? 5 : scale.current + 0.5 });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPagination({ ...pagination, numPages: numPages });
  };

  const goToPrevPage = () => {
    setPagination({ ...pagination, pageNumber: pagination.pageNumber <= 1 ? 1 : pagination.pageNumber - 1 });
  };

  const goToNextPage = () => {
    setPagination({ ...pagination, pageNumber: pagination.pageNumber >= pagination.numPages ? pagination.numPages : pagination.pageNumber + 1 });
  };

  return (
    <>
      <div className={ scale.current > 1 ? 'pdf-container-zoom' : ''}>
        <Document
          className={'pdf-container'}
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={'Loading please wait...'}
        >
          <Page 
            pageNumber={pagination.pageNumber} 
            scale={scale.current}
            width={parseInt(window.screen.width * .29, 10)}
          />
        </Document>
        
        <div style={{'position': 'absolute', 'top': '40%', 'right': '10px'}}>
          <button type="button" className="policyDocPrevNextBtn row"
            { ...( scale.current >= 5 && { disabled: true } ) } 
            onClick={increaseScale}
          >
            <i className='far fa-plus-square' title="Zoom In"></i>
          </button>

          <button type="button" className="policyDocPrevNextBtn row mt-3"
            { ...( scale.current === 1 && { disabled: true } ) } 
            onClick={resetDefaultScale}
          >
            <i className='fa fa-search-plus' title="Fit to width"></i>
          </button>	

          <button type="button" className="policyDocPrevNextBtn row mt-3"
            { ...( scale.current <= 1 && { disabled: true } ) }
            onClick={descreseScale}
          >
            <i className='far fa-minus-square' title="Zoom Out"></i>
          </button>
        </div>
      </div>
      
      <div className={"d-flex justify-content-center py-4"}>
        <button type="button" className="policyDocPrevNextBtn"
          { ...( pagination.pageNumber <= 1 && { disabled: true } ) }
          onClick={goToPrevPage}>
          <i className='fas fa-angle-left'></i>
        </button>

        <span className="px-3 py-2">
          {pagination.pageNumber}/{pagination.numPages}
        </span>
        
        <button type="button" className="policyDocPrevNextBtn"
          { ...( pagination.pageNumber >= pagination.numPages && { disabled: true } ) } 
          onClick={goToNextPage}>
          <i className='fas fa-angle-right'></i>
        </button>
      </div>
    </>
  );
};

const OtherPolicyDocument = (props) => {
  const { pages } = props;
  const { type, url} = extractTypeAndUrl(pages);

  return (
    <>
      <Row>
        <Col xl="6" className="fsize16">
          Policy Document
        </Col>
        <Col xl="6" className={"d-flex justify-content-right"}>
          <Button color="primary"
            outline
            className="ml-auto px-4" 
            type="button"
            size="sm"
            onClick={() => type === 'application/pdf' ? downloadFile(url) : downloadZipFile(convertToArray(pages)) }
          >
            Download
          </Button>
        </Col>
      </Row>
      <Row >
        <hr className={"p-0 m-24"}/>
      </Row>
      <Row>
        <Col xl="12">
          {/* <DocViewer source={"https://file-examples-com.github.io/uploads/2017/02/file-sample_100kB.doc"} /> */}
          {type === 'application/pdf' ? <PDFViewer url={url} /> : <ImageViewer items={pages} />}
        </Col>
      </Row>
    </>
  );

};

export default OtherPolicyDocument;