import React from "react";
import { Button, Col, Form, FormGroup, Modal, Row } from "reactstrap";
import {MultiSelect} from "react-multi-select-component";

class CustomMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props ?? props.options ?? [],
      selected: props.values || [],
      loader:false
    };
  }

  setSelected = (value,...rest) => {
    this.setState(
      {
        selected: value,
      },
      () => {
        this.props.filterByArray(value);
      }
    );
  };
  componentDidMount = () => {};

  filterOptions = () => {
    let options = [];
    let finalOptions = [];
    options = this.props.options && this.props.options;
    options.map((item) => {
      return finalOptions.push({ label: item.name, value: item.id });
    });
    return finalOptions;
  };

  /*************
   * 1. Search with api.
   * 2. Append returned array with existing with validation.
   * 3. Now perform local search.
   * ********* */

  filterOption = async(...rest)=>{
    try{
      if(this.props?.filterSearch){
        const res = await this.props.filterSearch(rest);
      }
  
      const arr =rest[0].filter((i)=>{
        if(`${this.props?.filtertTest?i[this.props.filtertTest]:i.value}`.match(rest[1])){
          return true;
        }
        return false
      })
      arr.sort();
      return arr
    }catch(error){
      console.log(error)
      return rest[0];
    }
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.props.showMultiSelectOption}
          toggle={() => this.props.closeMultiSelectOption()}
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.closeMultiSelectOption()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {/* <pre>{JSON.stringify(this.state.selected)}</pre> */}
            <MultiSelect
              options={this.filterOptions()}
              debounceDuration={300}
              value={this.state.selected}
              isLoading={this.props.loader}
              filterOptions={this.filterOption}
              onChange={this.setSelected}
              labelledBy={"Select " + this.props.title + " ..."}
            />
          </div>

          <div className="modal-footer date-modal-footer ">
            <Button
              color="primary"
              className="ml-auto"
              type="button"
              onClick={() => this.props.closeMultiSelectOption()}
            >
              Done
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CustomMultiSelect;
