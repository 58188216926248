import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import CustomFilterBadge from "./custom-badge";

const UnifiedPoliciesAppliedFilters = ({ title, filter }) => {
  const {
    defaultFromDate,
    defaultToDate,
    selectedFromDate = null,
    selectedToDate = null,
    selectedInsurerName = [],
    selectedProductCategory = [],
    selectedProductSubCategory = [],
    selectedChannel = [],
    selectedPlatform = [],
  } = filter;

  return (
    <Row className="bg-white mt-5">
      <Col md={2} xl={1} className="text-left vAlignMiddle">
        {title}:
      </Col>
      <Col md={10} className="text-left">
        {selectedFromDate &&
        defaultFromDate === moment(selectedFromDate).format("YYYY-MM-DD") &&
        selectedToDate &&
        defaultToDate === moment(selectedToDate).format("YYYY-MM-DD") ? (
          <CustomFilterBadge
            color={"primary"}
            visible={true}
            title={"Default Date Range - Last 3 Months"}
          />
        ) : (
          <CustomFilterBadge visible={true} title={"Custom Date Range"} />
        )}

        <CustomFilterBadge
          list={selectedInsurerName}
          title={"Insurance Company"}
        />

        <CustomFilterBadge
          list={selectedProductCategory}
          title={"Product Category"}
        />

        <CustomFilterBadge
          list={selectedProductSubCategory}
          title={"Product Sub Category"}
        />

        <CustomFilterBadge list={selectedChannel} title={"Channel"} />

        <CustomFilterBadge list={selectedPlatform} title={"Business Type"} />
      </Col>
    </Row>
  );
};

export default UnifiedPoliciesAppliedFilters;
