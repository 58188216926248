import React, { Component, Fragment } from 'react';
import ReactDatetime from "react-datetime";

import {
    Card,
    PaginationItem,
    Pagination,
    PaginationLink,
    Container,
    Row,
    Col,
    BreadcrumbItem,
    Breadcrumb,
    Badge,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Table
} from "reactstrap";
import CustomDropdown from '../../../components/Filters/CustomDropdown';
import CustomDateSelect from '../../../components/Filters/CustomDateSelect';

export default class Payments extends Component {
    state = {
        openMonthDialog: false,
        cardFilter: 'all',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        selectedDate: (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
    }
    dummyArray = [1, 2, 3, 4, 5, 6, 7]
    pager = {
        pagerStart: 0,
        pagerEnd: 0,
        pageArray: [],
        limit: 25,
        productId: '',
    }
    previousMonthDate = (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1)
    options = [{ name: 'A', id: 'a' }, { name: 'B', id: 'b' }, { name: 'C', id: 'c' }];
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }
    onRefreshAllCards = (filter) => {
        this.setState({
            cardFilter: filter
        })
    }
    handleClickOutside = (e) => {
        let classArray = [];
        if (e.path) {
            e.path.forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        } else {
            e.composedPath().forEach(element => {
                classArray = classArray.concat(element.className && element.className.split(" "));
            });
        }
        if (!classArray.includes('reactDateTimeCustomClass')) {
            this.setState({
                openMonthDialog: false
            })
        }
    }
    handleReactMonthFilterChange(e) {
        this.setState({
            cardFilter: 'custom',
            selectedCardsFilterDate: e._d
        })
        // this.props.onGetPartnerFinanceCardsData({ month: e._d.getMonth() + 1, year: e._d.getFullYear() })
    }
    getMonthYear = (date) => {
        return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
    }
    validDateMonthFilter = (current) => {
        var thisMonth = new Date();
        thisMonth.setDate(1);
        return (current.isBefore(thisMonth));
    };
    productChanged = (id) => {

    }
    providerChanged = (id) => {

    }
    render() {
        return (
            <Fragment>
                <Container fluid>

                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="8" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block "
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => {
                                            e.preventDefault()
                                            this.props.history.push('/admin/finance')
                                        }}>
                                            Finance
                          </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => {
                                            e.preventDefault()
                                        }}>
                                            Payments
                          </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button" onClick={e => {
                                        this.setState({
                                            hideFilters: !this.state.hideFilters
                                        })
                                    }}>
                                        <span className="btn-inner--icon">
                                            <i class="fas fa-filter"></i>
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* <Row className="py-1 d-none">
                        <Col xs={12} sm={6}>
                            <h5 className="text-primary h5 font-weight-400" > Policies</h5>
                        </Col>
                        <Col>
                            <div className="float-right">
                                <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button" onClick={e => {
                                    this.setState({
                                        hideFilters: !this.state.hideFilters
                                    })
                                }}>
                                    <span className="btn-inner--icon">
                                        <i class="fas fa-filter"></i>
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row> */}
                    <div >
                        <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
                            <Col className="p-1 px-3 text-muted">
                                <small>
                                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" >
                                        <input className="ml-2 bg-white border-0 text-muted pr-2 min-width-200px"
                                            value={this.state.searchText}
                                            onKeyDown={e => {
                                                (e.key === 'Enter') && this.onSearch(e.target.value)
                                            }}
                                            onChange={e => {
                                                if (e.target.value == " ") {
                                                    this.setState({
                                                        view: "finance-table"
                                                    })
                                                }
                                                this.setState({
                                                    searchText: e.target.value
                                                })
                                            }}
                                            type="text" placeholder="Payment Id / Toffee Id" aria-label="Search" />
                                        <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                                            onClick={
                                                e => {
                                                    e.preventDefault()
                                                    this.onSearch(this.state.searchText)
                                                }}
                                        ></i>
                                    </form>
                                </small>
                                {(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill>
                                    {this.state.searchText}
                                    <span className="p-1 ml-2 cursor-pointer" onClick={
                                        e => {
                                            e.preventDefault()
                                            this.refreshFilters()
                                            this.setState({
                                                view: "finance-table",
                                                searchText: ""
                                            })
                                        }} >
                                        <i className="fa fa-times-circle" aria-hidden="true"></i>
                                    </span>
                                </Badge>}
                            </Col>
                            <Col className="p-1 text-right px-3">
                                <CustomDropdown name="Status" selectedId={this.state.partnerFilter} handleSelection={id => { this.providerChanged(id) }} options={this.options} />
                                {/* <CustomDateSelect name="From Date" ></CustomDateSelect>
                                <CustomDateSelect name="To Date" ></CustomDateSelect> */}
                                <Button color="secondary " outline size="sm" type="button" onClick={e => {
                                    e.preventDefault()
                                    // this.refreshFilters()
                                }}>
                                    Clear
                </Button>
                            </Col>
                        </Row>
                    </div>

                    <Card className="m-0 mt-1" style={{ height: "1708px", overflowY: "scroll" }}>
                        <CardBody className="px-0 py-0" >
                            <div className="table-responsive">
                                <Table className="align-items-center table-flush" hover responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Payment ID</th>
                                            <th>Amount</th>
                                            <th>Toffee Id</th>
                                            <th>Customer Name</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.dummyArray.map(item => {
                                            return <tr>
                                                <td className="table-user">
                                                    <a className="font-weight-bold"
                                                        href="#"
                                                        onClick={e => e.preventDefault()}>
                                                        78324329847832</a>
                                                </td>
                                                <td>
                                                    ₹ 23405
                                                </td>
                                                <td>
                                                    783243298
                                                </td>
                                                <td>
                                                    XYZ Customer
                                                </td>
                                                <td>
                                                    13 Nov 2019
                                                </td>
                                                <td>
                                                    Hold
                                                </td>
                                                <td className="text-right">
                                                    <Button color="primary " outline size="sm" type="button" onClick={e => {
                                                        e.preventDefault()
                                                        // this.refreshFilters()
                                                    }}>
                                                        Collect
                                                    </Button>
                                                    <Button color="secondary "  size="sm" type="button" onClick={e => {
                                                        e.preventDefault()
                                                        // this.refreshFilters()
                                                    }}>
                                                        Refund
                                                    </Button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter className="py-4">
                            <nav aria-label="...">
                                <Pagination
                                    className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0"
                                >
                                    <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart);
                                            }}
                                            tabIndex="-1"
                                        >
                                            <i className="fas fa-angle-left" />
                                            <span className="sr-only">Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    {this.pager.pageArray.map(page => {
                                        return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.switchPage(page);
                                                }}
                                            >
                                                {page}
                                            </PaginationLink>
                                        </PaginationItem>
                                    })}
                                    <PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                                        <PaginationLink
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchPage(this.pager.pagerStart + 2);
                                            }}
                                        >
                                            <i className="fas fa-angle-right" />
                                            <span className="sr-only">Next</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </nav>
                        </CardFooter>
                    </Card>

                </Container>
            </Fragment>
        );
    }
}