import React, { useEffect, useState } from 'react';
import {Button, Col, Row} from "reactstrap";
import moment from "moment";
import isMobilePhone from 'validator/lib/isMobilePhone';
import { errorMessageComparison, initialComparisonFormState, options, touchFieldsComparison } from '../../../../defaultValues/comparison';
import { parseMessage, validataInputLength } from '../../../../helpers/utils';
import { buildCreateCompareQuotationPayload, isCalculatePremiumButtonDisabled } from '../../../../helpers/comparison';
import { ReducerUtils } from '../../../../constants/reducers';
import { Utils } from '../../../../constants/utils';
import { Validators } from '../../../../messages/validators';
import AxiosReactClient from '../../../../utilities/AxiosRestClient';
import Spinner from '../../../../components/Spinner/Spinner';

const QuotationComparisonForm = (props) => {
  const compareQuotation = localStorage.getItem('compareQuotation');
  const [errorMessage, setErrorMessage] = useState(errorMessageComparison);
  const [errors, setErrors] = useState([]);
  const [form, setForm] = useState(compareQuotation ? JSON.parse(compareQuotation) : initialComparisonFormState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [touchFields, setTouchFields] = useState(touchFieldsComparison);

  useEffect(() => {
    validateName();
  }, [form.name]);

  useEffect(() => {
    validateMobile();
  }, [form.mobile]);

  useEffect(() => {
    validatePincode();
  }, [form.pincode]);

  const validateName = () => {
    if (form.name) {
      let name = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/;
      let isValid = name.test(form.name);
      let message = isValid ? '' : Validators.name.valid;

      setFormState('hasError', !isValid);
      setErrorMessageState('name', message);
      setTouchFieldsState('name', true);
    } else if (touchFields.name) {
      setErrorMessageState('name', Validators.name.required);
    }
  };

  const validateMobile = () => {
    if (form.mobile) {
      let isValid = isMobilePhone(form.mobile, ['en-IN']);
      let message = isValid ? '' : Validators.mobile.valid;

      setFormState('hasError', !isValid);
      setErrorMessageState('mobile', message);
      setTouchFieldsState('mobile', true);
    } else if (touchFields.mobile) {
      setErrorMessageState('mobile', Validators.mobile.required);
    }
  };

  const validatePincode = () => {
    if (form.pincode) {
      let isValid = form.pincode.toString().length === 6;
      let message = isValid ? '' : Validators.pincode.valid;

      setFormState('hasError', !isValid);
      setErrorMessageState('pincode', message);
      setTouchFieldsState('pincode', true);
    } else if (touchFields.pincode) {
      setErrorMessageState('pincode', Validators.pincode.required);
    }
  };

  const setFormState = function (name, value) {
    setForm({
      ...form,
      [name]: value
    });
  };

  const setErrorMessageState = (name, value) => {
    setErrorMessage({
      ...errorMessage,
      [name]: value
    });
  };

  const setTouchFieldsState = (name, value) => {
    setTouchFields({
      ...touchFields,
      [name]: value
    });
  };
  
  const handleChange = (event) => {
    setFormState(event.target.name, event.target.value);
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  const cb = ({ data: { message }, data, success }) => {
    setIsFormSubmitted(false);
    const quotationData = {
      ...data,
      dob: data.dob.split(' ')[0]
    };
    localStorage.setItem('compareQuotation', JSON.stringify(quotationData));

    navigateTo(`/admin/comparisons/${data.id}`);
  };

  const cbError = ({ data: { error_msg }, data }) => {
    setIsFormSubmitted(false);
    setErrors(parseMessage(error_msg));

    console.log(errors);
  };
    
  const calculatePremium = async () => {
    setIsFormSubmitted(true);
    const payload = buildCreateCompareQuotationPayload(form);    

    try {
      const response = form.id 
        ? await AxiosReactClient.putRequest(`v2/comparisons/${form.id}`, payload)
        : await AxiosReactClient.postRequest('v2/comparisons', payload);
      
      cb(response.data);
    } catch (error) {
      cbError(error.response.data);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    calculatePremium();
  };

  const resetForm = () => {
    localStorage.removeItem('compareQuotation');
    setErrorMessage(errorMessageComparison);
    setErrors([]);
    setForm(initialComparisonFormState);
    setTouchFields(touchFieldsComparison);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <div className={"d-flex justify-content-between p-4"}>
            <div className={""}>
              <h3 className={"font-weight-bold"}>
                <i className="fa fa-arrow-left fnt-16 cursor-pointer mr-3" aria-hidden="true" 
                  onClick={() => navigateTo('/admin/comparisons')} /> Compare Quoation Form
              </h3>
            </div>
            <div className={""}>
              <Button
                className="btn-icon btn-2"
                size="md"
                outline
                color="primary"
                onClick={resetForm}>
                RESET
                <span className="btn-inner--icon ml-2">
                  <i className="fas fa-retweet" aria-hidden="true"/>
                </span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={3}/>
        <Col lg={6}>
          <div className={"d-flex justify-content-center"}>
            <div className={"w-100"}>
              <form onSubmit={onSubmit}>
                <div className="form-group w-100">
                  <label htmlFor="formControlInput1">Gender</label>
                  <select
                    name="gender"
                    className="form-control"
                    id="formControlInput1"
                    value={form.gender}
                    onChange={handleChange}>
                    <option value={''}>Select</option>
                    {options.gender.map((item) => {
                      return <option key={item.value} value={item.value}>{item.title}</option>
                    })}
                  </select>
                  {errorMessage.gender && <small className="text-danger">{errorMessage.gender}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput2">Name</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    id="formControlInput2"
                    placeholder="Full Name"
                    value={form.name}
                    onChange={handleChange}
                  />
                  {errorMessage.name && <small className="text-danger">{errorMessage.name}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput33">Mobile</label>
                  <input
                    name="mobile"
                    type="number"
                    className="form-control"
                    id="formControlInput33"
                    placeholder=""
                    value={form.mobile}
                    onChange={handleChange}
                    onInput={validataInputLength}
                  />
                  {errorMessage.mobile && <small className="text-danger">{errorMessage.mobile}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput4">Date of Birth</label>
                  <input
                    name="dob"
                    type="date"
                    className="form-control"
                    id="formControlInput4"
                    placeholder="dd/mm/yyyy"
                    value={form.dob}
                    onChange={handleChange}
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    min={moment()
                      .subtract(56, "years")
                      .add(1, "days").format("YYYY-MM-DD")}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput5">Pincode</label>
                  <input
                    name="pincode"
                    type="number"
                    className="form-control"
                    id="formControlInput5"
                    value={form.pincode}
                    onChange={handleChange}
                    onInput={validataInputLength}
                  />
                  {errorMessage.pincode && <small className="text-danger">{errorMessage.pincode}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput1">Consume Tobacco</label>
                  <select
                    name="is_smoker"
                    className="form-control"
                    id="formControlInput1"
                    value={form.is_smoker}
                    onChange={handleChange}>
                    <option value={''}>Select</option>
                    {options.yesNo.map((item) => {
                      return <option key={item.value} value={item.value}>{item.title}</option>
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput1">Occupation Type</label>
                  <select
                    name="occupation"
                    className="form-control"
                    id="formControlInput1"
                    value={form.occupation}
                    onChange={handleChange}>
                    <option value={''}>Select</option>
                    {options.occupation.map((item) => {
                      return <option key={item.value} value={item.value}>{item.title}</option>
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="formControlInput1">Annual Income in Lakhs</label>
                  <select
                    name="annual_income"
                    className="form-control"
                    id="formControlInput1"
                    value={form.annual_income}
                    onChange={handleChange}>
                    <option value={''}>Select</option>
                    {options.annualIncome.map((item) => {
                      return <option key={item.value} value={item.value}>{item.title}</option>
                    })}
                  </select>
                </div>
              
                <div className={"w-100"}>
                  <Button className="btn-icon btn-2 w-100" size="md" contained={"true"} color="primary" type="submit"
                    {...((isCalculatePremiumButtonDisabled(form) || isFormSubmitted) && {disabled: true})}
                    >
                    <span className="btn-inner--icon">
                      {isFormSubmitted ? <Spinner /> : 'Calculate Premium'}
                    </span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Col>
        <Col lg={3}/>
      </Row>
    </>
  );
}

export default QuotationComparisonForm