import React from 'react';
import Select2 from "react-select2-wrapper";
import { Card, CardBody } from "reactstrap";

const OemMappingPartner = (props) => {
    const {
        boolean,
        form,
        handleChange,
        partners = []
    } = props;

    return (
        <Card>
            <CardBody>
                <div className="w-100">
                    <label
                        className=" form-control-label mt-3 required"
                        htmlFor="dealer">
                        Partner
                    </label>

                    <Select2
                        className=" form-control-label mt-3"
                        options={{ placeholder: "Select Partner" }}
                        name="partnerId"
                        value={form.partnerId}
                        data={partners}
                        onChange={handleChange}
                        {...(boolean.mappingLoaded && { disabled: true })}
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default OemMappingPartner;