import React, { Component,Fragment} from 'react';
import Month from "../../../components/PartnerReports/Month";
import Date from "../../../components/PartnerReports/Date";
import ReactDatetime from "react-datetime";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    Container,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    Col,
    Progress
  } from "reactstrap";


export default class Reports extends Component{
    constructor(props) {
        super(props)
        this.state = {
            reportItems:[
                {item:"Finance Report", key: 'finance_report'},
                {item:"Policies Report", key: 'policies_report'},
                {item:"Claims Report", key: 'a'},
                {item:"Distributors Report", key: 'b'},
                {item:"Float Report", key: 'c'},
                {item:"Quarter Report", key: 'd'},
                {item:"Combined Report", key: 'e'},
            ],
            itemData:[
                {title:"Finance Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'finance_report'},
                {title:"Policies Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'policies_report'},
                {title:"Claims Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'a'},
                {title:"Distributors Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'b'},
                {title:"Float Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'c'},
                {title:"Quarter Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'd'},
                {title:"Combined Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'e'},
            ],
            isClicked: false,
            period: "",
            singleRow: {title:"Finance Report", content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis non dolore est fuga nobis ipsum illum eligendi nemo iure repellat, soluta, optio minus ut reiciendis voluptates enim impedit veritatis officiis.", key: 'finance_report'},
                 
        }
        
      }
      handleItemClick = (key) => {
          const singleRow = this.state.itemData.filter(arr => arr.key === key)[0];
          this.setState({ singleRow });
        //   console.log(this.state, key, typeof key);
      }
      handleChange = (event) => {
        this.setState({ period: event.target.value });
      }
      handleReactDatetimeChange = (who, date) => {
        if (
          this.state.startDate &&
          who === "endDate" &&
          new Date(this.state.startDate._d + "") > new Date(date._d + "")
        ) {
          this.setState({
            startDate: date,
            endDate: date
          });
        } else if (
          this.state.endDate &&
          who === "startDate" &&
          new Date(this.state.endDate._d + "") < new Date(date._d + "")
        ) {
          this.setState({
            startDate: date,
            endDate: date
          });
        } else {
          this.setState({
            [who]: date
          });
        }
      };
      // this function adds on the day tag of the date picker
      // middle-date className which means that this day will have no border radius
      // start-date className which means that this day will only have left border radius
      // end-date className which means that this day will only have right border radius
      // this way, the selected dates will look nice and will only be rounded at the ends
      getClassNameReactDatetimeDays = date => {
        if (this.state.startDate && this.state.endDate) {
        }
        if (
          this.state.startDate &&
          this.state.endDate &&
          this.state.startDate._d + "" !== this.state.endDate._d + ""
        ) {
          if (
            new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
            new Date(this.state.startDate._d + "") < new Date(date._d + "")
          ) {
            return " middle-date";
          }
          if (this.state.endDate._d + "" === date._d + "") {
            return " end-date";
          }
          if (this.state.startDate._d + "" === date._d + "") {
            return " start-date";
          }
        }
        return "";
      };
     

render(){
    const {reportItems} = this.state;
    
    // console.log(period, typeof period);
    
    return(
        <Fragment>
            <Container fluid>
            <CardHeader className="border-0 bg-transparent ">
            <Row>
              <Col xs="6">

                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-0"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary h5" onClick={this.clicked}>
                      Reports
                      </a>
                  </BreadcrumbItem>
                  
                </Breadcrumb>
                {/* <h3 className="h5 font-weight-500 text-primary mb-0 ml-3 mt-1 mb-1"><i className="fas fa-scroll"></i> - Claims</h3> */}
              </Col>
              <Col className="text-right" xs="6">
                {/* <Button
                    className="btn-round btn-icon"
                    color="danger"
                    href="#pablo"
                    id="tooltip28070728"
                    onClick={e => e.preventDefault()}
                    size="sm">
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-trash" />
                    </span>
                    <span className="btn-inner--text">Delete</span>
                  </Button> */}
                {/* <UncontrolledTooltip delay={0} target="tooltip28070728">
                    Edit product
                  </UncontrolledTooltip> */}
              </Col>
            </Row>
          </CardHeader>
                <Row>
                    <Col lg="12">
                        <div>
                            <Card>
                                <Row className="no-gutters">
                                    <Col lg="3" className="border-0 text-center ">
                                        <Row>
                                            <Col >
                                                <Card>
                                                    <Table className="text-left table-flush" hover responsive> 
                                                        <tbody>
                                                            {reportItems.map((data) => (
                                                            <tr onClick={() => this.handleItemClick(data.key)}>
                                                                <td >
                                                                    {data.item}
                                                                    <span><i className="fa fa-caret-right float-right" aria-hidden="true"></i></span>
                                                                </td>
                                                            </tr>
                                                            ))}
                                                            
                                                        </tbody>
                                                    </Table>
                                                </Card> 
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="9" className="border-left border-right">
                                        <Row>
                                            <Col>
                                                {/* <Card> */}
                                                    <CardBody className="pb-0">
                                                        <CardTitle className="mb-3" tag="h3">
                                                            {this.state.singleRow.title}
                                                        </CardTitle>
                                                        <CardText className="m-0">
                                                            {this.state.singleRow.content}
                                                        </CardText>
                                                        <hr className="mt-3 mb-3"/>
                                                    </CardBody>
                                                
                                                    {/* <CardText className="m-0 p-0"> */}
                                                        <Row className="no-gutters"> 
                                                            <Col lg="4">
                                                                <CardBody className="pt-0 pb-0">
                                                                    <Form>
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="exampleFormControlSelect3"
                                                                                >
                                                                                Period
                                                                            </label>
                                                                            <Input id="exampleFormControlSelect3" type="select" name="period" value={this.state.period} onChange={this.handleChange}>
                                                                                
                                                                                <option value="Date">Date</option>
                                                                                <option value="Month">Month</option>
                                                                                <option value="Custom">Custom</option>
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Form>
                                                                </CardBody>
                                                            </Col>
                                                            <Col lg="8">
                                                                <CardBody className="pt-0 pb-0">
                                                                {!(this.state.period === "Custom")?
                                                                    <Row>
                                                                        <Col md="6">
                                                                            <FormGroup>
                                                                                                                                    
                                                                                {(this.state.period === "Month") ?
                                                                                    <Month/>
                                                                                :
                                                                                    <Date/>
                                                                                    }
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row> 
                                                                    :
                                                                    <Form>
                                                                        <Row className="input-daterange datepicker align-items-center ">
                                                                            <Col xs={12} sm={6}>
                                                                            <label className=" form-control-label">
                                                                                Start date
                                                                            </label>
                                                                                <FormGroup>
                                                                                    <ReactDatetime
                                                                                    inputProps={{
                                                                                        placeholder: "Pick A Start Date"
                                                                                    }}
                                                                                    value={this.state.startDate}
                                                                                    timeFormat={false}
                                                                                    onChange={e =>
                                                                                        this.handleReactDatetimeChange("startDate", e)
                                                                                    }
                                                                                    renderDay={(props, currentDate, selectedDate) => {
                                                                                        let classes = props.className;
                                                                                        classes += this.getClassNameReactDatetimeDays(
                                                                                        currentDate
                                                                                        );
                                                                                        return (
                                                                                        <td {...props} className={classes}>
                                                                                            {currentDate.date()}
                                                                                        </td>
                                                                                        );
                                                                                    }}
                                                                                    />
                                                                                </FormGroup>    
                                                                            </Col>
                                                                            <Col xs={12} sm={6}>
                                                                                <FormGroup>
                                                                                    <label className=" form-control-label">
                                                                                    End date
                                                                                    </label>
                                                                                    <ReactDatetime
                                                                                    inputProps={{
                                                                                        placeholder: "Pick A End Date"
                                                                                    }}
                                                                                    value={this.state.endDate}
                                                                                    timeFormat={false}
                                                                                    onChange={e =>
                                                                                        this.handleReactDatetimeChange("endDate", e)
                                                                                    }
                                                                                    renderDay={(props, currentDate, selectedDate) => {
                                                                                        let classes = props.className;
                                                                                        classes += this.getClassNameReactDatetimeDays(
                                                                                        currentDate
                                                                                        );
                                                                                        return (
                                                                                        <td {...props} className={classes}>
                                                                                            {currentDate.date()}
                                                                                        </td>
                                                                                        );
                                                                                    }}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row> 
                                                                    </Form>
                                                                }
                                                                </CardBody>   
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <CardBody className="pt-0 pb-0">
                                                                    <Form>
                                                                        <FormGroup>
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="exampleFormControlSelect3"
                                                                                >
                                                                                Select File Format
                                                                            </label>
                                                                            <Input id="exampleFormControlSelect3" type="select" name="File">
                                                                                
                                                                                <option value="CSV">CSV</option>
                                                                                <option value="XLS">XLS</option>
                                                                                <option value="XLSX">XLSX</option>
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Form>
                                                                </CardBody>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <CardBody className="pt-0 pb-0">
                                                                    <Button color="primary" size="sm" type="button">
                                                                        Download Report
                                                                    </Button>
                                                                
                                                                    <Button color="secondary" size="sm" type="button">
                                                                        Email Report
                                                                    </Button>
                                                                </CardBody>
                                                            </Col>
                                                        </Row>
                                                        <CardBody className="pt-0 pb-0">
                                                                <hr/>
                                                        </CardBody>
                                                        <Row>
                                                            <Col lg="8">
                                                                <CardBody className="pt-0 pb-4">
                                                                    <div className="progress-wrapper">
                                                                        <div className="progress-info">
                                                                            
                                                                            <h5>Generating XYZ Montly Report For Oct'19</h5>
                                                                            
                                                                            <div className="progress-percentage ">
                                                                                
                                                                            <span><i className="ni ni-fat-remove"></i></span>
                                                                            </div>
                                                                        </div>
                                                                        <Progress max="100" value="90" color="success" />
                                                                    </div>
                                                                </CardBody>
                                                            </Col>
                                                                <hr/>
                                                        </Row>
                                                        
                                                    {/* </CardText>    */}
                                                {/* </Card> */}
                                                
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </Col>
                    
                </Row>
                    
            </Container>
        </Fragment>
    );
}
}