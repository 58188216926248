import React,{useState} from 'react';
import { connect } from 'react-redux';
import {
    Card, 
    CardTitle, 
    CardBody,
    Badge,
    Row,
    Col,
    Container,
    Button,
    Form,
    FormGroup,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    ButtonGroup,
    FormFeedback,
    FormText
} from 'reactstrap';
import { Loader } from 'semantic-ui-react';
import InitialLoader from '../../../components/DataLoader';
import * as actions from "../../../Store/actions";
import AxiosRestClient from '../../../utilities/AxiosRestClient';

const isNumber = /^[0-9]+$/;

function OfflineMap(props) {

    const [state, setState] = useState({
        dealer:null,dealerId:"",dealerCredit:{},isDealerLoading:false,isDealerLoaded:false,utrn:"",amount:"",isFormSubmitting:false,error:{utrn:"",amount:""}
    });

    async function getDealerInfo(e){
        if(e?.preventDefault){
            e.preventDefault();
        }
        try{
            if(state.dealerId && isNaN(state.dealerId) || !state.dealerId){
                showMsg("Please enter valid dealer id","danger");
                return false;
            }
            setState({...state,isDealerLoading:true,isDealerLoaded:true})
            const response = await AxiosRestClient.getRequest(`dealer_credit_amount/${state.dealerId}`)
            setState({
                ...state,
                dealer:{...response?.data?.data?.salesData,...response?.data?.data?.dealerData},
                isDealerLoading:false,
                isFormSubmitting:false,
                utrn:"",
                amount:"",
                error:{utrn:"",amount:""}
            })
        }catch(error){
            console.log(error)
            setState({...state,isDealerLoading:false,isDealerLoaded:false,isFormSubmitting:false})
        }
    }

    const showMsg = (msg, type) => {
        props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };


    async function submitCredit(){
        try{
         
            setState({...state, isFormSubmitting:true, error:{utrn:"", amount:""}});
            if(!state.utrn.trim()){
                // showMsg("UTRN cannot be empty", "danger");
                setState({...state,isFormSubmitting:false, error:{...state.error,utrn:"UTRN is required"}});
                return "Warning";
            }
            if(!state.dealerId){
                showMsg("Dealer id cannot be empty", "danger");
                setState({...state,isFormSubmitting:false});
                return "Warning";
            }
            if(!state.amount){
                // showMsg("Amount cannot be empty", "danger");
                setState({...state,isFormSubmitting:false,error:{...state.error,amount:"Amount is required"}});
                return "Warning";
            }
            if(state?.dealer?.creditAmount && !isNaN(state.amount)){
                if(Number(state?.dealer?.creditAmount)===Number(state.amount)){
                    const payload ={
                        "dealer_id":state.dealerId,
                        "UTRN_number":state.utrn.trim(),
                        "amount":state.amount
                    }
                    await AxiosRestClient.postRequest("admin/update-dealer-payment-status",payload);
                    showMsg("Payment completed", "success");
                    getDealerInfo()
                }else{
                    /*********Warning : Amount should be equal********* */
                    // showMsg("Amount and Credit amount should be equal", "danger");
                    setState({...state,isFormSubmitting:false,error:{...state.error,amount:"Amount and Credit amount should be equal"}});
                }
            }else{
                /*******Warning : Amount is not valid******* */
                // showMsg("Amount is invalid", "danger");

                setState({...state,isFormSubmitting:false,error:{...state.error,amount:"Please enter valid amount"}});
            }
        }catch(error){
            /*******Disable loader and show error****** */
            setState({...state,isFormSubmitting:false});
        }
    }

    function onValueChange(e){
        e.preventDefault();
        if(e.target.name==="amount" && !isNumber.test(e.target.value) && e.target.value!==""){
            return false;
        }
        if(e.target.name==="utrn" && e.target.value.trim().length>150){
            return false;
        }
        if(e.target.name==="dealerId" && !isNumber.test(e.target.value) && e.target.value!==""){
            return false;
        }
        if(e.target.name==="dealerId"){
            setState({...state,dealer:null,[e.target.name]:e.target.value.trim()})
        }else if(e.target.name==="utrn"){
            setState({...state,[e.target.name]:e.target.value,error:{utrn:"",amount:""}})
        }else{
            setState({...state,[e.target.name]:e.target.value.trim(),error:{utrn:"",amount:""}})
        }

        if(e.target.name==="amount" && isNaN(e.target.value)){
            setState({...state,[e.target.name]:e.target.value, error:{...state.error,amount:"Please enter valid amount"}})
        }else if(e.target.name==="amount"){
            setState({...state,[e.target.name]:e.target.value, error:{...state.error,amount:""}})
        }
    }

    return (
        <>
            <div className="container-fluid">
                <Row className="align-items-center py-4">
                    <Col lg="8" xs="6">
                        <Breadcrumb
                            className="d-none d-md-inline-block"
                            listClassName="breadcrumb-links"
                        >
                            <BreadcrumbItem>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <i className="fas fa-home" />
                                </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    Finance
                                </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    Map Offline Payment
                                </a>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="container-fluid">
                    <div className="row">
                        <Form onSubmit={getDealerInfo} className="w-100">
                            <div className="d-flex flex-row">
                                <Input name="dealerId" value={state.dealerId} onChange={onValueChange} className="mr-5" placeholder="Dealer Id"/>
                                <Button onClick={getDealerInfo}>Get Info</Button>
                            </div>
                        </Form>

                        <InitialLoader loading={state.isDealerLoading} showLoader={true}>
                            <InitialLoader loading={[null,undefined].includes(state?.dealer?.creditAmount)} showLoader={false}>
                                
                                <Card className="d-flex w-100 mt-5 p-4">
                                    <Row className="mt-3">
                                        <Col style={{whiteSpace: 'nowrap'}}>
                                            <span className="text-sm font-weight-bold pr-3">Dealership Name:</span>
                                            <span className="text-sm">
                                                {state?.dealer ? state.dealer.dealerName : "N/A"}
                                            </span>
                                        </Col>
                                        <Col style={{whiteSpace: 'nowrap'}}>
                                            <span className="text-sm font-weight-bold pr-3">Dealer Email:</span>
                                            <span className="text-sm">
                                                {state?.dealer ? state.dealer.dealerEmail : "N/A"}
                                            </span>
                                        </Col>
                                        <Col style={{whiteSpace: 'nowrap'}}>
                                            <span className="text-sm font-weight-bold pr-3">Dealer Mobile:</span>
                                            <span className="text-sm">
                                                {state?.dealer?.additionalNumbersData[0]?.mobile ? state.dealer.additionalNumbersData[0].mobile : "N/A"}
                                            </span>
                                        </Col>
                                    </Row>
                                    <div className="w-100 d-flex flex-row-reverse mt-5">
                                    <div className="w-25 d-flex justify-content-between border p-3 border-danger rounded dashed-border">
                                        <span className="text-md font-weight-bold">
                                            Total Credit Amount
                                        </span>
                                        <span className="text-md font-weight-bold">
                                            ₹{state?.dealer?.creditAmount}
                                        </span>
                                    </div>
                                </div>

                                    <Form className="w-100 mt-2">
                                        <div className="d-flex flex-row">
                                            <FormGroup className="w-100">
                                                <label
                                                        className="form-control-label"
                                                        htmlFor="utrn"
                                                    >
                                                        UTRN *
                                                    </label>
                                                <Input invalid={!!state.error?.utrn} value={state.utrn} disabled={state.isFormSubmitting} onChange={onValueChange} name="utrn" placeholder="UTRN"/>
                                                <FormFeedback>{state.error?.utrn}</FormFeedback>
                                            </FormGroup>
                                        </div>
                                
                                        <div className="d-flex flex-row">
                                            <FormGroup className="w-100">
                                            <label
                                                    className="form-control-label"
                                                    htmlFor="amount"
                                                >
                                                    Amount *
                                                </label>
                                                <Input invalid={!!state.error?.amount} value={state.amount} disabled={state.isFormSubmitting} onChange={onValueChange} name="amount" placeholder="Amount"/> 
                                                <FormFeedback>{state.error?.amount}</FormFeedback>
                                            </FormGroup>
                                        </div>
                                        <div className="d-flex w-100 flex-row-reverse">
                                            <Button disabled={state.isFormSubmitting} onClick={submitCredit} color="primary">
                                                {state.isFormSubmitting?<Loader active inline='centered'/>:"Submit"}
                                            </Button>
                                        </div>
                                    </Form>
                                </Card>
                                    
                            </InitialLoader>
                        </InitialLoader>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification))
    }
};
export default connect(null, mapDispatchToProps)(OfflineMap);