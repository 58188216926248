import moment from 'moment';
import { Utils } from '../constants/utils';
import { convertDateToString } from './utils';

export const rebuildAPIResponse = (list) => {
  let response = [];

  list.map((item, index) =>
    response.push({
      ...item,
      policy_holder_name: item.responses ? item.responses.policy_holder_name : '',
      expiry_date: item.responses ? item.responses.expiry_date : '',
      expiry_date_timestamp: item.responses ? (item.responses.expiry_date ? moment(item.responses.expiry_date).unix() : 0) : '',
      issued_date: item.responses ? item.responses.issued_date : '',
      issued_date_timestamp: item.responses ? (item.responses.issued_date ? moment(item.responses.issued_date).unix() : 0) : '',
      policy_number: item.responses ? item.responses.policy_number : '',
      insurance_provider: item.responses ? item.responses.insurance_provider : '',
      policy_premium: item.responses ? item.responses.policy_premium : '',
      policy_premium_sortby: item.responses ? (item.responses.policy_premium ? parseFloat(item.responses.policy_premium) : 0.0) : '',
      product: Utils.otherPolicies.text.lifeInsurance,
      sum_insured: item.responses ? item.responses.sum_insured : '',
      sum_insured_sortby: item.responses ? (item.responses.sum_insured ? parseFloat(item.responses.sum_insured) : 0.0) : '',
      policy_status_text: item.responses ? (item.responses.policy_status === 'policy_read'
        ? Utils.otherPolicies.status.policyReadManual : Utils.otherPolicies.status.policyDetailsSubmitted)
        : Utils.otherPolicies.status.policyDocumentsUploaded
    })
  );

  return response;
};

export const buildCsvHeaders = () => {
  return [
    { label: "ID", key: "id" },
    { label: "Partner Name", key: "name" },
    { label: "Float Amount", key: "float_amount" }
  ]
};

export const isUpdateButtonDisabled = ({ id, utrn, date_of_fund_transfer, amount, hasError }) => {
  return (!id || !utrn || !date_of_fund_transfer || !amount || hasError);
};

export const buildUpdateFloatAmountPayload = ({ utrn, amount, date_of_fund_transfer }) => {
  return {
    utrn,
    float_amount: parseInt(amount, 10),
    date_of_fund_transfer: convertDateToString(date_of_fund_transfer)
  };
};

export const isObject = (obj) => {
  return obj === Object(obj);
};

export const makeReadable = (str) => {
  var i, frags = str.split('_');

  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }

  return frags.join(' ');
};

export const renderData = (item) => {
  let strData = '';

  for (var key in item.data) {
    if (!isObject(item.data[key])) {
      strData = strData + '<div>' + `${makeReadable(key)}: ${item.data[key]}` + "</div>";
    } else {
      strData = strData + '<div>' + `${makeReadable(key)}:</div>`;
      for (var k in item.data[key]) {
        strData = strData + '<div style="margin-left: 20px; ">' + `${makeReadable(k)}: ${item.data[key][k]}` + "</div>";
      }
    }
  }

  return strData;
};

export const isValidDate = (current, field) => {
  if (field === 'date_of_fund_transfer') {
    return current.isBefore(moment());
  }
};

export const buildPartnerProductList = (products) => {
  let productList = [];

  if (!products.length) {
    return productList;
  }

  products.map((item) => productList.push({ id: item.id, text: `${item.name} (${item.id})` }));

  return productList;
};

export const buildMultiPartnerProductList = (products) => {
  let productList = [];

  if (!products.length) {
    return productList;
  }

  products.map((item) => productList.push({ value: item.id, label: `${item.name} (${item.id})` }));

  return productList;
};

export const extractProductIdsFromList = (products) => {
  let productIds = [];

  products.map((item) => {
    productIds.push(item.value);
  });

  return productIds;
};

export const buildPartnerListParams = () => {
  return {
    business_category: 'motor',
    excluded_partner_slug: 'motor-insurance'
  };
};