import React, { Component,Fragment} from 'react';
import ReactDatetime from "react-datetime";


export default class Month extends Component{


render(){
    return(
        <Fragment>
            <label className="form-control-label" htmlFor="exampleDatepicker" >
                Select a Month
            </label>
            <ReactDatetime
                inputProps={{
                    placeholder: "Pick A Month"
                }}
                
                dateFormat="MM-YYYY"
                timeFormat={false}
            />
        </Fragment>
    );

}
}