import { ReducerUtils } from '../constants/reducers';
import { compareValues } from '../helpers/utils';

export default function reducer(state, action) {
  switch (action.type) {
    case ReducerUtils.partners.list:
      return {
        ...state,
        partnersList: action.payload,
        partnersListApi : action.payload
      };

    case ReducerUtils.partners.search:
      const query = action.payload;
      
      if (query) {
        const filteredList = state.partnersListApi.filter(item => {
          return (
            item.name && item.name.toLowerCase().includes(query.toLowerCase())
          );
        });

        return {
          ...state,
          partnersList: filteredList || []
        };
      } else {
        return {
          ...state,
          partnersList: [ ...state.partnersListApi ]
        };
      }

    case ReducerUtils.partners.sort:
      const shallowCopy = [...state.partnersList];
      const sortedList = shallowCopy.sort(compareValues(action.payload.sortBy, action.payload.sortType));        

      return {
        ...state,
        partnersList: sortedList
      };

    case ReducerUtils.partners.updateFloatAmount:
      const arrayCopy = [...state.partnersList];

      arrayCopy.map((item) => {
        if (item.id === action.payload.id) {
          item.float_amount = action.payload.new_float_amount;
        }
      });

      return {
        ...state,
        partnersList: arrayCopy || []
      };

    default:
      return state;
  }
}
    