import Agents from "./Agents.jsx";
import * as permissions from "../../../config/Casl/permissions";
import AgentExamStudyMaterial from "./AgentExamStudyMaterial.jsx";
import AgentExam from "./AgentExam.jsx";
import AgentExamResult from "./AgentExamResult.jsx";
import AddAgent from "./AddAgent";
import AgentDetails from "./AgentDetails";

const agentRoute = {
  collapse: true,
  name: "Agents",
  icon: "ni ni-badge text-primary",
  state: "agentsCollapse",
  permission: permissions.DEALER.menu,
  subject: permissions.DEALER.subject,
  views: [
    {
      path: "/agents/all",
      name: "All Agents",
      component: Agents,
      layout: "/admin",
      permission: permissions.DEALER.list,
      subject: permissions.DEALER.subject,
    },
    {
      path: "/agents/addAgent",
      name: "Add Agent",
      component: AddAgent,
      layout: "/admin",
      permission: permissions.DEALER.create,
      subject: permissions.DEALER.subject,
    },
    {
      path: "/agents/study_material",
      name: "Agent Study Material",
      component: AgentExamStudyMaterial,
      layout: "/admin",
      permission: permissions.DEALER.list,
      subject: permissions.DEALER.subject,
    },
    {
      path: "/agents/:id/exam",
      name: "Agent Exam",
      component: AgentExam,
      layout: "/admin",
    },
    {
      path: "/agents/:id/exam-result",
      name: "Agent Exam Result",
      component: AgentExamResult,
      layout: "/admin",
    },
    {
      path: "/agents/:id",
      name: "Agent Details",
      component: AgentDetails,
      layout: "/admin",
    },
    {
      path: "/agents/:id/kyc",
      name: "Agent Details",
      component: AgentDetails,
      layout: "/admin",
    },
  ],
};

export default agentRoute;
