import React, { Component, Fragment } from "react";
import ReactDatetime from "react-datetime";
import {
  Card,
  PaginationItem,
  Pagination,
  PaginationLink,
  Container,
  Row,
  Col,
  BreadcrumbItem,
  Breadcrumb,
  CardBody,
  CardTitle,
  CardFooter,
  Table,
  Modal,
  Form,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
    Button,
    Badge
} from "reactstrap";
import DropdownCustom from "../../../components/dropDown";
import * as actions from "../../../Store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
import CustomMultiSelect from "../../../components/CustomMultiSelect";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import moment from "moment";
import { cycleProductList } from "../../../defaultValues/comparison";
import AlertModal from "../../../components/Modal/Alert";

const validPatternSearch = /^[ a-zA-Z&0-9_-]*$/;

class AllPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMonthDialog: false,
      cardFilter: "all",
      startDateFilter: "",
      endDateFilter: "",
      startDate: "",
      endDate: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      selectedDate:
        new Date().getMonth() === 0
          ? new Date(new Date().getFullYear() - 1, 11)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      showMultiSelectOption: false,
      showProduct: false,
      showProvider: false,
      filter: "This Month",
      customDateSelected: false,
      searchText: "",
      sweetAlertType: "",
      sweetAlertData: {}
    };
    this.invokeApiCall();
  }

  invokeApiCall = async () => {
    await this.getPolicies();
    await this.props.onGetProductList();
    await this.props.onGetProviderList();
    await this.props.onGetStatusList();
    await this.props.onGetPaymentDueCardData();
    await this.props.onGetResubscriptionCardData();
  };

  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);

  cancellationInitiatedOptions = [
    { value: "resendPolicyMail", text: "Resend Policy Mail" },
    { value: "cancellationApproved", text: "Cancellation Approved" },
  ];

  activePolicyOptions = [
    { value: "resendPolicyMail", text: "Resend Policy Mail" },
    { value: "cancelPolicy", text: "Cancel Policy" },
  ];
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
    dealerId: "",
    productIds: [],
    providerIds: [],
    policyStatusId: "",
    sortBy: 0,
    filter: "thisMonth",
    export: 0,
    statusIds: [],
    dir: "desc",
  };

  getPolicies = () => {
    this.props.onGetPolicyList(
      {},
      {
        page: this.pager.pagerStart,
        sortBy: this.pager.sortBy,
        dir: this.pager.dir,
        order_search: this.state.searchText,
        startDate: this.state.startDateFilter,
        endDate: this.state.endDateFilter,
        date: this.state.endDateFilter ? "custom" : this.pager.filter,
        "product_id[]":
          this.pager.productIds.length > 0 ? this.pager.productIds : "",
        "provider_id[]":
          this.pager.providerIds.length > 0 ? this.pager.providerIds : "",
        "status_id[]":
          this.pager.statusIds.length > 0 ? this.pager.statusIds : "",
        export: this.pager.export,
      }
    );
    this.pager.export = 0;
  };

  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getPolicies();
    }
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };
  handleCancel=()=>{
    this.setState({...this.state,sweetAlertType : ""})
  }
  handleConfirm=()=>{
    if(this.state.sweetAlertType==="convertToPaid" || this.state.sweetAlertType==="convertToEmbedded"){
      this.onConvertClick(this.state.sweetAlertData)
    }
    this.setState({...this.state,sweetAlertType : "", sweetAlertData:{}})
  }
  handleAction = (e, { value }, orderId, statusId) => {
    this.setState({
      orderId: orderId,
      isCancellationInitiated: statusId === 26,
      isCancelled: statusId === 27,
      dropDownValue: value,
    });
    if (value === "cancelPolicy") {
      this.setState({
        cancelConfirmDialog: true,
        dropDownValue: "",
      });
      this.cancelPolicy(orderId, { action: "initiate" }, value);
    }
    if (value === "cancellationApproved") {
      this.setState({
        cancelConfirmDialog: true,
        dropDownValue: "",
      });
      this.cancelPolicy(orderId, { action: "approve" }, value);
    }
    if (value === "resendPolicyMail") {
      this.onClickSend(orderId);
      this.setState({
        dropDownValue: "",
      });
    }
    if(value==="convertToPaid"){
      let payload ={
        action: "is_embedded",
        is_embedded: false,
        order_id: orderId
      }
      this.setState({...this.state,
        sweetAlertType:"convertToPaid",
        sweetAlertData: payload
      });
      
      // this.onConvertClick(payload)
    }
    else if(value==="convertToEmbedded"){
      let payload ={
        action: "is_embedded",
        is_embedded: true,
        order_id: orderId
      }
      this.setState({...this.state,
        sweetAlertType:"convertToEmbedded",
        sweetAlertData: payload
      });
      // this.onConvertClick(payload)
    }
  };

  onClickSend = (orderId) => {
    this.sendComms({
      order_id: orderId,
      template: "policy_mail",
    });
  };
  onConvertClick = (payload) => {
    AxiosReactClient.putRequest("v2/orders/"+payload.order_id, payload)
      .then((res) => {
        this.showMsg(res.data.message);
        this.getPolicies();
      })
      .catch((err) => {
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  sendComms = (payload) => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.postRequest("comms", payload)
      .then((res) => {
        this.setState({
          showLoader: false,
          sendMsgDialog: false,
        });
        this.showMsg("Communication Sent !", "success");
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  cancelPolicy = (id, params, type) => {
    let text;
    let order_id;
    if (type === "cancelPolicy") {
      text = "Initiated";
    } else {
      text = "Approved";
    }
    if (this.state.activePolicyCount === 1) {
      order_id = this.props.order_id;
    } else {
      order_id = id;
    }
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.getRequest("orders/" + order_id + "/cancel", params)
      .then((res) => {
        this.showMsg("Policy Cancellation " + text, "success");
        this.setState({
          showLoader: false,
          openPolicyActionDialog: false,
          orderId: null,
          cancelConfirmDialog: false,
        });
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };

  dateChangeFilter = (date, type) => {
    switch (type) {
      case "startDate":
        this.setState(
          {
            startDateFilter: date,
          },
          () => {
            this.getPolicies();
          }
        );
        break;
      case "endDate":
        this.setState(
          {
            endDateFilter: date,
          },
          () => {
            this.getPolicies();
          }
        );
        break;
      default:
        break;
    }
  };

  sortByColumn = (column_no) => {
    this.pager.sortBy = column_no;
    this.pager.dir = this.pager.dir === "desc" ? "asc" : "desc";
    this.getPolicies();
  };

  filterByArray(data, type) {
    let ids = data.map((element) => element.value);
    this.pager[type + "Ids"] = ids;
  }

  openMultiSelectOption() {
    this.setState({
      showMultiSelectOption: true,
    });
  }

  closeMultiSelectOption() {
    this.setState({
      showMultiSelectOption: false,
    });
    if (
      this.pager.productIds.length > 0 ||
      this.pager.providerIds.length > 0 ||
      this.pager.statusIds.length > 0
    ) {
      this.getPolicies();
    }
  }

  openRangeModal() {
    this.setState({
      startDate: moment(moment(new Date())).subtract(6, "days").format('YYYY-MM-DD'),
      endDate: moment(moment(new Date())).format('YYYY-MM-DD')
    });
    this.setState({
      showRangeModal: true,
    });
  }

  closeRangeModal() {
    this.setState({
      showRangeModal: false,
    });
  }

  sendDateRequest() {
    this.pager.pagerStart = 0;
    this.setState(
      {
        startDateFilter: moment(this.state.startDate._d).format("YYYY/MM/DD"),
        endDateFilter: moment(this.state.endDate._d).format("YYYY/MM/DD"),
        showRangeModal: false,
        filter: "custom",
        customDateSelected: true,
      },
      () => {
        this.getPolicies();
      }
    );
  }

  onReactDateFocused = (who) => {
    if (who === "startDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: true,
      });
    } else {
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
  };

  handleReactDatetimeChange = (type, date) => {
    let modifiedDate = moment(date).format("DD-MM-YYYY");
    
    if (this.state.startDate && type === "endDate" && new Date(this.state.startDate._d + "") > new Date(date._d + "")) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (this.state.endDate && type === "startDate" && new Date(this.state.endDate._d + "") < new Date(date._d + "")) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [type]: date,
      });
    }
    
    if (type === "startDate") {
      this.setState({
        endDate: moment(date).add(6, 'days').format('YYYY-MM-DD')
      });
      this.setState({
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
    if (type === "endDate") {
      this.setState({
        openEndDateDialog: false,
        openStartDateDialog: false,
      });
    }
  };

  getClassNameReactDatetimeDays = (date) => {
    if (this.state.startDate && this.state.endDate) {
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate._d + "" !== this.state.endDate._d + ""
    ) {
      if (
        new Date(this.state.endDate._d + "") > new Date(date._d + "") &&
        new Date(this.state.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (this.state.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (this.state.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  selectionOfOptions = (item) => {
    let selectOpt=[]
    if (item.status_id === 26) {
      selectOpt = this.cancellationInitiatedOptions;
    }
    if (item.status_id !== 26 && item.status_id !== 27) {
      selectOpt = this.activePolicyOptions;
    }
    if(cycleProductList.includes(item.product_id)){
      if(item.is_embedded){
        selectOpt=[...selectOpt,{ value: "convertToPaid", text: "Convert To Paid" }]
      }
      else{
        selectOpt=[...selectOpt,{ value: "convertToEmbedded", text: "Convert To Embedded" }]        
      }
    }
    return selectOpt
  };

  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };

  onCustomDateSelection = (filter) => {
    this.pager.filter = filter;
    this.pager.pagerStart = 0;
    this.setState(
      {
        startDateFilter: "",
        endDateFilter: "",
        startDate: "",
        endDate: "",
        filter: filter,
        customDateSelected: false,
      },
      () => {
        this.getPolicies();
      }
    );
  };

  onSearch = () => {
    if (!this.state.searchText || this.state.searchText.length < 2) {
      this.showMsg(" Please, enter at least 2 characters", "danger");
      return;
    }
    if (!this.state.searchText.match(validPatternSearch)) {
      this.showMsg(" Please, don't enter any special characters", "danger");
      return;
    }
    this.getPolicies();
    this.setState({
      view: "table",
    });
  };

  exportData = () => {
    this.pager.export = 1;
    let page = this.pager.pagerStart;
    let sortBy = this.pager.sortBy;
    let order_search = this.state.searchText;
    let startDate = this.state.startDateFilter;
    let endDate = this.state.endDateFilter;
    let date = this.state.endDateFilter ? "custom" : this.pager.filter;
    let productId =
      this.pager.productIds.length > 0 ? this.pager.productIds : "";
    let providerId =
      this.pager.providerIds.length > 0 ? this.pager.providerIds : "";
    let statusId = this.pager.statusIds.length > 0 ? this.pager.statusIds : "";
    this.pager.export = 0;

    let url =
      // "https://qa.toffeeinsurance.com/api/orders?page=" +
      "orders?page=" +
      page +
      "&sortBy=" +
      sortBy +
      "&order_search=" +
      order_search +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&date=" +
      date +
      "&product_id%5B%5D=" +
      productId +
      "&provider_id%5B%5D=" +
      providerId +
      "&status_id%5B%5D=" +
      statusId +
      "&export=1";

    AxiosReactClient.getRequest(url)
      .then((res) => {
        this.setState({
          showLoader: false,
          sendMsgDialog: false,
        });
        this.showMsg("Report sent at your registered email !", "success");
        this.getPolicies();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  refreshFilters = () => {
    this.pager.productIds = "";
    this.pager.providerIds = "";
    this.pager.policyStatusId = "";
    this.pager.sortBy = 0;
    this.pager.filter = "thisMonth";
    this.pager.export = 0;
    this.pager.statusIds = "";
    this.pager.pagerStart= 0;
    this.setState(
      {
        startDateFilter: "",
        endDateFilter: "",
        startDate: "",
        endDate: "",
        filter: "This Month",
        customDateSelected: false,
        searchText: ""
      },
      () => {
        this.getPolicies();
      }
    );
  };

  disableFutureDt = (current) => {
    const today = moment();
    return current.isBefore(today);
  };

  isValidEndDate = (current) => {
    const endDate = moment(this.state.startDate).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');

    return current.isSameOrAfter(this.state.startDate) && current.isSameOrBefore(endDate);
  };

  render() {
    const { policyListData, policyListLoading } = this.props;
    this.props.policyTotalCount !== null &&
      this.updatePagination(this.props.policyTotalCount);
    return (
      <Fragment>
        {this.state.sweetAlertType && <AlertModal showCancel={true} confirmText="Yes" cancelText="Cancel" 
        onCancel={this.handleCancel} onConfirm={this.handleConfirm} title="Are you sure to perform changes in policy?"/>
        }
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block "
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Policies
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
          <Row>
            <Col md="6" xl="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        ENACH RESUBSCRIPTION REQUIRED
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {this.props.policyResubscriptionCardData}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                        <i className="ni ni-chart-pie-35" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" xl="4">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        ONE TIME PAYMENT DUE THIS WEEK
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {this.props.policyPaymentDueCardData}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                        <i className="ni ni-money-coins" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="py-1 ">
            <Col xs={12} sm={6}>
              <h5 className="text-primary h5 font-weight-400"> Policies</h5>
            </Col>
          </Row>
          <div>
            <Row className="bg-white m-0" hidden={this.state.hideFilters}>
              <Col
                lg={6}
                md={8}
                className="p-0 text-muted d-flex justify-content-between"
              >
                <div className={"pt-2 mb--2 flex-grow-1 mr-3"}>
                  <form
                    xs="6"
                    className="form-inline md-form mr-3 mb-4 d-inline"
                  >
                    <div className="form-group border justify-content-between rounded-5">
                      <input
                        type="text"
                        className="form-control flex-grow-1 border-0"
                        placeholder="Search by Policy ID / Policy Number / Insured Name"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={this.state.searchText}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                          e.key === "Enter"
                            ? this.onSearch()
                            : this.setState({
                                searchText: e.target.value,
                              });
                        }}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.value == " ") {
                            this.setState({
                              view: "dealer-table",
                            });
                          }
                          this.setState({
                            searchText: e.target.value,
                          });
                        }}
                      />
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text cursor-pointer border-0 mr-2"
                            id="basic-addon1"
                          >
                            <i
                              className="fas fa-search cursor-pointer mt-2"
                              aria-hidden="true"
                              onClick={(e) => {
                                e.preventDefault();
                                this.onSearch();
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {this.state.searchText !== "" && this.state.view === "table" && (
                  <Badge color="primary" pill className={"m-auto py-2 px-3"}>
                    {this.state.searchText}
                    <span
                      className="p-1 ml-2 cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState(
                          {
                            view: "finance-table",
                            searchText: "",
                          },
                          () => this.getPolicies()
                        );
                      }}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true" />
                    </span>
                  </Badge>
                )}
              </Col>
              <Col className="text-right m-auto" lg="6" xs="6">
                <div className="">
                  <Badge
                    className={
                      this.state.customDateSelected ? "d-none" : "mr-3"
                    }
                    color={
                      this.state.filter === "custom" ? "secondary" : "primary"
                    }
                    href="#pablo"
                    pill
                  >
                    {this.state.filter !== "custom" ? this.state.filter : ""}
                  </Badge>
                  <Badge
                    className={
                      this.state.customDateSelected ? "mr-3" : "d-none"
                    }
                    color={
                      this.state.filter === "custom" ? "primary" : "secondary"
                    }
                    href="#pablo"
                    pill
                    onClick={(e) => {
                      e.preventDefault();
                      this.openRangeModal();
                    }}
                  >
                    {/* let modifiedDate = moment(date).format("DD-MM-YYYY"); */}
                    {(this.state.startDate
                      ? moment(this.state.startDate).format("DD-MM-YYYY")
                      : "") +
                      " TO " +
                      (this.state.endDate
                        ? moment(this.state.endDate).format("DD-MM-YYYY")
                        : "")}
                  </Badge>

                  <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={this.state.showRangeModal}
                    toggle={() => this.closeRangeModal()}
                  >
                    <div className="modal-body">
                      <Form role="form">
                        <Row className="input-daterange datepicker align-items-center">
                          <Col xs={12} sm={6}>
                            <label className=" form-control-label">
                              Start date
                            </label>
                            <FormGroup>
                              <ReactDatetime
                                open={this.state.openStartDateDialog}
                                isValidDate={this.disableFutureDt}
                                onFocus={(e) =>
                                  this.onReactDateFocused("startDate")
                                }
                                inputProps={{
                                  placeholder: "Start Date",
                                  readOnly: true
                                }}
                                value={
                                  this.state.startDate === ""
                                    ? this.state.startDate
                                    : moment(this.state.startDate).format(
                                        "DD-MM-YYYY"
                                      )
                                }
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("startDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes += this.getClassNameReactDatetimeDays(
                                    currentDate
                                  );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <FormGroup>
                              <label className=" form-control-label">
                                End date
                              </label>
                              <ReactDatetime
                                open={this.state.openEndDateDialog}
                                isValidDate={this.isValidEndDate}
                                onFocus={(e) =>
                                  this.onReactDateFocused("endDate")
                                }
                                inputProps={{
                                  placeholder: "End Date",
                                  readOnly: true
                                }}
                                value={
                                  this.state.endDate === ""
                                    ? this.state.endDate
                                    : moment(this.state.endDate).format(
                                        "DD-MM-YYYY"
                                      )
                                }
                                timeFormat={false}
                                onChange={(e) =>
                                  this.handleReactDatetimeChange("endDate", e)
                                }
                                renderDay={(
                                  props,
                                  currentDate,
                                  selectedDate
                                ) => {
                                  let classes = props.className;
                                  classes += this.getClassNameReactDatetimeDays(
                                    currentDate
                                  );
                                  return (
                                    <td {...props} className={classes}>
                                      {currentDate.date()}
                                    </td>
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </div>

                    <div className="modal-footer date-modal-footer ">
                      <Button
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.closeRangeModal()}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        className="ml-auto"
                        type="button"
                        onClick={() => this.sendDateRequest()}
                      >
                        Apply
                      </Button>
                    </div>
                  </Modal>

                  <UncontrolledDropdown className="">
                    <DropdownToggle
                      className="btn-neutral"
                      color=""
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      Filters
                    </DropdownToggle>
                    <DropdownMenu right>
                      {/* <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.refreshFilters();
                        }}
                      >
                        All{" "}
                        {this.props.filter === "all" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem> */}
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("today");
                        }}
                      >
                        Today{" "}
                        {this.props.filter === "today" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("yesterday");
                        }}
                      >
                        Yesterday{" "}
                        {this.props.filter === "yesterday" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("thisWeek");
                        }}
                      >
                        This Week{" "}
                        {this.props.filter === "thisWeek" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("thisMonth");
                        }}
                      >
                        This Month{" "}
                        {this.props.filter === "thisMonth" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("lastWeek");
                        }}
                      >
                        Last Week{" "}
                        {this.props.filter === "lastWeek" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("lastMonth");
                        }}
                      >
                        Last Month{" "}
                        {this.props.filter === "lastMonth" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.openRangeModal();
                          // this.setState({
                          //     customDateSelected: true
                          // })
                        }}
                      >
                        {this.state.filter !== "custom"
                          ? "Custom"
                          : this.state.startDate._d?.toLocaleDateString() +
                            " - " +
                            this.state.endDate._d?.toLocaleDateString() +
                            " "}
                        {this.props.filter === "custom" ? (
                          <i className="ni ni-check-bold" />
                        ) : (
                          ""
                        )}
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("resubscription");
                        }}
                      >
                        Enach resubscription required
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onCustomDateSelection("oneTimePayment");
                        }}
                      >
                        One time payment due this week
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  
                  <Button
                    color="primary "
                    outline
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.refreshFilters();
                    }}
                  >
                    Clear
                  </Button>

                  {/* <Button
                    color="primary "
                    outline
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.exportData();
                      // window.open("https://qa.toffeeinsurance.com/api/orders?page=0&sortBy=0&order_search=&startDate=&endDate=&date=&product_id%5B%5D=&provider_id%5B%5D=&status_id%5B%5D=&export=1")
                    }}
                  >
                    Export
                  </Button> */}
                </div>
              </Col>
            </Row>
          </div>
          <Card
            className="m-0 mt-1"
            style={{
              height: "1708px",
              overflowY: "scroll",
            }}
          >
            <CardBody className="px-0 py-0">
              <div className="table-responsive">
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th
                        className={
                          "cursor-pointer " +
                          (this.pager.sortBy === 4 && " text-primary")
                        }
                        onClick={() => this.sortByColumn(4)}
                      >
                        Policy ID <i className="fas fa-sort ml-2" />
                      </th>
                      <th
                        onClick={() => {
                          this.setState({
                            showProvider: false,
                            showProduct: true,
                            showStatus: false,
                          });
                          this.openMultiSelectOption();
                        }}
                      >
                        <div
                          className={
                            "cursor-pointer d-flex justify-content-between"
                          }
                        >
                          <span>Product</span>
                          {this.state.showProduct && (
                            <CustomMultiSelect
                              showMultiSelectOption={
                                this.state.showMultiSelectOption
                              }
                              closeMultiSelectOption={() =>
                                this.closeMultiSelectOption()
                              }
                              options={this.props.productListData}
                              filterByArray={(ids) =>
                                this.filterByArray(ids, "product")
                              }
                              title="Product"
                            />
                          )}
                          <i className="fas fa-filter align-self-center" />
                        </div>
                      </th>
                      <th
                        onClick={() => {
                          this.setState({
                            showProduct: false,
                            showProvider: true,
                            showStatus: false,
                          });
                          this.openMultiSelectOption();
                        }}
                      >
                        <div
                          className={
                            "cursor-pointer d-flex justify-content-between"
                          }
                        >
                          <span>Provider</span>
                          {this.state.showProvider && (
                            <CustomMultiSelect
                              showMultiSelectOption={
                                this.state.showMultiSelectOption
                              }
                              closeMultiSelectOption={() =>
                                this.closeMultiSelectOption()
                              }
                              options={this.props.providerListData}
                              filterByArray={(ids) =>
                                this.filterByArray(ids, "provider")
                              }
                              title="Provider"
                            />
                          )}
                          <i className="fas fa-filter align-self-center" />
                        </div>
                      </th>
                      <th>Insured Name </th>
                      <th
                        className={
                          "cursor-pointer " +
                          (this.pager.sortBy === 1 && "text-primary")
                        }
                        onClick={() => this.sortByColumn(1)}
                      >
                        Start Date <i className="fas fa-sort ml-2" />
                      </th>
                      <th
                        className={
                          "cursor-pointer " +
                          (this.pager.sortBy === 2 && "text-primary")
                        }
                        onClick={() => this.sortByColumn(2)}
                      >
                        End Date <i className="fas fa-sort ml-2" />
                      </th>
                      <th
                        className={
                          "cursor-pointer " +
                          (this.pager.sortBy === 0 && "text-primary")
                        }
                        onClick={() => this.sortByColumn(0)}
                      >
                        Purchased Date <i className="fas fa-sort ml-2" />
                      </th>
                      <th
                        className={
                          "cursor-pointer " +
                          (this.pager.sortBy === 3 && "text-primary")
                        }
                        onClick={() => this.sortByColumn(3)}
                      >
                        Premium <i className="fas fa-sort ml-2" />
                      </th>
                      <th
                        onClick={() => {
                          this.setState({
                            showProvider: false,
                            showProduct: false,
                            showStatus: true,
                          });
                          this.openMultiSelectOption();
                        }}
                      >
                        <div
                          className={
                            "cursor-pointer d-flex justify-content-between"
                          }
                        >
                          <span>Status</span>
                          {this.state.showStatus && (
                            <CustomMultiSelect
                              showMultiSelectOption={
                                this.state.showMultiSelectOption
                              }
                              closeMultiSelectOption={() =>
                                this.closeMultiSelectOption()
                              }
                              options={this.props.statusListData}
                              filterByArray={(ids) =>
                                this.filterByArray(ids, "status")
                              }
                              title="Status"
                            />
                          )}
                          <i className="fas fa-filter align-self-center" />
                        </div>
                      </th>
                      {/*<th className="text-right">Actions</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {policyListLoading && (
                      <tr>
                        <td colSpan="12">
                          <Spinner></Spinner>
                        </td>
                      </tr>
                    )}
                    {!policyListLoading &&
                      policyListData &&
                      policyListData.length < 1 && (
                        <tr className="cursor-pointer">
                          <td colSpan="12" className="text-center">
                            <span className="text-muted">
                              <b>No policy Data found</b>
                            </span>
                          </td>
                        </tr>
                      )}
                    {(!policyListLoading && policyListData) &&
                      policyListData.map((item, index) => {
                        return (
                          <tr key={index + "hgshdsh"}>
                            <td className="table-user">
                              <a
                                className="font-weight-bold"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/policies/" + item.id
                                  );
                                }}
                              >
                                {item.id}
                              </a>
                            </td>
                            <td>{item.product_name}</td>
                            <td>{item.provider_name}</td>
                            <td title={item.name}>{item.name ? (item.name.length >= 20 ? `${item.name.substring(0, 17)}...` : item.name) : '-'}</td>
                            <td>
                              {moment(item.start_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {moment(item.end_date).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {moment(item.policy_creation_ts).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>{item.premium}</td>
                            <td className="">
                              <div className="d-flex justify-content-between align-items-center">
                                {item.status}
                                <DropdownCustom
                                  open={this.state.isDropdownOpen}
                                  trigger={
                                    <i
                                      className="fa fa-ellipsis-v cursor-pointer ml-2"
                                      aria-hidden="true"
                                    />
                                  }
                                  options={this.selectionOfOptions(item)}
                                  position={"top right"}
                                  onClickOption={(e, { value }) => {
                                    this.handleAction(
                                      e,
                                      { value },
                                      item.id,
                                      item.status_id
                                    );
                                  }}
                                  value={this.state.dropDownValue}
                                />
                              </div>
                            </td>
                            {/*<td className="text-right">*/}
                            {/*    Action*/}
                            {/*</td>*/}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            {(!policyListLoading && policyListData && policyListData.length > 0) &&
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem
                    className={this.pager.pagerStart > 0 ? "" : "disabled"}
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart);
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {this.pager.pageArray.map((page) => {
                    return (
                      <PaginationItem
                        key={"pager_" + page}
                        hidden={this.isHidden(page)}
                        className={
                          this.pager.pagerStart + 1 === page ? "active" : ""
                        }
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.switchPage(page);
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}
                  <PaginationItem
                    className={
                      this.pager.pagerEnd > this.pager.pagerStart + 1
                        ? ""
                        : "disabled"
                    }
                  >
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.switchPage(this.pager.pagerStart + 2);
                      }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>}
          </Card>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    policyListLoading: state.policies.policyListLoading,
    policyListData: state.policies.policyListData,
    productListData: state.policies.policyProductListData,
    providerListData: state.policies.policyProviderListData,
    statusListData: state.policies.policyStatusListData,
    policyTotalCount: state.policies.policyTotalCount,
    policyPaymentDueCardLoading: state.policies.policyPaymentDueCardLoading,
    policyResubscriptionCardLoading:
      state.policies.policyResubscriptionCardLoading,
    policyPaymentDueCardData: state.policies.policyPaymentDueCardData,
    policyResubscriptionCardData: state.policies.policyResubscriptionCardData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
    onGetPolicyList: (filter, params) =>
      dispatch(actions.getPolicyList(filter, params)),
    onGetProductList: (filter, params) =>
      dispatch(actions.getPolicyProductListData(filter, params)),
    onGetProviderList: (filter, params) =>
      dispatch(actions.getPolicyProviderListData(filter, params)),
    onGetStatusList: (filter, params) =>
      dispatch(actions.getPolicyStatusListData(filter, params)),
    onGetPaymentDueCardData: () =>
      dispatch(actions.getPolicyPaymentDueCardData()),
    onGetResubscriptionCardData: () =>
      dispatch(actions.getPolicyResubscriptionCardData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllPolicies);

