import React from "react";
import {Modal} from "reactstrap";

function ModalWrapper({children, shouldVisible, toggle}) {
  return (<Modal
    className="modal-dialog-centered"
    size="sm"
    isOpen={shouldVisible}
    toggle={toggle}
  >
    <div className="modal-body">
      {children}
    </div>
  </Modal>)
}

export default ModalWrapper;