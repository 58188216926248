import React, { Component, Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import Loader from '../../../../components/CustomUi/Loader/Loader';
import AxiosReactClient from '../../../../utilities/AxiosRestClient'
import * as actions from '../../../../Store/actions';
import {
    Badge,
    Container,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
    CardHeader,
    CardTitle
} from "reactstrap";
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
class CreateUser extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        roleId: '',
        roleId1: '',
        password: '',
        cPassword: '',
        permissionIdList: [],
        showLoader: false,
        notification: null,
        roleName: "",
        groupNameArray: [],
        showPermissions: false
    };
    rolesList = [];
    permissionObjectArray = [];
    componentDidMount() {
        this.props.onGetRolesData();
    }
    handleTagsinput = tagsinput => {
        this.setState({ tagsinput });
    };
    submitUserData = () => {
        if (this.state.name.trim() === '') {
            this.showMsg("Please enter user name", "danger");
            return
        }
        if (this.state.email.trim() === '') {
            this.showMsg("Please enter user email", "danger");
            return
        }
        if (this.state.roleId === '') {
            this.showMsg("Please select a role", "danger");
            return
        }
        if (this.state.password.trim() === '') {
            this.showMsg("Please enter password.", "danger");
            return
        }
        if (this.state.password.trim().length < 6) {
            this.showMsg("Password should be 6 characters or more.", "danger");
            return
        }
        if (this.state.password.trim() !== this.state.cPassword) {
            this.showMsg("Passwords do not match", "danger");
            return
        }
        this.setState({
            showLoader: true
        })
        AxiosReactClient.postRequest('v2/users',
            {
                name: this.state.name,
                email: this.state.email,
                mobile: this.state.phone,
                role_id: this.state.roleId,
                permission_id: this.state.permissionIdsList,
                password: this.state.password
            }).then(response => {
                this.setState({
                    showLoader: false
                })
                this.showMsg("User Created successfully", "success")
                this.props.history.push('/admin/users')
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                }
                else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    removePermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, [permission.id])
        });
    }
    addPermissionElement = (permission) => {
        this.setState({
            permissionIdsList: this.concatArray([permission.id], this.state.permissionIdsList)
        })
    }
    concatArray(arr1, arr2) {
        var uniqueArray = [];
        let tempArr = arr1.concat(arr2);
        // Loop through array values
        for (var value of tempArr) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }
    spliceArray(original, remove) {
        return original.filter(value => !remove.includes(value));
    }
    addGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.concatArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.concatArray(this.state.groupNameArray, [groupName])
        })
    }
    removeGroupPermissions = (permissionArray, groupName) => {
        this.setState({
            permissionIdsList: this.spliceArray(this.state.permissionIdsList, permissionArray.map(({ id }) => { return id })),
            groupNameArray: this.spliceArray(this.state.groupNameArray, [groupName])
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            roleName: nextProps.roleIdData ? nextProps.roleIdData.name : ""
        })
        let idArray = [];
        nextProps.roleIdData && nextProps.roleIdData.permissions && nextProps.roleIdData.permissions.forEach(group => {
            group.permissions.forEach(permission => {
                idArray.push(permission.id)
            });
        })
        this.setState({
            permissionIdsList: []
        }, () => {
            this.setState({
                permissionIdsList: idArray
            })
        })
    }
    getPermissions = (id) => {
        this.props.onGetRoleIdData(id);
        this.setState({
            showPermissions: true
        })
    }
    onclickUpdateRole = () => {
        if (this.state.roleName === "") {
            this.showMsg("Please enter role name", 'danger');
            return
        }
        if (this.state.permissionIdsList.length < 1) {
            this.showMsg("Please select at least one permission", 'danger');
            return
        }

        this.setState({
            showLoader: true
        })
        AxiosReactClient.putRequest('roles/' + this.state.roleId1, {
            role_name: this.state.roleName,
            permission_id: this.state.permissionIdsList
        }).then(response => {
            this.showMsg("Role updated.", 'success');
            this.setState({
                showLoader: false
            })
        })
            .catch(err => {
                this.setState({
                    showLoader: false
                })
                if (err.response) {
                    this.showMsg(err.response.data.error.message, 'danger');
                }
                else {
                    this.showMsg(err.message, 'danger');
                }
            });
    }
    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    }
    changeString = (str) => {
        var a = str.indexOf(" ");
        var temp = str.substring(a + 1);
        return temp;
    }
    render() {
        this.rolesList = this.props.rolesData ? this.props.rolesData.roles : []
        if (!this.props.permissionsDataLoading && this.props.permissionsData) {
            this.permissionObjectArray = [].concat(this.props.permissionsData.permissions);
        }

        return (
            <Fragment>
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="4" xs="6">
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-2"
                                    listClassName="breadcrumb-links"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            New User
                                    </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="8" xs="6">
                            </Col>
                        </Row>
                    </div>
                    <Row className="m-0 p-0">
                        <Col className="p-2" xl="12">
                            <Card >
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">User Details</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="position-relative">
                                    {this.state.showLoader && <Loader msg={"Loading"} />}
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            User information
                                    </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Name
                                                    </label>
                                                        <Input
                                                            id="input-first-name"
                                                            value={this.state.name}
                                                            type="text"
                                                            onChange={e => {
                                                                this.setState({
                                                                    name: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-last-name"
                                                        >
                                                            Email
                                                    </label>
                                                        <Input
                                                            id="input-last-name"
                                                            value={this.state.email}
                                                            onChange={e => {
                                                                this.setState({
                                                                    email: e.target.value
                                                                })
                                                            }}
                                                            type="email"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-phone"
                                                        >
                                                            Phone
                                                    </label>
                                                        <Input
                                                            id="input-phone"
                                                            value={this.state.phone}
                                                            type="phone"
                                                            onChange={e => {
                                                                if ((e.target.value == '' || e.target.value.match(phoneRegex) && e.target.value.length < 15)) {
                                                                    this.setState({
                                                                        phone: e.target.value
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="exampleFormControlSelect1"
                                                        >
                                                            Role
                                                    </label>
                                                        <Input id="exampleFormControlSelect1" value={this.state.roleId} onChange={e => {
                                                            this.setState({
                                                                roleId: e.target.value,
                                                                roleId1: e.target.value
                                                            })
                                                            this.getPermissions(e.target.value)
                                                        }} type="select">
                                                            <option value="">Select</option>
                                                            {this.rolesList.map((role, index) => {
                                                                return <option key={"role_" + index} value={role.id}>{role.name}</option>
                                                            })
                                                            }
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password"
                                                        >Password</label>
                                                        <Input
                                                            id="input-password"
                                                            value={this.state.password}
                                                            onChange={e => {
                                                                this.setState({
                                                                    password: e.target.value
                                                                })
                                                            }}
                                                            type="password"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >Confirm Password</label>
                                                        <Input
                                                            id="input-username"
                                                            value={this.state.cPassword}
                                                            onChange={e => {
                                                                this.setState({
                                                                    cPassword: e.target.value
                                                                })
                                                            }}
                                                            type="password"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                    <div className="text-right">
                                        <Button
                                            color="primary"
                                            href="#pablo"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.submitUserData()
                                            }}
                                            size="mds"
                                        >
                                            Add user
                                    </Button>
                                    </div>
                                </CardBody>
                            </Card>
                            {this.state.showPermissions && <Card className="m-2">
                                <CardBody className="position-relative">
                                    {this.props.permissionsDataLoading && <Loader msg={"Loading"} />}
                                    <CardTitle className="mb-3" tag="h3">
                                        Permissions
                                </CardTitle>
                                    {this.permissionObjectArray.map((group, index) => {
                                        return <div key={Math.random(100)} className="mb-4">
                                            <label
                                                className="form-control-label cursor-pointer"
                                                htmlFor="example4cols1Input" >
                                                <span
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.state.groupNameArray.includes(group.groupName) ? this.removeGroupPermissions(group.permissions, group.groupName) : this.addGroupPermissions(group.permissions, group.groupName);
                                                    }}
                                                >
                                                    {group.groupName}
                                                </span>
                                            </label>
                                            <br />
                                            {group.permissions && group.permissions.map((permission, index) => {
                                                return <Badge key={Math.random(100)} id={"career-gray" + index}
                                                    className={`badge-md  cursor-pointer tag-behaviour m-1 ` + (this.state.permissionIdsList && this.state.permissionIdsList.includes(permission.id) ? " background-primary text-white" : " opacity-7 ")}
                                                    pill color="secondary"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        this.state.permissionIdsList.includes(permission.id) ? this.removePermissionElement(permission) : this.addPermissionElement(permission)
                                                    }}
                                                >{this.changeString(permission.display_name)}
                                                    <span className="btn-inner--icon ml-1">
                                                        {(this.state.permissionIdsList && this.state.permissionIdsList.includes(permission.id)) ?
                                                            <i className="fas fa-times" />
                                                            :
                                                            <i className="fas fa-plus" />
                                                        }
                                                    </span>
                                                </Badge>
                                            })}
                                        </div>
                                    })}
                                </CardBody>
                            </Card>}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        rolesDataLoading: state.roles.rolesDataLoading,
        permissionsDataLoading: state.roles.permissionsDataLoading,
        roleIdDataLoading: state.roles.roleIdDataLoading,

        rolesData: state.roles.rolesData,
        permissionsData: state.roles.permissionsData,
        roleIdData: state.roles.roleIdData,

        error: state.roles.error,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetRolesData: () => dispatch(actions.getRolesData()),
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPermissionsData: () => dispatch(actions.getPermissionsData()),
        onGetRoleIdData: (id) => dispatch(actions.getRoleIdData(id)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);