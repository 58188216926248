import React, { useEffect, Fragment } from "react";
import {
	Card,
	Container,
	CardBody,
	CardFooter,
	Button
} from "reactstrap";

const SuccessPage = (props) => {
  useEffect(() => {
    
  }, []);

  const handleClick = () => {
    navigateTo(`/admin/pet/dog`);
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };
	
	return (
		<Fragment>
			<Container fluid>
				<Card className="m-0 mt-1">
					<CardBody className="">
						<div className="">
							<div className={"d-flex m-3 mt-6 justify-content-between"}>
								<h3>Documents uploaded successfully</h3>
              </div>

						</div>
					</CardBody>

					<CardFooter className="py-4">
            <div className="text-right">
              <Button className="shadow-none mt-3" 
                size="md"
                contained={"true"}
                color={"primary"}
                type="button" 
                onClick={handleClick}
              >
                Done
              </Button>
            </div>
					</CardFooter>
				</Card>
			</Container>
		</Fragment>
	);
};

export default SuccessPage;