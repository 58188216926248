import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  CardBody,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  Input,
  Modal,
  Form,
  FormGroup,
} from 'reactstrap';
import ReactDatetime from "react-datetime";
// import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

// Import all custom default exports
import LeadsReducer from '../../../reducers/leadsReducer';
import Spinner from '../../../components/Spinner/Spinner';
import useApi from '../../../hooks/useApi';
import AxiosRestClient from '../../../utilities/AxiosRestClient';
import ModalWrapper from "../../../components/Wrapper/Modal"
import Notification from '../../../components/CustomUi/Notify/Notification';
import Can from '../../../config/Casl/Can';
import * as permissions from "../../../config/Casl/permissions";
import TableToggleRow from "../../../components/CustomUi/TableToggleRow"

// Import all third party named exports
import { CSVLink } from "react-csv";

// Import all custom named exports
import { ReducerUtils } from '../../../constants/reducers';
import {
  buildCreateLeadPayload, buildCsvHeaders, buildUpdatePolicyStartDatePayload, isLeadCaptureSubmitButtonDisabled,
  isIssuePolicySubmitButtonDisabled, rebuildAPIResponse, isValidDate
} from '../../../helpers/lead';
import { Utils } from '../../../constants/utils';
import { Validators } from '../../../messages/validators';
import { notify, redirectWithBlank, convertToArray, downloadZipFile } from '../../../helpers/utils';
import { errorMessageLead, initialLeadFormState, mapping, options, touchFieldsLead } from '../../../defaultValues/lead';
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import CustomMultiSelect from '../../../components/CustomSearchMultiSelect';

const MATCH_STRING_INSIDE_BRAC = /\s*\(.*?\)\s*/g;
let timeout2 = null;
let timeout3 = null;

function LeadDetailsPage({data}){

  function getAddOns(addons){
    if(addons){
      return addons.replace(MATCH_STRING_INSIDE_BRAC, ' ')
    }
    return addons;
  }

  function getDogAge(year, month){
    let age = "N/A";
    if(year){
      age = year+"year";
    }
    if(month){
      age=year?age+" "+month+" month":month+" month";
    }
    return age;
  }


  return <>
    <div className="d-flex flx-container p-3">
      <div className="flx-group mr-4 ml-4">
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Dog Name:
          </span>
          <div className="text-sm">{data?.dog_name?.capitalizeFirstLetter() || "N/A"}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Dog Breed:
          </span>
          <div className="text-sm">{data?.dog_breed?.capitalizeFirstLetter() || "N/A"}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Dog Category:
          </span>
          <div className="text-sm">{data?.dog_category || "N/A"}</div>
        </div>
      </div>
      <div className="flx-group mr-4 ml-4">
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Parent Name:
          </span>
          <div className="text-sm">{data?.dog_category || "N/A"}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Premium:
          </span>
          <div className="text-sm">{data?.order?.amount? "₹"+data.order.amount: "N/A"}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Dog Age:
          </span>
          <div className="text-sm">{getDogAge(data?.dog_age_year, data?.dog_age_month)}</div>
        </div>
      </div>
      <div className="flx-group mr-4 ml-4">
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Dog Gender:
          </span>
          <div className="text-sm">{data?.dog_gender?.capitalizeFirstLetter() || "N/A"}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Is Vaccinated:
          </span>
          <div className="text-sm">{[null, undefined].includes(data?.is_vaccinated)?"N/A":(data?.is_vaccinated?"Yes":"No")}</div>
        </div>
        <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Defects Ailments:
          </span>
          <div className="text-sm">{data?.defects_ailments || "N/A"}</div>
        </div>
      </div>
      <div className="flx-group mr-4 ml-4">
         <div className="p-0 pb-2">
          <span className="text-sm font-weight-bold pr-3">
            Addons:
          </span>
          <div className="text-sm">{getAddOns(data?.add_ons)?.split(",")?.map(ele=><>{ele}<br/></>) || "N/A"}</div>
        </div>
      </div>
    </div>
  </>
}

const Paginator = ({ pagination, handleClick, pageBuket, loader }) => {

  return (
    <Pagination aria-label="Page navigation example"
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0">

      <PaginationItem disabled={pagination.currentPage === 1 || loader}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage - 1)}
          previous
          href="#"
        />
      </PaginationItem>
      {[...pageBuket].map((page, i) =>
        <PaginationItem active={page === pagination.currentPage} key={i} disabled={loader}>
          <PaginationLink onClick={e => handleClick(e, page)} href="#">
            {page}
          </PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem disabled={pagination.currentPage === pagination.pagesCount || loader}>
        <PaginationLink
          onClick={e => handleClick(e, pagination.currentPage + 1)}
          next
          href="#"
        />
      </PaginationItem>
    </Pagination>
  );
};

let pager = [];

const DogInsuranceList = (props) => {
  const pageSize = 25;
  const csvHeaders = buildCsvHeaders();

  // Call useApi custom hook to fetch leads list
  // const leadsList = useApi('http://localhost:3000/data/leads-list.json', {}, true, []);
  const leads = useApi(`v2/dog_insurance`, { limit: pageSize }, false, [])
  const leadsList = leads?.data || [];

  const [state, dispatch] = useReducer(LeadsReducer, { leadsList: [], leadsListApi: [] });
  const [filter, setFilter] = useState({ search: '', sortBy: '', sortType: 'desc', status: '', dealerText: "", sourceText: "" });
  const [loader, setLoader] = useState(true);
  const [closeLead, setCloseLead] = useState(undefined);
  const [calender, setCalender] = useState({
    startDate: "", endDate: "", months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    openEndDateDialog: false,
    openStartDateDialog: false,
    showRangeModal:false,
    selectedDate:
      new Date().getMonth() === 0
        ? new Date(new Date().getFullYear() - 1, 11)
        : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
  });
  const [dateFilter, setDateFilter] = useState({
        startDateFilter: "",
        endDateFilter: "",
      })
  const [pagination, setPagination] = useState({ currentPage: 1, pagesCount: Math.ceil(leads?.last_page ? leads.last_page : 4) });
  const [modal, setModal] = useState({ isOpenLeadCapture: false, isOpenPolicyIssueDate: false, isRemarks: false, isCoverNote: false });
  const [form, setForm] = useState(initialLeadFormState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorMessageLead);
  const [touchFields, setTouchFields] = useState(touchFieldsLead);
  const [apiMessage, setApiMessage] = useState('');
  const [error, setError] = useState('');
  const [model, setModel] = useState({ dealerId: false, source: false });
  const [remarks, setRemarks] = useState('');
  const [pageBuket, setPagesBucket] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const [sources, setSources] = useState([{ name: "Others", id: "Others" }, { name: 'B2C Leads', id: 'B2C Leads' }]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sourceLoader, setSourceLoader] = useState(false);
  const [dealerLoader, setDealerLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [expand, setExpand] = useState();
  const [defaultDealer, setDefaultDealer] = useState(leads?.default_dealer_id);
  const [platformUrl, setPlatformUrl] = useState(leads?.current_domain);
  const platform_url = platformUrl;

  useEffect(() => {
    if (leadsList instanceof Array && leadsList.length > 0) {
      dispatchEvent(ReducerUtils.leads.list, rebuildAPIResponse(leadsList));
    }
  }, [leadsList]);

  useEffect(() => {
    if (state.leadsList instanceof Array && state.leadsList.length > 0) {
      setLoader(false);
    }
  }, [state.leadsList])

  useEffect(() => {
    if (pagination.pagesCount <= 4) {
      setPagesBucket([...Array.from({ length: pagination.pagesCount }, (_, i) => i + 1)])
    } else {
      setPagesBucket([...Array.from({ length: 4 }, (_, i) => i + 1)])
    }
  }, [pagination.pagesCount])

  useEffect(() => {
    if (Number(pagination.currentPage) === Number(pageBuket[pageBuket.length - 1]) && pagination.currentPage !== pagination.pagesCount) {
      const newPages = [...pageBuket];
      newPages.shift();
      newPages.push(pagination.currentPage + 1);
      setPagesBucket([...newPages])
    }
    if (Number(pagination.currentPage) === Number(pageBuket[0]) && pagination.currentPage !== 1) {
      const newPages = [...pageBuket];
      newPages.pop();
      newPages.unshift(pagination.currentPage - 1);
      setPagesBucket([...newPages])
    }
    // setPagination({ ...pagination, currentPage: index });
  }, [state.leadsList])

  useEffect(() => {
    validateName();
  }, [form.name]);

  useEffect(() => {
    validateMobile();
  }, [form.mobile]);

  useEffect(() => {
    validateEmail();
  }, [form.email]);

  useEffect(() => {
    validateRequiredInput('policy_number');
  }, [form.policy_number]);

  useEffect(() => {
    validateRequiredInput('premium');
  }, [form.premium]);

  useEffect(() => {
    getDealers({});
    getSources({});
  }, []);

  useEffect(() => {
   getList();
  }, [dateFilter,filter]);

  const getList = async (page, search, filterBucket = pager, shouldLoader=true) => {
    try {
      setLoader(shouldLoader);
      const query = { ...filter, page, limit: pageSize };
      if (search !== null && search !== undefined) {
        query.search = search;
      }

      /*******If Lead creation date filter added****** */
      if (dateFilter?.startDateFilter) {
        query.start_date = dateFilter.startDateFilter;
      }
      if (dateFilter?.endDateFilter) {
        query.end_date = dateFilter.endDateFilter;
      }

      pager = filterBucket;
      if (pager?.dealersIds?.length > 0) {
        query["dealer_id"] = [...pager.dealersIds].join(",");
      }

      if (pager?.sourceIds?.length > 0) {
        const sourceList = pager.sourceIds.map((i) => {
          return sources.find(src => src.id === i).name;
        })

        query["source"] = [...sourceList].join(",");
      }
      const res = await AxiosRestClient.getRequest(`v2/dog_insurance`, { ...query });
      if(res?.data?.data?.default_dealer_id){
        setDefaultDealer(res.data.data.default_dealer_id);
      }
      setPlatformUrl(res?.data?.data?.current_domain)
      setPagination({ ...pagination, pagesCount: res.data.data.last_page, currentPage: res.data.data.current_page })
      dispatchEvent(ReducerUtils.leads.list, rebuildAPIResponse(res.data.data.data));
      setLoader(false);
      setCloseLead(undefined);
    } catch (error) {
      console.log(error);
    }
  }

  async function getCompleteExportLink(){
    try{
      setExportLoader(true);
      const query = { ...filter };
      // if (search !== null && search !== undefined) {
      //   query.search = search;
      // }

      /*******If Lead creation date filter added****** */
      const current = moment(Date.now());
      if (dateFilter?.startDateFilter) {
        query.start_date = dateFilter.startDateFilter;
      }else{
        query.start_date = current.subtract(3, "months").format('DD-MMM-Y');
      }
      if (dateFilter?.endDateFilter) {
        query.end_date = dateFilter.endDateFilter;
      }else{
        query.end_date = current.add(3, "months").format('DD-MMM-Y');
      }

      if (pager?.dealersIds?.length > 0) {
        query["dealer_id"] = [...pager.dealersIds].join(",");
      }

      if (pager?.sourceIds?.length > 0) {
        const sourceList = pager.sourceIds.map((i) => {
          return sources.find(src => src.id === i).name;
        })

        query["source"] = [...sourceList].join(",");
      }
      const res = await AxiosRestClient.getRequest(`v2/dog_insurance`, { is_export:true,...query });
      setExportLoader(false);
      if(res?.data?.data){
        downloadFile(res.data.data)
      }
    }catch(error){
      setExportLoader(false);
      console.log(error)
    }
  }

  function downloadFile(url){
    const link = document.createElement('a');
    link.href = `${url}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const getDealers = async (params) => {
    try {
      setDealerLoader(true);
      const res = await AxiosRestClient.getRequest("dealers", { ...params, partner_slug: "pet-partner", limit: 100 });
      if (res.data?.data?.dealers) {
        res.data?.data?.dealers.map((item) => {
          return item.name = `${item.name}(${item.id})`
        })
        const filterObj = {};
        [...res.data?.data?.dealers, ...dealers].forEach(item => {
          if (!filterObj[item.id]) {
            filterObj[item.id] = item;
          }
        });
        setDealers([...Object.values(filterObj)]);
        setDealerLoader(false);
        return res.data?.data?.dealers;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const getSources = async (params) => {
    try {
      setSourceLoader(true);
      const res = await AxiosRestClient.getRequest("dealers", { ...params, partner_slug: "pet-partner", limit: 100 })
      if (res.data?.data?.dealers) {
        // res.data?.data?.dealers.map((item)=>{
        //   return item.name = `${item.name}(${item.id})`
        // })
        const filterObj = {};
        [...res.data?.data?.dealers, ...sources].forEach(item => {
          if (!filterObj[item.id || item.value]) {
            filterObj[item.id || item.value] = item;
          }
        });

        setSources([...Object.values(filterObj)]);
        setSourceLoader(false)
        return res.data?.data?.dealers;
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const setStateVariables = (key, isValid, message) => {
    setFormState('hasError', !isValid);
    setErrorMessageState(key, message);
    setTouchFieldsState(key, true);
  };

  const validateName = () => {
    if (form.name) {
      let isValid = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/.test(form.name);
      setStateVariables('name', isValid, isValid ? '' : Validators.name.valid);
    } else if (touchFields.name) {
      setErrorMessageState('name', Validators.name.required);
    }
  };

  const validateMobile = () => {
    if (form.mobile) {
      let isValid = isMobilePhone(form.mobile, ['en-IN']);
      setStateVariables('mobile', isValid, isValid ? '' : Validators.mobile.valid);
    } else if (touchFields.mobile) {
      setErrorMessageState('mobile', Validators.mobile.required);
    }
  };

  const validateEmail = () => {
    if (form.email) {
      let isValid = isEmail(form.email);
      setStateVariables('email', isValid, isValid ? '' : Validators.email.valid);
    } else if (touchFields.email) {
      setErrorMessageState('email', Validators.email.required);
    }
  };

  const validateRequiredInput = (fieldName) => {
    if (form[fieldName]) {
      setTouchFieldsState(fieldName, true);
      setErrorMessageState(fieldName, '');
    } else if (touchFields[fieldName] && !form[fieldName]) {
      setErrorMessageState(fieldName, Validators[fieldName].required);
    }
  };

  const setFormState = function (name, value) {
    setForm({ ...form, [name]: value });
  };

  const setErrorMessageState = (name, value) => {

    setErrorMessage({ ...errorMessage, [name]: value });
  };

  const setTouchFieldsState = (name, value) => {
    setTouchFields({ ...touchFields, [name]: value });
  };

  const validateInputLength = (event) => {
    let elName = event.target.name;
    let elValue = event.target.value;

    if (elValue.length > Utils.limit.digits[elName]) {
      event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
    }
  };

  const dispatchEvent = (type, payload) => {
    dispatch({ type, payload });
  };

  const handleClick = async (e, index) => {
    e.preventDefault();
    getList(index, filter.search, pager);
    setPagination({ ...pagination, currentPage: index });
  };

  let timeout = null;

  function handleSearch(event) {
    if (timeout) {
      clearTimeout(timeout);
    }
    const e = event.target.value;
    timeout = setTimeout(function () {
      setFilter({ ...filter, search: e });
      getList(1, e);
    }, 1000)
  };

  const handleColumnSort = (sortBy) => {
    const sortType = filter.sortType === 'desc' ? 'asc' : 'desc';

    setFilter({ ...filter, 'sortType': sortType, 'sortBy': sortBy });
    dispatchEvent(ReducerUtils.leads.sort, { sortBy: sortBy, sortType: sortType });
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date, keyname) => {
    setForm({ ...form, [keyname]: date });
  };

  const handleFilter = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
    // dispatchEvent(ReducerUtils.leads.filter, event.target.value);
    setPagination({ ...pagination });
  };

  const openModal = (keyName = 'isOpenLeadCapture', item = null) => {
    setIsFormSubmitted(false);
    item ? setForm({ ...initialLeadFormState, id: item.id }) : setForm(initialLeadFormState);
    setErrorMessage(errorMessageLead);
    setTouchFields(touchFieldsLead);
    setModal({ ...modal, [keyName]: true });
  };

  const cb = ({ data, message }) => {
    dispatchEvent(ReducerUtils.leads.create, {
      ...data,
      status_text: mapping.status[data.status],
      created_at_readable: moment(data.created_at.date).format('DD-MMM-Y'),
      payment_status_text: mapping.paymentStatus[data.payment_status]
    });

    setModal({ ...modal, isOpenLeadCapture: false });
    setIsFormSubmitted(false);
    notify(message);
  };

  const createLead = async () => {
    const payload = buildCreateLeadPayload(form);
    const response = await AxiosRestClient.postRequest(`v2/dog_insurance`, payload);

    cb(response.data);
  };

  const openModel = (toBeOpenModel) => {
    const models = {}
    setModel({ ...models, ...toBeOpenModel })
  }

  const openDealerIDFilter = () => {
    openModel({ dealerId: true })
  }

  const openSourceIDFilter = () => {
    openModel({ sourceId: true })
  }

  const filterByArray = (data, type) => {
    let ids = data.map((element) => element.value);
    // console.log(data)
    pager[type + "Ids"] = ids;
  }

  const closeDealerIDFilter = () => {
    openModel({ dealerId: false });
    if (pager?.dealersIds instanceof Array) {
      getList();
    }
  }

  const closeSourceIDFilter = () => {
    openModel({ ...model, sourceId: false });
    if (pager?.sourceIds instanceof Array) {
      getList();
    }
  }

  const fetchLeadList = async () => {
    const response = await AxiosRestClient.getRequest(`v2/dog_insurance`);
    const { data } = response.data.data;
    if(response?.data?.data?.default_dealer_id){
      setDefaultDealer(response.data.data.default_dealer_id)
    }
    dispatchEvent(ReducerUtils.leads.list, rebuildAPIResponse(data));
  };

  const cbUpdatePolicyStartData = ({ data, message, status }) => {
    // dispatchEvent(ReducerUtils.leads.issuePolicy, data);
    fetchLeadList();
    setModal({ ...modal, isOpenPolicyIssueDate: false });
    setIsFormSubmitted(false);
    notify(message);
    // window.location.reload();  
  };

  const cbError = ({ data: { error_msg }, data }) => {
    setIsFormSubmitted(false);
    console.log(`error message = ${error_msg}`);
    // setError(error_msg);
  };

  const updatePolicyStartData = async () => {
    try {
      const payload = buildUpdatePolicyStartDatePayload(form);
      const response = await AxiosRestClient.putRequest(`v2/dog_insurance/status/${form.id}`, payload);

      cbUpdatePolicyStartData(response.data);
    } catch (error) {
      cbError(error.response.data);
    }
  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  const viewEditLead = (item) => {
    // navigateTo(`/admin/pet/dog/${item.id}/customer`);
  };

  const verifyPhotos = (item) => {
    navigateTo(`/admin/pet/dog/${item.id}/documents`);
  };

  const openModalLeadCapture = () => {
    openModal('isOpenLeadCapture');
  };

  const openModalPolicyIssueDate = (item) => {
    openModal('isOpenPolicyIssueDate', item);
  };

  const onSubmitCreateLead = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    createLead();
  };

  const onSubmitIssuePolicy = (event) => {
    event.preventDefault();
    setIsFormSubmitted(true);
    updatePolicyStartData();
  };

  const viewRemarks = (item) => {
    setRemarks(item.remarks);
    setModal({ ...modal, 'isRemarks': true });
  };

  const createCoverNote = async (item) => {
    try {
      const response = await AxiosReactClient.postRequest(`v2/dog_insurance/covernote`, { id: item.id });
      const { data: { id }, data } = response.data;
      id ? setModal({ ...modal, 'isCoverNote': true }) : alert('There is some issue while creating covernote. Please try again.');
    } catch (e) {
      alert('There is some issue while creating covernote. Please try again.');
    }
  };

  const handleClose = () => {
    setModal({ ...modal, 'isCoverNote': false });
    // We need to reload the current page since create covernote process is a background job which might take sometime.
    window.location.reload();
  };

  const downloadPhotos = ({ photo }) => {
    if (!photo.length) {
      alert('Sorry it seems that there is no dog photos to download!');
      return false;
    }
    downloadZipFile(convertToArray(photo), 'dog-photos.zip')
  };

  const changeDispositionStatus = async ({ id, status }) => {
    try {
      await AxiosRestClient.putRequest(`v2/dog_insurance/change-disposition-status/${id}`, { status })
      // if(res?.data?.data){
      getList(pagination.currentPage, false)
      // }
    } catch (error) {
      getList(pagination.currentPage, false)
    }
  };

  const filterOptions = async (data) => {
    try {
      // setSelectedDealers([...data[0]])
      if (filter.dealerText === data[1]) {
        return data;
      }
      if (data[1] === "") {
        return data;
      }
      setSelectedDealers([...data[0]])
      if (timeout2) {
        clearTimeout(timeout2);
      }
      const e = data[1];
      setFilter({ dealerText: e })
      timeout2 = setTimeout(function () {
        getDealers({ dealer_search: e });
      }, 2000)
    } catch (error) {
      console.log(error);
    }
  }

  function navigateToPet(e, url) {
    window.open(`${url}`, '_blank').focus()
  }

  const filterSoucesOptions = async (data) => {
    try {
      // setSelectedDealers([...data[0]])
      if (filter.sourceText === data[1]) {
        return data;
      }
      if (data[1] === "") {
        return data;
      }
      setSelectedSources([...data[0]])
      if (timeout3) {
        clearTimeout(timeout3);
      }
      const e = data[1];
      setFilter({ sourceText: e })
      timeout3 = setTimeout(function () {
        getSources({ dealer_search: e });
      }, 2000)
    } catch (error) {
      console.log(error);
    }
  }

  function disableFutureDate(current) {
    const today = moment();
    return current.isBefore(today);
  }

  function isValidEndDate(current) {
    const endDate = moment(calender.startDate).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD');
    return current.isSameOrAfter(calender.startDate) && current.isSameOrBefore(endDate);
  }

  function handleReactDatetimeChange(type, date){
    if (calender.startDate && type === "endDate" && new Date(calender.startDate._d + "") > new Date(date._d + "")) {
      setCalender({
        ...calender,
        startDate: date,
        endDate: date,
      });
    }else if(calender.endDate && type === "startDate" && new Date(calender.endDate._d + "") < new Date(date._d + "")){
      setCalender({
        ...calender,
        openEndDateDialog: true,
        openStartDateDialog: false,
        startDate: date,
        endDate: date,
      });
    } else {
      const obj = {
        ...calender,
        [type]: date,
      }
      if(type==="startDate"){
        obj.openStartDateDialog = false;
        obj.openEndDateDialog=true;
      }else{
        obj.openStartDateDialog = false;
        obj.openEndDateDialog=false;
      }
      setCalender({
        ...obj
      });
    }
  }

  function getClassNameReactDatetimeDays(date){
    if (
      calender.startDate &&
      calender.endDate &&
      calender.startDate._d + "" !== calender.endDate._d + ""
    ) {
      if (
        new Date(calender.endDate._d + "") > new Date(date._d + "") &&
        new Date(calender.startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (calender.endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (calender.startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  }
  
  function onReactDateFocused(who){
    if (who === "startDate") {
      setCalender({
        ...calender,
        openEndDateDialog: false,
        openStartDateDialog: true,
      });
    } else {
      setCalender({
        ...calender,
        openEndDateDialog: true,
        openStartDateDialog: false,
      });
    }
  }

  function closeRangeModal() {
    setCalender({
      ...calender,
      showRangeModal: false,
    });
  }

  function editQuote(e,item) {
    const path = [];
    if(item?.id){
      path.push(item.id);
    }

    if(item?.order?.id){
      path.push(item.order.id);
    }

    if(item?.user_id){
      path.push(item.user_id)
    }

    if(path.length!==3){
      /**
       * If Any of orderId, refId and UserId is not their
       */
      navigateToPet({},`${platform_url}/calculate-premium?source=${item?.dealer_id || leads?.default_dealer_id}&std=true`);
      return false;
    }
    
    if(item?.order?.payment_successful){
      if(item?.status==="documents_pending" || item?.status==="Payment Captured"){
        /**
         * If Payment done but Document upload is pending
         * * */
        navigateToPet({},`${platform_url}/documents/${path.join("/")}?source=${item?.dealer_id || leads?.default_dealer_id}&ref=${item?.id}&online=true&std=true`);
      }else if(item?.status==="documents_uploaded" || item?.status==="Documents Verified"){
        /**
         * If Payment done and Document upload is done
         * * */
        navigateToPet({},`${platform_url}/success/${path.join("/")}?source=${item?.dealer_id || leads?.default_dealer_id}&ref=${item?.id}&online=true&std=true`);
      }
    }else{
      /***
       * If payment is not made
       */
      let url = item.dropoff_url;
      if(item.dropoff_url.search("/order-review")===0){
        url = url.replace("/order-review","/add-ons")
      }
      navigateToPet({},`${platform_url}${url}`);
      
    }
  }

  function createQuote(e, item) {
    e.preventDefault();
    if(item?.dropoff_url){
      if(item.dropoff_url.search("/calculate-premium")===0){
        navigateToPet({},`${platform_url}/calculate-premium?source=${item?.dealer_id || leads?.default_dealer_id}&ref=${item?.id}&std=true`)
      }else{
        navigateToPet({},`${platform_url}${item.dropoff_url}`)
      }
    }else{
      navigateToPet({},`${platform_url}/calculate-premium?source=${item?.dealer_id || leads?.default_dealer_id}&ref=${item?.id}&std=true`)
    }
  }

  async function closeLeads(id, status = "Closed - Not Interested"){
    try{
      setCloseLead(id);
      const res = await AxiosRestClient.putRequest(`v2/dog_insurance/update-lead-status/${id}`, { status: status.trim() });
      if(res?.data?.data){
        getList(pagination?.currentPage,filter?.search,pager,false)
      }
    }catch(error){
      setCloseLead(undefined);
      console.log(error);
    }
  }

  function sendDateRequest() {
    pager.pagerStart = 0;
    setDateFilter(
      {
        startDateFilter: moment(calender.startDate._d).format("YYYY-MM-DD"),
        endDateFilter: moment(calender.endDate._d).format("YYYY-MM-DD"),
      }
    );
    setCalender({
      ...calender,
      showRangeModal: false,
    });
  }

  function openLeadFilter(){
    setCalender({...calender, showRangeModal:true})
  }

  function navigateToDetail(id){
    props.history.push(`/admin/pet/dog/${id}`)
  }

  return (
    <Container fluid>
      <div className="header-body">
        <Row className="align-items-center py-4">
          <Col lg="8" xs="6">
            <Breadcrumb
              className="d-none d-md-inline-block "
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <a href="#" onClick={e => e.preventDefault()}>
                  <i className="fas fa-home" />
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href="#" onClick={e => {
                  e.preventDefault();
                }}>
                  Dog Insurance - Leads Listing
                </a>
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="text-right" lg="4" xs="6"></Col>
        </Row>
      </div>

      <div>
        <Row className="bg-white m-0 py-2">
          <Col lg={7} md={7} className="p-1 px-3 text-muted">
            <small className="form-inline md-form mr-3 mb-4 d-inline">
              <div className="form-group border justify-content-between rounded-5">
                <input type="text"
                  name="searchText"
                  className="form-control flex-grow-1 border-0"
                  placeholder="Customer Name, Mobile, Dog Name, Source"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  value={filter.searchText}
                  onChange={handleSearch}
                />
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text cursor-pointer border-0 mr-2" id="basic-addon1">
                      <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
            </small>
          </Col>

          <Col lg={3} md={3} className="text-center p-1">
            <select
              className="form-control"
              name="status"
              value={filter.status}
              onChange={handleFilter}>
              <option value={""}>All Policy Status</option>
              {options.policyStatus.map((item, index) => (
                <option value={item.value} key={index}>{item.title}</option>
              ))}
            </select>
          </Col>

          <Col lg={2} md={2} className="text-right mt-3">
            <Can I={permissions.DOG_INSURANCE.store} a={permissions.DOG_INSURANCE.subject}>
              {/* <Button className="shadow-none mr-3" size="sm"
                contained={"true"}
                color={"primary"}
                type="button" onClick={() => openModalLeadCapture('isOpenLeadCapture')}>
                Create Lead
              </Button> */}
              {defaultDealer?<Button className="shadow-none mr-3" size="sm"
                contained={"true"}
                color={"primary"}
                type="button"
                onClick={(e) => navigateToPet(e, `${platform_url}/calculate-premium?source=${defaultDealer}&std=true`)}
              >
                Create Quotation
              </Button>:<></>}
            </Can>

            {state.leadsList.length > 0 &&
              // <CSVLink
              //   data={state.leadsList}
              //   headers={csvHeaders}
              //   filename={"doginsurance-list.csv"}
              //   target="_blank"
              // >
                <Button 
                  color="primary"
                  outline 
                  size="sm"
                  onClick={getCompleteExportLink}
                  type="button"
                  disabled={exportLoader}>
                    {exportLoader?"Generating":"Export"}
                </Button>
              // </CSVLink>
              }
          </Col>
        </Row>
      </div>

      <Card className="m-0 mt-1">
        <CardBody className="px-0 py-0">
          <div className="table-responsive">
            <Table className="align-items-center table-flush" hover responsive>
              <thead className="thead-light">
                <tr>
                  <th className={(filter.sortBy === 'id' ? 'text-primary' : '')}
                    onClick={() => handleColumnSort('id')}>
                    Lead ID <i className="fas fa-sort ml-2" />
                  </th>
                  <th className={(filter.sortBy === 'name' ? 'text-primary' : '')}
                    onClick={() => handleColumnSort('name')}>
                    Customer Name <i className="fas fa-sort ml-2" />
                  </th>
                  <th>
                    <div className='cursor-pointer d-flex justify-content-between'>
                      <span className='mr-3'>
                        Dealer Id
                      </span>
                      <CustomMultiSelect
                        showMultiSelectOption={
                          model.dealerId
                        }
                        loader={dealerLoader}
                        closeMultiSelectOption={() =>
                          closeDealerIDFilter()
                        }
                        filterByArray={(ids) =>
                          filterByArray(ids, "dealers")
                        }
                        values={[...selectedDealers]}
                        options={[...dealers]}
                        filterSearch={filterOptions}
                        title="Dealer"
                      />
                      <i onClick={openDealerIDFilter} className="fas fa-filter align-self-center" />
                    </div>
                  </th>
                  <th className={(filter.sortBy === 'mobile' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('mobile')}>
                    Mobile <i className="fas fa-sort ml-2" />
                  </th>
                  <th className={(filter.sortBy === 'email' ? ' text-primary' : '')}
                    onClick={() => handleColumnSort('email')}>
                    Email <i className="fas fa-sort ml-2" />
                  </th>
                  <th>
                    <div className='cursor-pointer d-flex justify-content-between'>
                      Source
                      <CustomMultiSelect
                        loader={sourceLoader}
                        showMultiSelectOption={
                          model.sourceId
                        }
                        closeMultiSelectOption={() =>
                          closeSourceIDFilter()
                        }
                        filterByArray={(ids) =>
                          filterByArray(ids, "source")
                        }
                        filtertTest={"label"}
                        values={[...selectedSources]}
                        options={[...sources]}
                        filterSearch={filterSoucesOptions}
                        title="Source"
                      />
                      <i onClick={openSourceIDFilter} className="fas fa-filter align-self-center" />
                    </div>
                  </th>
                  <th className="cursor-pointer d-flex justify-content-between">
                    Lead Creation Date
                    <i onClick={openLeadFilter} className="fas fa-filter align-self-center" />
                  </th>
                  <th> Status </th>
                  {/* <th> Policy Details </th> */}
                  {/* <th> Payment Status </th> */}
                  {/* <th>Disposition</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loader &&
                  <tr>
                    <td colSpan="8">
                      <Spinner></Spinner>
                    </td>
                  </tr>}
                {(!loader && !state.leadsList.length) &&
                  <tr className="cursor-pointer">
                    <td colSpan="12" className="text-center">
                      <span className="text-muted">
                        <b>
                          No Lead Data found
                        </b>
                      </span>
                    </td>
                  </tr>}

                {(!loader && state.leadsList.length > 0) &&
                  state.leadsList
                    .map((item, index) => {
                      return <tr className="cursor-pointer" key={index + 'hgshdsh'}>
                        <td className="table-user">
                          <a
                            className="font-weight-bold"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push(
                                "/admin/pet/dog/" + item.id
                              );
                            }}
                          >
                            {item.id}
                          </a>
                        </td>
                        <td>{item.name}</td>
                        <td>{item?.dealer ? item.dealer?.id : ""}</td>
                        <td>{item.mobile}</td>
                        <td>{item.email}</td>
                        {/* <td>{item.is_vaccinated ? 'Yes' : 'No'}</td> */}
                        <td>{item.source_id}</td>
                        <td>{item.created_at_readable}</td>
                        <td>{item.status_text || item.status}</td>
                        {/* <td>
                      <div style={{width:"200px"}}>
                        <Dropdown
                          placeholder='Select Disposition'
                          fluid
                          selection
                          search
                          options={dispositions}
                          value={item.disposition_status}
                          onChange={(...rest)=>onDispositionChange(rest,item.id)}
                        />
                      </div>
                    </td> */}
                        {/* <td>
                      {item.status === 'policy_issued' ? <div>
                        <p style={{ 'fontSize': '0.8125rem' }}>Policy Number: {item.policy_number ?? 'NA'}</p>
                        <p style={{ 'fontSize': '0.8125rem' }}>Premium: Rs. {item.premium ?? '-'}</p>
                        <p style={{ 'fontSize': '0.8125rem' }}>Start Date: {item.start_date ?? '-'}</p>
                        <p style={{ 'fontSize': '0.8125rem' }}>End Date: {item.end_date ?? '-'}</p>
                        <p style={{ 'fontSize': '0.8125rem', 'color': 'blue', 'cursor': 'pointer' }}
                          onClick={() => viewRemarks(item)}>View Remarks</p>
                      </div> : <div>-</div>}
                    </td> */}
                        {/* <td>{item.payment_status_text}</td> */}
                          <td>
                            <div>
                              {item.status!=="Closed - Not Interested" && (item?.is_quotation_send === 1 || item?.order?.payment_successful?
                                <Button color="primary" disabled={item?.order?.payment_successful} outline size="sm" type="button" onClick={(e) => editQuote(e,item)}>Edit Quotation</Button>:
                                <Button color="primary " outline size="sm" type="button" onClick={(e) => createQuote(e,item)}>Create Quotation</Button>)
                              }
                              {item.status=="Closed - Not Interested" && <Button color="primary" disabled={true} outline size="sm" type="button">Edit Quotation</Button>}
                            {item.status !== "Closed - Not Interested" && (!isNaN(closeLead) && closeLead === item.id ? <Spinner animation="border" /> : <Button color="primary " outline size="sm" disabled={!isNaN(closeLead)} type="button" onClick={(e) => closeLeads(item.id)}>Close Lead</Button>)}
                            <Button color="primary " outline size="sm" disabled={!isNaN(closeLead)} type="button" onClick={(e) => closeLeads(item.id, "Policy Issued")}>Policy Issued</Button>
                              {/* {item.status === 'documents_uploaded' &&
                                <Button color="primary " outline size="sm" type="button" onClick={() => verifyPhotos(item)}>Verify Photos</Button>} */}
                            </div>

                            {/* <div className="mt-2">
                              {(item.status === 'documents_uploaded' || item.status === 'documents_verified' || item.status === 'policy_issued') &&
                                <Button color="primary " outline size="sm" type="button" onClick={() => downloadPhotos(item)}>Download Pictures</Button>}
                            </div> */}

                            {/* <div className="mt-2">
                              {((item.status === 'documents_verified' || item.status === 'policy_issued') && item.cover_note) &&
                                <Button color="primary " outline size="sm" type="button" onClick={() => redirectWithBlank(item.cover_note)}>View Covernote</Button>}

                              {(item.status === 'documents_verified' || item.status === 'policy_issued') &&
                                <Button color="primary " outline size="sm" type="button" onClick={() => createCoverNote(item)}>Create Covernote</Button>}
                            </div> */}
                          </td>
                        </tr>
                    })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        {(state.leadsList.length > 0) &&
          <CardFooter className="py-4">
            <Paginator
              pagination={pagination}
              pageBuket={pageBuket}
              loader={loader}
              handleClick={(...rest) => {
                handleClick(...rest)
              }}
            />
          </CardFooter>}

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal.isOpenLeadCapture}
          toggle={() => {
            setModal({ ...modal, isOpenLeadCapture: !modal.isOpenLeadCapture })
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <div className={"modal-header"}>
            <h4>Create New Lead</h4>
            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
              setModal({ ...modal, isOpenLeadCapture: false })
            }} />
          </div>
          <hr className={"text-dark m-0 p-0"} />

          <div className="modal-body text-left">
            <form
              onSubmit={onSubmitCreateLead}
            >
              <Row>
                <Col xl="12">
                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Gender</label>
                      <select
                        className="form-control"
                        name="gender"
                        value={form.gender}
                        onChange={handleChange}>
                        <option value={""}>Select</option>
                        {options.genderCustomer.map((item, index) => (
                          <option value={item.value} key={index}>{item.title}</option>
                        ))}
                      </select>
                      {errorMessage.gender && <small className="text-danger">{errorMessage.gender}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Full Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder=""
                        fullwidth="true"
                        value={form.name}
                        onChange={handleChange}
                        onInput={validateInputLength}
                      />
                      {errorMessage.name && <small className="text-danger">{errorMessage.name}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Mobile</label>
                      <input className="form-control"
                        type="number"
                        name="mobile"
                        placeholder=""
                        fullwidth="true"
                        value={form.mobile}
                        onChange={handleChange}
                        onInput={validateInputLength}
                      />
                      {errorMessage.mobile && <small className="text-danger">{errorMessage.mobile}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Email</label>
                      <input className="form-control"
                        type="text"
                        name="email"
                        placeholder=""
                        fullwidth="true"
                        value={form.email}
                        onChange={handleChange}
                      />
                      {errorMessage.email && <small className="text-danger">{errorMessage.email}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4 mt-4"}>
                    <div className="form-group w-100 px-2 text-right">
                      <button className="btn btn-primary" type="submit"
                        {...((isFormSubmitted || isLeadCaptureSubmitButtonDisabled(form, errorMessage)) && { disabled: true })}
                      >
                        {isFormSubmitted ? <Spinner /> : 'Submit'}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal.isOpenPolicyIssueDate}
          toggle={() => {
            setModal({ ...modal, isOpenPolicyIssueDate: !modal.isOpenPolicyIssueDate })
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <div className={"modal-header"}>
            <h4>Issue Policy</h4>
            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
              setModal({ ...modal, isOpenPolicyIssueDate: false })
            }} />
          </div>
          <hr className={"text-dark m-0 p-0"} />

          <div className="modal-body text-left">
            <form
              onSubmit={onSubmitIssuePolicy}
            >
              <Row>
                <Col xl="12">
                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Policy Start Date</label>
                      <ReactDatetime
                        closeOnSelect={true}
                        dateFormat="DD-MM-YYYY"
                        onChange={date => { handleDateChange(date, 'start_date') }}
                        timeFormat={false}
                        value={form.start_date}
                        isValidDate={(current) => isValidDate(current, 'start_date')}
                      />
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Policy Number</label>
                      <input className="form-control"
                        type="text"
                        name="policy_number"
                        placeholder=""
                        fullwidth="true"
                        value={form.policy_number}
                        onChange={handleChange}
                      />
                      {errorMessage.policy_number && <small className="text-danger">{errorMessage.policy_number}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Policy Premium</label>
                      <input className="form-control"
                        type="number"
                        name="premium"
                        placeholder=""
                        fullwidth="true"
                        value={form.premium}
                        onChange={handleChange}
                      />
                      {errorMessage.premium && <small className="text-danger">{errorMessage.premium}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Payment Status</label>
                      <select
                        className="form-control"
                        name="payment_status"
                        value={filter.payment_status}
                        onChange={handleChange}>
                        {options.paymentStatus.map((item, index) => (
                          <option value={item.value} key={index}>{item.title}</option>
                        ))}
                      </select>
                      {errorMessage.payment_status && <small className="text-danger">{errorMessage.payment_status}</small>}
                    </div>
                  </div>

                  <div className={"d-flex justify-content-around px-4"}>
                    <div className="form-group w-100 px-2">
                      <label htmlFor="example-text-input" className="form-control-label">Remarks</label>
                      <input className="form-control"
                        type="text"
                        name="remarks"
                        placeholder=""
                        fullwidth="true"
                        value={form.remarks}
                        onChange={handleChange}
                      />
                    </div>
                  </div>


                  {error && <p className="danger" >{{ error }}</p>}

                  <div className={"d-flex justify-content-around px-4 mt-4"}>
                    <div className="form-group w-100 px-2 text-right">
                      <button className="btn btn-primary" type="submit"
                        {...((isFormSubmitted || isIssuePolicySubmitButtonDisabled(form)) && { disabled: true })}
                      >
                        {isFormSubmitted ? <Spinner /> : 'Submit'}
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal.isCoverNote}
          toggle={() => {
            setModal({ ...modal, 'isCoverNote': !modal.isCoverNote })
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <div className={"modal-header"}>
            <h4>Cover Note</h4>
            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={handleClose} />
          </div>
          <hr className={"text-dark m-0 p-0"} />

          <div className="modal-body text-left">
            <Row>
              <Col xl="12">
                <div className={"d-flex justify-content-left px-4 mb-5"}>
                  <p>Cover note creation in progress. Please wait.</p>
                </div>

                <div className={"d-flex justify-content-around px-4 mt-5"}>
                  <div className="form-group w-100 px-2 text-right">
                    <button className="btn btn-primary" type="button"
                      onClick={handleClose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={modal.isRemarks}
          toggle={() => {
            setModal({ ...modal, 'isRemarks': !modal.isRemarks })
          }}
          backdrop={'static'}
          keyboard={false}
        >
          <div className={"modal-header"}>
            <h4>Remarks</h4>
            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
              setModal({ ...modal, 'isRemarks': false })
            }} />
          </div>
          <hr className={"text-dark m-0 p-0"} />

          <div className="modal-body text-left">
            <Row>
              <Col xl="12">
                <div className={"d-flex justify-content-left px-4"}>
                  <p>{remarks}</p>
                </div>

                <div className={"d-flex justify-content-around px-4 mt-4"}>
                  <div className="form-group w-100 px-2 text-right">
                    {/* <button className="btn btn-primary" type="button" 
                        onClick={handleDone}
                      >
                        Done
                      </button> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>

         <ModalWrapper shouldVisible={calender.showRangeModal} toggle={() => closeRangeModal()}>
            <>
              <div className={"modal-header"} style={{padding:"0rem 0rem 0.5rem 0rem"}}>
                <h4>Lead Creation Date</h4>
              </div>
              <div className="modal-body">
                <Form role="form">
                  <Row className="input-daterange datepicker align-items-center">
                    <Col xs={12} sm={6}>
                      <label className=" form-control-label">
                        Start date
                      </label>
                      <FormGroup>
                        <ReactDatetime
                          open={calender.openStartDateDialog}
                          isValidDate={disableFutureDate}
                          onFocus={(e) =>
                            onReactDateFocused("startDate")
                          }
                          inputProps={{
                            placeholder: "Start Date",
                            readOnly: true
                          }}
                          value={
                            calender.startDate === ""
                              ? calender.startDate
                              : moment(calender.startDate).format(
                                  "DD-MM-YYYY"
                                )
                          }
                          timeFormat={false}
                          onChange={(e) =>
                            handleReactDatetimeChange("startDate", e)
                          }
                          renderDay={(
                            props,
                            currentDate,
                          ) => {
                            let classes = props.className;
                            classes += getClassNameReactDatetimeDays(
                              currentDate
                            );
                            return (
                              <td {...props} className={classes}>
                                {currentDate.date()}
                              </td>
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup>
                        <label className=" form-control-label">
                          End date
                        </label>
                        <ReactDatetime
                          open={calender.openEndDateDialog}
                          isValidDate={isValidEndDate}
                          onFocus={(e) =>
                            onReactDateFocused("endDate")
                          }
                          inputProps={{
                            placeholder: "End Date",
                            readOnly: true
                          }}
                          value={
                            calender.endDate === ""
                              ? calender.endDate
                              : moment(calender.endDate).format(
                                  "DD-MM-YYYY"
                                )
                          }
                          timeFormat={false}
                        onChange={(e) =>
                          handleReactDatetimeChange("endDate", e)
                        }
                        renderDay={(
                          props,
                          currentDate,
                        ) => {
                          let classes = props.className;
                          classes += getClassNameReactDatetimeDays(
                            currentDate
                          );
                          return (
                            <td {...props} className={classes}>
                              {currentDate.date()}
                            </td>
                          );
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>

              <div className="modal-footer date-modal-footer" style={{padding:0}}>
                <Button
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => closeRangeModal()}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  className="ml-auto"
                  type="button"
                  onClick={() => sendDateRequest()}
                >
                  Apply
                </Button>
              </div>
            </>
          </ModalWrapper>

        <Notification />

      </Card>
    </Container>
  );
};

export default DogInsuranceList;