import * as actionTypes from './actionTypes/common';
import AxiosReactClient from '../../utilities/AxiosRestClient'
export const showToast = (notification) => {
    return {
        type: actionTypes.SHOW_TOAST,
        notification: notification
    };
};

export const setNotificationData = (notification) => {
    return dispatch => {
        dispatch(showToast(notification));
    };
};

// START

export const getProductFilterListStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_FILTER_LIST_START,
    };
};
export const getPartnerFilterListStart = () => {
    return {
        type: actionTypes.GET_PARTNER_FILTER_LIST_START,
    };
};


// SUCCESS

export const getProductFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_FILTER_LIST_SUCCESS,
        data: data
    };
};
export const getPartnerFilterListSuccess = (data) => {
    return {
        type: actionTypes.GET_PARTNER_FILTER_LIST_SUCCESS,
        data: data
    };
};

// FAIL


export const getProductFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCT_FILTER_LIST_FAIL,
        error: error
    };
};

export const getPartnerFilterListFail = (error) => {
    return {
        type: actionTypes.GET_PARTNER_FILTER_LIST_FAIL,
        error: error
    };
};


export const getProductFilterListData = () => {
    return dispatch => {
        dispatch(getProductFilterListStart());
        AxiosReactClient.getRequest('filter/product/list').then(response => {
                dispatch(getProductFilterListSuccess(response.data.data.product));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getProductFilterListFail(err.response.data.error));
                else
                    dispatch(getProductFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};

export const getPartnerFilterListData = () => {
    return dispatch => {
        dispatch(getPartnerFilterListStart());
        AxiosReactClient.getRequest('filter/partner/list').then(response => {
                dispatch(getPartnerFilterListSuccess(response.data.data.partner));
            })
            .catch(err => {
                console.log(err);
                if (err.response)
                    dispatch(getPartnerFilterListFail(err.response.data.error));
                else
                    dispatch(getPartnerFilterListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};