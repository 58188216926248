import * as actionTypes from './actionTypes/invoiceTypes';
import AxiosReactClient from '../../utilities/AxiosRestClient'


// START
export const getInvoiceListStart = () => {
    return {
        type: actionTypes.GET_INVOICE_LIST_START
    };
};

//SUCCESS
export const getInvoiceListSuccess = (data, total) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_SUCCESS,
        data: data,
        total: total
    };
};

//FAIL
export const getInvoiceListFail = (error) => {
    return {
        type: actionTypes.GET_INVOICE_LIST_FAIL,
        error: error
    };
};


//ACTIONS
export const getInvoiceListData = (params) => {
    return dispatch => {
        dispatch(getInvoiceListStart());
        AxiosReactClient.getRequest('v2/invoices', params).then(response => {
            dispatch(getInvoiceListSuccess(response.data.data.invoices, response.data.data.total_count));
        })
            .catch(err => {
                if (err.response)
                    dispatch(getInvoiceListFail(err.response.data.error));
                else
                    dispatch(getInvoiceListFail({
                        code: 502,
                        message: err.message
                    }));
            });
    };
};