import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions";
import Loader from "../../../components/CustomUi/Loader/Loader";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Shimmer from "../../../components/CustomUi/Shimmer/Shimmer";
import Spinner from "../../../components/Spinner/Spinner";
import Select2 from "react-select2-wrapper";
import ReactDatetime from "react-datetime";
import CustomDateSelect from "../../../components/Filters/CustomDateSelect";
import {
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  FormGroup,
  Form,
  Modal,
  Container,
  Row,
  Col,
} from "reactstrap";

import Can from "../../../config/Casl/Can";
import * as permissions from "../../../config/Casl/permissions";
import ability from "../../../config/Casl/ability";
import {
  reBuildAgentList,
  renderAgentCurrentStatus,
} from "../../../helpers/utils";
import moment from "moment";
import DocumentModal from "./DocumentModal";
import KYCDetailsModal from "./KYCDetailsModal";
import AxiosRestClient from "../../../utilities/AxiosRestClient";
import { Select } from "semantic-ui-react";

const orderIdSearch = /^[ a-zA-Z&0-9_-]*$/;

class AgentsListing extends Component {
  previousMonthDate =
    new Date().getMonth() === 0
      ? new Date(new Date().getFullYear() - 1, 11)
      : new Date(new Date().getFullYear(), new Date().getMonth() - 1);

  constructor(props) {
    super(props);
    this.state = {
      hideFilters: false,
      dealerFilter: "",
      cardFilter: "all",
      startDateFilter: "",
      endDateFilter: "",
      searchText: "",
      view: "dealer-table",
      openMonthDialog: false,
      selectedKam: "",
      selectedDealer: null,
      showLoader: false,
      selectedCardsFilterDate: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      isConfirmModalOpen: false,
      isDocumentModalOpen: false,
      isCompleteKycOpen: false,
      agentId: null,
      isActive: false,
      buttonActionName: "active_inactive_request",
      statusList: [
        { text: "All Status", id: "all" },
        { text: "Active", id: "active" },
        { text: "Inactive", id: "inactive" },
        { text: "Registered", id: "registered" },
        { text: "KYC Done", id: "kyc_done" },
      ],
      selectedStatus: "all",
      selectedDate:
        new Date().getMonth() === 0
          ? new Date(new Date().getFullYear() - 1, 11)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
    };
    props.onGetDealerDealerCardsData({});
    this.getDealers();
    props.onGetKamListData();
  }

  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
    dealerId: "",
    sortBy: 0,
  };
  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1);
    }
  };
  switchPage = (page) => {
    if (this.pager.pagerStart + 1 !== page) {
      this.pager.pagerStart = page - 1;
      this.getDealers();
    }
  };
  getDealers = () => {
    this.props.onGetDealerDealerListData(
      {},
      {
        start: this.state.searchText != "" ? 0 : this.pager.pagerStart,
        sortBy: this.pager.sortBy,
        dealer_search: this.state.searchText,
        startDate: this.state.startDateFilter
          ? new Date(this.state.startDateFilter).toDateString()
          : "",
        endDate: this.state.endDateFilter
          ? new Date(this.state.endDateFilter).toDateString()
          : "",
        date: this.state.endDateFilter ? "custom" : "",
        is_agent: 1,
      }
    );
  };
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if (page - start > 2 && start === 1) {
        return true;
      } else if (start !== 1 && page - start > 1) {
        return true;
      }
    } else {
      if (start - page > 2 && start === this.pager.pagerEnd) {
        return true;
      } else if (start !== this.pager.pagerEnd && start - page > 1) {
        return true;
      }
    }
    return false;
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  resetFilters = () => {
    this.pager.pagerStart = 0;
    this.pager.sortBy = 0;
    this.setState(
      {
        dealerFilter: "",
        searchText: "",
        startDateFilter: "",
        endDateFilter: "",
      },
      () => {
        this.getDealers();
      }
    );
  };
  onSearch = (value) => {
    if (!this.state.searchText || this.state.searchText.length < 2) {
      this.showMsg(" Please, enter atlest 2 characters", "danger");
      return;
    }
    if (!this.state.searchText.match(orderIdSearch)) {
      this.showMsg(" Please, don't enter any special characters", "danger");
      return;
    }
    this.getDealers();
    this.setState({
      view: "table",
    });
  };
  onRefreshAllCards = (filter) => {
    this.setState({
      cardFilter: filter,
      selectedCardsFilterDate: "",
    });
    this.props.onGetDealerDealerCardsData({});
  };
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    } else {
      e.composedPath().forEach((element) => {
        classArray = classArray.concat(
          element.className && element.className.split(" ")
        );
      });
    }
    if (!classArray.includes("reactDateTimeCustomClass")) {
      this.setState({
        openMonthDialog: false,
      });
    }
  };

  handleReactMonthFilterChange(e) {
    this.setState({
      cardFilter: "custom",
      selectedCardsFilterDate: e._d,
    });
    this.props.onGetDealerDealerCardsData({
      month: e._d.getMonth() + 1,
      year: e._d.getFullYear(),
    });
  }

  getMonthYear = (date) => {
    return (
      this.state.months[new Date(date).getMonth()] +
      " " +
      new Date(date).getFullYear()
    );
  };
  validDateMonthFilter = (current) => {
    var thisMonth = new Date();
    return current.isBefore(thisMonth);
  };
  sortByColumn = (column_no) => {
    this.pager.sortBy = column_no;
    this.getDealers();
  };
  openKamModel = (dealer_id, kam_id) => {
    this.setState({
      showKamModel: true,
      selectedDealer: dealer_id,
      selectedKam: kam_id,
    });
  };
  onUpdateKam = (id, kam_id) => {
    this.sendUpdatedKam(id, { id: kam_id });
  };
  sendUpdatedKam = (id, payload) => {
    this.setState({
      showLoader: true,
    });
    AxiosReactClient.putRequest(`dealers/` + id + `/kam`, payload)
      .then((response) => {
        this.setState({
          showLoader: false,
          showKamModel: false,
        });
        this.showMsg("Kam updated successfully", "success");
        this.getDealers();
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
        if (err.response) {
          this.showMsg(err.response.data.message, "danger");
        } else if (err.message) {
          this.showMsg(err.message, "danger");
        }
      });
  };
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };
  dateChangeFilter = (date, type) => {
    switch (type) {
      case "startDate":
        this.setState(
          {
            startDateFilter: date,
          },
          () => {
            this.getDealers();
          }
        );

        break;
      case "endDate":
        this.setState(
          {
            endDateFilter: date,
          },
          () => {
            this.getDealers();
          }
        );
        break;
      default:
        break;
    }
  };
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type,
    });
  };
  navigateTo = (id, urlSuffix) => {
    this.props.history.push(`/admin/agents/${id}/${urlSuffix}`);
  };
  handleButtonClick = ({
    id,
    is_active,
    is_active_for_sell,
    general_exam_result,
    profile_created_at,
    next_action_item_slug,
  }) => {
    if (!general_exam_result && profile_created_at) {
      this.navigateTo(id, "exam");
    } else if (next_action_item_slug === "complete_kyc") {
      this.setState({
        agentId: id,
        buttonActionName: "complete_kyc",
        isCompleteKycOpen: true,
      });
    } else {
      this.setState({
        agentId: id,
        buttonActionName: is_active_for_sell
          ? "active_inactive_request"
          : "approve_request",
        isActive: is_active,
        isConfirmModalOpen: true,
      });
    }
  };
  handleViewResultClick = ({ id }) => {
    this.navigateTo(id, "exam-result");
  };
  handleUploadDocumentClick = ({ id }) => {
    this.setState({
      isDocumentModalOpen: true,
      agentId: id,
    });
  };
  toggleModal = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
  cbSuccess = (modalKey = "isConfirmModalOpen") => {
    this.getDealers();
    this.toggleModal(modalKey, false);
  };
  submitActiveInactiveRequest = async (payload) => {
    await AxiosRestClient.putRequest(
      `dealers/${this.state.agentId}/status`,
      payload
    );

    this.cbSuccess();
  };
  submitApprovalRequest = async () => {
    await AxiosRestClient.putRequest(`agents/${this.state.agentId}`, {
      is_active_for_sell: true,
    });

    this.cbSuccess();
  };
  handleConfirmClick = (action = "") => {
    action === "yes"
      ? this.state.buttonActionName === "approve_request"
        ? this.submitApprovalRequest()
        : this.submitActiveInactiveRequest({ is_active: !this.state.isActive })
      : this.toggleModal("isConfirmModalOpen", false);
  };
  handleExportClick = async () => {
    const payload = {};
    if (
      (this.state.startDateFilter && this.state.startDateFilter) ||
      (!this.state.startDateFilter && !this.state.startDateFilter)
    ) {
      payload.start_date = this.state.startDateFilter
        ? moment(this.state.startDateFilter).format("YYYY-MM-DD")
        : "";
      payload.end_date = this.state.endDateFilter
        ? moment(this.state.endDateFilter).format("YYYY-MM-DD")
        : "";
    }

    const data = await AxiosRestClient.postRequest(`agents/download`, payload);
    const { message } = data.data.data;
    console.log(`message = ${message}`);
    this.showMsg(
      "Agents list has been sent to your registered email id",
      "success"
    );
  };
  render() {
    this.dealerList = this.props.dealerDealerListData
      ? this.props.dealerDealerListData
      : [];
    this.dealerCards = this.props.dealerDealerCardsData
      ? this.props.dealerDealerCardsData
      : "";
    this.props.dealerTotalCount !== null &&
      this.updatePagination(this.props.dealerTotalCount);

    return (
      <>
        <Fragment>
          <Container fluid>
            <CardHeader className="border-0 px-0 bg-transparent ">
              <Row>
                <Col xs="6">
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-0"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary h5"
                        onClick={(e) => e.preventDefault()}
                      >
                        Agents
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a
                        href="#pablo"
                        className="text-primary h5"
                        onClick={(e) => e.preventDefault()}
                      >
                        All
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="text-right" xs="6"></Col>
              </Row>
            </CardHeader>
            {/* <Row className="px-3">
              <Col className="pr-0 pb-1">
                <div className="float-right">
                  <Button
                    className="btn-icon btn-2"
                    size="sm"
                    outline
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      this.setState({
                        hideFilters: !this.state.hideFilters,
                      });
                    }}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-filter"></i>
                    </span>
                  </Button>
                </div>
              </Col>
            </Row> */}
            <div>
              <Row
                className="bg-white m-0 py-2"
                hidden={this.state.hideFilters}
              >
                <Col className="p-1 px-3 text-muted">
                  <small>
                    <form
                      xs="6"
                      className="form-inline md-form mr-3 mb-4 d-inline"
                      action="javascript:void(-1)"
                    >
                      <input
                        className="ml-2 bg-white border-0 text-muted pr-2 searchWidth"
                        value={this.state.searchText}
                        onKeyDown={(e) => {
                          e.key === "Enter" && this.onSearch(e.target.value);
                        }}
                        onChange={(e) => {
                          if (e.target.value == " ") {
                            this.setState({
                              view: "dealer-table",
                            });
                          }
                          this.setState({
                            searchText: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="Search by ref id or name"
                        aria-label="Search"
                      />
                      <i
                        className="fas fa-search cursor-pointer mt-2"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onSearch(this.state.searchText);
                        }}
                      />
                    </form>
                  </small>
                  {this.state.searchText !== "" &&
                    this.state.view === "table" && (
                      <Badge color="primary" pill>
                        {this.state.searchText}
                        <span
                          className="p-1 ml-2 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            this.resetFilters();
                            this.setState({
                              view: "dealer-table",
                              searchText: "",
                            });
                          }}
                        >
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Badge>
                    )}
                </Col>
                {1 === 2 && (
                  <Col xs="2" className="p-1 px-3">
                    <FormGroup>
                      <Select2
                        className="form-control"
                        defaultValue=""
                        options={{
                          placeholder: "All Status",
                        }}
                        value={this.state.selectedStatus}
                        data={this.state.statusList}
                        onChange={(e) => {
                          this.setState({
                            selectedStatus: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col className="p-1 text-right px-3">
                  {/* Start Filter Duration */}
                  <CustomDateSelect
                    selectedDate={this.state.startDateFilter}
                    dateChanged={(date) => {
                      this.dateChangeFilter(date, "startDate");
                    }}
                    name="From Date"
                  ></CustomDateSelect>
                  <CustomDateSelect
                    selectedDate={this.state.endDateFilter}
                    dateChanged={(date) => {
                      this.dateChangeFilter(date, "endDate");
                    }}
                    name="To Date"
                  ></CustomDateSelect>
                  <Button
                    color="secondary "
                    outline
                    size="sm"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.resetFilters();
                    }}
                  >
                    Clear
                  </Button>
                  {/* End Filter Duration */}

                  {/* Start Export Button */}
                  <Button
                    color="primary"
                    variant="contained"
                    size="md"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleExportClick();
                    }}
                  >
                    Export
                  </Button>
                  {/* End Export Button */}
                </Col>
              </Row>
            </div>
            <Card className="m-0 mt-1">
              <CardBody className="px-0 py-0">
                <div className=" table-responsive">
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Ref ID</th>
                        <th>POSP Code</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>PAN</th>
                        <th>Aadhaar</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody style={{ height: "466px" }}>
                      {this.props.dealerDealerListLoading && (
                        <tr>
                          <td colSpan="12">
                            <Spinner></Spinner>
                          </td>
                        </tr>
                      )}
                      {!this.props.dealerDealerListLoading &&
                        this.props.dealerDealerListData &&
                        this.props.dealerDealerListData.length < 1 && (
                          <tr
                            key={"keyxasx1_duwe823"}
                            className="cursor-pointer"
                          >
                            <td colSpan="12" className="text-center">
                              <span className="text-muted">
                                <b>No Agent Data found</b>
                              </span>
                            </td>
                          </tr>
                        )}
                      {!this.props.dealerDealerListLoading &&
                        reBuildAgentList(
                          this.dealerList,
                          this.state.selectedStatus
                        ).map((dealer, index) => (
                          <tr key={"hjsdfae_sdf" + index}>
                            {ability.can(
                              permissions.DEALER.show,
                              permissions.DEALER.subject
                            ) ? (
                              <td
                                className="table-user cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(
                                    "/admin/agents/" + dealer.id
                                  );
                                }}
                                title={
                                  "Click here for details about " + dealer.name
                                }
                              >
                                <span className="text-primary">
                                  <b>{dealer.id}</b>
                                </span>
                              </td>
                            ) : (
                              <td>{dealer.id}</td>
                            )}
                            <td>{dealer.posp_id ?? "-"}</td>
                            <td>{dealer.name}</td>
                            <td>{dealer.mobile}</td>
                            <td>{dealer.pan ?? "-"}</td>
                            <td>
                              {dealer.aadhaar
                                ? dealer.aadhaar.replace(/^.{8}/g, "XXXXXXXX")
                                : "-"}
                            </td>
                            <td>
                              {moment(dealer.onboarded_on).format("DD-MM-YYYY")}
                            </td>
                            <td className="agent-status">
                              <span
                                className={`badge badge-pill ${dealer.class_name}`}
                                style={{ fontSize: "0.75rem" }}
                              >
                                {dealer.status_text}
                              </span>
                            </td>
                            <td className="text-right" style={{ width: "10%" }}>
                              {dealer?.documents?.length < 6 && (
                                <Button
                                  color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                  size="md"
                                  type="button"
                                  onClick={() =>
                                    this.handleUploadDocumentClick(dealer)
                                  }
                                >
                                  <span className="btn-inner--icon mr-2">
                                    <i className="fas fa-plus-circle" />
                                  </span>
                                  {"Document"}
                                </Button>
                              )}
                              {(dealer.next_action_item_slug === "take_exam" ||
                                dealer.next_action_item_slug ===
                                  "make_inactive" ||
                                dealer.next_action_item_slug ===
                                  "make_active" ||
                                dealer.next_action_item_slug === "approve" ||
                                1 === 1) && (
                                <Button
                                  color={`btn-round btn-icon btn btn-primary btn-md btn-block1`}
                                  size="md"
                                  type="button"
                                  onClick={() => this.handleButtonClick(dealer)}
                                >
                                  <span className="btn-inner--text">
                                    {dealer.next_action_item}
                                  </span>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem
                      className={this.pager.pagerStart > 0 ? "" : "disabled"}
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.switchPage(this.pager.pagerStart);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {this.pager.pageArray.map((page) => {
                      return (
                        <PaginationItem
                          key={"pager_" + page}
                          hidden={this.isHidden(page)}
                          className={
                            this.pager.pagerStart + 1 === page ? "active" : ""
                          }
                        >
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              this.switchPage(page);
                            }}
                          >
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}
                    <PaginationItem
                      className={
                        this.pager.pagerEnd > this.pager.pagerStart + 1
                          ? ""
                          : "disabled"
                      }
                    >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          this.switchPage(this.pager.pagerStart + 2);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Container>
        </Fragment>

        <Modal
          className="modal-dialog-centered"
          size="md"
          isOpen={this.state.isConfirmModalOpen}
          toggle={() => {
            this.setState({
              isConfirmModalOpen: false,
            });
          }}
          backdrop="static"
        >
          <div className="modal-header">Confirm Dialog</div>
          <div className="modal-body">
            <Row>
              <Col xs={12}>Do you really want to perform this action?</Col>
            </Row>
          </div>
          <div className="modal-footer date-modal-footer ">
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmClick("yes")}
            >
              Yes
            </Button>
            <Button
              color="primary"
              // className="ml-auto"
              type="button"
              size="sm"
              onClick={() => this.handleConfirmClick("no")}
            >
              No
            </Button>
          </div>
        </Modal>

        {/* Start: POSP Agent Document Section */}
        {this.state.isDocumentModalOpen && (
          <DocumentModal
            isModalOpen={this.state.isDocumentModalOpen}
            agentId={this.state.agentId}
            toggleModal={this.toggleModal}
          />
        )}
        {/* End: POSP Agent Document Section */}

        {/* Start: POSP Agent KYC Details Section */}
        {this.state.isCompleteKycOpen && (
          <KYCDetailsModal
            isModalOpen={this.state.isCompleteKycOpen}
            agentId={this.state.agentId}
            toggleModal={this.toggleModal}
            cbSuccess={this.cbSuccess}
          />
        )}
        {/* End: POSP Agent KYC Details Section */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dealerDealerListLoading: state.dealer.dealerDealerListLoading,
    dealerDealerCardsLoading: state.dealer.dealerDealerCardsLoading,
    kamListLoading: state.dealer.kamListLoading,

    dealerDealerListData: state.dealer.dealerDealerListData,
    dealerTotalCount: state.dealer.totalDealerListCount,
    dealerDealerCardsData: state.dealer.dealerDealerCardsData,
    kamListData: state.dealer.kamListData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetDealerDealerListData: (filter, params) =>
      dispatch(actions.getDealerDealerListData(filter, params)),
    onGetDealerDealerCardsData: (data) =>
      dispatch(actions.getDealerDealerCardsData(data)),
    onGetKamListData: (id, data) => dispatch(actions.getKamListData(id, data)),
    onShowNotification: (notification) =>
      dispatch(actions.setNotificationData(notification)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentsListing);
