import React from 'react';
import { Button } from "reactstrap";
import { renderFormattedDate } from '../../../helpers/oemMapping';
import Can from '../../../config/Casl/Can';
import * as permissions from '../../../config/Casl/permissions';

const OemMappingListItem = (props) => {
    const {
        handleDelete,
        index,
        item,
        selectedObject
    } = props;

    return (
        <tr key={item.id}>
            <td>{item.vehicle_make}</td>
            <td>{item.product_name}</td>
            <td>{item.vehicle_type}</td>
            <td>{renderFormattedDate(item)}</td>
            <td>
                <Can I={permissions.PARTNERS.oem_delete} a={permissions.PARTNERS.subject}>
                    <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDelete(item, index)}
                        {...(selectedObject?.id === item.id && { disabled: true })}
                    >
                        Delete
                    </Button>
                </Can>
            </td>
        </tr>
    );
}

export default OemMappingListItem;