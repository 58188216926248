import React from 'react';
import { Loader } from 'semantic-ui-react'

function InitialLoader({children, loading, showLoader=true}) {
    return !loading?(
        <>
            {children}
        </>
    ):showLoader?<Loader className="mt-5" active inline='centered'/>:<></>;
}

export default InitialLoader;