import React, {useState} from 'react';
import {Box} from "@material-ui/core";
import {Button} from 'reactstrap'
import moment from 'moment';
import ReactDatetime from "react-datetime";

export const ExportTransactionSection = ({setExportDateRangeError, onExport, onClearFilter, onFilter}) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [isExportDisable, setIsExportDisable] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onDateClick = (value) => {
        let diff = moment(value[1]).diff(value[0]);
        let diffInDays = Math.ceil(diff / (1000*60*60*24));
        if(!isNaN(diffInDays)){
            if(diffInDays>365){
                setExportDateRangeError('Date ranges exceeding one year are invalid and cannot be searched for');
                setIsExportDisable(true);
            } else{
                setExportDateRangeError('');
                setIsExportDisable(false);
            }
        } else{
            if(!value[0]){
                setExportDateRangeError("Please select From date");
                setIsExportDisable(true);
            } else if(!value[1]){
                setExportDateRangeError("Please select To date");
                setIsExportDisable(true);
            } 
        }
        setDateRange(value);
    }
    const onExportClick = () => {
        if(dateRange[0] === null && dateRange[1] === null){
            let currentDate = moment().format('YYYY-MM-DD');
            let prevMonth = moment().subtract(1,'months').format('YYYY-MM-DD');
            let payload = {start_date: prevMonth, end_date: currentDate};
            onExport(payload);
            setStartDate(moment().subtract(1,'months'))
            setEndDate(moment())
            setDateRange([moment().subtract(1,'months'),moment()])
        } else{
            let payload = {start_date: moment(dateRange[0]).format('YYYY-MM-DD'), end_date: moment(dateRange[1]).format('YYYY-MM-DD')};
            onExport(payload);
        }
        onFilterClick()
    }
    const onFilterClick = () => {
        if(dateRange[0] === null && dateRange[1] === null){
            let currentDate = moment().format('YYYY-MM-DD');
            let prevMonth = moment().subtract(1,'months').format('YYYY-MM-DD');
            let payload = {start_date: prevMonth,end_date: currentDate,transaction_limit: 25,
                action: 'filter'};
            onFilter(payload);
            setStartDate(moment().subtract(1,'months'))
            setEndDate(moment())
            setDateRange([moment().subtract(1,'months'),moment()])
        } else{
            let payload = {start_date: moment(dateRange[0]).format('YYYY-MM-DD'), end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
                transaction_limit: 25,action: 'filter'};
            onFilter(payload);
        }
    }

    const onClearClick = () => {
        setStartDate(null)
        setEndDate(null)
        setDateRange([null, null])
        onClearFilter()
    }

    const onDateChange = (value, index) => {
        dateRange[index] = value
        if(index === 0){
            setStartDate(value)
        } else if(index === 1){
            setEndDate(value)
        }
        onDateClick(dateRange)
    }

    const isValidEndDate = (current) => {
        const today = moment();
        if(startDate){
            return current.isSameOrAfter(startDate) && current.isSameOrBefore(today);
        }
        else{
            return current.isSameOrBefore(today);
        }
    }
    
    const isValidStartDate = (current) => {
        const today = moment();
        if(endDate){
            return current.isBefore(today) && current.isSameOrBefore(endDate);
        }
        else{
            return current.isSameOrBefore(today);
        }
    }

    return (
        <div className='exportTransactionSection' >
            <ReactDatetime
            isValidDate={isValidStartDate}
            value={startDate}
            timeFormat={false}
            dateFormat='DD-MM-YYYY'
            className='exportDate'
            onChange={(val) => onDateChange(val,0)            
            }
            />
            <span className='exportDateSpan' >to</span>            
            <ReactDatetime
            isValidDate={isValidEndDate}
            value={endDate}
            dateFormat='DD-MM-YYYY'
            className='exportDate'
            timeFormat={false}
            onChange={(val) => onDateChange(val,1)
            }
            />

            <Button
            color="primary"
            className='btn-round btn btn-sm filterButton'
            onClick={onFilterClick}
            disabled={isExportDisable}
            type="button">
                <span className="btn-inner--icon mr-1">
                    <i className="fa fa-filter"/>
                </span>
                <span className="btn-inner--text">
                    Filter
                </span>
            </Button>

            <Button
            color="success"
            className='btn-round btn btn-sm exportButton'
            onClick={onExportClick}
            disabled={isExportDisable}
            type="button">
                <span className="btn-inner--icon mr-1">
                    <i className="fas fa-file-export"/>
                </span>
                <span className="btn-inner--text">
                    Export
                </span>
            </Button>
                        
            <Button
            className='btn-round btn btn-sm clearButton'
            onClick={onClearClick}
            type="button">
                <span className="btn-inner--icon mr-1">
                    <i className="fa  fa-times"/>
                </span>
                <span className="btn-inner--text">
                    Clear
                </span>
            </Button>

        </div>
    )
}