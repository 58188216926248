import React, { Fragment, useContext, useEffect, useReducer, useState  } from "react";
import { Container, Row, Col, BreadcrumbItem, Breadcrumb } from "reactstrap";
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

// Import all custom default exports
import useApi from '../../../hooks/useApi';
import LeadsReducer from '../../../reducers/leadsReducer';
import LeadContext from '../../../context/leadContext';
import Loader from '../../../components/CustomUi/Loader/Loader';
import AxiosRestClient from '../../../utilities/AxiosRestClient';

// Import all custom named exports
import { Utils } from '../../../constants/utils';
import { Validators } from '../../../messages/validators';
import { errorMessageDogDetails, initialDogDetailsFormState, touchFieldsDogDetails } from '../../../defaultValues/lead';
import { isDogDetailsNextButtonDisabled, buildDogDetailsPayload, isValidDate } from '../../../helpers/lead';
import { ReducerUtils } from '../../../constants/reducers';
import DogDetailsForm from './DogDetailsForm';

const DogDetails = (props) => {
  // Call useApi custom hook to fetch lead details
  const leadDetails = useApi(`v2/dog_insurance/${props.match.params.id}`);
  
  const initialLeadReducerState = useContext(LeadContext);
  const [state, dispatch] = useReducer(LeadsReducer, initialLeadReducerState);
  const [form, setForm] = useState(initialDogDetailsFormState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorMessageDogDetails);
  const [touchFields, setTouchFields] = useState(touchFieldsDogDetails);

  useEffect(() => {
    dispatchEvent(ReducerUtils.leads.details, leadDetails);
  }, [leadDetails]);

  useEffect(() => {
    validateRequiredInput('dog_name');
  }, [form.dog_name]);

  useEffect(() => {
    validateRequiredInput('dog_gender');
  }, [form.dog_gender]);

  useEffect(() => {
    validateRequiredInput('dog_breed');

    if (form.dog_breed !== 'others') {
      setTouchFieldsState('other_breed', false);
      setErrorMessageState('other_breed', '');
    }
  }, [form.dog_breed]);

  useEffect(() => {
    validateRequiredInput('other_breed');
  }, [form.other_breed]);

  useEffect(() => {
    validateRequiredInput('dog_age_year');
    if (form.dog_age_year == 0 && form.dog_age_month == 0) {
      setForm({ ...form, 'dog_age_month': '' })
    }
  }, [form.dog_age_year]);

  useEffect(() => {
    validateRequiredInput('dog_age_month');
  }, [form.dog_age_month]);

  useEffect(() => {
    validateRequiredInput('dog_mark');
  }, [form.dog_mark]);

  useEffect(() => {
    validateRequiredInput('spayed_castrated');
  }, [form.spayed_castrated]);

  useEffect(() => {
    validateRequiredInput('defects_ailments');
  }, [form.defects_ailments]);

  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const setStateVariables = (key, isValid, message) => {
    setFormState('hasError', !isValid);
    setErrorMessageState(key, message);
    setTouchFieldsState(key, true);
  };

  const validateRequiredInput = (fieldName) => {
    if (form[fieldName]) {
      setTouchFieldsState(fieldName, true);
      setErrorMessageState(fieldName, '');
    } else if (touchFields[fieldName] && !form[fieldName]) {
      setErrorMessageState(fieldName, Validators[fieldName].required);
    }
  };

  const setFormState = function (name, value) {
    setForm({ ...form, [name]: value });
  };

  const setErrorMessageState = (name, value) => {
    setErrorMessage({ ...errorMessage, [name]: value });
  };

  const setTouchFieldsState = (name, value) => {
    setTouchFields({ ...touchFields, [name]: value });
  };

  const validateInputLength = (event) => {
    let elName = event.target.name;
    let elValue = event.target.value;

    if (elValue.length > Utils.limit.digits[elName]) {
        event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
    }
  };
  
  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date, keyname) => {
    setFormState(keyname, date);
  };

  const showMessage = (message) => {

  };

  const navigateTo = (pathname) => {
    props.history.push(pathname)
  };

  const cb = ({ data, message }) => {
    setIsFormSubmitted(false);
    showMessage(message);
    navigateTo(`/admin/pet/dog/${props.match.params.id}/documents`);
  };

  const updateDetails = async (e,sources=[]) => {
    setIsFormSubmitted(true);
    form.source_id = sources.find(i=>i.id==form.source_id)?.text;
    const payload = buildDogDetailsPayload(form);
    const response = await AxiosRestClient.putRequest(`v2/dog_insurance/${props.match.params.id}`, payload);
  
    cb(response.data);
  };
	
	return (
		<Fragment>
			<Container fluid>
				<div className="header-body">
					<Row className="align-items-center py-4">
						<Col lg="8" xs="6">
							<Breadcrumb
								className="d-none d-md-inline-block "
								listClassName="breadcrumb-links"
								>
								<BreadcrumbItem>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
									<i className="fas fa-home"/>
									</a>
								</BreadcrumbItem>
								<BreadcrumbItem>
									<a
										href="#pablo"
										onClick={(e) => {
									e.preventDefault();
									}}
									>
									Dog Insurance - Dog Details
									</a>
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
						<Col className="text-right" lg="4" xs="6">
							
						</Col>
					</Row>
				</div>
        
        {!state.leadDetails ? 
        <Loader msg={"Please Wait..."}/> :
        <LeadContext.Provider value={{ state, dispatch }}>
          <DogDetailsForm
            errorMessage={errorMessage}
            form={form}
            handleChange={handleChange}
            updateDetails={updateDetails}
            handleDateChange={handleDateChange}
            isDogDetailsNextButtonDisabled={isDogDetailsNextButtonDisabled}
            isFormSubmitted={isFormSubmitted}
            isValidDate={isValidDate}
            setForm={setForm}
            validateInputLength={validateInputLength}
          />
        </LeadContext.Provider>}
			</Container>
		</Fragment>
	);
};

export default DogDetails;