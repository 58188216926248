import React from 'react';
import { Button, Col, Form, FormGroup, Label, Input, Row } from "reactstrap";
import { validateInput } from '../../../helpers/oemMapping';

const OemMappingSearch = (props) => {
    const {
        boolean,
        form: {
            searchText
        },
        handleChange,
        handleResetClick,
        onSubmitSearch
    } = props;

    return (
        <Row className="m-1" >
            <Col>
                <Form onSubmit={onSubmitSearch}>
                    <FormGroup row>
                        <Label for="exampleSearchText" sm={1}>Make:</Label>
                        <Col sm={9}>
                            <Input
                                type="text"
                                name="searchText"
                                id="exampleSearchText"
                                placeholder="Enter search text of minimum 3 characters..."
                                onChange={handleChange}
                                value={searchText}
                                onInput={validateInput}
                            />
                        </Col>
                        <Col sm={2}>
                            <Button
                                color="primary"
                                outline
                                type="submit"
                                {...((!searchText || searchText.length < 3 || boolean.searchSubmitted) && { disabled: true })}
                            >
                                Search
                            </Button>
                            {searchText.length >= 3 &&
                                <Button
                                    type="button"
                                    name="btnReset"
                                    color="secondary"
                                    outline
                                    onClick={handleResetClick}
                                >
                                    Clear
                                </Button>}
                        </Col>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    );
}

export default OemMappingSearch;