import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import * as actions from '../../../Store/actions';
import CustomDropdown from '../../../components/Filters/CustomDropdown';
import Shimmer from '../../../components/CustomUi/Shimmer/Shimmer';
import Spinner from "../../../components/Spinner/Spinner";
import ReactDatetime from "react-datetime";
import {
  Breadcrumb,
  BreadcrumbItem,
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
import Can from '../../../config/Casl/Can'
import * as permissions from '../../../config/Casl/permissions';

const orderIdSearch = /^[ a-zA-Z0-9_-]*$/;

class Claims extends Component {
  previousMonthDate = (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1)
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      view: "claim-table",
      cardFilter: 'custom',
      partnerId: '',
      hideProductDropdown: true,
      openDateDialog: false,
      openMonthDialog: false,
      hideFilters: false,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      selectedDate: (new Date().getMonth() === 0) ? new Date(new Date().getFullYear() - 1, 11) : new Date(new Date().getFullYear(), new Date().getMonth() - 1),
      selectedCardsFilterDate: new Date()
    };
    props.onGetPartnerClaimCardsData(this.buildDefaultParams())
    this.getClaims()
  }
  pager = {
    pagerStart: 0,
    pagerEnd: 0,
    pageArray: [],
    limit: 25,
  }
  updatePagination = (total) => {
    this.pager.pageArray = [];
    this.pager.pagerEnd = Math.ceil(total / this.pager.limit);
    for (let index = 0; index < this.pager.pagerEnd; index++) {
      this.pager.pageArray.push(index + 1)
    }
  }
  switchPage = (page) => {
    if (((this.pager.pagerStart + 1) !== page)) {
      this.pager.pagerStart = page - 1
      this.getClaims()
    }
  }
  getClaims = () => {
    this.props.onGetPartnerClaimListData({}, { start: this.pager.pagerStart, partner_id: this.state.partnerId, month: this.state.selectedCardsFilterDate.getMonth() + 1, year: this.state.selectedCardsFilterDate.getFullYear() })
  }
  isHidden = (page) => {
    var start = this.pager.pagerStart + 1;
    if (page > start) {
      if ((page - start) > 2 && (start === 1)) {
        return true;
      } else if ((start !== 1) && ((page - start) > 1)) {
        return true;
      }
    } else {
      if ((start - page) > 2 && (start === this.pager.pagerEnd)) {
        return true;
      } else if ((start !== this.pager.pagerEnd) && ((start - page) > 1)) {
        return true;
      }
    }
    return false
  }
  partnerChanged = (partnerId) => {
    this.pager.pagerStart = 0;
    this.setState({
      partnerId: partnerId
    }, () => {
      this.getClaims()
    })
  }
  componentDidMount() {
    this.props.onGetPartnerClaimFilterListData()
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  refreshFilters = () => {
    this.pager.pagerStart = 0
    this.setState({
      partnerId: '',
      view: "Claim-table",
      searchText: ""
    }, () => {
      this.getClaims()
    })
  }
  onSearch = (value) => {
    if (!this.state.searchText.match(orderIdSearch)) {
      this.showMsg("Please, don't enter any special characters", "danger")
      return
    }
    this.props.onGetPartnerClaimListData('', { claim_search: value })
    this.setState({
      view: "table"
    })
  }
  onRefreshAllCards = (filter) => {
    this.pager.pagerStart = 0
    this.setState({
      cardFilter: filter
    })
    this.props.onGetPartnerClaimCardsData(this.buildDefaultParams())
  }
  handleClickOutside = (e) => {
    let classArray = [];
    if (e.path) {
      e.path.forEach(element => {
        classArray = classArray.concat(element.className && element.className.split(" "));
      });
    } else {
      e.composedPath().forEach(element => {
        classArray = classArray.concat(element.className && element.className.split(" "));
      });
    }
    if (!classArray.includes('reactDateTimeCustomClass')) {
      this.setState({
        openDateDialog: false,
        openMonthDialog: false
      })
    }
  }
  getMonthYear = (date) => {
    return this.state.months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()
  }
  handleReactMonthFilterChange(e) {
    this.pager.pagerStart = 0;
    this.setState({
      cardFilter: 'custom',
      selectedCardsFilterDate: e._d
    }, () => {
      this.getClaims()
    })
    this.props.onGetPartnerClaimCardsData({ month: e._d.getMonth() + 1, year: e._d.getFullYear() })
  }
  truncateText = (text, length) => {
    return text ? text.substring(0, length) + "..." : "NA";
  }
  validDateMonthFilter = (current) => {
    return (current.isSameOrBefore(new Date()));
  };
  showMsg = (msg, type) => {
    this.props.onShowNotification({
      message: msg,
      title: "",
      type: type
    })
  }
  getTwoFixedPoint = (num) => {
    var x = num * 1;
    return x.toFixed(2)
  }
  buildDefaultParams = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();

    return { month, year };
  }
  render() {
    this.claimList = this.props.partnerClaimListData ? this.props.partnerClaimListData : [];
    this.claimCards = this.props.partnerClaimCardsData ? this.props.partnerClaimCardsData : "";
    (this.props.claimTotalCount !== null) && this.updatePagination(this.props.claimTotalCount)
    return (<>
      <Fragment>
        <Container fluid>
          <CardHeader className="border-0 bg-transparent px-0">
            <Row>
              <Col lg="8" xs="6">
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-0"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary h5 font-weight-400" onClick={e => e.preventDefault()}>
                      Partners
                      </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" className="text-primary h5 font-weight-400" onClick={e => e.preventDefault()}>
                      Claims
                      </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="text-right" lg="4" xs="6">
                <div className="d-inline">
                  {/* <Badge
                    color={this.state.cardFilter === 'all' ? 'primary' : 'secondary'}
                    href="#pablo"
                    pill
                    onClick={e => {
                      e.preventDefault();
                      this.onRefreshAllCards('all')
                    }}
                  >
                    All</Badge> */}
                  <Badge
                    color={this.state.cardFilter === 'custom' ? 'primary' : 'secondary'}
                    href="#pablo"
                    pill
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        openMonthDialog: true,
                      })
                    }}
                  >
                    {this.state.cardFilter === 'custom' ? this.getMonthYear(this.state.selectedCardsFilterDate) : "Select Month"}
                  </Badge>
                  <ReactDatetime
                    className="d-inline cursor-pointer reactDateTimeCustomClass position-absolute rdtPicker-right-0"
                    open={this.state.openMonthDialog}
                    onFocus={e => {
                      this.setState({
                        openMonthDialog: true
                      })
                    }}
                    onBlur={e => {
                      this.setState({
                        openMonthDialog: false
                      })
                    }}
                    disableCloseOnClickOutside={false}
                    inputProps={{
                      placeholder: "Month"
                    }}
                    value={this.state.selectedCardsFilterDate}
                    dateFormat="MMM-YYYY"
                    timeFormat={false}
                    onChange={e => {
                      this.handleReactMonthFilterChange(e)
                      this.setState({
                        openMonthDialog: false
                      })
                    }}
                    isValidDate={this.validDateMonthFilter}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <>
            <Can I={permissions.PARTNERS.claim_cards} a={permissions.PARTNERS.subject}>
              <Row>
                <Col md="6" xl="3">
                  <Card className="bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-white mb-0"
                          >
                            Total Claims
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {(!this.props.partnerClaimCardsLoading && this.props.partnerClaimCardsData) ? this.props.partnerClaimCardsData.totalClaims : <Shimmer />}
                          </span>
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className=" bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-white mb-0"
                          >
                            Approved Claims
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {(!this.props.partnerClaimCardsLoading && this.props.partnerClaimCardsData) ? this.props.partnerClaimCardsData.approvedClaims : <Shimmer />}
                          </span>
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-white mb-0"
                          >
                            Rejected Claims
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {(!this.props.partnerClaimCardsLoading && this.props.partnerClaimCardsData) ? this.props.partnerClaimCardsData.rejectedClaims : <Shimmer />}
                          </span>
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className=" bg-gradient-primary card-stats">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-white mb-0"
                          >
                            Disbursed Amount
                          </CardTitle>
                          <span className="h2 font-weight-bold text-white mb-0">
                            {(!this.props.partnerClaimCardsLoading && this.props.partnerClaimCardsData) ? "₹ " + this.props.partnerClaimCardsData.disburshedAmount : <Shimmer />}
                          </span>
                        </div>
                        <Col className="col-auto">

                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                          {this.state.cardFilter === 'all' ? 'Till ' + this.getMonthYear(this.previousMonthDate) : this.getMonthYear(this.state.selectedCardsFilterDate)}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Can>
            <Row className="px-3">
              <Col xs={12} sm={6} className="pl-0">
                <h5 className="text-primary h5 font-weight-400" >All Claims</h5>
              </Col>
              <Col className="pr-0 pb-1">
                <div
                  className="float-right"
                >
                  <Button className="btn-icon btn-2" size="sm" outline color="secondary" type="button" onClick={e => {
                    this.setState({
                      hideFilters: !this.state.hideFilters
                    })
                  }}>
                    <span className="btn-inner--icon">
                      <i className="fas fa-filter"></i>
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
            <div >
              <Row className="bg-white m-0 py-2" hidden={this.state.hideFilters}>
                <Col className="p-1 px-3 text-muted">
                  <small>
                    <form xs="6" className="form-inline md-form mr-3 mb-4 d-inline" action="javascript:void(-1)">
                      <input className="ml-2 bg-white border-0 text-muted pr-2"
                        value={this.state.searchText}
                        onKeyDown={e => {
                          (e.key === 'Enter') && this.onSearch(e.target.value)
                        }}
                        onChange={e => {
                          if (e.target.value == " ") {
                            this.setState({
                              view: "claim-table"
                            })
                          }
                          this.setState({
                            searchText: e.target.value
                          })
                        }}
                        type="text" placeholder="Claim ID / Name" aria-label="Search" />
                      <i className="fas fa-search cursor-pointer mt-2" aria-hidden="true"
                        onClick={
                          e => {
                            e.preventDefault()
                            this.onSearch(this.state.searchText)
                          }}
                      ></i>
                    </form>
                  </small>
                  {(this.state.searchText !== "") && (this.state.view === "table") && <Badge color="primary" pill className="ml-2">
                    {this.state.searchText}
                    <span className="p-1 ml-2 cursor-pointer" onClick={
                      e => {
                        e.preventDefault()
                        this.refreshFilters()
                        this.setState({
                          view: "Claim-table",
                          searchText: ""
                        })
                      }} >
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </span>
                  </Badge>}
                </Col>
                <Col className="p-1 text-right px-3">
                  <CustomDropdown name="Partner" selectedId={this.state.partnerId} handleSelection={id => { this.partnerChanged(id) }} options={this.props.partnerClaimFilterListData} />
                  <Button color="secondary " outline size="sm" type="button" onClick={e => {
                    e.preventDefault()
                    this.refreshFilters()
                  }}>
                    Clear
                </Button>
                </Col>
              </Row>
            </div>
            <Card className="m-0 mt-1" >
              <CardBody className="px-0 py-0" style={{ minHeight: "708px" }}>
                <div className=" table-responsive">
                  <Table className="align-items-center table-flush" hover responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>Claim ID</th>
                        <th>Name</th>
                        <th>Product / Claim Type</th>
                        <th>Filed at</th>
                        <th>Claimed Amount</th>
                        <th>Approved Amount</th>
                        <th className="text-right">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.partnerClaimListLoading && <tr >
                          <td colSpan="8">
                            <Spinner></Spinner>
                          </td>
                        </tr>
                      }
                      {!this.props.partnerClaimListLoading && this.props.partnerClaimListData && this.props.partnerClaimListData.length < 1 && <tr key={"keyxasx1_duwe823"} className="cursor-pointer">
                        <td colSpan="12" className="text-center">
                          <span className="text-muted">
                            <b>
                              No Claims Data found
                          </b>
                          </span>
                        </td>
                      </tr>}
                      {!this.props.partnerClaimListLoading && this.claimList.map((claim, index) => (
                        <tr onClick={(e) => {
                          e.preventDefault()
                        }} key={index}>
                          <td className="table-user">{claim.id}</td>
                          <td className="table-user">{claim.name}</td>
                          <td className="table-user text-wrap" title={claim.type}>
                            Product: <b>{claim.product}</b><br />
                            {this.truncateText(claim.type, 20)}
                          </td>
                          <td><span className="text-muted">{claim.created_at}</span></td>
                          <td>{"₹ " + this.getTwoFixedPoint(claim.claimed_amount)}</td>
                          <td>{"₹ " + this.getTwoFixedPoint(claim.approved_amount)}</td>
                          <td className="text-right"><span className="text-muted">{claim.active}</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {!this.props.partnerClaimListLoading && this.props.partnerClaimListData && this.props.partnerClaimListData.length > 0 && <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={this.pager.pagerStart > 0 ? '' : 'disabled'}>
                      <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          this.switchPage(this.pager.pagerStart);
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {this.pager.pageArray.map(page => {
                      return < PaginationItem key={'pager_' + page} hidden={this.isHidden(page)} className={((this.pager.pagerStart + 1) === page) ? "active" : ''}>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            this.switchPage(page);
                          }}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    })}
                    <PaginationItem className={this.pager.pagerEnd > this.pager.pagerStart + 1 ? '' : 'disabled'}>
                      <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          this.switchPage(this.pager.pagerStart + 2);
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>}
            </Card> 
            </>
        </Container>
      </Fragment>
    </>
    );
  }
}
const mapStateToProps = state => {
  return {
    partnerClaimListLoading: state.partnerClaim.partnerClaimListLoading,
    partnerClaimCardsLoading: state.partnerClaim.partnerClaimCardsLoading,

    partnerClaimListData: state.partnerClaim.partnerClaimListData,
    claimTotalCount: state.partnerClaim.totalClaimListCount,
    partnerClaimCardsData: state.partnerClaim.partnerClaimCardsData,

    partnerClaimFilterListData: state.partnerClaim.partnerClaimFilterListData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetPartnerClaimListData: (filter, params) => dispatch(actions.getPartnerClaimListData(filter, params)),
    onGetPartnerClaimCardsData: (data) => dispatch(actions.getPartnerClaimCardsData(data)),
    onGetPartnerClaimFilterListData: () => dispatch(actions.getPartnerClaimFilterListData()),
    onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Claims);