import React, { Component,Fragment} from 'react';
import ReactDatetime from "react-datetime";



export default class Date extends Component{

render(){
    return(
        <Fragment>
            <label className="form-control-label" htmlFor="exampleDatepicker" >
                Select a Date
            </label>
            <ReactDatetime
                inputProps={{
                    placeholder: "Pick A Date"
                }}
               
                dateFormat="MM-DD-YYYY"
                timeFormat={false}
            />

        </Fragment>
    );

}
}