import React from 'react';
import { Row, Col } from 'reactstrap';

const SectionHeader = (props) => {
    const { title = "Section Heading" } = props;

    return (
        <Row>
            <Col xs="12">
                <h4 className="h4 pt-2 font-weight-400">
                    {title}
                </h4>
            </Col>
        </Row>
    );
};

export default SectionHeader;