import React, {Component} from 'react';
import {
    Card,
    Container,
    Row,
    Col,
    Badge,
    CardBody,
    CardTitle,
    Button,
    Modal,
} from "reactstrap";
import Transactions from "./transactionsTab";
import PolicyDetails from "./policyDetailsTab";
import DocumentsTab from "./documentsTab";
import DetailsTab from "./detailsTab";
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import {Products} from "../../../data/products";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import Otp from "./otp";
import OrderDocumentsTab from "./orderDocumentsTab";

const mobileRegex = /^[6-9]\d{9}$/;
const b2c_url = `${process.env.REACT_APP_B2C_URL}`;
const platform_url = `${process.env.REACT_APP_PLATFORM_URL}`;

class ViewPolicies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [
                {title: "Life Insurance", color: "danger", action: ""},
                {title: "Home Insurance", color: "success", action: ""},
                {title: "Health Insurance", color: "success", action: ""},
                {title: "Health Top Up", color: "success", action: ""}
            ],
            userInfo: [
                {title: "Name", value: "Gokul Mondal"},
                {title: "Phone Number", value: "+91 9876543210", icon: "ni ni-chat-round"},
                {title: "Email ID", value: "pasrservices@gmail.com", icon: "ni ni-email-83"},
                {title: "Seller", value: "Avinash Kumar "}
            ],
            toffeePlanTabs: [
                {title: "Details", key: "details"},
                {title: "Documents", key: "documents"},
                {title: "Policy Details", key: "policy_details"},
                {title: "Transaction", key: "transaction"}
            ],
            dogInsuranceTabs: [
                {title: "Details", key: "details"},
                {title: "Policy Details", key: "policy_details"},
                {title: "Documents", key: "order_documents"},
            ],
            tabs: [
                {title: "Details", key: "details"},
                {title: "Policy Details", key: "policy_details"},
            ],
            msgTabs: [
                {title: "Message", key: "sms"},
                {title: "Email", key: "email"},
            ],
            selectedTab: "details",
            selectedMsgTab: "sms",
            editDetails: false,
            sendMsgDialog: false,
            fontStyle: 'font-weight-normal',
            showLinkInput: false,
            linkValue: '',
            selectedTemplate: '',
            templateText: '',
            finalTemplateText: '',
            policyHolderName: '',
            messageOptions: [
                {id: 'select', name: 'Select a Template'},
                {id: 'documents', name: 'Documents Pending'},
                {id: 'change_premium', name: 'Change in Policy premium '},
                {id: 'pending_policy', name: 'Pending policy (life insurance)'},
                {id: 'policy_mail', name: 'Policy Mail'},
            ],
            addRemarkDialog: false,
            remarkText: '',
            userDetails: {},
            openOtpDialog: false,
            otpSent: false,
            mobile: this.props.policyHolderDetailsData?.mobile,
            error: '',
            savedOtp: null,
            enteredOtp: null,
            mobileChanged: false,
            userPhoto: true,
            bankDetails: true,
            aadhar: true,
            incomeProof: true,
            pan: true,
            valueChanged: false,
            newMobile: null
        }

        this.getPolicyData()
        this.onValueChange = this.onValueChange.bind(this);
    }

    lifeInsuranceProducts=[51,48,52,58,49]
    dogInsuranceProducts=[60]

    userPhoto;
    bankDetails;
    aadhar;
    incomeProof;
    pan;
    templates;

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.policyHolderDetailsData !== this.props.policyHolderDetailsData) {
            this.getInitialData();
        }
        this.userPhoto = !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_USER_PHOTO'];
        this.bankDetails = !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_BANK_DETAILS_PROOF'];
        this.aadhar = !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_ID'];
        this.incomeProof = !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_INCOME_PROOF'];
        this.pan = !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_PAN'];
        this.updated_values = this.props.policyHolderDetailsData?.updated_details ?  this.props.policyHolderDetailsData?.updated_details : {}
        this.templates = [
            {
                id: 'documents',
                msg: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan) => <div className={"p-4"}
                                                                                                    id={"test1"}>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        You`re just one step away! We took a look at your documents. Looks like the following are
                        missing.
                        You will need to upload them for verification before issuing your policy:<br/>
                        #List of Documents <br/>
                        {!userPhoto && 'User Photo'} <br/>
                        {!bankDetails && 'Bank Details'} <br/>
                        {!aadhar && 'Aadhar Card'}<br/>
                        {!incomeProof && 'Income Proof'}<br/>
                        {!pan && 'Pan Card'}<br/>
                        Click link to upload now - <br/>
                        {b2c_url + '/policies/' + this.props.match.params.id}<br/>
                        Thank you for choosing Toffee Insurance for your service!
                    </p>
                </div>,
                mail: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan) => <div className={"p-4"}>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        You're just one step away!<br/>
                        We took a look at your documents. Looks like the following are missing. You will need to upload
                        them
                        for verification before issuing your policy:<br/>
                        #List of Documents<br/>
                        {!userPhoto && 'User Photo'}<br/>
                        {!bankDetails && 'Bank Details'}<br/>
                        {!aadhar && 'Aadhar Card'}<br/>
                        {!incomeProof && 'Income Proof'}<br/>
                        {!pan && 'Pan Card'}<br/>
                        Click link to upload now - <br/>
                        {b2c_url + '/policies/' + this.props.match.params.id}<br/>
                        Thank you for choosing Toffee Insurance for your service!
                    </p>
                </div>,
                smsContent: '<div> <p>You`re just one step away! We took a look at your documents. </p> <br/><p>Looks like the following are missing. You will need to upload them for verification before issuing your policy:</p><br/><p> #List of Documents </p> <br/>' + (!this.userPhoto ? 'User Photo' : "") + '<br/> ' + (!this.bankDetails ? 'Bank Details' : "") + ' <br/>' + (!this.aadhar ? 'Aadhar Card' : "") + '<br/> ' + (!this.incomeProof ? 'Income Proof' : "") + '<br/> ' + (!this.pan ? 'Pan Card' : "") + '<br/><p>Click link to upload now - ' + (b2c_url + '/policies/' + this.props.match.params.id) + ' </p> <br/>Thank you for choosing Toffee Insurance for your service!</div>',
                mailContent: '<div> <p>You`re just one step away! We took a look at your documents. </p> <br/><p>Looks like the following are missing. You will need to upload them for verification before issuing your policy:</p><br/><p> #List of Documents </p> <br/>' + (!this.userPhoto ? 'User Photo' : "") + '<br/> ' + (!this.bankDetails ? 'Bank Details' : "") + ' <br/>' + (!this.aadhar ? 'Aadhar Card' : "") + '<br/> ' + (!this.incomeProof ? 'Income Proof' : "") + '<br/> ' + (!this.pan ? 'Pan Card' : "") + '<br/><p>Click Button to upload now -  </p> <br/>Thank you for choosing Toffee Insurance for your service!</div>',
                subject: 'Pending: Submit Document For Verification',
                buttonLink: b2c_url + '/policies/' + this.props.match.params.id,
                buttonText: 'UPLOAD NOW',
            },
            {
                id: 'change_premium',
                msg: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products, updated_values) =>
                    <div className={"p-4"}>
                        <h3 className={"text-left"}>
                            Dear <span id="name">{name}</span>,
                        </h3>
                        <p className={"text-left"}>
                            {updated_values !== "" ? <>
                                    {updated_values && updated_values['dob'] && <>Your request for changing DOB
                                        from {updated_values['dob'].old_value} to {updated_values['dob'].new_value} has been
                                        approved.<br/></>}
                                    {updated_values && updated_values['response_members.spouse.dob'] && <>Your request for
                                        changing DOB of your Spouse
                                        from {updated_values['response_members.spouse.dob'].old_value} to {updated_values['response_members.spouse.dob'].new_value} has
                                        been approved.<br/></>}
                                    {updated_values && updated_values['response_members.child1.dob'] && <>Your request for
                                        changing DOB of your first Child
                                        from {updated_values['response_members.child1.dob'].old_value} to {updated_values['response_members.child1.dob'].new_value} has
                                        been approved.<br/></>}
                                    {updated_values && updated_values['response_members.child2.dob'] && <>Your request for
                                        changing DOB of your second Child
                                        from {updated_values['response_members.child2.dob'].old_value} to {updated_values['response_members.child2.dob'].new_value} has
                                        been approved.<br/></>}
                                    Your policy premium has now been revised from Rs.{updated_values['premium'].old_value}{!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)' : '(per month)'} to Rs.{updated_values['premium'].new_value}
                                    {!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)' : '(per month)'} <br/> <br/>
                                </>
                                :
                               " No Details changed to effect Premium"
                            }
                            <br/><br/>
                            Thank you for choosing Toffee Insurance for your service!
                        </p>
                    </div>,
                mail: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products, updated_values) =>
                    <div className={"p-4"}>
                        <h3 className={"text-left"}>
                            Dear <span id="name">{name}</span>,
                        </h3>
                        <p className={"text-left"}>
                            You're just one step away!<br/><br/>
                            {updated_values !== "" ? <>
                            {updated_values && updated_values['dob'] && <>Your request for changing DOB from {updated_values['dob'].old_value} to {updated_values['dob'].new_value} has been approved.<br/></>}
                            {updated_values && updated_values['response_members.spouse.dob'] && <>Your request for changing DOB of your Spouse from {updated_values['response_members.spouse.dob'].old_value} to {updated_values['response_members.spouse.dob'].new_value} has been approved.<br/></>}
                            {updated_values && updated_values['response_members.child1.dob'] && <>Your request for changing DOB of your first Child from {updated_values['response_members.child1.dob'].old_value} to {updated_values['response_members.child1.dob'].new_value} has been approved.<br/></>}
                            {updated_values && updated_values['response_members.child2.dob'] && <>Your request for changing DOB of your second Child from {updated_values['response_members.child2.dob'].old_value} to {updated_values['response_members.child2.dob'].new_value} has been approved.<br/><br/></>}

                            Your policy premium has now been revised from Rs.{updated_values['premium'].old_value}{!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)'}
                            to Rs.{updated_values['premium'].new_value}{!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)'} <br/> <br/>
                                </>
                                :
                                " No Details changed to effect Premium"
                            }
                            <br/><br/>
                            Thank you for choosing Toffee Insurance for your service!
                        </p>
                    </div>,

                smsContent: '<div><p>'+ (this.updated_values['dob'] ?  'Your request for changing DOB from' + (this.updated_values['dob']?.old_value)+ ' to '+(this.updated_values['dob']?.new_value) +'has been approved.' : "") +'<br/></>.</p><br/><p>' +
                    (this.updated_values['response_members.spouse.dob'] ?  'Your request for changing DOB of your Spouse from' + (this.updated_values['response_members.spouse.dob']?.old_value)+ ' to '+(this.updated_values['response_members.spouse.dob']?.new_value) +'has been approved.' +'<br/></>.</p><br/><p>' : "") +
                    (this.updated_values['response_members.child1.dob'] ?  'Your request for changing DOB of your first Child from' + (this.updated_values['response_members.child1.dob']?.old_value)+ ' to '+(this.updated_values['response_members.child1.dob']?.new_value) +'has been approved.'+'<br/></>.</p><br/><p>' : "") +
                    (this.updated_values['response_members.child2.dob'] ?  'Your request for changing DOB of your second Child from' + (this.updated_values['response_members.child2.dob']?.old_value)+ ' to '+(this.updated_values['response_members.child2.dob']?.new_value) +'has been approved.'+'<br/></>.</p><br/><p>' : "") +
                    ' Your policy premium has now been revised from Rs. '+(this.updated_values['premium']?.old_value) +' '+(!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)')+ ' to Rs.'+(this.updated_values['premium']?.new_value)+' '+(!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)')+'</p><br/> Thank you for choosing Toffee Insurance for your service!</div>',

                mailContent: '<div> <p>You`re just one step away! </p><br/><br/><p>'+ (this.updated_values['dob'] ?  'Your request for changing DOB from' + (this.updated_values['dob']?.old_value)+ ' to '+(this.updated_values['dob']?.new_value) +'has been approved.' : "") +'<br/></>.</p><br/><p>' +
                     (this.updated_values['response_members.spouse.dob'] ?  'Your request for changing DOB of your Spouse from' + (this.updated_values['response_members.spouse.dob']?.old_value)+ ' to '+(this.updated_values['response_members.spouse.dob']?.new_value) +'has been approved.'+'<br/></>.</p><br/><p>' : "") +
                 (this.updated_values['response_members.child1.dob'] ?  'Your request for changing DOB of your first Child from' + (this.updated_values['response_members.child1.dob']?.old_value)+ ' to '+(this.updated_values['response_members.child1.dob']?.new_value) +'has been approved.'+'<br/></>.</p><br/><p>' : "") +
                    (this.updated_values['response_members.child2.dob'] ?  'Your request for changing DOB of your second Child from' + (this.updated_values['response_members.child2.dob']?.old_value)+ ' to '+(this.updated_values['response_members.child2.dob']?.new_value) +'has been approved.'+'<br/></>.</p><br/><p>' : "") +
                    ' Your policy premium has now been revised from Rs. '+(this.updated_values['premium']?.old_value) +' '+(!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)')+ ' to Rs.'+(this.updated_values['premium']?.new_value)+' '+(!this.props.policyHolderDetailsData?.is_monthly ? '(p.a.)':'(per month)')+'</p><br/> Thank you for choosing Toffee Insurance for your service!</div>',

                subject: 'Policy Update Request Approved.',
                buttonLink: '',
                buttonText: 'My Policy',
            },
            {
                id: 'pending_policy',
                msg: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products) => <div
                    className={"p-4"}>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        We apologize for the delay in issuing your {inactive_products?.toString()} policy. We're trying
                        our best to have it
                        delivered to your inbox ASAP. This might take close the 7-12 working
                        days.<br/><br/>
                        Thank you for your patience, and for choosing Toffee Insurance for your service!
                    </p>
                </div>,
                mail: (name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products) => <div
                    className={"p-4"}>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        We apologize for the delay in issuing your {inactive_products?.toString()} policy. We're trying
                        our best to have it
                        delivered to your inbox ASAP. This might take close the 7-12 working
                        days.<br/><br/>
                        For queries, feel free to contact us!<br/>
                        {platform_url + '/contact-us'}<br/>
                        Thank you for your patience, and for choosing Toffee Insurance for your service!
                    </p>
                </div>,
                smsContent: '<div> <p>We apologize for the delay in issuing your ' + (this.state.inactive_products?.toString()) + ' policy.</p><br/><p> We\'re trying our best to have it delivered to your inbox ASAP. </p> <br/><p>This might take close the 7-12 working days.</p><br/> Thank you for your patience, and for choosing Toffee Insurance for your service!</div>',
                mailContent: '<div> <p>We apologize for the delay in issuing your ' + (this.state.inactive_products?.toString()) + ' policy.</p><br/><p> We\'re trying our best to have it delivered to your inbox ASAP.</p><br/><p> This might take close the 7-12 working days. </p><br/><p>For queries, feel free to contact us!. </p><br/> Thank you for your patience, and for choosing Toffee Insurance for your service!</div>',
                subject: 'Pending: Apologies for the Delay!',
                buttonLink: platform_url + '/contact-us',
                buttonText: 'CONTACT US',
            },
            {
                id: 'policy_mail',
                msg: (name) => <div className={"p-4"}>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        You have purchased the Life + Health + Household Insurance Plan successfully. Your Plan Card and benefits summary document are attached here. Please go through them carefully. <br/>
                        Here is what this plan will cover: <br/><br/>
                       (List of People covered)<br/><br/>
                        COVID-19 test and treatment expenses (if hospitalised)<br/>
                        Health Insurance worth ₹5 Lakhs<br/><br/>
                        and more!<br/><br/>
                        Please note that your policy documents will be emailed to you in 2-3 working days. Meanwhile, you can contact us anytime for any issues.
                    </p>
                </div>,
                mail: (name) => <div className={"p-4"}>
                    <h4><b>Subject:</b> Pending: Submit Document For Verification</h4>
                    <h3 className={"text-left"}>
                        Dear <span id="name">{name}</span>,
                    </h3>
                    <p className={"text-left"}>
                        Not Available
                    </p>
                </div>,
                smsContent: '',
                mailContent: '',
                subject: 'Policy Issuesd',
                buttonLink: '',
                buttonText: '',
            },
        ]
    }

    htmlDecode = (str) => {
        let map = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            "\"": "&quot;",
            "'": "&#39;"
        };
        return str?.replace(/[&<>"']/g, function (m) {
            return map[m];
        });
    }

    getInitialData = () => {
        this.setState({
            userDetails: {
                'name': this.props.policyHolderDetailsData?.name,
                'email': this.props.policyHolderDetailsData?.email,
                'mobile': this.props.policyHolderDetailsData?.mobile,
                "new_mobile": null
            },
            mobile: this.props.policyHolderDetailsData?.mobile,
            userPhoto: !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_USER_PHOTO'],
            bankDetails: !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_BANK_DETAILS_PROOF'],
            aadhar: !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_ID'],
            incomeProof: !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_INCOME_PROOF'],
            pan: !!this.props.policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_PAN'],
        })
    }

    getPolicyData = () => {
        this.props.onGetPolicyHolderDetails(this.props.match.params.id)
    };
    onClickEditDetails = () => {
        this.setState({
            editDetails: !this.state.editDetails
        }, () => {
                if (!this.state.editDetails) {
                    if (this.state.mobileChanged) {
                        this.setState({
                            editDetails: true
                        })
                    }
                    let userDetails = this.state.userDetails;
                    userDetails = {
                        ...userDetails,
                        "new_mobile": this.state.mobileChanged ? this.state.newMobile : null,
                        "mobile": this.state.mobile,
                    }
                    this.updateDetails(this.props.match.params.id, userDetails)
                    this.setState({
                        error: '',
                        editDetails: true,
                    })
                }
        })
    }
    onClickCancelEdit = () => {
        if (this.state.valueChanged) {
            window.location.reload();
            this.setState({
                editDetails: false,
                error: '',
            })
        } else {
            this.setState({
                editDetails: false,
                error: '',
            })
        }
    }
    sendMsgClick = () => {
        this.setState({
            sendMsgDialog: true
        })
    }

    handleTemplateChange = (e, name, policyNumber, {userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products, updated_values}) => {
        let template = this.templates.find((option, index) => e.target.value === option.id);
        this.setState({
            selectedTemplate: e.target.value,
            templateText: template.msg(name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products, updated_values),
            templateMail: template.mail(name, policyNumber, userPhoto, bankDetails, aadhar, incomeProof, pan, inactive_products, updated_values),
            userPhoto: userPhoto,
            bankDetails: bankDetails,
            aadhar: aadhar,
            incomeProof: incomeProof,
            pan: pan,
            inactive_products: inactive_products,
        }, () => {
            this.setState({
                smsContent: template.smsContent,
                mailContent: template.mailContent,
                subject: template.subject,
                button_text: template.buttonText,
                button_link: template.buttonLink,
            })
        })
    }
    onChangeComm = (tab) => {
        this.setState({
            selectedMsgTab: tab
        })
    }
    openRemarkDialog = () => {
        this.setState({
            addRemarkDialog: true
        })
    }

    onChangeRemark = (e) => {
        this.setState({
            remarkText: e.target.value
        })
    }
    saveRemark = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.postRequest('orders/' + id + '/remark', payload)
            .then((res) => {
                this.setState({
                    showLoader: false,
                    addRemarkDialog: false
                });
                this.showMsg('Remark added successfully !', "success")
                this.getPolicyData()
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }
    onValueChange = (e, key, isMobile) => {
        this.setState({
            valueChanged: true
        })
        if (key === 'mobile') {
            this.setState({
                mobileChanged: true,
                newMobile: e.target.value
            })
        }
        let userDetails = this.state.userDetails;
        userDetails[key] = e.target.value;
        this.setState({
            userDetails: userDetails,
        }, () => {
            if (!userDetails['mobile'].match(mobileRegex)) {
                this.setState({
                    error: 'Please enter valid mobile number'
                })
            } else {
                this.setState({
                    error: ''
                })
            }
        });
    }

    updateDetails = (id, payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.putRequest('orders/' + id, payload)
            .then((res) => {
                if (res.data.data.otp_sent) {
                    this.setState({
                        showLoader: false,
                        editDetails: true,
                        openOtpDialog: true,
                    });
                    this.showMsg('OTP sent !', "success")
                } else if (!res.data.data.updated_data) {
                    if (res.data.data.otp_verified) {
                        this.setState({
                            showLoader: false,
                            editDetails: false,
                            openOtpDialog: false,
                        });
                        this.showMsg('OTP Verified', "success")
                        this.showMsg('Mobile number updated successfully !', "success")
                    }
                    else {
                        this.showMsg('Incorrect OTP', "danger")
                        this.setState({
                            showLoader: false,
                            editDetails: true,
                            openOtpDialog: true
                        });
                    }
                } else {
                    this.setState({
                        showLoader: false,
                        editDetails: false,
                        openOtpDialog: false
                    });
                    this.showMsg('Details updated successfully !', "success")
                }
                this.getPolicyData()
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.data.error_msg
                        ? this.parseMessage(err.response.data.data.error_msg) : err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    parseMessage = message => {
        if (typeof message === "string") {
            return message;
        } else if (Array.isArray(message) && message.length) {
            let errors = [];
            const output = message.map(item => [...errors, item.msg] );
            return output.join(', ');
        } else {
            let errors = [];
            for (const [key, value] of Object.entries(message)) {
                errors.push(value);
            }
            return errors;
        }
    };

    onClickVerifyOtp = (e) => {
        let userDetails = this.state.userDetails;
        userDetails = {
            ...userDetails,
            otp: this.state.enteredOtp,
            "new_mobile": this.state.newMobile,
            "mobile": this.state.mobile,
        }
        this.updateDetails(this.props.match.params.id, userDetails)
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };
    onClickSend = () => {
        this.sendComms({
            order_id: this.props.match.params.id,
            comms_type: this.state.selectedMsgTab,
            template: this.state.selectedTemplate === 'policy_mail' ? this.state.selectedTemplate : "",
            content: this.state.selectedMsgTab === 'sms' ? this.htmlDecode(this.state.smsContent) : this.htmlDecode(this.state.mailContent),
            name: this.props.policyHolderDetailsData?.name,
            subject: this.state.subject,
            button_text: this.state.button_text,
            button_link: this.state.button_link
        })
    }

    sendComms = (payload) => {
        this.setState({
            showLoader: true
        });
        AxiosReactClient.postRequest('comms', payload)
            .then((res) => {
                this.setState({
                    showLoader: false,
                    sendMsgDialog: false,
                });
                this.showMsg('Communication Sent !', "success")
                this.getPolicyData()
            })
            .catch(err => {
                this.setState({
                    showLoader: false
                });
                if (err.response) {
                    this.showMsg(err.response.data.message, "danger")
                } else if (err.message) {
                    this.showMsg(err.message, "danger")
                }
            });
    }

    isLifeInsuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.lifeInsuranceProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }

    isDogInsuranceProduct = () => {
        return this.props.policyHolderDetailsData && this.dogInsuranceProducts.indexOf(this.props.policyHolderDetailsData.product_id) > -1;
    }

    render() {
        const {policyHolderDetailsData} = this.props;
        let DOCUMENT_USER_PHOTO = !!policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_USER_PHOTO'];
        let DOCUMENT_BANK_DETAILS_PROOF = !!policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_BANK_DETAILS_PROOF'];
        let DOCUMENT_ID = !!policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_ID'];
        let DOCUMENT_INCOME_PROOF = !!policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_INCOME_PROOF'];
        let DOCUMENT_PAN = !!policyHolderDetailsData?.uploaded_documents?.['DOCUMENT_PAN'];
        let inactive_products = [];

        return (<>
                <Row>
                    <Card className={"bg-primary w-100"} style={{height: "200px"}}/>
                </Row>
                <Container fluid className={"mt--9"}>
                    <Row>
                        <Card className={"w-100 p-4 h"}>
                            <CardTitle>
                                <Row>
                                    <Col md="6" xl="3">
                                        <h1>
                                            {this.props.policyHolderDetailsData && Products[this.props.policyHolderDetailsData?.product_id]?.name}
                                        </h1>
                                    </Col>
                                    <Col md="6" xl="3">
                                        {this.props.policyHolderDetailsData?.are_documents_required &&
                                        <Badge className="rounded-20 p-3"
                                               color={this.props.policyHolderDetailsData && this.props.policyHolderDetailsData.is_documentation_complete ? "success" : "danger"}>
                                            {this.props.policyHolderDetailsData?.is_documentation_complete ? "Documents Complete" : "Documents Pending"}
                                        </Badge>
                                        }
                                    </Col>

                                </Row>

                            </CardTitle>
                            <hr className={"p-0 m-0"}/>

                            <CardBody>
                                <Row>
                                    <Col lg="3" md="3" xl="3">
                                        <h4 className={"font-weight-400 text-gray"}>
                                            Order ID
                                        </h4>
                                        <h3>
                                            {this.props.match.params.id}
                                        </h3>
                                    </Col>
                                    <Col lg="7" md="7" xl="7">
                                        <h4 className={"font-weight-400 text-gray"}>
                                            Product Name
                                        </h4>
                                        <div className={"d-flex justfy-content-start pr-3 flex-wrap "}>
                                            {this.props.policyHolderDetailsData && Object.values(this.props.policyHolderDetailsData.products).map((product, index) => {
                                                let product_id = Object.keys(this.props.policyHolderDetailsData.products)[index];
                                                inactive_products = [...inactive_products, product.policy_number === null ? Products[product_id]?.name : null]
                                                return <Badge key={index + 'dfgd'} className="btn-icon btn-2 rounded-20 p-3 my-1"
                                                              color={product.policy_creation_ts ? (!product.is_cancelled ? (product.policy_number !== null ? "success" : "primary") : "danger" ) : "info" }>
                                                    {/*{Products[product_id]?.name}*/}
                                                    {Products[this.props.policyHolderDetailsData?.product_id]?.name}
                                                </Badge>
                                            })}
                                        </div>

                                    </Col>
                                    <Col lg={"2"} md={"2"} className={"d-flex m-auto"}>
                                        <Button color="primary"
                                                disabled={this.state.error !== '' || this.props.policyHolderDetailsData?.is_cancelled}
                                                outline
                                                className="ml-auto px-4" type="button"
                                                size="sm"
                                                onClick={e => {
                                                    this.onClickEditDetails()
                                                }}
                                        >
                                            {!this.state.editDetails ? "Edit" : "Save"}
                                        </Button>
                                        {this.state.editDetails &&
                                        <Button color="primary"
                                                outline
                                                className="" type="button"
                                                size="sm"
                                                onClick={e => {
                                                    this.onClickCancelEdit()
                                                }}
                                        >
                                            X
                                        </Button>
                                        }
                                    </Col>
                                </Row>
                                <Row className={" mt-4"}>
                                    <Col lg="3">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Name
                                        </h4>
                                        <input type="text"
                                               className={"form-control " + (!this.state.editDetails ? " bg-transparent border-0 shadow-none text-dark p-0 mt--3 fnt-16" : " bg-transparent border-1 text-dark")}
                                               placeholder="Name" defaultValue={policyHolderDetailsData?.name}
                                               onChange={(e) => this.onValueChange(e, 'name')}
                                               disabled={!this.state.editDetails}/>
                                    </Col>

                                    <Col lg="2">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Phone Number
                                        </h4>
                                        <input type="text"
                                               className={"form-control " + (!this.state.editDetails ? " bg-transparent border-0 shadow-none text-dark p-0 mt--3 fnt-16" : " bg-transparent border-1 text-dark")}
                                               placeholder="Mobile"
                                               defaultValue={policyHolderDetailsData?.mobile}
                                               onChange={(e) => this.onValueChange(e, 'mobile', true)}
                                               disabled={!this.state.editDetails}/>
                                        {this.state.error !== '' &&
                                        <h6 className={"text-danger"}>
                                            {this.state.error}
                                        </h6>
                                        }
                                    </Col>

                                    <Col lg="4">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Email ID
                                        </h4>
                                        <input type="text"
                                               className={"form-control " + (!this.state.editDetails ? " bg-transparent border-0 shadow-none text-dark p-0 mt--3 fnt-16" : " bg-transparent border-1 text-dark")}
                                               placeholder="Email" defaultValue={policyHolderDetailsData?.email}
                                               onChange={(e) => this.onValueChange(e, 'email')}
                                               disabled={!this.state.editDetails}/>
                                    </Col>

                                    <Col lg="3">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Seller
                                        </h4>
                                        <h4 className={"font-weight-400 text-dark m-0 fnt-16"}>
                                            {policyHolderDetailsData?.agent ? policyHolderDetailsData?.agent : "N/A"}
                                        </h4>
                                    </Col>

                                </Row>

                                <Row className={" mt-4"}>
                                    {policyHolderDetailsData?.partner != null && <Col lg="3" md="3" xl="3">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Partner
                                        </h4>
                                        <span className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.partner}</span>
                                    </Col>}

                                    {policyHolderDetailsData?.response_source && <Col lg="3" md="3" xl="3">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Dealer Id
                                        </h4>
                                        <span className={"text-dark p-0 mt--2 fnt-16"}>{policyHolderDetailsData?.manufacturer_detail?.dealer_id}</span>
                                    </Col>}

                                    {/* <Col lg="3">
                                        <h4 className={"font-weight-400 text-gray fnt-12"}>
                                            Seller
                                        </h4>
                                        <h4 className={"font-weight-400 text-dark m-0 fnt-16"}>
                                            {policyHolderDetailsData?.agent ? policyHolderDetailsData?.agent : "N/A"}
                                        </h4>
                                    </Col> */}

                                </Row>

                            </CardBody>

                        </Card>
                    </Row>

                    <Row>
                        <Col lg="9" md="9" xl="9" className={"pl-0"}>
                            <Card>
                                <CardTitle className={"mb-0"}>
                                    {(this.isLifeInsuranceProduct() ? this.state.toffeePlanTabs : this.isDogInsuranceProduct() ? this.state.dogInsuranceTabs : this.state.tabs).map((tab, index) => {
                                        return <Button
                                            key={index + 'sdfd'}
                                            className={"shadow-none rounded-0 fnt-20 " + (tab.key === this.state.selectedTab ? "text-primary" : "text-gray")}
                                            size="lg"
                                            outline
                                            type="button" onClick={e => {
                                            e.preventDefault()
                                            this.setState({
                                                selectedTab: tab.key
                                            })
                                        }}>
                                            {tab.title}
                                        </Button>
                                    })}
                                    <hr className={"p-0 m-0 "}/>
                                </CardTitle>
                                <CardBody>
                                    {this.state.selectedTab === "details" &&
                                    <DetailsTab
                                        userData={policyHolderDetailsData}
                                        loading={this.props.policyHolderDetailsLoading}
                                        order_id={this.props.match.params.id}
                                    />
                                    }
                                    {this.state.selectedTab === "documents" &&
                                    <DocumentsTab
                                        documentData={policyHolderDetailsData}
                                        order_id={this.props.match.params.id} refresh={this.getPolicyData}/>
                                    }
                                    {this.state.selectedTab === "order_documents" &&
                                    <OrderDocumentsTab
                                        documentData={policyHolderDetailsData}
                                        uploadedDocuments={policyHolderDetailsData?.uploaded_documents}
                                        order_id={this.props.match.params.id} refresh={this.getPolicyData}/>
                                    }
                                    {this.state.selectedTab === "policy_details" &&
                                    <PolicyDetails
                                        policyData={policyHolderDetailsData}
                                        order_id={this.props.match.params.id} refresh={this.getPolicyData}/>
                                    }
                                    {this.state.selectedTab === "transaction" &&
                                    <Transactions
                                        order_id={this.props.match.params.id}
                                    />
                                    }
                                </CardBody>
                            </Card>
                        </Col>


                        {/* ----------------- Action Center ---------------*/}


                        <Col lg="3" md="3" xl="3" className={"pr-0"}>
                            <Card className={"p-4"}>
                                <CardTitle>
                                    <h3>
                                        Action Center
                                    </h3>
                                    <div className={"d-flex justify-content-start"}>
                                        <Button color="primary"
                                                outline
                                                className="px-4"
                                                size="sm"
                                                type="button"
                                                disabled={this.props.policyHolderDetailsData?.is_cancelled}
                                                onClick={e => {
                                                    this.sendMsgClick()
                                                }}>
                                            Send Message
                                        </Button>
                                        <Button color="primary"
                                                outline
                                                className="px-4"
                                                size="sm"
                                                type="button"
                                                onClick={e => {
                                                    this.openRemarkDialog()
                                                }}>
                                            Add Remark
                                        </Button>
                                    </div>
                                    <hr className={"p-0 mt-4 m-0"}/>
                                </CardTitle>
                                <CardBody>
                                    <div
                                        className="timeline timeline-one-side"
                                        data-timeline-axis-style="dashed"
                                        data-timeline-content="axis"
                                    >
                                        {policyHolderDetailsData?.remarks.map((remark) => {
                                                return <div className="timeline-block">
                                                <span className="timeline-step bg-white">
                                                        <img alt="Image"
                                                             src={require("../../../assets/img/icons/userIcon.svg")}
                                                             className={"bg-transparent"}
                                                        />
                                                </span>
                                                    <div className="timeline-content">
                                                        <h5 className="mt-0 mb-1">{remark.id}</h5>
                                                        <small className="text-muted font-weight-bold">
                                                            {remark.date}, {" "} {remark.time}
                                                        </small>
                                                        <p className="text-sm mt-2 mb-0">
                                                            {remark.remark}
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                        )}
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={this.state.sendMsgDialog}
                        toggle={() => {
                            this.setState({
                                sendMsgDialog: !this.state.sendMsgDialog
                            })
                        }}
                    >
                        <div className={"modal-header"}>
                            <select className="form-control w-50"
                                    id="exampleFormControlSelect1"
                                    size={"sm"}
                                    value={this.state.selectedTemplate}
                                    onChange={(e) => {
                                        this.handleTemplateChange(e, this.props.policyHolderDetailsData?.name, '', {
                                            'userPhoto': DOCUMENT_USER_PHOTO,
                                            'bankDetails': DOCUMENT_BANK_DETAILS_PROOF,
                                            'aadhar': DOCUMENT_ID,
                                            'incomeProof': DOCUMENT_INCOME_PROOF,
                                            'pan': DOCUMENT_PAN,
                                            'inactive_products': inactive_products,
                                            'updated_values': this.props.policyHolderDetailsData?.updated_details
                                        })
                                    }}
                            >
                                {this.state.messageOptions.map((option, index) => {
                                    return <option value={option.id}
                                                   key={index + 'ghshd'}>{option.name}</option>
                                })}

                            </select>
                            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                                this.setState({
                                    sendMsgDialog: false
                                })
                            }}/>
                        </div>
                        <hr className={"text-dark m-0 p-0"}/>

                        <div className="modal-body text-center">
                            <div className={"msg-template-box"}>
                                <Card className={"m-0"}>
                                    <CardTitle className={"mb-0 text-left p-1 d-flex justify-content-between"}>
                                        <div>
                                            {this.state.msgTabs.map((tab, index) => {
                                                return <Button
                                                    key={index + 'sdgf'}
                                                    className={"shadow-none rounded-0 border-0 fnt-12 bg-transparent " + (tab.key === this.state.selectedMsgTab ? "text-primary" : "text-gray")}
                                                    size="lg"
                                                    outline
                                                    type="button" onClick={e => {
                                                    e.preventDefault()
                                                    this.onChangeComm(tab.key)
                                                }}>
                                                    {tab.title}
                                                </Button>
                                            })}
                                        </div>
                                    </CardTitle>
                                    <hr className={"p-0 m-0 "}/>
                                    <CardBody className={"p-0 pt-1"}>
                                        <div className={"msg-template-body"}>
                                            {this.state.selectedMsgTab === 'sms' ?
                                                this.state.templateText
                                                :
                                                this.state.templateMail
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>
                        <div className="modal-footer date-modal-footer ">
                            <Button
                                color="primary"
                                data-dismiss="modal"
                                type="button"
                                size="sm"
                                onClick={() => {
                                    this.onClickSend()

                                }}
                            >
                                Send {this.state.selectedMsgTab === 'sms' ? 'Message' : 'Email'}</Button>
                        </div>
                    </Modal>

                    <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={this.state.addRemarkDialog}
                        toggle={() => {
                            this.setState({
                                addRemarkDialog: !this.state.addRemarkDialog
                            })
                        }}
                    >
                        <div className={"modal-header"}>
                            <h4>Remark</h4>
                            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                                this.setState({
                                    addRemarkDialog: false
                                })
                            }}/>
                        </div>
                        <hr className={"text-dark m-0 p-0"}/>

                        <div className="modal-body text-center">
                            <div className={"remark-box"}>
                                <Card className={"m-0"}>
                                    <CardBody className={"p-0 pt-1"}>
                                        <div className={"remark-body"}>
                                            <textarea
                                                className="form-control border-0"
                                                id="exampleFormControlTextarea1"
                                                placeholder={"Add Remarks here…."}
                                                rows="10"
                                                onChange={(e) => {
                                                    this.onChangeRemark(e)
                                                }}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>
                        <div className="modal-footer date-modal-footer ">
                            <Button
                                color="primary"
                                data-dismiss="modal"
                                type="button"
                                size="sm"
                                onClick={() => {
                                    this.saveRemark(this.props.match.params.id, {remark: this.state.remarkText})
                                }}
                            >
                                Done</Button>
                        </div>
                    </Modal>


                    <Modal
                        className="modal-dialog-centered"
                        size="lg"
                        isOpen={this.state.openOtpDialog}
                        toggle={() => {
                            this.setState({
                                openOtpDialog: !this.state.openOtpDialog
                            })
                        }}
                    >
                        <div className={"modal-header"}>
                            <h4>Verify OTP</h4>
                            <i className="ni ni-fat-remove fnt-20 cursor-pointer" onClick={(e) => {
                                this.setState({
                                    openOtpDialog: false
                                })
                            }}/>
                        </div>
                        <hr className={"text-dark m-0 p-0"}/>
                        <div className="modal-body text-center">
                            <Card className={"m-0 bg-transparent shadow-none"}>
                                <CardBody className={"p-4"}>
                                    <div>
                                        <Otp
                                            otpCallBack={(otp) => {
                                                this.setState({
                                                    enteredOtp: otp,
                                                });
                                            }}
                                            resend-={false}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="modal-footer date-modal-footer ">
                            <Button
                                color="primary"
                                data-dismiss="modal"
                                type="button"
                                size="sm"
                                onClick={() => {
                                    this.onClickVerifyOtp()
                                }}
                            >
                                Verify OTP</Button>
                        </div>
                    </Modal>

                    {this.state.showLoader &&
                    <Loader msg={"Please Wait..."}/>
                    }

                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        policyHolderDetailsLoading: state.policies.policyHolderDetailsLoading,
        policyHolderDetailsData: state.policies.policyHolderDetailsData,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),

        onGetPolicyHolderDetails: (id) => dispatch(actions.getPolicyHolderDetails(id)),

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPolicies);