import React, { Fragment, useContext, useEffect, useReducer, useState  } from "react";
import { Container, Row, Col, BreadcrumbItem, Breadcrumb } from "reactstrap";
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

// Import all custom default exports
import useApi from '../../../hooks/useApi';
import LeadsReducer from '../../../reducers/leadsReducer';
import LeadContext from '../../../context/leadContext';
import Loader from '../../../components/CustomUi/Loader/Loader';
import AxiosRestClient from '../../../utilities/AxiosRestClient';

// Import all custom named exports
import { Utils } from '../../../constants/utils';
import { Validators } from '../../../messages/validators';
import { errorMessageCustomerDetails, initialCustomerDetailsFormState, touchFieldsCustomerDetails } from '../../../defaultValues/lead';
import { isCustomerDetailsNextButtonDisabled, buildCustomerDetailsPayload } from '../../../helpers/lead';
import { IndiaFirstLocation } from "../../../locations/indiaFirstLocations";
import { isValidDate } from '../../../helpers/lead';
import { ReducerUtils } from '../../../constants/reducers';
import CustomerDetailsForm from './CustomerDetailsForm';

const CustomerDetails = (props) => {
   // Call useApi custom hook to fetch lead details
  const leadDetails = useApi(`v2/dog_insurance/${props.match.params.id}`);
  
  const initialLeadReducerState = useContext(LeadContext);
  const [state, dispatch] = useReducer(LeadsReducer, initialLeadReducerState);
  const [form, setForm] = useState(initialCustomerDetailsFormState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorMessageCustomerDetails);
  const [touchFields, setTouchFields] = useState(touchFieldsCustomerDetails);

  useEffect(() => {
    dispatchEvent(ReducerUtils.leads.details, leadDetails);
  }, [leadDetails]);

  useEffect(() => {
    validateRequiredInput('gender');
  }, [form.gender]);

  useEffect(() => {
    validateName();
  }, [form.name]);

  useEffect(() => {
    validateMobile();
  }, [form.mobile]);

  useEffect(() => {
    validateEmail();
  }, [form.email]);

  useEffect(() => {
    getCityStateByPincode(form.pincode);
  }, [form.pincode]);

  useEffect(() => {
    validateRequiredInput('addressLine1');
  }, [form.addressLine1]);

  const dispatchEvent = (type, payload) => {
		dispatch({ type, payload });
  };

  const setStateVariables = (key, isValid, message) => {
    setFormState('hasError', !isValid);
    setErrorMessageState(key, message);
    setTouchFieldsState(key, true);
  };

  const validateRequiredInput = (fieldName) => {
    if (form[fieldName]) {
      setTouchFieldsState(fieldName, true);
      setErrorMessageState(fieldName, '');
    } else if (touchFields[fieldName] && !form[fieldName]) {
      setErrorMessageState(fieldName, Validators[fieldName].required);
    }
  };

  const validateName = () => {
    if (form.name) {
      let isValid = /^([a-zA-Z]{2,}[\s]+[a-zA-Z]{2,})*$/.test(form.name);
      setStateVariables('name', isValid, isValid ? '' : Validators.name.valid);
    } else if (touchFields.name) {
      setErrorMessageState('name', Validators.name.required);
    }
  };

  const validateMobile = () => {
    if (form.mobile) {
      let isValid = isMobilePhone(form.mobile, ['en-IN']);
      setStateVariables('mobile', isValid, isValid ? '' : Validators.mobile.valid);
    } else if (touchFields.mobile) {
      setErrorMessageState('mobile', Validators.mobile.required);
    }
  };

  const validateEmail = () => {
    if (form.email) {
      let isValid = isEmail(form.email);
      setStateVariables('email', isValid, isValid ? '' : Validators.email.valid);
    } else if (touchFields.email) {
      setErrorMessageState('email', Validators.email.required);
    }
  };

  // const validateAddressLine1 = () => {
  //   if (form.addressLine1) {
  //     setTouchFieldsState('addressLine1', true);
  //     setErrorMessageState('addressLine1', '');
  //   } else if (touchFields.addressLine1 && !form.addressLine1) {
  //     setErrorMessageState('addressLine1', Validators.addressLine1.required);
  //   }
  // };

  const cbCityState = (response=['', '']) => {
    setForm({
        ...form,
        'city': response[0],
        'state': response[1]
    });
  };

  const getCityStateByPincode = async (pincode) => {
    if (pincode) {
        let isValid = pincode.length === 6;
        let message = isValid ? '' : Validators.pincode.valid;
        setFormState('hasError', !isValid);
        setErrorMessageState('pincode', message);
        setTouchFieldsState('pincode', true);
        if (isValid) {
            const res = await new IndiaFirstLocation().get(pincode);
            if (res) {
                cbCityState(res);
            } else {
                setErrorMessageState('pincode', Validators.pincode.noData);
                cbCityState();
            }
        } else {
            cbCityState();
        }
    } else {
        cbCityState();
        if (touchFields.pincode) {
            setErrorMessageState('pincode', Validators.pincode.required);
        }
    }
  };

  const setFormState = function (name, value) {
    setForm({ ...form, [name]: value });
  };

  const setErrorMessageState = (name, value) => {
    setErrorMessage({ ...errorMessage, [name]: value });
  };

  const setTouchFieldsState = (name, value) => {
    setTouchFields({ ...touchFields, [name]: value });
  };

  const validateInputLength = (event) => {
    let elName = event.target.name;
    let elValue = event.target.value;

    if (elValue.length > Utils.limit.digits[elName]) {
        event.target.value = elValue.substring(0, Utils.limit.digits[elName]);
    }
  };
  
  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  // Date change handler
  const handleDateChange = (date, keyname) => {
    setFormState(keyname, date);
  };

  const showMessage = (message) => {

  };

  const navigateTo = (pathname) => {
    props.history.push(pathname);
  };

  const cb = ({ data, message }) => {
    setIsFormSubmitted(false);
    showMessage(message);
    navigateTo(`/admin/pet/dog/${props.match.params.id}/dog`);
  };

  const updateDetails = async () => {
    setIsFormSubmitted(true);
    const payload = buildCustomerDetailsPayload(form, form.dob);
    const response = await AxiosRestClient.putRequest(`v2/dog_insurance/${props.match.params.id}`, payload);
  
    cb(response.data);
  };
	
	return (
		<Fragment>
			<Container fluid>
				<div className="header-body">
					<Row className="align-items-center py-4">
						<Col lg="8" xs="6">
							<Breadcrumb
								className="d-none d-md-inline-block "
								listClassName="breadcrumb-links"
								>
								<BreadcrumbItem>
									<a href="#pablo" onClick={(e) => e.preventDefault()}>
									<i className="fas fa-home"/>
									</a>
								</BreadcrumbItem>
								<BreadcrumbItem>
									<a
										href="#pablo"
										onClick={(e) => {
									e.preventDefault();
									}}
									>
									Dog Insurance - Customer Details
									</a>
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
						<Col className="text-right" lg="4" xs="6">
							
						</Col>
					</Row>
				</div>
        
        {!state.leadDetails ? 
        <Loader msg={"Please Wait..."}/> :
        <LeadContext.Provider value={{ state, dispatch }}>
          <CustomerDetailsForm
            errorMessage={errorMessage}
            form={form}
            handleChange={handleChange}
            updateDetails={updateDetails}
            handleDateChange={handleDateChange}
            isCustomerDetailsNextButtonDisabled={isCustomerDetailsNextButtonDisabled}
            isFormSubmitted={isFormSubmitted}
            isValidDate={isValidDate}
            setForm={setForm}
            validateInputLength={validateInputLength}
          />
        </LeadContext.Provider>}
			</Container>
		</Fragment>
	);
};

export default CustomerDetails;