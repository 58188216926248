import React, {Component, Fragment} from 'react';
import * as actions from "../../../Store/actions";
import {connect} from "react-redux";
import {Button, Card, CardBody, Col, Modal, Row} from "reactstrap";
import {IndiaFirstLocation} from "../../../locations/indiaFirstLocations";
import AxiosReactClient from "../../../utilities/AxiosRestClient";
import Loader from "../../../components/CustomUi/Loader/Loader";
import moment from "moment";
import ReligareQuotation from "./religareQuotation";
import CycleQuotation from "./cycleQuotation";

const mobileRegex = /^([0]|\+91)?[6789]\d{9}$/;

class CreateQuotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: 'cycle',
        }
    }

    onProductChange = (e) => {
        this.setState({
            product: e.target.value
        })
    }

    showMsg = (msg, type) => {
        this.props.onShowNotification({
            message: msg,
            title: "",
            type: type
        })
    };


    render() {
        console.log(moment().subtract(12, 'years').format("YYYY-MM-DD"))
        return (
            <Fragment>
                {this.state.showLoader &&
                <Loader msg={"Please Wait..."}/>
                }
                <div className={"container-fluid"}>
                    <Row>
                        <Col lg={6}>
                            <div className={'my-3 '}>
                                <h2 className={'mb-3'}>Create Quotation</h2>
                                <select
                                    className="form-control"
                                    value={this.state.product}
                                    onChange={(e) => {
                                        this.onProductChange(e)
                                    }}>
                                    <option value={'cycle'}>Cycle Policy</option>
                                    <option value={'health'}>Religare Health Policy</option>
                                </select>

                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {this.state.product === 'health' &&
                        <ReligareQuotation/>
                        }
                        {this.state.product === 'cycle' &&
                        <CycleQuotation/>
                        }
                    </Row>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        quotationListLoading: state.policies.quotationListLoading,
        quotationListData: state.policies.quotationListData,
        quotationTotalCount: state.policies.quotationTotalCount,

    }
};
const mapDispatchToProps = dispatch => {
    return {
        onShowNotification: (notification) => dispatch(actions.setNotificationData(notification)),
        onGetPolicyQuotationList: (filter, params) => dispatch(actions.getPolicyQuotationList(filter, params)),


    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotation);